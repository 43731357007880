/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useMemo } from "react";

import type { FC } from "react";

type InvalidFileSize = "L" | "S";
type Props = {
    size: InvalidFileSize;
};

export const InvalidFile: FC<Props> = ({ size }) => {
    const sizeVal = useMemo(() => {
        switch (size) {
            case "S":
                return "100";
            case "L":
                return "250";
        }
    }, [size]);

    return (
        <svg
            width={sizeVal}
            height={sizeVal}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M82.1002 92.9995H25.8002C23.4002 92.9995 21.3002 91.0995 21.2002 88.5995V78.5995C21.2002 77.6995 21.9002 77.0995 22.7002 77.0995C23.6002 77.0995 24.2002 77.7995 24.2002 78.5995V88.7995C24.3002 89.5995 24.9002 90.1995 25.7002 90.0995H82.0002C82.8002 90.0995 83.4002 89.5995 83.5002 88.7995V11.3995C83.4002 10.5995 82.8002 9.99953 82.0002 10.0995H25.8002C25.0002 10.0995 24.4002 10.5995 24.3002 11.3995V53.3995C24.3002 54.2995 23.6002 54.8995 22.8002 54.8995C21.9002 54.8995 21.3002 54.1995 21.3002 53.3995V11.3995C21.4002 8.99953 23.4002 6.89953 25.9002 6.99953H82.2002C84.6002 6.99953 86.7002 8.89953 86.8002 11.3995V88.7995C86.6002 91.1995 84.6002 93.0995 82.1002 92.9995Z"
                fill="#B1B1B1"
            />
            <path
                d="M30.0001 75.2992C30.6001 75.8992 31.5001 75.8992 32.2001 75.2992C32.8001 74.6992 32.8001 73.7992 32.2001 73.0992L25.2001 66.0992L32.2001 59.0992C32.8001 58.4992 32.8001 57.5992 32.2001 56.8992C31.6001 56.2992 30.7001 56.2992 30.0001 56.8992L23.0001 63.8992L16.0001 56.8992C15.4001 56.2992 14.5001 56.2992 13.8001 56.8992C13.2001 57.4992 13.2001 58.3992 13.8001 59.0992L20.8001 66.0992L13.8001 73.0992C13.2001 73.6992 13.2001 74.5992 13.8001 75.2992C14.4001 75.8992 15.3001 75.8992 16.0001 75.2992L23.0001 68.2992L30.0001 75.2992Z"
                fill="#B1B1B1"
            />
        </svg>
    );
};

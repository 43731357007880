/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export type ModelInfoType = {
    bbMin: number[];
    bbMax: number[];
    bbCenter: number[];
    bbSize: number[];
    area3D: number;
    numVertices: number;
    numVerticesSingleIndexed: number;
    numTriangles: number;
    numQuads: number;
    numMeshes: number;
    numMaterials: number;
    numNodes: number;
    numLights: number;
    numSubMeshes: number;
    hasRigidAnimations: boolean;
    numRigidAnimations: number;
    hasSkinnedAnimations: boolean;
    hasMorphTargets: boolean;
    animations: any[];
    extensionsUsed: any[];
    extensionsUnsupported: any[];
    numTexels: number;
    hasUVs: boolean;
    hasVertexColors: boolean;
    hasVertexNormals: boolean;
    hasVertexTangents: boolean;
    hasVertexBitangents: boolean;
    hasTSNormalMap: boolean;
    hasOSNormalMap: boolean;
    hasBaseColorMap: boolean;
    hasMetallicMap: boolean;
    hasRoughnessMap: boolean;
    hasOcclusionMap: boolean;
    hasDisplacmentMap: boolean;
    hasEmissiveMap: boolean;
    hasClearcoatMap: boolean;
    hasClearcoatColorMap: boolean;
    hasClearcoatRoughnessMap: boolean;
    hasClearcoatNormalMap: boolean;
    hasTransmissionMap: boolean;
    hasSheenColorMap: boolean;
    hasSheenRoughnessMap: boolean;
    hasSpecularValueMap: boolean;
    hasSpecularColorMap: boolean;
    hasThicknessMap: boolean;
    hasIridescenceMap: boolean;
    hasIridescenceThicknessMap: boolean;
    numTSNormalMaps: number;
    numOSNormalMaps: number;
    numBaseColorMaps: number;
    numMetallicMaps: number;
    numRoughnessMaps: number;
    numOcclusionMaps: number;
    numDisplacmentMaps: number;
    numEmissiveMaps: number;
    numClearcoatMaps: number;
    numClearcoatColorMaps: number;
    numClearcoatRoughnessMaps: number;
    numClearcoatNormalMaps: number;
    numTransmissionMaps: number;
    numSheenColorMaps: number;
    numSheenRoughnessMaps: number;
    numSpecularValueMaps: number;
    numSpecularColorMaps: number;
    numThicknessMaps: number;
    numIridescenceMaps: number;
    numIridescenceThicknessMaps: number;
    avgAngleDistortion: number;
    maxAngleDistortion: number;
    displacementReferenceMin: number;
    displacementReferenceMax: number;
};

export type ModelBoundsInfoType = {
    bbMin: number[];
    bbMax: number[];
    bbCenter: number[];
    bbSize: number[];
    units: string;
    metersPerUnit: number;
};

export enum HealthCheckStatus {
    fail = 1,
    warn,
    pass,
}

export enum HealthCheckAttribute {
    fileSize = "fileSize",
    drawCalls = "drawCalls",
    polygons = "polygons",
    materials = "materials",
    meshes = "meshes",
    textures = "textures",
}
export type CriteriaResult = {
    value: number;
    status: HealthCheckStatus;
};

export const HEALTH_CHECK_FORMAT_VERSION = 2;

export type HealthCheckResults = {
    formatVersion: number;
    fileSize: CriteriaResult;
    drawCalls: CriteriaResult;
    polygons: CriteriaResult;
    materials: CriteriaResult;
    meshes: CriteriaResult;
    textures: CriteriaResult;
    cumulativeStatus: HealthCheckStatus;
};

/** @deprecated */
export interface HealthCheckResultsV1 {
    web?: HealthCheckResults;
    ar?: HealthCheckResults;
}

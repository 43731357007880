/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Flex,
    Heading,
    Item,
    Menu,
    MenuTrigger,
} from "@adobe/react-spectrum";
import {
    ALL_PROJECTS_FILTER_VALUE,
    PROJECT_FILTER_VALUES,
} from "@shared/types";
import Filter from "@spectrum-icons/workflow/Filter";
import { useTranslation } from "react-i18next";

import { ProjectsList } from "./ProjectsList";
import { useUIStateContext } from "@src/contexts/UIStateContext";

import type { Key } from "@react-types/shared";

export type ProjectsFilters =
    | typeof ALL_PROJECTS_FILTER_VALUE
    | (typeof PROJECT_FILTER_VALUES)[number];

export const ProjectsLeftNav = () => {
    const { t } = useTranslation(["studio"]);
    const { projectFilterSelection, setProjectFilterSelection } =
        useUIStateContext();

    const filters = [...PROJECT_FILTER_VALUES, ALL_PROJECTS_FILTER_VALUE].map(
        (filterKey) => {
            return {
                key: filterKey,
                name: t(`studio:filter.${filterKey}`),
            };
        },
    );

    return (
        <Flex
            direction="column"
            width="100%"
            height="100%"
            rowGap="size-150"
            alignItems="start">
            <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center">
                <Heading level={4} marginStart="size-150">
                    {t("studio:project.title")}
                </Heading>
                <MenuTrigger closeOnSelect>
                    <ActionButton isQuiet>
                        <Filter size="S" />
                    </ActionButton>
                    <Menu
                        items={filters}
                        selectionMode="single"
                        disallowEmptySelection
                        selectedKeys={new Set([projectFilterSelection as Key])}
                        onSelectionChange={(selection) => {
                            if (selection !== "all") {
                                setProjectFilterSelection(
                                    [...selection][0] as ProjectsFilters,
                                );
                            }
                        }}>
                        {(item) => <Item key={item.key}>{item.name}</Item>}
                    </Menu>
                </MenuTrigger>
            </Flex>
            <ProjectsList filterKey={projectFilterSelection}/>
        </Flex>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";
import { ASSET_COMPOSITE_TYPE } from "@shared/types";
import { useContext } from "react";

import { ShareSheetView } from "./ShareSheet";
import { ThemeContext } from "@src/contexts/ThemeContext";

import type { FC } from "react";

/**
 * This component renders the share sheet within a modal dialog for sharing 3D assets.
 */
interface Props {
    id: string;
    name: string;
    closeFunction: VoidFunction;
}

export const ShareAsset: FC<Props> = ({ id, name, closeFunction }) => {
    const { isDarkMode } = useContext(ThemeContext);

    return (
        <View
            UNSAFE_className="modal-share-container"
            width="size-4600"
            minHeight="size-4600"
            UNSAFE_style={{
                backgroundColor: isDarkMode ? "#1e1e1e" : "#ffffff",
            }}>
            <ShareSheetView
                id={id}
                assetType={ASSET_COMPOSITE_TYPE}
                assetName={name}
                closeShareViewFunction={closeFunction}
            />
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Text, View } from "@adobe/react-spectrum";
import { matchPath, useLocation } from "react-router-dom";
import Box from "@spectrum-icons/workflow/Box";
import CheckmarkCircleOutline from "@spectrum-icons/workflow/CheckmarkCircleOutline";
import RotateCW from "@spectrum-icons/workflow/RotateCW";
import User from "@spectrum-icons/workflow/User";
import { useTranslation } from "react-i18next";

import { AssetsFilterItem } from "./AssetsFilterItem";
import { rpc } from "@src/contexts/RpcContext";

import { useEffect, type ReactElement } from "react";
import { useUIStateContext } from "@src/contexts/UIStateContext";
import { useRedirects } from "@src/interfaces/common/hooks/useRedirects";
import { Routes } from "@src/routes";

const filters = ["all", "reviewable", "approved", "myAssets"] as const;
export type AssetListKey = typeof filters[number];

export const AssetsFilter = () => {
    const { t } = useTranslation(["library"]);
    const { assetsLibraryRedirect } = useRedirects();
    const { data: assetCount } = rpc.libraryAssets.getCount.useQuery({});
    const { data: approvedCount } = rpc.libraryAssets.getCount.useQuery({
        filters: ["approved"],
    });
    const { data: reviewableCount } = rpc.libraryAssets.getCount.useQuery({
        filters: ["reviewable"],
    });
    const { data: myAssetsCount } = rpc.libraryAssets.getCount.useQuery({
        filters: ["myAssets"],
    });

    const { assetFilterSelection, setAssetFilterSelection } =
        useUIStateContext();

    function getFilterData(filter: AssetListKey) {
        switch (filter) {
            case "all":
                return {
                    count: assetCount ?? 0,
                    icon: <Box size="S" marginEnd="size-100" />,
                };
            case "reviewable":
                return {
                    count: reviewableCount ?? 0,
                    icon: <RotateCW size="S" marginEnd="size-100" />,
                };
            case "approved":
                return {
                    count: approvedCount ?? 0,
                    icon: (
                        <CheckmarkCircleOutline size="S" marginEnd="size-100" />
                    ),
                };
            case "myAssets":
                return {
                    count: myAssetsCount ?? 0,
                    icon: <User size="S" marginEnd="size-100" />,
                };
                break;
        }
    }

    const location = useLocation();

    function onSelect(key: AssetListKey) {
        if (!matchPath(Routes.library.path, location.pathname)) {
            assetsLibraryRedirect();
        }
        setAssetFilterSelection(key);
    }

    useEffect(() => {
        if (
            matchPath(Routes.search.path, location.pathname) &&
            assetFilterSelection !== "all"
        ) {
            setAssetFilterSelection("all");
        }
    }, [location.pathname]);

    return (
        <Flex
            direction="column"
            width="100%"
            aria-label={t("library:navigation.assetLeftNav.label")}>
            {filters.map((value) => {
                const additionalData = getFilterData(value);
                return (
                    <AssetsFilterItem
                        key={value}
                        filterKey={value}
                        filterSelected={assetFilterSelection}
                        setSelectedFilter={onSelect}
                        filterLabel={t(`library:navigation.${value}`)}>
                        <Flex
                            columnGap="size-100"
                            alignItems="center"
                            height="100%"
                            marginX="size-150">
                            <View flex="none">{additionalData?.icon}</View>
                            <Text flex={1}>
                                {t(`library:navigation.${value}`)}
                            </Text>
                            <Text flex="none">{additionalData?.count}</Text>
                        </Flex>
                    </AssetsFilterItem>
                );
            })}
        </Flex>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";
import { ShellWrapper } from "workfront-task-panel/src/workfront/ShellWrapper/ShellWrapper";

import { BaseInfoPanel } from "../../common/BaseInfoPanel";
import { config } from "@src/config";
import { rpc } from "@src/contexts/RpcContext";

export function WorkfrontPanel() {
    const { data } = rpc.orgs.getConfigs.useQuery();

    return (
        <BaseInfoPanel>
            <View height="100%">
                {data?.aemConfig?.enabled && (
                    <ShellWrapper
                        configuration={{
                            imsConfig: {
                                imsEnv: "PROD",
                                clientId: data.aemConfig.imsClientId,
                                imsScope: data.aemConfig.imsScope,
                            },
                            requiredAuth: true,
                            wfInstanceUrl: `${window.location.protocol}//${window.location.host}/api/proxy/wf`,
                        }}
                        sharedContext={{
                            imsToken: ""
                        }}
                        editorState={{
                            editables: []
                        }}
                    />
                )}
            </View>
        </BaseInfoPanel>
    );
}

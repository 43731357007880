/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Divider,
    Flex,
    Text,
} from "@adobe/react-spectrum";
import { HealthCheckStatus } from "@shared/types";
import AlertCircleFilled from "@spectrum-icons/workflow/AlertCircleFilled";
import Info from "@spectrum-icons/workflow/Info";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { HealthCheckSection } from "./HealthCheckSection";
import { useFeatureFlagContext } from "@src/contexts/FeatureFlagContext";
import type { LibraryAsset } from "@src/contexts/RpcContext";
import { useIsOptimized } from "@src/interfaces/detail/hooks/useIsOptimized";

import type { FC } from "react";

interface Props {
    asset: LibraryAsset;
}

const leftColumnStyleOverrides = {
    fontSize: "12px",
    color: "var(--spectrum-global-color-gray-400)",
};
const rightColumnStyleOverrides = {
    fontSize: "12px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
};

export const MessagesTab: FC<Props> = ({ asset }) => {
    const { t } = useTranslation(["library"]);
    const { DFF_SHOW_HEALTH_CHECK_RESULTS } = useFeatureFlagContext();

    const { isOptimized } = useIsOptimized(asset);

    const healthCheckResults = useMemo(() => {
        if (
            !asset.modelMetaData.source?.jobCompleted &&
            !asset.modelMetaData.source?.healthCheckResults
        ) {
            return undefined;
        }
        return isOptimized
            ? asset.modelMetaData.web?.healthCheckResults
            : asset.modelMetaData.source?.healthCheckResults;
    }, [
        isOptimized,
        asset.modelMetaData.source?.healthCheckResults,
        asset.modelMetaData.source?.jobCompleted,
        asset.modelMetaData.web?.healthCheckResults,
    ]);

    const everythingLooksGood = useMemo(() => {
        if (!healthCheckResults) {
            return false;
        }
        const healthCheckPassed =
            healthCheckResults.formatVersion === 1
                ? healthCheckResults.cumulativeStatus !== HealthCheckStatus.fail
                : healthCheckResults.cumulativeStatus ===
                  HealthCheckStatus.pass;
        return healthCheckPassed && !DFF_SHOW_HEALTH_CHECK_RESULTS;
    }, [healthCheckResults, DFF_SHOW_HEALTH_CHECK_RESULTS]);

    const infoCount = useMemo(() => {
        let count = 0;
        if (
            (healthCheckResults &&
                healthCheckResults.cumulativeStatus !==
                    HealthCheckStatus.pass) ||
            DFF_SHOW_HEALTH_CHECK_RESULTS
        ) {
            count++;
        }
        return count;
    }, [healthCheckResults, DFF_SHOW_HEALTH_CHECK_RESULTS]);

    const warnCount = 0;

    return (
        <>
            <Flex direction="column" gap="size-75">
                <Text
                    UNSAFE_style={{
                        fontSize: "12px",
                    }}>
                    {t("detailView.messagesPanel.title.optimizedVariants")}
                </Text>
                <Flex alignItems="center" columnGap="size-50">
                    <Text
                        width="size-500"
                        UNSAFE_style={leftColumnStyleOverrides}>
                        {t("detailView.title.2D")}
                    </Text>
                    <Text UNSAFE_style={rightColumnStyleOverrides}>
                        {t("detailView.messagesPanel.originalFile", {
                            file: `${asset.name}${
                                asset.modelMetaData.source?.fileExtension ?? ""
                            }`,
                            interpolation: {
                                escapeValue: false,
                            },
                        })}
                    </Text>
                </Flex>
                {(healthCheckResults || isOptimized) && (
                    <>
                        <Flex alignItems="center" columnGap="size-50">
                            <Text
                                width="size-500"
                                UNSAFE_style={leftColumnStyleOverrides}>
                                {t("detailView.title.3DWeb")}
                            </Text>
                            <Text UNSAFE_style={rightColumnStyleOverrides}>
                                {isOptimized
                                    ? t(
                                          "detailView.messagesPanel.optimizedFile",
                                          {
                                              formats: "GLB",
                                          },
                                      )
                                    : t(
                                          "detailView.messagesPanel.convertedFile",
                                          {
                                              formats: "GLB",
                                          },
                                      )}
                            </Text>
                        </Flex>
                        <Flex alignItems="center" columnGap="size-50">
                            <Text
                                width="size-500"
                                UNSAFE_style={leftColumnStyleOverrides}>
                                {t("detailView.title.AR")}
                            </Text>
                            <Text UNSAFE_style={rightColumnStyleOverrides}>
                                {isOptimized
                                    ? t(
                                          "detailView.messagesPanel.optimizedFile",
                                          {
                                              formats: "GLB, USDZ",
                                          },
                                      )
                                    : t(
                                          "detailView.messagesPanel.convertedFile",
                                          {
                                              formats: "GLB, USDZ",
                                          },
                                      )}
                            </Text>
                        </Flex>
                    </>
                )}
            </Flex>
            <Divider size="S" marginTop="size-50" marginBottom="size-200" />

            {everythingLooksGood ? (
                /* Removed everything looks good text and illustration */
                <span />
            ) : (
                healthCheckResults && (
                    <>
                        <div style={{ textAlign: "center" }}>
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                columnGap="size-50"
                                marginBottom="size-150">
                                <Info size="S" color="informative" />
                                <Text
                                    marginEnd="size-100"
                                    UNSAFE_style={{
                                        fontSize: "12px",
                                    }}>
                                    {`(${infoCount})`}
                                </Text>
                                <AlertCircleFilled size="S" color="notice" />
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "12px",
                                    }}>
                                    {`(${warnCount})`}
                                </Text>
                            </Flex>
                            <Text
                                UNSAFE_style={{
                                    fontSize: "12px",
                                }}>
                                {t(
                                    "detailView.messagesPanel.description.messages",
                                )}
                            </Text>
                        </div>
                        <HealthCheckSection results={healthCheckResults} />
                    </>
                )
            )}
        </>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, Text } from "@adobe/react-spectrum";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AssetCardActionMenu } from "./AssetActionCardMenu";
import { useUserLibraryContext } from "../UserLibrary";
import { rpc } from "@src/contexts/RpcContext";

import type { FC } from "react";

interface Props {
    assetId: string;
    assetName: string;
}

export const AssetCardHeader: FC<Props> = ({ assetId, assetName }) => {
    const { t } = useTranslation(["library", "common"]);
    const { setAssetToUpgrade } = useUserLibraryContext();

    const { data: assetData } = rpc.libraryAssets.getCardItem.useQuery(assetId);

    const validModels = useMemo(() => {
        if (
            !assetData ||
            !assetData.uploadComplete ||
            assetData.uploadFailed ||
            !assetData.jobsSubmitted ||
            assetData.hasValidationErrors
        ) {
            return "";
        }

        let validString = "";
        if (assetData.isWebReady) {
            validString += t("detailView.title.3DWeb");
        }
        if (assetData.isArReady) {
            if (validString.length) {
                validString += " \u2022 ";
            }
            validString += t("detailView.title.AR");
        }
        if (assetData.is2dReady) {
            if (validString.length) {
                validString += " \u2022 ";
            }
            validString += t("detailView.title.2D");
        }

        return validString;
    }, [
        assetData,
        assetData?.uploadComplete,
        assetData?.uploadFailed,
        assetData?.jobsSubmitted,
        assetData?.hasValidationErrors,
        assetData?.is2dReady,
        assetData?.isArReady,
        assetData?.isWebReady,
    ]);

    return (
        <Flex
            justifyContent="space-between"
            alignItems="center"
            minHeight="50px">
            <Flex direction="column" marginBottom="size-100">
                <Heading
                    data-uia="asset-card-name"
                    level={4}
                    alignSelf="auto"
                    // This calc is needed because there is no size-2200 variable.
                    width="calc((size-2000 + size-2400) / 2)"
                    UNSAFE_style={{
                        fontWeight: 600,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}>
                    {assetData?.name}
                </Heading>
                <Text>{validModels}</Text>
            </Flex>
            <AssetCardActionMenu
                id={assetId}
                name={assetName}
                setAssetToUpgrade={setAssetToUpgrade}
            />
        </Flex>
    );
};

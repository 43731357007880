/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Button,
    Content,
    ContextualHelp,
    DialogContainer,
    DialogTrigger,
    Divider,
    Flex,
    Text,
    Tooltip,
    TooltipTrigger,
} from "@adobe/react-spectrum";
import CloseCircle from "@spectrum-icons/workflow/CloseCircle";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { ApprovalPicker } from "../../../common/components/ApprovalPicker";
import { DetailViewNav } from "../common/DetailViewNav";
import { ShareAsset } from "../share/ShareAsset";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";
import { useFeatureFlagContext } from "@src/contexts/FeatureFlagContext";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import { rpc } from "@src/contexts/RpcContext";
import { useUploadDownloadContext } from "@src/contexts/UploadDownloadContext";
import { ProcessProgressBar } from "@src/interfaces/common/components/AssetProgressBars";
import { AssetStatusLight } from "@src/interfaces/common/components/AssetStatusLight";
import { useDoNotShowMeAgain } from "@src/interfaces/common/hooks/useDoNotShowMeAgain";
import { useIsChromeless } from "@src/interfaces/common/hooks/useIsChromeless";
import { useRedirects } from "@src/interfaces/common/hooks/useRedirects";
import { useUpload } from "@src/interfaces/common/hooks/useUpload";
import { useVersionUpgrade } from "@src/interfaces/common/hooks/useVersionUpgrade";
import { AssetCardActionMenu } from "@src/interfaces/library/components/AssetActionCardMenu";
import { AssetApprovalDialog } from "@src/interfaces/library/components/AssetApprovalDialog";
import { UploadDialog } from "@src/interfaces/library/components/UploadDialog";
import { UploadStatusEvent } from "@src/lib/upload/Upload";
import type { UploadEmitEventData } from "@src/lib/upload/UploadManager";

import type { DetailViewType } from "../common/DetailViewTabs";
import type { TRPCClientError } from "@trpc/client";
import type { AnyRouter } from "@trpc/server";

type Props = {
    id: string;
    name: string;
    jobsCompleted: boolean;
    jobsFailed: boolean;
    onBackButtonClicked: () => void;
    currentViewType: DetailViewType;
    hasValidationErrors: boolean;
};

export const AssetDetailViewNav = ({
    id,
    name,
    jobsCompleted,
    jobsFailed,
    onBackButtonClicked,
    currentViewType,
    hasValidationErrors,
}: Props) => {
    const { t } = useTranslation(["library", "common"]);
    const { showToastForInvalidPermissions, success, error } =
        useMessagingContext();
    const { FF_ASSET_APPROVAL_CONFIRMATION } =
        useFeatureFlagContext();
    const { assetDetailsRedirectToPrevious } = useRedirects();
    const { isChromeless } = useIsChromeless();

    const { data: asset } = rpc.libraryAssets.getCardItem.useQuery(id);
    const { data: approved, isFetched: approvedIsFetched } =
        rpc.libraryAssets.isAssetApproved.useQuery(id);
    const { data: isOwner, isFetched: isOwnerIsFetched } =
        rpc.libraryAssets.isUserOwner.useQuery(id);
    const { data: predecessor } =
        rpc.libraryAssets.getAssetPredecessor.useQuery(id);

    const cancelAssetMutation = rpc.libraryAssets.cancel.useMutation();
    const updateApprovalMutation = rpc.libraryAssets.setApproval.useMutation();
    const { logIngestEvent } = useAnalyticsContext();

    const [assetToApprove, setAssetToApprove] = useState<string | undefined>();

    const { cancelUpload } = useUpload();
    const handleCancel = () => {
        if (!asset?.uploadComplete && asset?.uploadId) {
            cancelUpload(asset?.uploadId);
        }
        if (!asset?.jobsCompleted || !asset?.uploadComplete) {
            cancelAssetMutation.mutateAsync(asset?.id || "").catch((e) => {
                if (
                    (e as TRPCClientError<AnyRouter>)?.data?.code ===
                    "FORBIDDEN"
                ) {
                    showToastForInvalidPermissions();
                } else {
                    throw e;
                }
            });
        }
        assetDetailsRedirectToPrevious(currentViewType, predecessor?.id);
    };

    const showApprovalToast = (isApproved: boolean) => {
        if (isApproved) {
            success(t("detailView.approved.toProjects"));
        }
    };

    const {
        assetToUpgrade,
        setAssetToUpgrade,
        uploadId,
        setUploadId,
        handleUploadFile,
    } = useVersionUpgrade();

    const { uploadManager } = useUploadDownloadContext();
    const { libraryDetailsRedirect } = useRedirects();

    const uploadSuccessWatcher = useCallback(
        (data: UploadEmitEventData) => {
            if (uploadId && data.uploadId === uploadId) {
                setUploadId("");
                libraryDetailsRedirect({
                    assetId: data.libraryAssetId,
                    viewType: currentViewType,
                    navigateOptions: { replace: true },
                });
            }
        },
        [uploadId, currentViewType],
    );

    useEffect(() => {
        uploadManager.addListener(
            UploadStatusEvent.COMPLETED,
            uploadSuccessWatcher,
        );
        return () => {
            uploadManager.removeListener(
                UploadStatusEvent.COMPLETED,
                uploadSuccessWatcher,
            );
        };
    }, [uploadManager, uploadSuccessWatcher]);

    const [approvedState, setApprovedState] = useState(approved);
    async function updateApprovalStatus(isApproved: boolean) {
        const prevApprovalStatus = approvedState;
        // update state immediately
        setApprovedState(isApproved);
        try {
            await updateApprovalMutation.mutateAsync({
                id,
                approved: isApproved,
            });
            showApprovalToast(isApproved);
            logIngestEvent("assetStatusSetApproved");
        } catch {
            error(
                t("notification.approval.error", {
                    assetName: name,
                    interpolation: { escapeValue: false },
                }),
            );
            // Presumably the DB update didn't take, so revert the state.
            setApprovedState(prevApprovalStatus);
        }
    }

    useEffect(() => {
        if (approved !== undefined && approved !== approvedState) {
            setApprovedState(approved);
        }
    }, [approved]);

    const {
        doNotShowAssetApprovalConfirmation: {
            state: doNotShowAssetApprovalConfirmation,
        },
    } = useDoNotShowMeAgain();

    const onApprovalChange = useCallback(
        (isApproved: boolean) => {
            if (
                !FF_ASSET_APPROVAL_CONFIRMATION ||
                doNotShowAssetApprovalConfirmation
            ) {
                updateApprovalStatus(isApproved);
            } else {
                isApproved && setAssetToApprove(asset?.id);
            }
        },
        [
            FF_ASSET_APPROVAL_CONFIRMATION,
            doNotShowAssetApprovalConfirmation,
            asset?.id,
        ],
    );

    const [shouldDisableButtons, setShouldDisableButtons] =
        useState<boolean>(true);
    useEffect(() => {
        if (
            uploadId ||
            !jobsCompleted ||
            approved ||
            !approvedIsFetched ||
            hasValidationErrors
        ) {
            setShouldDisableButtons(true);
        } else {
            setShouldDisableButtons(false);
        }
    }, [
        uploadId,
        jobsCompleted,
        approved,
        approvedIsFetched,
        hasValidationErrors,
        asset?.version,
    ]);

    const showApprovalUI = useMemo(() => {
        return (
            !hasValidationErrors &&
            jobsCompleted &&
            !jobsFailed &&
            approvedState !== undefined
        );
    }, [jobsCompleted, jobsFailed, approvedState, hasValidationErrors]);

    return (
        <DetailViewNav disableBackButton={isChromeless} name={name} onBackButtonClicked={onBackButtonClicked}>
            <Flex
                alignItems="center"
                justifyContent="center"
                columnGap="size-150"
                marginEnd="size-100">
                {
                    asset &&
                    !asset.jobsCompleted &&
                    !asset.uploadFailed &&
                    !hasValidationErrors && (
                        <>
                            <ProcessProgressBar
                                version={asset.version}
                                jobsQueued={asset.jobsQueued}
                                progress={asset.jobsProgress}
                            />
                            <TooltipTrigger>
                                <ActionButton
                                    data-uia="asset-cancel-btn"
                                    aria-label={t("common:actions.cancel")}
                                    isQuiet
                                    marginStart="size-100"
                                    onPress={handleCancel}>
                                    <CloseCircle size="S" />
                                </ActionButton>
                                <Tooltip>{t("common:actions.cancel")}</Tooltip>
                            </TooltipTrigger>
                            <Divider
                                orientation="vertical"
                                size="S"
                                marginStart="size-100"
                                marginY="size-50"
                            />
                        </>
                    )}
                <AssetCardActionMenu
                    id={id}
                    name={name}
                    isDetailView={true}
                    detailViewType={currentViewType}
                    setAssetToUpgrade={setAssetToUpgrade}
                />

                {hasValidationErrors && asset && (
                    <AssetStatusLight marginTop="size-25" assetStatus={asset} />
                )}
                {showApprovalUI && approvedState !== undefined ? (
                    <>
                        <ApprovalPicker
                            dataUiaPrefix="asset-"
                            approved={approvedState}
                            setApproval={(approved) =>
                                onApprovalChange(approved)
                            }
                            disabled={shouldDisableButtons}
                        />
                        {FF_ASSET_APPROVAL_CONFIRMATION && (
                            <AssetApprovalDialog
                                assetToApprove={assetToApprove}
                                setAssetToApprove={setAssetToApprove}
                                doApprovalMutation={() =>
                                    updateApprovalStatus(true)
                                }
                            />
                        )}
                    </>
                ) : null}
                <Flex alignItems="center" columnGap="size-50">
                    <DialogTrigger type="modal">
                        <Button
                            variant="cta"
                            isDisabled={!isOwner || shouldDisableButtons}>
                            {t("common:actions.share")}
                        </Button>
                        {(close) => (
                            <ShareAsset
                                id={id}
                                name={name}
                                closeFunction={close}
                            />
                        )}
                    </DialogTrigger>
                    {isOwnerIsFetched && !isOwner && (
                        <ContextualHelp variant="info">
                            <Content>
                                <Text>
                                    {t("common:actions.shareAsset.disabled")}
                                </Text>
                            </Content>
                        </ContextualHelp>
                    )}
                </Flex>
            </Flex>
            <DialogContainer onDismiss={() => setAssetToUpgrade(undefined)}>
                {assetToUpgrade && (
                    <UploadDialog
                        multiSelect={false}
                        isRevision={(asset?.version ?? 0) > 1}
                        handleFiles={handleUploadFile}
                        close={() => setAssetToUpgrade(undefined)}
                    />
                )}
            </DialogContainer>
        </DetailViewNav>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { DialogContainer, Text } from "@adobe/react-spectrum";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmDialog } from "../../common/components/ConfirmDialog";
import { useDoNotShowMeAgain } from "@src/interfaces/common/hooks/useDoNotShowMeAgain";

import type { FC } from "react";

type Props = {
    clearPopupState: () => void;
};

export const ProjectRendersAcknowledgment: FC<Props> = ({
    clearPopupState,
}) => {
    const { t } = useTranslation(["studio"]);
    const {
        doNotShowProjectRendersAcknowledgment: {
            state: doNotShowProjectRendersAcknowledgment,
            saveFn: saveDoNotShowRendersAcknowledgment,
        },
    } = useDoNotShowMeAgain();

    const [isOpen, setIsOpen] = useState(
        !doNotShowProjectRendersAcknowledgment,
    );

    const closeDialog = () => {
        setIsOpen(false);
        clearPopupState();
    };

    const doAcknowledgment = useCallback(() => {
        saveDoNotShowRendersAcknowledgment();
        closeDialog();
    }, []);

    return (
        <DialogContainer onDismiss={closeDialog}>
            {isOpen && (
                <ConfirmDialog
                    size="S"
                    title={t("dialogs.rendersPopup.title")}
                    onConfirm={doAcknowledgment}
                    confirmBtnText={t("dialogs.rendersPopup.acknowledge")}>
                    <Text>{t("dialogs.rendersPopup.message")}</Text>
                </ConfirmDialog>
            )}
        </DialogContainer>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionGroup, Flex, Item, Text, View } from "@adobe/react-spectrum";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useSearchParams } from "react-router-dom";

import { useRedirects } from "../../../common/hooks/useRedirects";
import { Routes } from "@src/routes";

export enum DetailViewType {
    views2D = "2D",
    views3D = "3D",
    viewsAR = "AR",
}

export const DetailViewTabs = () => {
    const { t } = useTranslation("library");

    const { libraryDetailsRedirect } = useRedirects();
    const location = useLocation();

    const matches = location.pathname.match("/review/");
    const reviewOnly = matches?.length === 1;
    const [searchParams] = useSearchParams();
    const commentId = searchParams.get("commentId");
    const chromeless = searchParams.get("chromeless");

    const pathMatch = matchPath(
        reviewOnly
            ? Routes.limitedAssetReview.path
            : Routes.libraryDetailView.path,
        location.pathname,
    );

    const viewType = pathMatch
        ? pathMatch.params.viewType
        : DetailViewType.views2D;
    const [selected, setSelected] = useState<Set<DetailViewType>>(
        new Set([viewType as DetailViewType]),
    );

    useEffect(() => {
        if (viewType !== selected.keys().next().value) {
            setSelected(new Set([viewType as DetailViewType]));
        }
    }, [viewType]);

    const handleSelectionChange = (keys: any) => {
        setSelected(keys);
    };

    useEffect(() => {
        const assetId = pathMatch?.params.assetId;
        if (!assetId) {
            throw new Error("Tried to redirect when assetId was undefined");
        }
        libraryDetailsRedirect({
            assetId,
            viewType: selected.keys().next().value,
            search: {
                chromeless,
                commentId,
            },
            navigateOptions: { replace: true, state: location.state },
            limitedAccess: reviewOnly,
        });
    }, [selected]);

    return (
        <View
            padding="size-100"
            backgroundColor="gray-100"
            borderTopColor="gray-50"
            borderTopWidth="thick">
            <Flex justifyContent="center" alignContent="center">
                <ActionGroup
                    disallowEmptySelection
                    overflowMode="collapse"
                    orientation="horizontal"
                    selectionMode="single"
                    density="compact"
                    selectedKeys={selected}
                    onSelectionChange={handleSelectionChange}>
                    <Item key={DetailViewType.views2D}>
                        <Text>{t("detailView.title.2D")}</Text>
                    </Item>
                    <Item key={DetailViewType.views3D}>
                        <Text>{t("detailView.title.3DWeb")}</Text>
                    </Item>
                    <Item key={DetailViewType.viewsAR}>
                        <Text>{t("detailView.title.AR")}</Text>
                    </Item>
                </ActionGroup>
            </Flex>
        </View>
    );
};

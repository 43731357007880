/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionButton, Divider, Flex, Text } from "@adobe/react-spectrum";
import Camera from "@spectrum-icons/workflow/Camera";
import { useTranslation } from "react-i18next";

import { DisplaySettingsPicker } from "./DisplaySettingsPicker";
import { DetailViewNav } from "../common/DetailViewNav";
import { rpc } from "@src/contexts/RpcContext";
import { ShotBadges } from "@src/interfaces/projects/components/ShotBadges";
import { useOpenShot } from "@src/interfaces/projects/hooks/useOpenShot";

import type { PropsWithChildren } from "react";

type Props = {
    projectId: string;
    shotId: string;
    name: string;
    is3D: boolean;
    showEditButton: boolean;
    onBackButtonClicked: () => void;
};

export const ProjectDetailViewNav = ({
    projectId,
    shotId,
    name,
    is3D,
    showEditButton,
    onBackButtonClicked,
    children,
}: PropsWithChildren<Props>) => {
    const { t } = useTranslation(["common", "studio"]);
    const { openShotEditor } = useOpenShot(projectId);

    const { data: shotModel } = rpc.shots.getShotModelJob.useQuery(shotId);
    const { data: approved } =
        rpc.projects.isProjectApproved.useQuery(projectId);

    return (
        <DetailViewNav name={name} onBackButtonClicked={onBackButtonClicked}>
            <Flex flex={1} justifyContent="space-between" alignItems="center">
                <ShotBadges shotId={shotId} />
                <Flex columnGap="size-100" alignItems="center">
                    {children}
                    {!is3D && <DisplaySettingsPicker />}
                    {showEditButton && (
                        <>
                            <ActionButton
                                data-uia="asset-action-btn"
                                aria-label={t("common:actions.label")}
                                onPress={() => {
                                    openShotEditor(shotId);
                                }}
                                isDisabled={
                                    approved ||
                                    !shotModel?.jobCompleted ||
                                    !!shotModel.jobFailed
                                }>
                                {is3D ? (
                                    <Text>
                                        {t("studio:actions.addHotspots")}
                                    </Text>
                                ) : (
                                    <>
                                        <Camera />
                                        <Text>
                                            {t("studio:actions.editShot")}
                                        </Text>
                                    </>
                                )}
                            </ActionButton>
                            <Divider
                                orientation="vertical"
                                size="S"
                                height="size-300"
                                marginStart="size-100"
                                marginY="size-100"
                            />
                        </>
                    )}
                </Flex>
            </Flex>
        </DetailViewNav>
    );
};

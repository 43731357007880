/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Divider, Flex } from "@adobe/react-spectrum";
import { usePress } from "@react-aria/interactions";
import { useMemo } from "react";

import { AssetCardFooter } from "./AssetCardFooter";
import { AssetCardHeader } from "./AssetCardHeader";
import { AssetCardPreview } from "./AssetCardPreview";
import { useUserLibraryContext } from "../UserLibrary";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";
import type { LibraryAssetListItem } from "@src/contexts/RpcContext";
import { rpc } from "@src/contexts/RpcContext";
import { Card } from "@src/interfaces/common/components/Card";
import { DetailViewType } from "@src/interfaces/detail/components/common/DetailViewTabs";
import { usePrecacheDetails } from "@src/interfaces/detail/hooks/usePrecacheDetails";
import type { ViewAssetDetailsOpenPathToAccess } from "@src/lib/analytics/AnalyticsConstants";

interface Props {
    assetListItem: LibraryAssetListItem;
}

export function AssetCard({ assetListItem: { id: assetId, name } }: Props) {
    const { setSelectedAsset } = useUserLibraryContext();
    const { data: assetData } = rpc.libraryAssets.getCardItem.useQuery(assetId);
    const { logIngestEvent } = useAnalyticsContext();
    const { precacheDetails } = usePrecacheDetails(assetId);

    const showPointerCursor = useMemo(() => {
        return assetData?.uploadComplete && !assetData.uploadFailed;
    }, [assetData?.uploadComplete, assetData?.uploadFailed]);

    const onPress = (pathToAccess: ViewAssetDetailsOpenPathToAccess) => {
        if (assetData && assetData.uploadFailed) {
            return;
        }
        setSelectedAsset({
            assetId,
            viewType: DetailViewType.views2D,
        });
        logIngestEvent("viewAssetDetailsOpen", {
            "event.workflow": "ASSETS",
            "ui.path_to_access": pathToAccess,
            "event.context_guid": assetId,
        });
    };

    const onPreviewPress = () => {
        onPress("asset-thumbnail");
    };
    const { pressProps: previewPressProps } = usePress({
        onPress: onPreviewPress,
    });

    const onHeaderFooterPress = () => {
        onPress("asset-card");
    };
    const { pressProps: headerFooterPressProps } = usePress({
        onPress: onHeaderFooterPress,
    });

    return (
        <div
            onMouseEnter={precacheDetails}
            {...headerFooterPressProps}
            style={{
                cursor: showPointerCursor ? "pointer" : "default",
            }}>
            <Card dataUia="library-assets" key={assetId}>
                <div
                    {...previewPressProps}
                    style={{
                        cursor: showPointerCursor ? "pointer" : "default",
                    }}>
                    <AssetCardPreview
                        assetId={assetId}
                        name={name}
                        version={assetData?.version}
                        jobsCompleted={assetData?.jobsCompleted ?? false}
                        uploadComplete={assetData?.uploadComplete ?? false}
                        uploadFailed={assetData?.uploadFailed}
                        hasValidationErrors={assetData?.hasValidationErrors}
                    />
                </div>
                <Flex
                    data-uia="library-asset-card-base"
                    position="relative"
                    height="100%"
                    direction="column"
                    marginX="size-100"
                    marginTop="size-100">
                    <AssetCardHeader assetId={assetId} assetName={name} />
                    <Divider size="S" />
                    <AssetCardFooter asset={assetData} />
                </Flex>
            </Card>
        </div>
    );
}

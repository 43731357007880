/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, Link, Text, View } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import type { LibraryAssetProject } from "@src/contexts/RpcContext";
import { useRedirects } from "@src/interfaces/common/hooks/useRedirects";

import type { FC } from "react";

interface UsedInSectionProps {
    projectsUsedIn: LibraryAssetProject[];
}

export const UsedInSection: FC<UsedInSectionProps> = ({ projectsUsedIn }) => {
    const { t } = useTranslation(["library"]);
    const { projectRedirect } = useRedirects();

    return (
        <View paddingTop="size-300">
            <Heading level={4}>
                {t("detailView.detailsPanel.usedIn.title")}
            </Heading>
            <Flex direction="column">
                {projectsUsedIn.map((project) => (
                    <Text key={project.id}>
                        <Link
                            onPress={() =>
                                projectRedirect({ projectId: project.id })
                            }>
                            {project.name}
                        </Link>
                    </Text>
                ))}
            </Flex>
        </View>
    );
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import React from "react";
import { Content, ActionButton, Heading } from "@adobe/react-spectrum";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import "./BackToProject.css";
import { BackToProjectProps } from "./types";

export const BackToProject = (props: BackToProjectProps) => {
    const { setTaskView, setFetchDataTrigger } = props;

    return (
        <Content UNSAFE_className="widget-section panel-top-action">
            <ActionButton
                UNSAFE_className="back-to-project-button"
                onPress={() => {
                    setTaskView(false, "");
                    setFetchDataTrigger(true);
                }}
            >
                <ChevronLeft aria-label="ChevronLeft" />
                <Heading width="100%" level={4}>
                    Back
                </Heading>
            </ActionButton>
        </Content>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, Text, View } from "@adobe/react-spectrum";
import { getTemplate } from "@shared/common";
import { toSpearCase } from "@shared/common/src/util/strings";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { BaseInfoPanel } from "../common/BaseInfoPanel";
import { CreatedBySection } from "../common/CreatedBySection";
import { TimestampSection } from "../common/TimestampSection";
import { rpc } from "@src/contexts/RpcContext";

interface Props {
    projectId: string;
}

export function ProjectInfoPanel({ projectId }: Props) {
    const { t } = useTranslation("studio");
    const { data: project } =
        rpc.projects.getProjectWithOwner.useQuery(projectId);

    const [templateName, setTemplateName] = useState<string>("");
    const [templateVersion, setTemplateVersion] = useState<number>();
    const [resolution, setResolution] = useState<number[]>();

    useEffect(() => {
        if (project?.template) {
            const { template } = getTemplate(project.template);
            const adobeData = template.metadata.customLayerData.adobe;
            setTemplateName(
                t(`project.template.${toSpearCase(adobeData.templateName)}.name`),
            );
            setTemplateVersion(
                adobeData.templateVersion,
            );
            // In theory, each camera has its own resolution that is independent of all the other
            // cameras in the template. For alpha/beta however, the resolution is the same for all
            // cameras so just grab the first one and report its resolution.
            // Tracked by https://jira.corp.adobe.com/browse/SRV3D-6044
            const parsedResolution: number[] = Object.values(
                template.variants[0],
            )[0]?.render_product?.resolution;
            setResolution(parsedResolution);
        } else {
            setTemplateName("");
            setTemplateVersion(undefined);
            setResolution(undefined);
        }
    }, [project?.template]);

    return (
        <BaseInfoPanel>
            <View paddingX="size-400" paddingTop="size-300">
                <Flex direction={"column"} marginBottom="size-300">
                    <CreatedBySection
                        avatarUrl={project?.avatarLink}
                        displayName={project?.displayName}
                    />
                    <TimestampSection
                        timestamp={project?.createdAt ?? ""}
                        timestampType="createdAt"
                    />
                    <Heading level={4} marginTop="size-300">
                        {t("sidePanel.info.template.title")}
                    </Heading>
                    <Text>
                        {t("sidePanel.info.template.nameVersion", {
                            templateName,
                            templateVersion,
                            interpolation: {
                                escapeValue: false
                            }
                        })}
                    </Text>
                    {resolution?.length === 2 && (
                        <Text>
                            {t("sidePanel.info.template.resolution", {
                                width: resolution[0],
                                height: resolution[1],
                            })}
                        </Text>
                    )}
                </Flex>
            </View>
        </BaseInfoPanel>
    );
};

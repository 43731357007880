/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ALL_PROJECTS_FILTER_VALUE } from "@shared/types";
import { createContext, useContext, useState } from "react";

import type { DetailPanelKey } from "@src/interfaces/detail/components/common/DetailViewRail";
import { DETAIL_PANEL_KEYS } from "@src/interfaces/detail/components/common/DetailViewRail";
import type { DisplaySize } from "@src/interfaces/detail/components/projects/DisplaySettingsPicker";
import { DISPLAY_SIZE } from "@src/interfaces/detail/components/projects/DisplaySettingsPicker";
import type { ProjectsFilters } from "@src/interfaces/projects/components/ProjectsLeftNav";

import type { PropsWithChildren, Dispatch, SetStateAction } from "react";
import { AssetListKey } from "@src/interfaces/library/components/AssetsFilter";

/******************************************************************************
 * DIRECTIONS FOR ADDING A PERSISTENT UI STATE
 * When adding values to the UIStateContext, some boilerplate code is required.
 *  1. Add your state variable and setter to the UIState type
 *  2. Add useState inside the UIStateProvider
 *  3. Add the new state and setter to the value of the UIStateContext.Provider.
 *  NOTE: Please sort each of these areas by state name, followed by the setter.
 ******************************************************************************/

type UIState = {
    assetDetailPanelSelection: DetailPanelKey;
    setAssetDetailPanelSelection: Dispatch<SetStateAction<DetailPanelKey>>;
    assetFilterSelection: AssetListKey;
    setAssetFilterSelection: Dispatch<SetStateAction<AssetListKey>>;
    projectDetailDisplaySize: DisplaySize;
    setProjectDetailDisplaySize: Dispatch<SetStateAction<DisplaySize>>;
    projectViewPanelSelection: DetailPanelKey;
    setProjectViewPanelSelection: Dispatch<SetStateAction<DetailPanelKey>>;
    projectSelection: string;
    setProjectSelection: Dispatch<SetStateAction<string>>;
    projectFilterSelection: ProjectsFilters;
    setProjectFilterSelection: Dispatch<SetStateAction<ProjectsFilters>>;
    searchValue: string;
    setSearchValue: (search: string) => void;
};

const UIStateContext = createContext<UIState | undefined>(undefined);

export const UIStateProvider = ({ children }: PropsWithChildren) => {
    const [assetDetailPanelSelection, setAssetDetailPanelSelection] =
        useState<DetailPanelKey>(DETAIL_PANEL_KEYS.messages);
    const [assetFilterSelection, setAssetFilterSelection] =
        useState<AssetListKey>("all");
    const [projectDetailDisplaySize, setProjectDetailDisplaySize] =
        useState<DisplaySize>(DISPLAY_SIZE.fitToScreen);
    const [projectViewPanelSelection, setProjectViewPanelSelection] =
        useState<DetailPanelKey>(DETAIL_PANEL_KEYS.info);
    const [projectSelection, setProjectSelection] = useState("");
    const [projectFilterSelection, setProjectFilterSelection] =
        useState<ProjectsFilters>(ALL_PROJECTS_FILTER_VALUE);
    const [searchValue, setSearchValue] = useState("");

    return (
        <UIStateContext.Provider
            value={{
                assetDetailPanelSelection,
                setAssetDetailPanelSelection,
                assetFilterSelection,
                setAssetFilterSelection,
                projectDetailDisplaySize,
                setProjectDetailDisplaySize,
                projectViewPanelSelection,
                setProjectViewPanelSelection,
                projectSelection,
                setProjectSelection,
                projectFilterSelection,
                setProjectFilterSelection,
                searchValue,
                setSearchValue,
            }}>
            {children}
        </UIStateContext.Provider>
    );
};

export const useUIStateContext = () => {
    const context = useContext(UIStateContext);
    if (!context) {
        throw new Error(
            "useUIStateContext must be used within a UIStateContextProvider.",
        );
    }
    return context;
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    IllustratedMessage,
    type SpectrumImageProps,
} from "@adobe/react-spectrum";
import Image from "@spectrum-icons/workflow/Image";
import { useTranslation } from "react-i18next";

import { DelayedDisplay } from "./DelayedDisplay";
import { ImageFallback } from "./ImageFallback";

interface Props extends Omit<SpectrumImageProps, "src"> {
    url?: string;
    alt: string;
    fallbackImageSize?: "L" | "XXL";
    enableSpinner?: boolean;
    showFallbackImage?: boolean;
}

export function Thumbnail({
    url,
    alt,
    fallbackImageSize = "XXL",
    showFallbackImage,
    enableSpinner = true,
}: Props) {
    const { t } = useTranslation("common");

    let imageComponent;

    if (url) {
        imageComponent = (
            <ImageFallback
                showErrorIcon
                enableSpinner={enableSpinner}
                errorMessage={t("error.thumbnail")}
                width="100%"
                objectFit="contain"
                src={url}
                alt={alt}
            />
        );
    } else if (showFallbackImage) {
        imageComponent = (
            <DelayedDisplay delayTime={500}>
                <IllustratedMessage>
                    <Image size={fallbackImageSize} />
                </IllustratedMessage>
            </DelayedDisplay>
        );
    }

    return imageComponent;
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { observable } from "mobx";

export enum NotificationType {
    Error,
    Info,
}

export type Notification = {
    type: NotificationType;
    fileName: string;
    description: string;
};

export class NotificationService {
    @observable
    readonly notifications: Notification[];

    constructor() {
        this.notifications = [];
    }

    addNotification(newNotification: Notification) {
        this.notifications.push(newNotification);
    }
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";
import { matchRoutes, useLocation } from "react-router-dom";

import { LeftNavListItem } from "@src/interfaces/common/components/LeftNavListItem";
import { Routes } from "@src/routes";

import type { PropsWithChildren } from "react";
import { AssetListKey } from "./AssetsFilter";

interface Props {
    filterKey: AssetListKey; // This item's filter key.
    filterLabel: string; // This item's filter label.
    filterSelected: AssetListKey; // The current filter selected in order to test if this is the filter selected.
    setSelectedFilter: (key: AssetListKey) => void;
}

export const AssetsFilterItem = ({
    filterKey,
    filterLabel,
    filterSelected,
    setSelectedFilter,
    children,
}: PropsWithChildren<Props>) => {
    const location = useLocation();
    const matchesRoute = matchRoutes([Routes.library, Routes.search], location);
    const [showItemSelection, setShowItemSelection] = useState<boolean>(
        filterSelected === filterKey && !!matchesRoute,
    );

    useEffect(() => {
        setShowItemSelection(filterSelected === filterKey && !!matchesRoute);
    }, [filterSelected, filterKey, matchesRoute]);

    function onClickHandler() {
        setSelectedFilter(filterKey as AssetListKey);
    }

    return (
        <LeftNavListItem
            isSelected={showItemSelection}
            shouldDisableHover={false}
            onClickHandler={onClickHandler}
            setIsHovered={() => {}}
            aria-label={filterLabel}
            key={filterKey}>
            {children}
        </LeftNavListItem>
    );
};

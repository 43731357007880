/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Heading, Text, View } from "@adobe/react-spectrum";
import React, { useState } from "react";

import { useDialogStyles } from "../hooks/useDialogStyles";
import { useHotSpots } from "../hooks/useHotSpots";

import type { SceneManager } from "../scene/SceneManager";
import type { HotSpot } from "@shared/types";

interface Props {
    sceneManager?: SceneManager;
    overlayRef: React.RefObject<HTMLDivElement>;
    hotSpots: HotSpot[];
}

export function HotSpotViewer({ sceneManager, overlayRef, hotSpots }: Props) {
    const [dialogEl, setDialogEl] = useState<HTMLDivElement | null>(null);
    const { activeHotSpot, OverlayPortal, activePosition } = useHotSpots(
        sceneManager,
        overlayRef,
        dialogEl,
        {
            offsetPadding: 10,
        },
        hotSpots,
    );

    const dialogStyles = useDialogStyles(activePosition);

    return (
        <div>
            {OverlayPortal && (
                <OverlayPortal>
                    {activeHotSpot && (
                        <div style={dialogStyles} ref={(el) => setDialogEl(el)}>
                            <View
                                key={activeHotSpot.id}
                                width="size-3600"
                                backgroundColor="gray-100"
                                borderRadius="medium"
                                padding="size-150"
                                UNSAFE_style={{ pointerEvents: "auto" }}>
                                {activeHotSpot.title && (
                                    <Heading>{activeHotSpot.title}</Heading>
                                )}
                                {activeHotSpot.text && (
                                    <Text>{activeHotSpot.text}</Text>
                                )}
                            </View>
                        </div>
                    )}
                </OverlayPortal>
            )}
        </div>
    );
}

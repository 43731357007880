/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Icon } from "@adobe/react-spectrum";

import type { IconProps } from "@adobe/react-spectrum";

export const CameraFrame = (props: Omit<IconProps, "children">) => {
    return (
        <Icon {...props}>
            <svg id="Icons" width="18" height="18" viewBox="0 0 18 18">
                {/* Note: "fill" has been commented out below. It was added in the SVG given from the icon team, yet it
                    is inconsistent with the other button icons, which don't have that color fill. After discussion
                    with design, we have decided to comment it out for now. The icons are supposed to get replaced
                    eventually with "official" icons. -jakes */}
                <path
                    // fill="#6e6e6e"
                    d="M16,12.25v2.75h-2.75c-.138,0-.25,.112-.25,.25v.5c0,.138,.112,.25,.25,.25h3.75v-3.75c0-.138-.112-.25-.25-.25h-.5c-.138,0-.25,.112-.25,.25Z"
                />
                <path
                    // fill="#6e6e6e"
                    d="M2,15v-2.75c0-.138-.112-.25-.25-.25h-.5c-.138,0-.25,.112-.25,.25v3.75h3.75c.138,0,.25-.112,.25-.25v-.5c0-.138-.112-.25-.25-.25H2Z"
                />
                <path
                    // fill="#6e6e6e"
                    d="M13,2.25v.5c0,.138,.112,.25,.25,.25h2.75v2.75c0,.138,.112,.25,.25,.25h.5c.138,0,.25-.112,.25-.25V2h-3.75c-.138,0-.25,.112-.25,.25Z"
                />
                <path
                    // fill="#6e6e6e"
                    d="M2,3h2.75c.138,0,.25-.112,.25-.25v-.5c0-.138-.112-.25-.25-.25H1v3.75c0,.138,.112,.25,.25,.25h.5c.138,0,.25-.112,.25-.25V3Z"
                />
                <rect
                    // fill="#6e6e6e"
                    x="3"
                    y="4"
                    width="12"
                    height="10"
                    rx=".25"
                    ry=".25"
                />
            </svg>
        </Icon>
    );
};

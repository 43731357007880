/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";
import { PROJECT_COMPOSITE_TYPE } from "@shared/types";
import { useContext } from "react";

import { rpc } from "@src/contexts/RpcContext";
import { ThemeContext } from "@src/contexts/ThemeContext";
import { ShareSheetView } from "@src/interfaces/detail/components/share/ShareSheet";

import type { FC } from "react";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";

/**
 * This component renders the share sheet within a modal for sharing projects.
 */
interface Props {
    projectId: string;
    closeFunction: VoidFunction;
}

export const ShareProject: FC<Props> = ({ projectId, closeFunction }) => {
    const { isDarkMode } = useContext(ThemeContext);
    const { data: project } = rpc.projects.get.useQuery(projectId);
    const { logIngestEvent } = useAnalyticsContext();

    return (
        <View
            UNSAFE_className="modal-share-container"
            width="size-4600"
            minHeight="size-4600"
            UNSAFE_style={{
                backgroundColor: isDarkMode ? "#1e1e1e" : "#ffffff",
            }}>
            {project && (
                <ShareSheetView
                    id={project.id}
                    assetType={PROJECT_COMPOSITE_TYPE}
                    assetName={project.name}
                    closeShareViewFunction={closeFunction}
                    onInviteFulfilled={() =>
                        logIngestEvent("projectInviteSubmit", {
                            "event.context_guid": projectId,
                            "3di.dimensions": {
                                "review-type": "invite-to-view",
                            },
                        })
                    }
                />
            )}
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    AlertDialog,
    DialogContainer,
    Flex,
    Link as LinkSpectrum,
    Text,
} from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { useOpenInNewTab } from "../hooks/useOpenInNewTab";

import type { FC } from "react";

declare global {
    interface Window {
        adobePrivacy?: {
            showConsentPopup: () => void;
        };
    }
}

const { openInNewTab } = useOpenInNewTab();

const onConsentPreferences = () => {
    globalThis.window.adobePrivacy?.showConsentPopup();
};

// This link is part of the Adobe third-party notices.
// In order to show the clickable link inlined, the notice text is broken into 2 parts.
// The noticed is formatted as translated "common:account.legal.3rdPartyNotices1" + link +
// "common:account.legal.3rdPartyNotices2" below.
const ADOBE_THIRD_PARTY_URL = "https://www.adobe.com/go/thirdparty";

interface Props {
    onLegalNoticesClose: () => void;
}

// Note on localization for the links
// https://wiki.corp.adobe.com/display/legalwiki/UI+Review%2C+Notices+and+Localization?preview=/1975781265/2000147962/UI-Notices_Services.pdf

export const LegalNotices: FC<Props> = ({ onLegalNoticesClose }) => {
    const { t } = useTranslation(["common"]);

    return (
        <DialogContainer onDismiss={() => {}}>
            <AlertDialog
                variant="confirmation"
                title={t("common:account.legal.notices")}
                primaryActionLabel={t("common:actions.close")}
                onPrimaryAction={onLegalNoticesClose}>
                <Flex
                    direction="column"
                    rowGap="size-75"
                    UNSAFE_style={{
                        color: "#0265DC",
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "18.20px",
                    }}>
                    <LinkSpectrum
                        isQuiet
                        onPress={() => {
                            openInNewTab(
                                "https://www.adobe.com/legal/terms.html",
                            );
                        }}
                        variant="secondary">
                        {t("common:account.legal.termsOfUse")}
                    </LinkSpectrum>
                    <LinkSpectrum
                        isQuiet
                        onPress={() => {
                            openInNewTab("https://www.adobe.com/privacy.html");
                        }}
                        variant="secondary">
                        {t("common:account.legal.privacyPolicy")}
                    </LinkSpectrum>
                    <LinkSpectrum
                        isQuiet
                        onPress={onConsentPreferences}
                        variant="secondary">
                        {t("common:account.legal.cookiePreferences")}
                    </LinkSpectrum>
                    <LinkSpectrum
                        isQuiet
                        onPress={() => {
                            openInNewTab(
                                "https://www.adobe.com/privacy/us-rights.html",
                            );
                        }}
                        variant="secondary">
                        {t("common:account.legal.doNotSellOrShare")}
                    </LinkSpectrum>
                </Flex>
                <Flex
                    direction="column"
                    rowGap="size-300"
                    marginTop="size-300"
                    UNSAFE_style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "18.20px",
                    }}>
                    <Text aria-label={t("common:account.legal.copyright")}>
                        Copyright © 2022-{new Date().getFullYear()} Adobe. All
                        rights reserved.
                    </Text>
                    <Text>{t("common:account.legal.adobeNotices")}</Text>
                    <Flex
                        direction="row"
                        UNSAFE_style={{
                            display: "inline-block",
                        }}>
                        <Text>
                            {t("common:account.legal.3rdPartyNotices1")}
                        </Text>
                        <LinkSpectrum
                            isQuiet
                            UNSAFE_style={{
                                color: "blue-900",
                            }}
                            onPress={() => {
                                openInNewTab(ADOBE_THIRD_PARTY_URL);
                            }}
                            variant="primary">
                            {t("common:account.legal.thirdPartyNotices")}
                        </LinkSpectrum>
                        <Text>
                            {t("common:account.legal.3rdPartyNotices2")}
                        </Text>
                    </Flex>
                </Flex>
            </AlertDialog>
        </DialogContainer>
    );
};

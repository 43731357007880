export const mediaTypeModelImport = new Set([
    ".3dm",
    ".asm",
    ".catpart",
    ".catproduct",
    ".dn",
    ".fbx",
    ".glb",
    ".gltf",
    ".iges",
    ".igs",
    ".jt",
    ".obj",
    ".prt",
    ".sbsm",
    ".skp",
    ".sldasm",
    ".sldprt",
    ".ssg",
    ".step",
    ".stl",
    ".stp",
    ".usd",
    ".usda",
    ".usdc",
    ".usdz",
    ".x_t",
]);
export const mediaTypeSceneWrite = new Set([".ssg"]);
export const mediaTypeModelExport = new Set([".glb", ".gltf", ".obj", ".ssg"]);
export const mediaTypeJson = new Set([".json"]);
export const mediaTypeImageRender = new Set([".png", ".psd"]);
export const mediaTypeScene = new Set([".dn", ".ssg"]);
export const mediaTypeImageImport = new Set([
    ".ai",
    ".bmp",
    ".eps",
    ".epsf",
    ".exr",
    ".gif",
    ".hdr",
    ".iff",
    ".j2k",
    ".jp2",
    ".jpe",
    ".jpeg",
    ".jpf",
    ".jpg",
    ".jpm",
    ".jpx",
    ".pcx",
    ".pdf",
    ".png",
    ".psb",
    ".psd",
    ".svg",
    ".svgz",
    ".tga",
    ".tif",
    ".tiff",
]);
export const mediaTypeMaterialImport = new Set([".sbsar", ".mdlz"]);
export const mediaTypeText = new Set([".txt"]);
export const mediaTypeModelCompact = new Set([".fbx", ".glb", ".usdz"]);
export const mediaTypeModelUsd = new Set([
    ".usd",
    ".usda",
    ".usdc",
    ".usdz",
    ".glb",
    ".fbx",
]);
export const mediaTypeModelUsdArchive = new Set([".usdz"]);

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionGroup, Item, View } from "@adobe/react-spectrum";

import type { SpectrumSelection } from "@src/types/spectrum";

import type { FC, ReactElement } from "react";

export const DETAIL_PANEL_KEYS = {
    comment: "comment",
    info: "info",
    messages: "messages",
    workfront: "workfront",
    none: "none",
} as const;
export type DetailPanelKey =
    (typeof DETAIL_PANEL_KEYS)[keyof typeof DETAIL_PANEL_KEYS];

export type DetailViewRailButton = {
    key: string;
    label: string;
    icon: ReactElement;
};

type Props = {
    selected: string;
    handleToggleSidePanel: (selectedKey: DetailPanelKey) => void;
    buttons: DetailViewRailButton[];
};

export const DetailViewRail: FC<Props> = ({
    selected,
    handleToggleSidePanel,
    buttons,
}) => {
    function handleSelectionChange(selection: SpectrumSelection) {
        const keys = Array.from(selection) as DetailPanelKey[];
        handleToggleSidePanel(keys[0] || DETAIL_PANEL_KEYS.none);
    }

    return (
        <View
            backgroundColor="gray-100"
            height="100%"
            borderStartColor="gray-50"
            borderStartWidth="thick"
            paddingTop="size-100"
            paddingX="size-75">
            <ActionGroup
                orientation="vertical"
                selectionMode="single"
                selectedKeys={[selected]}
                onSelectionChange={handleSelectionChange}
                isQuiet>
                {buttons.map((button: DetailViewRailButton) => {
                    return (
                        <Item key={button.key} aria-label={button.label}>
                            {button.icon}
                        </Item>
                    );
                })}
            </ActionGroup>
        </View>
    );
};

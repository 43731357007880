/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Flex,
    Heading,
    Item,
    ListView,
    SearchField,
    Text,
    View,
} from "@adobe/react-spectrum";
import Clock from "@spectrum-icons/workflow/Clock";
import Filter from "@spectrum-icons/workflow/Filter";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import type { TextFieldRef } from "@react-types/textfield";
import type { FC } from "react";

interface Props {
    initialValue?: string;
    placeholder?: string;
    enableFilter?: boolean;
    isQuiet?: boolean;
    width?: string;
    searchHistory?: boolean;
    onSubmit: (text: string) => void;
    blurOnSubmit?: boolean;
    submitOnClear?: boolean;
    submitOnChange?: boolean;
}

const savedAssetSearchesKey = "savedAssetSearches";

function writeSavedSearches(text: string) {
    let savedSearches = getSavedSearches();
    savedSearches.unshift(text);
    // dedupe
    savedSearches = [...new Set(savedSearches)];
    window.localStorage.setItem(
        savedAssetSearchesKey,
        JSON.stringify(savedSearches.slice(0, 3)),
    );
}

function getSavedSearches(): string[] {
    const savedData = window.localStorage.getItem("savedAssetSearches") || "[]";

    try {
        return JSON.parse(savedData);
    } catch (e) {
        console.error("Error getting saved searches");
    }
    return [];
}

function cleanText(text: string) {
    return text.replace(/[\^#%&$*:<>?/{|}/\\]/g, "").replace(/\s+/g, " ");
}

export const SearchControl: FC<Props> = ({
    initialValue,
    placeholder,
    isQuiet,
    enableFilter,
    searchHistory = false,
    width,
    onSubmit,
    blurOnSubmit,
    submitOnClear,
    submitOnChange,
}) => {
    const { t } = useTranslation();
    const ref = useRef<TextFieldRef | null>(null);
    const [currentText, setCurrentText] = useState(initialValue);
    const [searchFocussed, setSearchFocussed] = useState(false);

    const throttledOnSubmit = useMemo(() => {
        return debounce(onSubmit, 250);
    }, [onSubmit]);

    useEffect(() => {
        if (initialValue !== currentText) {
            setCurrentText(initialValue);
        }
    }, [initialValue]);

    function onSearch(text: string) {
        const trimmedText = text.trim();
        if (trimmedText) {
            searchHistory && writeSavedSearches(trimmedText);
            onSubmit(trimmedText);
            if (blurOnSubmit) {
                ref.current?.getInputElement()?.blur();
            }
        }
    }

    function onChange(text: string) {
        const cleanedText = cleanText(text);
        setCurrentText(cleanedText);
        if (!cleanedText && currentText && submitOnClear) {
            onSubmit("");
        }
        if (submitOnChange) {
            throttledOnSubmit(cleanedText);
        }
    }

    const savedSearches = getSavedSearches();

    return (
        <Flex
            alignItems="center"
            position="relative"
            width={
                width || "size-3400"
            }>
            <SearchField
                ref={ref}
                placeholder={placeholder}
                isQuiet={isQuiet ? true : false}
                aria-label={t("common:search")}
                onClear={() => setCurrentText("")}
                onChange={onChange}
                onSubmit={onSearch}
                onFocusChange={(focus) =>
                    setTimeout(() => setSearchFocussed(focus), 100)
                }
                value={currentText}
                inputMode="text"
                width="100%"
                maxLength={40}
            />
            {searchFocussed && searchHistory && !!savedSearches.length && (
                <View
                    position="absolute"
                    top="100%"
                    marginTop="size-250"
                    backgroundColor="gray-50"
                    width="100%"
                    borderColor="gray-300"
                    borderWidth="thin"
                    borderRadius="medium"
                    paddingTop="size-250"
                    paddingBottom="size-250"
                    paddingStart="size-200">
                    <Heading
                        level={4}
                        marginBottom="size-125"
                        marginStart="size-125">
                        {t("common:search.previousSearches")}
                    </Heading>
                    <ListView
                        isQuiet
                        selectedKeys={[]}
                        selectionMode="single"
                        selectionStyle="highlight"
                        onSelectionChange={(key) =>
                            onSearch([...(key as unknown as Set<string>)][0])
                        }>
                        {savedSearches.map((search) => (
                            <Item key={search}>
                                <Clock />
                                <Text marginStart="size-125">{search}</Text>
                            </Item>
                        ))}
                    </ListView>
                </View>
            )}
            {enableFilter && (
                <ActionButton
                    isQuiet
                    onPress={
                        () => console.log("Filter button pressed")
                    }>
                    <Filter size="S" />
                </ActionButton>
            )}
        </Flex>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type { JobErrors, RenderSettings } from ".";

/**
 * Artifact (render or model) data needed for detail view content, including commenting.
 */
export type ArtifactData = {
    componentId: string;
    jobCompleted: boolean;
    jobFailed: boolean;
    jobErrors?: JobErrors;
    type: string;
    url?: string;
    iblFilename?: string;
    aspectRatio?: number;
    isShot?: boolean;
    renderSettings?: RenderSettings;
    isRendition?: boolean;
    resolution?: number[];
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { User } from "../../../../types/User";
import {
    RecommendedUser,
    SmarterAssignment,
    TaskRecommendedAssigneeResponse,
} from "../../requests/types/TaskRecommendedAssigneeResponse";
import { fetchWorkfrontTaskRecommendedAssignees } from "../../requests/fetchTaskRecommendedAssignees";
import { WF_OBJECT_CODE_USER } from "../../constants";
import { fetchAvatarImageAsBlob } from "../../requests/fetchAvatarImageAsBlob";
import { fetchWorkfrontUserById } from "../../requests/fetchUserById";
import { UserResponse } from "../../requests/types/UserResponse";
import { convertToTimestamp } from "../../util";
import { RecommendedAssigneesQuery } from "../../requests/types/RecommendedAssigneesQuery";
import { WorkfrontParams } from "../../../../types/wfParams";

//TODO localStorage or sessionStorage?
const loadedUsers: Map<string, User> = new Map<string, User>();

const formAvatarDownloadUrl = (smarterAssignment: SmarterAssignment): string | null => {
    if (!smarterAssignment.avatarDate) {
        return null;
    }
    return `/internal/user/avatar?ID=${smarterAssignment.ID}&time=${convertToTimestamp(smarterAssignment.avatarDate)}`;
};

const extractUsersFromSmarterAssignments = async (
    wfParams: WorkfrontParams,
    smarterAssignments: SmarterAssignment[],
): Promise<User[]> => {
    const loadUserPromises: Promise<User | undefined>[] = smarterAssignments
        .filter((smarterAssignment: SmarterAssignment) => {
            return smarterAssignment.objCode === WF_OBJECT_CODE_USER;
        })
        .map(async (smarterAssignment: SmarterAssignment): Promise<User | undefined> => {
            if (!loadedUsers.has(smarterAssignment.ID)) {
                const avatarDownloadUrl = formAvatarDownloadUrl(smarterAssignment);
                const avatar: Blob | null = avatarDownloadUrl
                    ? await fetchAvatarImageAsBlob(wfParams, avatarDownloadUrl)
                    : null;

                const user: User = {
                    ID: smarterAssignment.ID,
                    name: smarterAssignment.name,
                    title: smarterAssignment.title || "",
                    avatar,
                };
                loadedUsers.set(smarterAssignment.ID, user);
            }
            return loadedUsers.get(smarterAssignment.ID);
        });
    const result: (User | undefined)[] = await Promise.all(loadUserPromises);
    return result.filter((user: User | undefined): user is User => user !== undefined);
};

const extractUsersFromRecommendedUsers = async (
    wfParams: WorkfrontParams,
    recommendedUsers: RecommendedUser[],
): Promise<User[]> => {
    const loadUserPromises: Promise<User | undefined>[] = recommendedUsers.map(
        async (recommendedUser: RecommendedUser): Promise<User | undefined> => {
            if (!loadedUsers.has(recommendedUser.ID)) {
                const userResponse: UserResponse = await fetchWorkfrontUserById(
                    wfParams,
                    recommendedUser.ID,
                );
                const avatar: Blob | null = userResponse.avatarDownloadURL
                    ? await fetchAvatarImageAsBlob(wfParams, userResponse.avatarDownloadURL)
                    : null;

                const user: User = {
                    ID: userResponse.ID,
                    name: userResponse.name,
                    title: userResponse.title || "",
                    avatar,
                };
                loadedUsers.set(recommendedUser.ID, user);
            }
            return loadedUsers.get(recommendedUser.ID);
        },
    );
    const result: (User | undefined)[] = await Promise.all(loadUserPromises);
    return result.filter((user: User | undefined): user is User => user !== undefined);
};

const formQueryString = (parameters: RecommendedAssigneesQuery): string => {
    const query: string[] = [];
    for (const parameterName in parameters) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const parameterValue: string | undefined = parameters[parameterName];
        if (parameterValue !== undefined) {
            query.push(`${parameterName}=${parameterValue}`);
        }
    }
    return query.join("&");
};

export const fetchTaskRecommendedAssignees = async (
    wfParams: WorkfrontParams,
    parameters: RecommendedAssigneesQuery,
): Promise<User[]> => {
    const query: string = formQueryString(parameters);
    const apiTaskRecommendedAssigneeResponse: TaskRecommendedAssigneeResponse =
        await fetchWorkfrontTaskRecommendedAssignees(wfParams, query);
    const { recommendedUsers, smarterAssignments } = apiTaskRecommendedAssigneeResponse;
    if (smarterAssignments) {
        const usersFromSmartAssignments = await extractUsersFromSmarterAssignments(
            wfParams,
            smarterAssignments,
        );
        if (usersFromSmartAssignments.length) {
            return usersFromSmartAssignments;
        }
    }
    return recommendedUsers ? extractUsersFromRecommendedUsers(wfParams, recommendedUsers) : [];
};

import type { IconProps } from "@adobe/react-spectrum";
import { Icon } from "@adobe/react-spectrum";

export const CameraPan = (props: Omit<IconProps, "children">) => {
    return (
        <Icon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 36">
                <path d="M33,13.501V16H27.65125A6.00632,6.00632,0,0,0,24,12.34875V7h2.499a.5.5,0,0,0,.35355-.85355l-4.499-4a.5.5,0,0,0-.7071,0l-4.499,4A.5.5,0,0,0,17.501,7H20v5.34875A6.00632,6.00632,0,0,0,16.34875,16H11V13.501a.5.5,0,0,0-.85355-.35355l-4,4.499a.5.5,0,0,0,0,.7071l4,4.499A.5.5,0,0,0,11,22.499V20h5.34875A6.00632,6.00632,0,0,0,20,23.65125V29H17.501a.5.5,0,0,0-.35355.85355l4.499,4a.5.5,0,0,0,.7071,0l4.499-4A.5.5,0,0,0,26.499,29H24V23.65125A6.00632,6.00632,0,0,0,27.65125,20H33v2.499a.5.5,0,0,0,.85355.35355l4-4.499a.5.5,0,0,0,0-.7071l-4-4.499A.5.5,0,0,0,33,13.501Z" />
            </svg>
        </Icon>
    );
};

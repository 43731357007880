/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import reactArrayToTree from "react-array-to-tree";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { App } from "./app";
import { AccessContextProvider } from "./contexts/AccessContext";
import { AnalyticsContextProvider } from "./contexts/AnalyticsContext";
import { AppContextProvider } from "./contexts/AppContext";
import { CachingContextProvider } from "./contexts/CachingContext";
import { FeatureFlagContextProvider } from "./contexts/FeatureFlagContext";
import { MessagingContextProvider } from "./contexts/MessagingContext";
import { RpcContextProvider } from "./contexts/RpcContext";
import { SentryContextProvider } from "./contexts/SentryContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import { UIStateProvider } from "./contexts/UIStateContext";
import { UploadDownloadContextProvider } from "./contexts/UploadDownloadContext";

import "./externals";
import "./i18n";

// Contexts will not be held in an iterable, so disable key requirement
/* eslint-disable react/jsx-key */
const Providers = reactArrayToTree([
    <BrowserRouter />,
    <FeatureFlagContextProvider />,
    <SentryContextProvider />,
    <AppContextProvider />,
    <RpcContextProvider />,
    <ThemeProvider />,
    <MessagingContextProvider/>,
    <UIStateProvider />,
    <AccessContextProvider/>,
    <AnalyticsContextProvider />,
    <UploadDownloadContextProvider />,
    <CachingContextProvider />,
]);

document.addEventListener("DOMContentLoaded", () => {
    const container = document.getElementById("app-root");
    if (container) {
        const root = ReactDOM.createRoot(container);
        root.render(
            <Providers>
                <App />
            </Providers>,
        );
    }
});

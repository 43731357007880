/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    AlertDialog,
    DialogContainer,
    Flex,
    ProgressCircle,
    View,
} from "@adobe/react-spectrum";
import { ALL_PROJECTS_FILTER_VALUE } from "@shared/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ProjectsListItem } from "./ProjectsListItem";
import { useOpenProject } from "../hooks/useOpenProject";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import type { ProjectListItem, RPCRouter } from "@src/contexts/RpcContext";
import { rpc } from "@src/contexts/RpcContext";
import { ScrollingContainer } from "@src/interfaces/common/components/ScrollingContainer";
import { useInfiniteScroll } from "@src/interfaces/common/hooks/useInfiniteScroll";
import { useRedirects } from "@src/interfaces/common/hooks/useRedirects";

import type { ProjectsFilters } from "./ProjectsLeftNav";
import type { TRPCClientError } from "@trpc/client";

const PAGE_SIZE = 50;
interface Props {
    filterKey: ProjectsFilters;
}

export const ProjectsList = ({ filterKey }: Props) => {
    const { openProject } = useOpenProject();
    const { assetsLibraryRedirect } = useRedirects();
    const { t } = useTranslation(["common", "studio"]);
    const { showToastForInvalidPermissions, info } =
        useMessagingContext();

    const filteredProjects = rpc.projects.getAll.useInfiniteQuery(
        {
            count: PAGE_SIZE,
            filters:
                filterKey !== ALL_PROJECTS_FILTER_VALUE
                    ? [filterKey]
                    : filterKey,
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
        },
    );

    const { handleScroll, scrollRef } = useInfiniteScroll(
        "projects-list",
        filteredProjects,
    );

    const deleteProjectMutation = rpc.projects.delete.useMutation();

    const [projects, setProjects] = useState<ProjectListItem[]>([]);
    const [projectBeingDeleted, setProjectBeingDeleted] =
        useState<ProjectListItem>();

    useEffect(() => {
        setProjects(
            filteredProjects.data?.pages.flatMap((page) => page.items) ?? [],
        );
    }, [filteredProjects.data]);

    function deleteProject(
        project: ProjectListItem,
        projectList: ProjectListItem[],
    ) {
        const indexOfProject = projectList.findIndex(
            (projectInList) => projectInList.id === project.id,
        );
        // Select the project directly above in the list, if one exists.
        // If one doesn't exist, select the one directly below.
        // If the list will be empty, select nothing
        // Selection is done automatically via a redirect
        const projectToSelect =
            projectList.length > 1
                ? indexOfProject === 0
                    ? projectList[1].id
                    : projectList[indexOfProject - 1].id
                : undefined;

        deleteProjectMutation
            .mutateAsync(project.id)
            .then(() => {
                if (project.name) {
                    info(
                        t("common:notification.deleted", {
                            filename: project.name,
                            interpolation: {
                                escapeValue: false,
                            },
                        }),
                        { timeout: 5000 },
                    );
                }
            })
            .catch((e) => {
                if (
                    (e as TRPCClientError<RPCRouter>)?.data?.code ===
                    "FORBIDDEN"
                ) {
                    showToastForInvalidPermissions(true);
                } else {
                    throw e;
                }
            });
        if (projectToSelect) {
            openProject(projectToSelect);
        } else {
            assetsLibraryRedirect({ replace: true });
        }
    }

    return (
        <View flexGrow={1} width="100%" height="100%">
            {!filteredProjects.data ? (
                <Flex justifyContent="center">
                    <ProgressCircle
                        isIndeterminate
                        size="M"
                        aria-label={t("common:progress.loading")}
                    />
                </Flex>
            ) : (
                <Flex height="100%">
                    <View flexGrow={1} height="100%">
                        <ScrollingContainer
                            onScroll={handleScroll}
                            forwardedRef={scrollRef}>
                            <View
                                data-uia="projects-list"
                                height="100%"
                                UNSAFE_style={{
                                    cursor: "pointer",
                                }}>
                                {projects.map((project) => (
                                    <ProjectsListItem
                                        project={project}
                                        deleteProject={setProjectBeingDeleted}
                                        key={project.id}
                                    />
                                ))}
                            </View>
                            <DialogContainer
                                onDismiss={() =>
                                    setProjectBeingDeleted(undefined)
                                }>
                                {projectBeingDeleted && (
                                    <AlertDialog
                                        title={t(
                                            "studio:dialogs.delete.title",
                                            {
                                                projectName:
                                                    projectBeingDeleted.name,
                                                interpolation: {
                                                    escapeValue: false,
                                                },
                                            },
                                        )}
                                        primaryActionLabel={t(
                                            "studio:dialogs.delete.deleteButton",
                                        )}
                                        cancelLabel={t(
                                            "studio:dialogs.delete.cancelButton",
                                        )}
                                        onCancel={() => {
                                            setProjectBeingDeleted(undefined);
                                        }}
                                        onPrimaryAction={() => {
                                            deleteProject(
                                                projectBeingDeleted,
                                                projects,
                                            );
                                            setProjectBeingDeleted(undefined);
                                        }}
                                        autoFocusButton="primary"
                                        variant="destructive">
                                        {t("studio:dialogs.delete.body", {
                                            projectName:
                                                projectBeingDeleted.name,
                                            interpolation: {
                                                escapeValue: false,
                                            },
                                        })}
                                    </AlertDialog>
                                )}
                            </DialogContainer>
                        </ScrollingContainer>
                    </View>
                </Flex>
            )}
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useRef } from "react";

type UpdateNodeFunction = (nodeId: string) => void;

/**
 * Facilitate updating the active commenting node when scrolling the detail views
 * either vertically or horizontal.
 * @param viewRefs - The array of view ref objects.
 * @param isVerticalScroll - True if scrolling vertically.
 * @param artifactIds - The array of artifact node Ids.
 * @param updateActiveNode - The function for updating the active node Id.
 * @returns The handler for scrolling to the active node.
 */
export const useDetailGridViewScroll = (
    viewRefs: React.RefObject<HTMLDivElement>[],
    isVerticalScroll: boolean,
    artifactIds: string[],
    updateActiveNode?: UpdateNodeFunction,
) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const shouldPauseScrolling = useRef<boolean>(false);

    const handleScroll = (event: Event) => {
        if (
            shouldPauseScrolling.current ||
            viewRefs.some((ref) => !ref.current)
        ) {
            return;
        }

        let activeNodeIndex = 0;
        let activeSpan = 0;
        const scroller = event.target as HTMLDivElement;

        if (scroller) {
            const scrollerTopLeft = isVerticalScroll
                ? scroller.scrollTop
                : scroller.scrollLeft;
            const visibleScrollBottomRight =
                scrollerTopLeft +
                (isVerticalScroll
                    ? scroller.clientHeight
                    : scroller.clientWidth);

            for (let index = 0; index < viewRefs.length; index++) {
                const artifactElement = viewRefs[index]
                    .current as HTMLDivElement;
                if (!artifactElement) {
                    break;
                }
                const viewSpan = isVerticalScroll
                    ? artifactElement.clientHeight
                    : artifactElement.clientWidth;
                const viewTopLeft = isVerticalScroll
                    ? artifactElement.offsetTop
                    : artifactElement.offsetLeft;
                const viewBottomRight = viewTopLeft + viewSpan;
                if (
                    viewBottomRight > scrollerTopLeft &&
                    viewBottomRight < visibleScrollBottomRight
                ) {
                    // view's lower portion is visible
                    const visibleSpan = viewBottomRight - scrollerTopLeft;
                    if (visibleSpan > activeSpan) {
                        activeNodeIndex = index;
                        activeSpan = Math.min(visibleSpan, viewSpan);
                    }
                }

                if (
                    viewTopLeft > scrollerTopLeft &&
                    viewBottomRight < visibleScrollBottomRight
                ) {
                    // view's is entirely visible
                    if (viewSpan > activeSpan) {
                        activeNodeIndex = index;
                        activeSpan = viewSpan;
                    }
                }

                if (
                    viewTopLeft > scrollerTopLeft &&
                    viewTopLeft < visibleScrollBottomRight
                ) {
                    // view's upper portion is visible
                    const visibleSpan = visibleScrollBottomRight - viewTopLeft;
                    if (visibleSpan > activeSpan) {
                        activeNodeIndex = index;
                        activeSpan = Math.min(visibleSpan, viewSpan);
                    }
                }
            }
            if (updateActiveNode) {
                updateActiveNode(artifactIds[activeNodeIndex]);
            }
        }
    };

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.addEventListener("scroll", handleScroll);
        }
        return () => {
            scrollRef.current?.removeEventListener("scroll", handleScroll);
        };
    }, [scrollRef.current, artifactIds.toString()]);

    return { scrollRef, shouldPauseScrolling };
};

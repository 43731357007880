/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Button,
    Flex,
    Heading,
    Text,
    TextField,
    View,
} from "@adobe/react-spectrum";
import AddCircle from "@spectrum-icons/workflow/AddCircle";
import Edit from "@spectrum-icons/workflow/Edit";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { AssetInfoSection } from "./AssetInfoSection";
import { AssetTagSection } from "./AssetTagSection";
import { UsedInSection } from "./UsedInSection";
import { BaseInfoPanel } from "../../common/BaseInfoPanel";
import { CreatedBySection } from "../../common/CreatedBySection";
import { TimestampSection } from "../../common/TimestampSection";
import type { LibraryAsset } from "@src/contexts/RpcContext";
import { rpc } from "@src/contexts/RpcContext";
import { useFormatPhysicalSize } from "@src/interfaces/common/hooks/useFormatPhysicalSize";

import type { FC } from "react";

interface Props {
    asset: LibraryAsset;
}

export const AssetInfoPanel: FC<Props> = ({ asset }) => {
    const { t } = useTranslation(["common", "library"]);

    const { data } = rpc.libraryAssets.getAssetWithOwner.useQuery(asset.id);

    const modelInfo = asset.modelMetaData.source?.modelInfo;
    const modelBoundsInfo = asset.modelMetaData.source?.modelBoundsInfo;

    const { formatPhysicalSize } = useFormatPhysicalSize();

    function getPhysicalSize() {
        if (!modelInfo && !modelBoundsInfo) return;
        const info = modelBoundsInfo ?? modelInfo;
        return {
            width: formatPhysicalSize(info.bbSize[0]),
            height: formatPhysicalSize(info.bbSize[1]),
            depth: formatPhysicalSize(info.bbSize[2]),
        };
    }
    const physicalSize = useMemo(() => getPhysicalSize(), [modelInfo]);

    const updateProductIdMutation =
        rpc.libraryAssets.updateProductId.useMutation();
    const [productId, setProductId] = useState<string>(asset.productId);
    const [isEditingProductId, setIsEditingProductId] =
        useState<boolean>(false);
    const [shouldHighlightText, setShouldHighlightText] =
        useState<boolean>(false);

    function updateProductId() {
        const newValue = productId.trim();

        if (asset.productId !== newValue) {
            updateProductIdMutation.mutate({
                id: asset.id,
                productId: newValue,
            });
            setProductId(newValue);
        } else {
            setProductId(asset.productId);
        }

        setIsEditingProductId(false);
    }

    function cancelProductIdEdit() {
        setProductId(asset.productId);
        setIsEditingProductId(false);
    }

    const renderProductIdEditor = () => {
        return (
            <>
                <TextField
                    aria-label={t("library:detailView.detailsPanel.productID")}
                    data-uia="asset-detail-product-id-field"
                    width="100%"
                    marginBottom={"size-200"}
                    value={productId}
                    maxLength={100}
                    autoFocus
                    onFocus={(event: React.FocusEvent) => {
                        if (shouldHighlightText) {
                            (event.target as HTMLInputElement).select();
                            setShouldHighlightText(false);
                        }
                    }}
                    onChange={setProductId}
                    onKeyUp={(e) => {
                        e.preventDefault();
                        if (e.key === "Enter") {
                            updateProductId();
                        } else if (e.key === "Escape") {
                            cancelProductIdEdit();
                        }
                    }}
                />
                <Flex justifyContent="end" marginBottom="size-300">
                    <Button
                        variant="secondary"
                        marginEnd="size-100"
                        onPress={cancelProductIdEdit}>
                        {t("common:actions.cancel")}
                    </Button>
                    <Button
                        variant="secondary"
                        style="fill"
                        onPress={updateProductId}>
                        {t("common:actions.save")}
                    </Button>
                </Flex>
            </>
        );
    };

    const renderExistingProductId = () => {
        return (
            <Flex
                alignItems="center"
                justifyContent="space-between"
                marginBottom="size-300">
                <Text
                    UNSAFE_style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    flex={1}
                    marginEnd="size-100">
                    {productId}
                </Text>
                <ActionButton
                    isQuiet
                    onPress={() => {
                        setShouldHighlightText(true);
                        setIsEditingProductId(true);
                    }}>
                    <Edit />
                </ActionButton>
            </Flex>
        );
    };

    const renderAddProductId = () => {
        return (
            <Flex justifyContent="start" marginBottom="size-300">
                <ActionButton
                    isQuiet
                    onPress={() => {
                        setIsEditingProductId(true);
                    }}>
                    <AddCircle size="M" />
                    <Text
                        UNSAFE_style={{
                            fontWeight: "bold",
                        }}>
                        {t("library:detailView.detailsPanel.addProductID")}
                    </Text>
                </ActionButton>
            </Flex>
        );
    };

    return (
        <BaseInfoPanel>
            <View paddingX="size-400" paddingTop="size-300">
                <Flex direction={"column"} marginBottom="size-300">
                    <Heading level={4}>
                        {t("library:detailView.detailsPanel.productID")}
                    </Heading>
                    {isEditingProductId
                        ? renderProductIdEditor()
                        : productId
                        ? renderExistingProductId()
                        : renderAddProductId()}
                    {physicalSize && (
                        <>
                            <Heading level={4}>
                                {t("library:detailView.detailsPanel.size")}
                            </Heading>
                            <Text>
                                {t("library:detailView.detailsPanel.width", {
                                    width: physicalSize.width,
                                    units: modelBoundsInfo?.units ?? "meters",
                                })}
                            </Text>
                            <Text>
                                {t("library:detailView.detailsPanel.height", {
                                    height: physicalSize.height,
                                    units: modelBoundsInfo?.units ?? "meters",
                                })}
                            </Text>
                            <Text>
                                {t("library:detailView.detailsPanel.depth", {
                                    depth: physicalSize.depth,
                                    units: modelBoundsInfo?.units ?? "meters",
                                })}
                            </Text>
                        </>
                    )}
                    <CreatedBySection
                        avatarUrl={data?.avatarLink}
                        displayName={data?.displayName}
                    />
                    <TimestampSection
                        timestampType="uploadedAt"
                        timestamp={asset.createdAt}
                    />
                    {asset.projects?.length > 0 && (
                        <UsedInSection projectsUsedIn={asset.projects} />
                    )}
                    <AssetTagSection assetId={asset.id} tags={asset.tags} />
                    <AssetInfoSection asset={asset} />
                </Flex>
            </View>
        </BaseInfoPanel>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Grid, repeat } from "@adobe/react-spectrum";

import type { PropsWithChildren } from "react";

interface Props {
    width?: string /** The desired width of the card. */;
    gap?: string /** The desired gap between the card items. */;
    dataUia: string /** Attribute for test automation */;
}
export function CardGrid({
    children,
    width = "size-3000",
    gap = "size-250",
    dataUia,
}: PropsWithChildren<Props>) {
    return (
        <Grid
            data-uia={`${dataUia}-card-grid`}
            aria-label="Grid"
            columns={repeat("auto-fit", width)}
            autoRows="min-content"
            justifyContent="start"
            gap={gap}
            marginBottom="size-250">
            {children}
        </Grid>
    );
}

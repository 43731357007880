/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

function getSvgTemplate(
    text: string,
    strokeWidth: number,
    fillColor: string,
    strokeColor: string,
    opacity: number,
) {
    return `<svg
    version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve">
  <circle opacity="${opacity}" stroke-width="${strokeWidth}" cx="150" cy="150" r="${
        150 - strokeWidth
    }" fill="${fillColor}" stroke="${strokeColor}"/>
  <text opacity="${opacity}" x="150" y="200" text-anchor="middle" font-size="150">${text}</text>
</svg>
`;
}

export interface PinSpriteConfig {
    width: number;
    height: number;
    text?: string;
    strokeWidth?: number;
    fillColor?: string;
    strokeColor?: string;
    opacity?: number;
}

export async function pinSpriteGenerator({
    width,
    height,
    text = "",
    strokeWidth = 10,
    fillColor = "#ffffff",
    strokeColor = "#000000",
    opacity = 1,
}: PinSpriteConfig) {
    const svg = getSvgTemplate(
        text,
        strokeWidth,
        fillColor,
        strokeColor,
        opacity,
    );

    return new Promise<string>((res, rej) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = width;
        canvas.height = height;

        if (ctx) {
            const img = new Image();
            img.width = width;
            img.height = height;
            const timer = setTimeout(rej, 5000);

            img.onload = function () {
                clearTimeout(timer);
                ctx.drawImage(img, 0, 0);
                res(ctx.canvas.toDataURL());
            };

            img.src = `data:image/svg+xml,${encodeURIComponent(svg)}`;
        } else {
            rej();
        }
    });
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ASSET_COMPOSITE_TYPE } from "@shared/types";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useShareReviewPath } from "../../hooks/useShareReviewPath";
import { useShareSheet } from "../../hooks/useShareSheet";
import { useShareSheetCallbacks } from "../../hooks/useShareSheetCallbacks";
import { useAppContext } from "@src/contexts/AppContext";
import { rpc } from "@src/contexts/RpcContext";
import { ThemeContext } from "@src/contexts/ThemeContext";
import { useLocale } from "@src/interfaces/common/hooks/useLocale";
import type {
    ShareSheetCallbackData,
    ShareSheetAssetData,
    ShareDialogBooleanAttributes,
    ShareDialogStringAttributes,
} from "@src/types/ccac/CCACData";
import {
    ASSET_INVITE_NOTIFICATION,
    DEFAULT_SHARE_INVITATION_DIALOG_DATA,
    PROJECT_INVITE_NOTIFICATION,
} from "@src/types/ccac/CCACData";

import type { FC } from "react";

/**
 * This file provides access to the share/invite dialog using the component loader with share-sheet
 * web component.
 * See this integration guide for more detail:
 * https://git.corp.adobe.com/CCX/ccx-sharing/blob/main/packages/component-web/README.md
 */

/**
 * Default share sheet dialog configuration for boolean attributes.
 */
const SHARE_DIALOG_BOOLEAN_PROPS: ShareDialogBooleanAttributes = {
    disablelinkaccess: true,
    disablelinkcopy: true,
    disablepublicaccess: true,
    displaylinkcopy: false,
    hideclosebutton: false,
    hideheader: false,
    invitecreateacceptancerequired:
        DEFAULT_SHARE_INVITATION_DIALOG_DATA.inviteCreateAcceptanceRequired,
    invitecreateusemounts:
        DEFAULT_SHARE_INVITATION_DIALOG_DATA.inviteCreateUseMounts,
    invitemessageenabled: true,
    readonlylinkaccess: false,
    sendmessagerequestevent: false,
    showsettingsbutton: false,
    touchmode: false,
};

/**
 * Default share sheet dialog configuration for string attributes.
 */
const SHARE_DIALOG_STRING_PROPS: ShareDialogStringAttributes = {
    assetid: "",
    assetname: "",
    assettype: "",
    emailnotification: "",
    messageconfig: "",
    notificationlinkurl: "",
    projectdata: "",
    roles: JSON.stringify(["VIEW"]),
    targeturl: "",
    title: "Share document",
    wizarddata: "",
};

/**
 * Filter boolean attributes to only attributes whose values are "true".
 */
const filterBooleanAttributes = (attributes: ShareDialogBooleanAttributes) => {
    const result: Partial<ShareDialogBooleanAttributes> = {};
    for (const key in attributes) {
        if (attributes[key] !== false) {
            result[key] = attributes[key];
        }
    }
    return result;
};

/**
 * This interface defines the properties passed to ShareSheetView.
 * cc-share-dialog is the web component wrapper for the React ShareDialog component.
 */
export interface ShareSheetViewProps {
    id: string; // data id library asset.
    assetName: string; // asset name
    assetType: string; // asset composite type, 3D asset or project
    closeShareViewFunction: VoidFunction;
    onInviteFulfilled?: (data: ShareSheetCallbackData) => void;
    onRemoveFulfilled?: (data: ShareSheetCallbackData) => void;
}

export const ShareSheetView: FC<ShareSheetViewProps> = ({
    id,
    assetType,
    assetName,
    closeShareViewFunction,
    onInviteFulfilled,
    onRemoveFulfilled,
}) => {
    const { t } = useTranslation("common");
    const { ace, acp, logger } = useAppContext();
    const { isDarkMode } = useContext(ThemeContext);

    const [assetId, setAssetId] = useState("");
    const isAssetComposite = assetType === ASSET_COMPOSITE_TYPE;

    const { data: asset } = rpc.libraryAssets.get.useQuery(id);

    const { locale } = useLocale();

    useEffect(() => {
        if (isAssetComposite) {
            if (asset?.compositeId) {
                setAssetId(asset.compositeId);
            }
        } else {
            // share with the composite id of the containing project folder.
            acp.getProjectFolderCompositeId(id).then((assetId) => {
                setAssetId(assetId);
            });
        }
    }, [id, asset?.compositeId]);

    const templateName = isAssetComposite
        ? ASSET_INVITE_NOTIFICATION
        : PROJECT_INVITE_NOTIFICATION;
    const shareInviteTemplateData = DEFAULT_SHARE_INVITATION_DIALOG_DATA;
    shareInviteTemplateData.emailNotification.sharing = {
        viewer: templateName,
        editor: templateName,
    };

    const [updatedShareAssetData, setUpdatedShareAssetData] =
        useState<ShareSheetAssetData>();

    const { shareAssetData } = useShareSheet(assetId, assetName);
    const { resourceId, resourceViewUrl } = useShareReviewPath(
        assetId,
        assetType,
    );

    useEffect(() => {
        if (assetId && shareAssetData) {
            const updatedAssetData = {
                ...shareAssetData,
                resourceId,
                resourceViewUrl,
            };
            setUpdatedShareAssetData(updatedAssetData);
        }
    }, [assetId, resourceId, resourceViewUrl, shareAssetData]);

    const [shareSheetLoaded, setShareSheetLoaded] = useState<boolean>(false);
    const { stringProps, booleanProps } = useMemo(() => {
        const stringProps =
            shareSheetLoaded && updatedShareAssetData?.id
                ? {
                      ...SHARE_DIALOG_STRING_PROPS,
                      assetid: updatedShareAssetData.id,
                      assetname: assetName,
                      assettype: assetType,
                      title: t("shareDialog.title", {
                          name: assetName,
                          interpolation: { escapeValue: false },
                      }),
                      emailnotification: JSON.stringify(
                          shareInviteTemplateData.emailNotification,
                      ),
                      targeturl: resourceViewUrl,
                  }
                : SHARE_DIALOG_STRING_PROPS;
        // Need to only return boolean attributes whose values are set to true.
        const booleanProps = filterBooleanAttributes(
            SHARE_DIALOG_BOOLEAN_PROPS,
        );
        return { stringProps, booleanProps };
    }, [shareSheetLoaded, updatedShareAssetData?.id, resourceViewUrl]);

    useEffect(() => {
        if (
            updatedShareAssetData?.resourceId &&
            updatedShareAssetData?.resourceViewUrl &&
            !shareSheetLoaded
        ) {
            ace.loadShareSheet({
                locale,
                assetData: updatedShareAssetData,
                analyticsEnabled: false,
                theme: isDarkMode ? "darkest" : "light",
                features: {
                    groups: true,
                },
            })
                .then(() => {
                    // ccx-sharing documents that "cc-share-dialog" tag should exist
                    // if share-sheet loaded. But if for some reason it doesn't exist,
                    // we throw and close the dialog manually.
                    const loaded = Boolean(
                        customElements.get("cc-share-dialog"),
                    );
                    if (!loaded) {
                        throw new Error("Failed to load share-sheet");
                    }
                    // Setting this state inside a useEffect that also depends it
                    // will not cause an infinite loop in this case. If it gets set to
                    // true, then the dependency will trigger an early return.
                    setShareSheetLoaded(loaded);
                })
                .catch(() => {
                    logger.logError({
                        errorCode: "2191",
                        resources: [{ assetId: id, componentId: assetId }],
                    });
                    // close dialog manually to avoid an empty share dialog in this case.
                    closeShareViewFunction();
                });
        }
    }, [
        updatedShareAssetData?.resourceId,
        updatedShareAssetData?.resourceViewUrl,
        shareSheetLoaded,
        isDarkMode,
    ]);

    const { attachCallbacks, removeCallbacks } = useShareSheetCallbacks({
        onDialogClose: closeShareViewFunction,
        onInviteFulfilled,
        onRemoveFulfilled,
    });

    useEffect(() => {
        if (!shareSheetLoaded) {
            return;
        }
        const [shareDialog] = document.getElementsByTagName("cc-share-dialog");
        if (shareDialog) {
            attachCallbacks(shareDialog as HTMLElement);
        }
        return () => {
            if (shareDialog) {
                removeCallbacks(shareDialog as HTMLElement);
            }
        };
    }, [shareSheetLoaded]);

    // Using the web component wrapper of the React ShareDialog component
    return shareSheetLoaded && stringProps.assetid ? (
        <cc-share-dialog {...booleanProps} {...stringProps} />
    ) : null;
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { action, makeObservable, observable } from "mobx";

interface ConnectableClass {
    connect: () => Promise<void>;
}

export function connected(
    target: ConnectableClass,
    propertyKey: string | symbol,
    descriptor: TypedPropertyDescriptor<(...args: any[]) => Promise<any>>,
) {
    const connectedMethod = descriptor.value!;
    descriptor.value = async function (...args) {
        await (this as ConnectableClass).connect();
        return connectedMethod?.apply(this, args);
    };
}

export abstract class ConnectedService {
    constructor() {
        makeObservable(this);
    }

    @observable
    connected = false;

    @action
    setConnected() {
        this.connected = true;
    }

    private initializer?: Promise<void>;

    async connect() {
        if (this.connected) {
            return;
        }
        if (this.initializer) {
            return this.initializer;
        }
        this.initializer = this.initialize();
        this.initializer.then(() => {
            this.setConnected();
            this.initializer = undefined;
        });
        return this.initializer;
    }

    abstract initialize(): Promise<void>;
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { downloadZip } from "@shared/client/src/download";
import {
    getRenderSettings,
    getTemplate,
    WEBVIEWER_VARIANT,
} from "@shared/common";
import { useTranslation } from "react-i18next";

import { config } from "@src/config";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";
import type {
    HeliosRPCClient,
    LogErrorCode,
    ProjectDownloadInfo,
    LogResources,
} from "@src/contexts/RpcContext";
import { useUploadDownloadContext } from "@src/contexts/UploadDownloadContext";
import type { HeliosLogger } from "@src/lib/services/HeliosLogger";
import type { ImsUser } from "@src/lib/services/ims";

import type { HeliosApi } from "@components/hermes";
import type {
    BlobConfig,
    DownloadConfig,
    DownloadZipConfig,
    FolderConfig,
    SaveConfig,
} from "@shared/client/src/download";
import type { RenderSettings, TemplateEntry } from "@shared/types";

export function useDownloads(apiClient: HeliosRPCClient) {
    const { t } = useTranslation(["studio", "common"]);
    const { logIngestEvent } = useAnalyticsContext();
    const { addDownload, removeDownload } = useUploadDownloadContext();

    function buildShotFilename(libraryAssetName: string, camera: string) {
        return `${libraryAssetName}-${t(camera)}.png`;
    }

    function buildHotspotFilename(libraryAssetName: string) {
        return `${libraryAssetName}-hotspots.json`;
    }

    function buildModelFilename(
        libraryAssetName: string,
        type: HeliosApi.ModelType,
        fileExtension: string,
    ) {
        return `${libraryAssetName}-${type}${fileExtension}`;
    }

    function buildFolderFilename(libraryAssetName: string, createdAt: string) {
        const dateString = Intl.DateTimeFormat(undefined, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
        })
            .format(new Date(createdAt))
            .replace(/[/|\\|:]/gi, ".");
        return `${libraryAssetName} ${dateString}`;
    }

    function buildShotDownloadConfig(
        camera: string,
        libraryAssetName: string,
        isPreview: boolean,
        ims: ImsUser,
        logger: HeliosLogger,
        url?: string,
    ): DownloadConfig | BlobConfig | undefined {
        if (!url) {
            return undefined;
        }
        const fileName = buildShotFilename(libraryAssetName, camera);
        return {
            url,
            fileName,
        };
    }

    function buildModelDownloadConfig(
        libraryAssetName: string,
        fileExtension: string,
        type: HeliosApi.ModelType,
        url?: string,
    ): DownloadConfig | undefined {
        if (!url) {
            return undefined;
        }
        return {
            url,
            fileName: buildModelFilename(libraryAssetName, type, fileExtension),
        };
    }

    function buildHotspotSaveConfig(
        libraryAssetName: string,
        data?: string,
    ): SaveConfig | undefined {
        if (!data) {
            return undefined;
        }
        return {
            data,
            fileName: buildHotspotFilename(libraryAssetName),
        };
    }

    function buildViewerSettingsSaveConfig(
        libraryAssetName: string,
        viewerSettings?: RenderSettings,
    ): SaveConfig | undefined {
        if (!viewerSettings) {
            return undefined;
        }
        return {
            data: JSON.stringify(viewerSettings),
            fileName: `${libraryAssetName}-config.json`,
        };
    }

    async function downloadProjectInternal(
        projectId: string,
        ims: ImsUser,
        logger: HeliosLogger,
    ) {
        let projectDownloadInfo: ProjectDownloadInfo | undefined;
        const assetsToInclude: DownloadZipConfig[] = [
            { fileName: "readme.txt", data: config.developerReadmeUrl },
        ];
        const errorFiles: string[] = [];
        const resources: LogResources = [
            {
                dbId: projectId,
                dbTable: "Project",
            },
        ];
        try {
            projectDownloadInfo =
                await apiClient.projects.getProjectDownloadInfo.query(
                    projectId,
                );
            const assetIdToZipFolderConfig = new Map<string, FolderConfig>();
            let viewerSettings: RenderSettings | undefined;

            if (projectDownloadInfo.template) {
                const template = getTemplate(
                    projectDownloadInfo.template,
                ).template;
                viewerSettings = getRenderSettings(
                    template as unknown as TemplateEntry,
                    WEBVIEWER_VARIANT,
                );
                assetsToInclude.push({
                    fileName: `${template.iblFilename}.env`,
                    url: `/assets/ibl/${template.iblFilename}.env`,
                });
                // Do we need both?  Are the project files used in the 2D renders?
                assetsToInclude.push({
                    fileName: `${template.iblFilename}.hdr`,
                    url: `/assets/ibl/${template.iblFilename}.hdr`,
                });
            }

            if (!viewerSettings) {
                logger.logError({ errorCode: "2077", resources });
            }

            const getFolderForId: (info: {
                libraryAssetId: string;
                libraryAssetName: string;
                createdAt: string;
            }) => FolderConfig = ({
                libraryAssetId,
                libraryAssetName,
                createdAt,
            }) => {
                let folder = assetIdToZipFolderConfig.get(libraryAssetId);
                if (!folder) {
                    folder = {
                        fileName: buildFolderFilename(
                            libraryAssetName,
                            createdAt,
                        ),
                        children: [],
                    };
                    assetIdToZipFolderConfig.set(libraryAssetId, folder);
                }
                return folder;
            };

            projectDownloadInfo.renders.forEach((render) => {
                const renderConfig = buildShotDownloadConfig(
                    render.camera,
                    render.libraryAssetName,
                    render.isPreview,
                    ims,
                    logger,
                    render.url,
                );
                if (!renderConfig) {
                    return;
                }
                getFolderForId(render).children.push(renderConfig);
            });
            projectDownloadInfo.models.forEach((model) => {
                const modelConfig = buildModelDownloadConfig(
                    model.libraryAssetName,
                    model.fileExtension,
                    model.type,
                    model.url,
                );
                if (modelConfig) {
                    getFolderForId(model).children.push(modelConfig);
                }
                const hotspotData = buildHotspotSaveConfig(
                    model.libraryAssetName,
                    model.hotspotData,
                );
                if (hotspotData) {
                    getFolderForId(model).children.push(hotspotData);
                }
                const viewerSettingsConfig = buildViewerSettingsSaveConfig(
                    model.libraryAssetName,
                    viewerSettings,
                );
                if (viewerSettingsConfig) {
                    getFolderForId(model).children.push(viewerSettingsConfig);
                }
            });
            projectDownloadInfo.failures.models.forEach((failedModel) => {
                const folderName = buildFolderFilename(
                    failedModel.libraryAssetName,
                    failedModel.createdAt,
                );
                errorFiles.push(
                    `${folderName}/${buildModelFilename(
                        failedModel.libraryAssetName,
                        failedModel.type,
                        failedModel.fileExtension,
                    )}`,
                );
                if (failedModel.hasHotspots) {
                    errorFiles.push(
                        `${folderName}/${buildHotspotFilename(
                            failedModel.libraryAssetName,
                        )}`,
                    );
                }
            });
            projectDownloadInfo.failures.renders.forEach((failedRender) => {
                errorFiles.push(
                    `${buildFolderFilename(
                        failedRender.libraryAssetName,
                        failedRender.createdAt,
                    )}/${buildShotFilename(
                        failedRender.libraryAssetName,
                        failedRender.camera,
                    )}`,
                );
            });
            if (!assetIdToZipFolderConfig.size) {
                logger.logError({
                    errorCode: "2072",
                    resources,
                });
                throw new Error("No valid download configs");
            }
            assetsToInclude.push(...assetIdToZipFolderConfig.values());
        } catch (err) {
            throw err;
        }

        if (!projectDownloadInfo) {
            throw new Error("Insufficient information to initiate download.");
        }

        return downloadZip(
            `${projectDownloadInfo.name}.zip`,
            assetsToInclude,
            errorFiles,
            {
                errorPrompt: t("common:download.error.file.prompt"),
                logError: (errorType) => {
                    let errorCode: LogErrorCode | undefined;
                    switch (errorType) {
                        case "blobError":
                            errorCode = "2073";
                            break;
                        case "folderError":
                            errorCode = "2074";
                            break;
                        case "fetchError":
                            errorCode = "2076";
                            break;
                    }
                    if (errorCode) {
                        logger.logError({
                            errorCode,
                            resources,
                        });
                    }
                },
            },
        )
            .catch((err) => {
                logger.logError({
                    errorCode: "2070",
                    resources,
                });
                throw err;
            })
    }

    async function downloadProject(
        projectId: string,
        projectName: string,
        ims: ImsUser,
        logger: HeliosLogger,
    ) {
        const id = addDownload(projectName);
        const projectContextFields = {
            "event.context_guid": projectId,
            "content.extension": "zip",
            "content.name": projectName,
        };
        logIngestEvent("projectDownloadStart", projectContextFields);
        return downloadProjectInternal(projectId, ims, logger)
            .catch((e) => {
                logIngestEvent("projectDownloadError", projectContextFields);
                throw e;
            })
            .then(() => {
                logIngestEvent("projectDownloadSuccess", projectContextFields);
            })
            .finally(() => {
                removeDownload(id);
            });
    }

    return {
        downloadProject,
    };
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { createHeaders, getWorkfrontApiObjectBaseUrl } from "../util";
import { WF_OBJECT_CODE_STATUSES } from "../constants";
import { AbstractStatusListResponse, AbstractStatusResponse } from "./types/AbstractStatusResponse";
import { WorkfrontParams } from "../../../types/wfParams";

export const fetchWorkfrontTaskPossibleStatuses = async (
    wfParams: WorkfrontParams,
): Promise<AbstractStatusResponse[]> => {
    const removeStatusDuplicates = (
        statuses: AbstractStatusResponse[],
    ): AbstractStatusResponse[] => {
        return statuses.filter((result, index, self) => {
            return self.findIndex((t) => t.label === result.label) === index;
        });
    };

    try {
        const headers = createHeaders(wfParams.imsToken);
        const res = await fetch(
            `${getWorkfrontApiObjectBaseUrl({
                objCode: WF_OBJECT_CODE_STATUSES,
                wfInstanceUrl: wfParams.wfInstanceUrl,
            })}/search?enumClass=STATUS_TASK`,
            {
                headers,
            },
        );
        const apiResponse: AbstractStatusListResponse = await res.json();

        const taskPossibleStatuses: AbstractStatusResponse[] = removeStatusDuplicates(
            apiResponse.data,
        );

        return taskPossibleStatuses;
    } catch (e) {
        throw new Error("Can not fetch possible task statuses");
    }
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import Cloud from "@spectrum-icons/workflow/Cloud";

import { useMessagingContext } from "@src/contexts/MessagingContext";
import { rpc } from "@src/contexts/RpcContext";
import {CloudActivity} from "@src/icons/CloudActivity"
import { CloudError } from "@src/icons/CloudError";

export function ActivityPanelIcon() {
    const { data: processingCount } = rpc.libraryAssets.getCount.useQuery({
        filters: ["processing", "myAssets"],
    })
    const { persistentMessages } = useMessagingContext();


    if (processingCount) {
        return <CloudActivity size="M"/>
    }

    if (persistentMessages.length) {
        return <CloudError size="M"/>
    }

    return <Cloud size="M"/>

}

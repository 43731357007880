/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ComponentLoader } from "@ccx-public/component-loader";
import { createContext, useContext, useMemo } from "react";

import type { ShareSheet, Commenting, Env } from "@ccx-public/component-loader";
import type { WithOptional } from "@shared/types/src";
import type { PropsWithChildren } from "react";

/**
 * For sharing for review integration.
 */
// const SHARE_SHEET_ARTIFACT_ID = "artifact";
// const SHARE_SHEET_SHARE_ID = "share";
// const IMS_SERVICE_ACCOUNT_CODE = "creative_cloud";
// const IMS_SERVICE_LEVEL = "CS_LVL_2";

const COMMENTING_VERSION = "30.10.0";
const SHARE_SHEET_VERSION = "30.2.1";

/**
 * Default share sheet dialog feature configuration.
 */
// const SHARE_SHEET_FEATURE_CONFIG = {
//     analyticsEnabled: false,
//     dialogId: "share",
//     invitationAPIVersion: "v4",
//     theme: "darkest",
// };

export function getShareSheetAnalyticsData(config: {
    imsClientId: string;
    appName: string;
}) {
    return {
        event: {
            // Currently, there doesn't seem to be consensus on how to set this.
            // One recommendation is to use the Machine ID.
            // This needs to be a stable ID to use in future sessions and needs to be
            // reliable to detect the device.
            // Passing an empty string for now since the actual analytics collection is disabled.
            device_guid: "", // RFC 4122 - v4 compliant (TODO: Verify)
            category: "WEB",
        },
        source: {
            client_id: config.imsClientId,
            name: config.appName,
            platform: "web",
            version: SHARE_SHEET_VERSION,
        },
        ui: {
            view_type: config.appName,
        },
    };
}

// Basic minimum required share-sheet asset data for initial load of the share-sheet component.
// const BlankShareAssetData = {
//     id: "",
//     assetId: "",
//     resourceId: "",
//     resourceViewUrl: "",
//     mimeType: "",
//     name: "",
//     type: "",
//     useProductViewUrl: true,
// };

const COMMENTS_FEATURE_CONFIG = {
    spectrumUIScale: "medium",
    annotationsMultiPageEnabled: true,
    annotationTypes: {
        pin: true,
        draw: true,
        strikethrough: true,
        insert: true,
        replace: true,
        highlight: true,
    },
    groupBy: "node",
    enableEmojiPicker: true,
    enableReadUnread: false,
    mentionsEnabled: true,
    pollForUpdates: true,
    locale: "en",
    globalCommentsViewAll: false,
    annotationsCreationEnabled: true,
    isOffline: false,
    authenticateFetch: true,
    cacheComments: true,
    showBetaLabel: false,
    peopleGraphAPIKey: "",

};

// const COMMENTS_LIST_TAG_NAME = "cc-comments-list";

export function getCommentingAnalyticsData(config: {
    imsClientId: string;
    appName: string;
}) {
    return {
        event: {
            session_guid: "",
        },
        source: {
            client_id: config.imsClientId,
            name: config.appName,
            platform: "web",
        },
        ui: {
            view_type: config.appName,
        },
    };
}

export interface AceContextValue {
    loadShareSheet: () => Promise<void>;
    loadComments: () => Promise<void>;
}

export const AceContext = createContext<AceContextValue>({} as AceContextValue);

export function useAceContext() {
    const context = useContext(AceContext);
    if (!context) {
        throw new Error("useAceContext must be used within a AceProvider.");
    }
    return context;
}

export type ShareSheetOptions = Parameters<ShareSheet.Init>[0];
export type CommentingOptions = Parameters<Commenting.Init>[0];
export type AceImsData = CommentingOptions["imsData"];

/**
 * IMS data for share for review.
 * Used in ccx-comments and ccx-sharing.
 * sharing: https://git.corp.adobe.com/CCX/ccx-sharing/tree/main/packages/component-react#imsdata-format
 * commenting: https://git.corp.adobe.com/CCX/ccx-comments/tree/main/packages/ccx-comments#imsdata-prop-details
 * Note that optional data fields are used for analytics only.
 */
export interface CCACImsData {
    id: string;
    token: string;
    isPaid?: boolean;
    countryCode?: string;
    email?: string;
    name?: string;
    serviceCode?: string;
    serviceLevel?: string;
}

export interface AceProviderProps {
    adobeEnv: Env;
    imsData: CCACImsData;
    shareSheetOptions?: ShareSheetOptions;
    commentingOptions?: WithOptional<
        CommentingOptions,
        | "spectrumUIScale"
        | "locale"
        | "globalCommentsViewAll"
        | "annotationsCreationEnabled"
        | "isOffline"
        | "authenticateFetch"
        | "cacheComments"
    >;
}

export const AceProvider = ({
    adobeEnv,
    imsData,
    shareSheetOptions,
    commentingOptions,
    children,
}: PropsWithChildren<AceProviderProps>) => {
    const componentLoader = useMemo(
        () => new ComponentLoader({ cdnEnvironment: adobeEnv }),
        [adobeEnv],
    );

    async function loadShareSheet() {
        if (!shareSheetOptions) {
            throw new Error("No comments config provided");
        }
        await componentLoader.load("ShareSheet", SHARE_SHEET_VERSION, {
            imsData,
            ...shareSheetOptions,
        });
    }

    async function loadComments() {
        if (!commentingOptions) {
            throw new Error("No comments config provided");
        }
        return await componentLoader.load("Commenting", COMMENTING_VERSION, {
            ...COMMENTS_FEATURE_CONFIG,
            ...commentingOptions,
            imsData,
        });
    }

    return (
        <AceContext.Provider
            value={{
                loadShareSheet,
                loadComments,
            }}>
            {children}
        </AceContext.Provider>
    );
};

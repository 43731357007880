/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Icon, IconProps } from "@adobe/react-spectrum";

export function CloudError(props: Omit<IconProps, "children">) {
    return (
        <Icon {...props}>
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.0555 11.4445C12.0561 10.1989 12.4796 8.99045 13.2566 8.01691C14.0336 7.04337 15.1181 6.36248 16.3325 6.08572C16.3328 6.06613 16.3365 6.04752 16.3365 6.02786C16.3364 4.93617 15.921 3.88541 15.1744 3.08891C14.4278 2.29241 13.4061 1.80986 12.3167 1.73923C11.2273 1.66861 10.1518 2.0152 9.30867 2.70863C8.46551 3.40207 7.91782 4.39039 7.77681 5.47293C7.16319 5.3084 6.51709 5.30819 5.90336 5.47231C5.28963 5.63643 4.72988 5.95911 4.28029 6.40796C3.8307 6.85681 3.50709 7.41603 3.34196 8.02949C3.17683 8.64294 3.17598 9.28904 3.33949 9.90293C3.06158 9.8547 2.77667 9.86474 2.50285 9.93243C2.22902 10.0001 1.97225 10.124 1.74881 10.2961C1.52538 10.4683 1.34016 10.685 1.2049 10.9325C1.06964 11.18 0.987292 11.453 0.96308 11.734C0.938868 12.015 0.97332 12.298 1.06425 12.565C1.15518 12.832 1.30061 13.0772 1.4913 13.2851C1.682 13.4929 1.9138 13.6589 2.17202 13.7724C2.43023 13.8859 2.70922 13.9445 2.99129 13.9446H12.6608C12.2632 13.1712 12.0557 12.3141 12.0555 11.4445Z"
                />
                <path
                    d="M17.5552 7.06958C16.6899 7.06958 15.844 7.32617 15.1246 7.8069C14.4051 8.28763 13.8443 8.97091 13.5132 9.77034C13.1821 10.5698 13.0954 11.4494 13.2642 12.2981C13.4331 13.1468 13.8497 13.9263 14.4616 14.5382C15.0734 15.15 15.853 15.5667 16.7017 15.7355C17.5503 15.9043 18.43 15.8177 19.2294 15.4866C20.0288 15.1554 20.7121 14.5947 21.1929 13.8752C21.6736 13.1557 21.9302 12.3099 21.9302 11.4446C21.9302 10.2843 21.4692 9.17146 20.6488 8.35099C19.8283 7.53052 18.7155 7.06958 17.5552 7.06958Z"
                    fill="#EA3829"
                />
                <path
                    d="M17.2131 11.9446H17.8964C17.9752 11.9446 17.9915 11.8262 17.9915 11.8024C18.1142 10.8795 18.1854 9.95052 18.2047 9.01973V8.21236C18.2047 8.18855 18.1094 8.13264 18.0439 8.10836C17.8787 8.06559 17.7079 8.04832 17.5374 8.05715C17.3755 8.04454 17.2126 8.0619 17.0569 8.10836C16.9934 8.13265 16.906 8.18129 16.906 8.21236V9.01973C16.906 9.89796 17.0894 11.5806 17.1154 11.8024C17.1279 11.8335 17.1608 11.9446 17.2131 11.9446Z"
                />
                <path
                    d="M17.5554 12.9446C17.4128 12.9447 17.2734 12.987 17.1549 13.0663C17.0363 13.1456 16.944 13.2582 16.8895 13.39C16.835 13.5218 16.8208 13.6668 16.8486 13.8066C16.8765 13.9465 16.9452 14.0749 17.0461 14.1757C17.147 14.2765 17.2755 14.3451 17.4154 14.3729C17.5552 14.4006 17.7002 14.3863 17.8319 14.3317C17.9637 14.2771 18.0762 14.1846 18.1554 14.066C18.2346 13.9474 18.2768 13.808 18.2768 13.6654C18.2766 13.4742 18.2005 13.2909 18.0652 13.1557C17.9299 13.0206 17.7466 12.9446 17.5554 12.9446Z"
                />
            </svg>
        </Icon>
    );
}

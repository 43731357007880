/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Composite,
    DirectoryMediaType,
    getDirectory,
    getIndexDocument,
    getPagedChildren,
    Properties,
    resolveAsset,
    Directory,
} from "@dcx/assets";
import {
    createHTTPService,
    createRepoAPISession,
    newDCXComposite,
    pullCompositeManifestOnly,
    pushComposite,
    uploadComponent,
    uploadNewComponent,
} from "@dcx/dcx-js";
import { getSunriseEnv } from "@shared/common";

import { connected, ConnectedService } from "./ConnectedService";
import { getContentType } from "../utils/filenameHelper";
import { config } from "@src/config";
import type { ImsUser } from "@src/lib/services/ims";

import type { HeliosLogger } from "./HeliosLogger";
import type { AdobeAsset } from "@dcx/assets";
import type {
    AdobeDCXError,
    ProgressCallback,
    AssetWithRepoAndPathOrId,
} from "@dcx/common-types";
import type { AdobeHTTPService, AdobeRepoAPISession } from "@dcx/dcx-js";

const BYTES_IN_ONE_MB = 1024 * 1024;

type CloudContent = Required<
    Pick<AdobeAsset, "assetId" | "repositoryId" | "path">
>;

export type DirectoryResult = AdobeAsset & {
    [Properties.REPO_ASSET_ID]: string;
    [Properties.REPO_PATH]: string;
    [Properties.REPO_REPOSITORY_ID]: string;
};

export type CompositeVersionInfo = {
    version: string;
    created_by: string;
    created: string;
};

export type ComponentInfo = {
    id?: string;
    path?: string;
    version?: string;
};

type StorageStats = {
    size: number;
    count: number;
    medianSize: number;
};

export class ACP extends ConnectedService {
    private imsUser!: ImsUser;
    private _service!: AdobeHTTPService;
    private _session!: AdobeRepoAPISession;
    private _cloudContent!: CloudContent;
    private _indexRootDir!: Directory;
    private _logger: HeliosLogger;

    constructor(imsUser: ImsUser, logger: HeliosLogger) {
        super();
        this._logger = logger;
        this.imsUser = imsUser;
        this.connect();
    }

    get service() {
        return this._service;
    }

    get session() {
        return this._session;
    }

    get cloudContent() {
        return this._cloudContent;
    }

    async initialize() {
        await this.imsUser.connect();
        if (this.imsUser.accessToken === undefined) {
            this._logger.logError({
                errorCode: "2013",
            });
            throw new Error("Access token for user does not exist");
        }

        const service = createHTTPService((service) => {
            service?.setApiKey(config.imsClientId);
            service?.setAuthToken(this.imsUser.accessToken as string);
        });

        const session = createRepoAPISession(service, config.hostAcp);

        this._indexRootDir = await this.getRootDirAdobeAsset(service);
        if (!this._indexRootDir || !this._indexRootDir.repositoryId) {
            this._logger.logError({
                errorCode: "2014",
            });
            throw new Error(`Could not get root directory or repository ID.`);
        }

        await this.ensureDirectoryFromIndexRoot(
            service,
            `cloud-content/${getSunriseEnv(config.env, config.hostHermes)}`,
        );
        const cloudContent = await this.getCloudContentAsset(service);

        this._service = service;
        this._session = session;
        this._cloudContent = cloudContent;
    }

    private async getRootDirAdobeAsset(service: AdobeHTTPService) {
        let rootDir: Directory | undefined;

        try {
            const indDoc = await getIndexDocument(service).catch((err) => {
                this._logger.logError({
                    errorCode: "2015",
                    statusCode: (err as AdobeDCXError)?.response?.statusCode,
                });
                throw new Error(
                    `[dcx-js] Bad response on discovering index doc because ${err}`,
                );
            });

            const {
                result: { assignedDirectories },
            } = indDoc;

            if (
                Array.isArray(assignedDirectories) &&
                assignedDirectories.length > 0
            ) {
                const indexRepoId = assignedDirectories[0].repositoryId;
                const indexAssetId = assignedDirectories[0].assetId;
                let indexPath = assignedDirectories[0].path;
                if (indexPath) {
                    indexPath += indexPath.endsWith("/") ? "" : "/";
                }

                if (!indexRepoId || !indexAssetId) {
                    this._logger.logError({
                        errorCode: "2016",
                    });
                    throw new Error(
                        `[dcx-js] Unable to read required data from the index document`,
                    );
                }

                rootDir = new Directory(
                    {
                        repositoryId: indexRepoId,
                        assetId: indexAssetId,
                        path: indexPath,
                    } as AdobeAsset,
                    service,
                );
            }
        } catch (err) {
            this._logger.logError({
                errorCode: "2015",
                statusCode: (err as AdobeDCXError)?.response?.statusCode,
            });
            throw new Error(
                `[dcx-js] Bad response on discovering index document.`,
            );
        }

        if (!rootDir) {
            this._logger.logError({
                errorCode: "2014",
            });
            throw new Error(`Could not get root directory.`);
        }

        return rootDir;
    }

    /**
     * @param {AdobeHTTPService} svc        HTTP Service
     * @param {string} path                 The path to ensure. Do NOT include a leading "/" as this will be handled for you.
     * @returns {Promise<AdobeAsset>}
     */
    private async ensureDirectoryFromIndexRoot(
        service: AdobeHTTPService,
        path: string,
    ) {
        const pathFromRoot = `${this._indexRootDir.path}${path}`;

        let result: AdobeAsset | undefined;
        const asset = {
            repositoryId: this._indexRootDir.repositoryId,
            path: pathFromRoot,
        };
        try {
            // Directory result, if not newly created uses the "repo:" keys.
            const directoryResultRaw = await getDirectory(service, asset);
            const directoryResult =
                directoryResultRaw.result as unknown as DirectoryResult;
            result = {
                repositoryId: directoryResult[Properties.REPO_REPOSITORY_ID],
                assetId: directoryResult[Properties.REPO_ASSET_ID],
                path: directoryResult[Properties.REPO_PATH],
            };
        } catch (e) {
            // eslint-disable-next-line no-console
            console.info(
                `Directory at ${pathFromRoot} did not exist. Attempt to create.`,
            );
        }

        if (!result) {
            try {
                const { result: createResult, response } =
                    await this._indexRootDir.createAsset(
                        path,
                        true,
                        DirectoryMediaType,
                    );
                if (!createResult) {
                    this._logger.logError({
                        errorCode: "2017",
                        statusCode: response.statusCode,
                    });
                    throw new Error(
                        `Failed to create ${pathFromRoot} directory.`,
                    );
                }
                result = createResult;
            } catch (e) {
                this._logger.logError({
                    errorCode: "2017",
                    statusCode: (e as AdobeDCXError)?.response?.statusCode,
                });
                throw new Error(`Failed to create ${pathFromRoot} directory`);
            }
        }
        return result;
    }

    private async getCloudContentAsset(service: AdobeHTTPService) {
        const cloudContentResult = await this.ensureDirectoryFromIndexRoot(
            service,
            "cloud-content",
        );

        // If the ensured directory was freshly created, access the properties like AdobeAsset.
        if (cloudContentResult.repositoryId) {
            return {
                repositoryId: cloudContentResult.repositoryId,
                assetId: cloudContentResult.assetId,
                path: cloudContentResult.path,
            } as CloudContent;
        }

        // Directory result, if not newly created uses the "repo:" keys.
        const cloudContentDirectoryResult =
            cloudContentResult as DirectoryResult;
        return {
            repositoryId:
                cloudContentDirectoryResult[Properties.REPO_REPOSITORY_ID],
            assetId: cloudContentDirectoryResult[Properties.REPO_ASSET_ID],
            path: cloudContentDirectoryResult[Properties.REPO_PATH],
        } as CloudContent;
    }

    private async forEachFileInDirectory(
        dir: AdobeAsset,
        recursive: boolean,
        includeDirectories: boolean,
        callback: (child: AdobeAsset) => Promise<void>,
    ): Promise<void> {
        const flattenDirectoryChildren = async (
            items: AdobeAsset[],
        ): Promise<AdobeAsset[]> => {
            return (
                await Promise.all(
                    items.map(async (asset) => {
                        if (asset.assetClass === "directory") {
                            const retVal = includeDirectories ? [asset] : [];
                            if (!recursive) {
                                return retVal;
                            }
                            const children: AdobeAsset[] = [];
                            let page = await getPagedChildren(
                                this._service,
                                asset,
                            );
                            do {
                                children.push(...page.paged.items);
                            } while ((page = await page.paged.getNextPage()));

                            return [
                                ...retVal,
                                ...(await flattenDirectoryChildren(children)),
                            ];
                        }
                        return [asset];
                    }),
                )
            ).flat();
        };

        const assetWithRepoIdAndBaseId = <AssetWithRepoAndPathOrId>{
            repositoryId: dir.repositoryId,
            assetId: dir.assetId,
        };
        const { result: resolvedDir } = await resolveAsset(
            this._service,
            assetWithRepoIdAndBaseId,
            "id",
        );
        let page = await getPagedChildren(this._service, resolvedDir);
        do {
            const flatChildren = await flattenDirectoryChildren([
                ...page.paged.items,
            ]);
            await Promise.all(flatChildren.map((child) => callback(child)));
        } while ((page = await page.paged.getNextPage()));
    }

    /**
     * @param numbers An array of numbers
     * @returns the median or zero if the array is empty.
     */
    private getMedian(numbers: number[]): number {
        if (numbers.length === 0) {
            return 0;
        }
        if (numbers.length === 1) {
            return numbers[0];
        }
        const sortedNumbers = numbers.sort((a, b) => a - b);
        const middle = Math.floor(sortedNumbers.length / 2);
        return (
            (numbers.length % 2 !== 0
                ? sortedNumbers[middle]
                : (sortedNumbers[middle - 1] + sortedNumbers[middle]) / 2) || 0
        );
    }

    @connected
    public async getAssetsDirStats(): Promise<StorageStats> {
        const assetsDir = (await this.ensureDirectoryFromIndexRoot(
            this._service,
            `cloud-content/${getSunriseEnv(
                config.env,
                config.hostHermes,
            )}/assets`,
        )) as CloudContent;

        let totalSize = 0;
        let count = 0;
        const files: AdobeAsset[] = [];

        await this.forEachFileInDirectory(
            assetsDir,
            true,
            false,
            async (asset) => {
                const size = asset.size ?? 0;
                totalSize += size;
                count++;
                files.push(asset);
            },
        );

        const sizeArray = files
            .map((asset) => asset.size ?? 0)
            .filter((size) => size !== 0);
        const medianSize = this.getMedian(sizeArray);
        return {
            size: Math.round(totalSize / BYTES_IN_ONE_MB),
            count,
            medianSize: Math.round(medianSize / BYTES_IN_ONE_MB),
        };
    }

    @connected
    public async getProjectsDirStats(): Promise<StorageStats> {
        const projectsDir = (await this.ensureDirectoryFromIndexRoot(
            this._service,
            `cloud-content/${getSunriseEnv(
                config.env,
                config.hostHermes,
            )}/projects`,
        )) as CloudContent;

        let totalSize = 0;
        let count = 0;
        const sizeArray: number[] = [];

        await this.forEachFileInDirectory(
            projectsDir,
            false,
            true,
            async (projectDir) => {
                let projectSize = 0;
                await this.forEachFileInDirectory(
                    projectDir,
                    true,
                    false,
                    async (asset) => {
                        projectSize += asset.size ?? 0;
                    },
                );
                totalSize += projectSize;
                count++;
                sizeArray.push(projectSize);
            },
        );

        const medianSize = this.getMedian(
            sizeArray.filter((size) => size !== 0),
        );
        return {
            size: Math.round(totalSize / BYTES_IN_ONE_MB),
            count,
            medianSize: Math.round(medianSize / BYTES_IN_ONE_MB),
        };
    }

    // TODO: Remove this and clean up TestLibrary
    @connected
    public async getCompositeVersions(
        assetId: string,
    ): Promise<CompositeVersionInfo[]> {
        const composite = this.getCompositeFromId(assetId);
        const { result } = await composite.getPagedVersions({});
        return result.children;
    }

    // TODO: Remove this and clean up TestLibrary
    @connected
    public async getComponentVersions(
        compositeId: string,
    ): Promise<ComponentInfo[]> {
        const composite = this.getCompositeFromId(compositeId);
        const dcxComposite = this.getDcxCompositeFromComposite(composite);
        const branch = await pullCompositeManifestOnly(
            this.session,
            dcxComposite,
        );
        dcxComposite.resolvePullWithBranch(branch);

        const components = dcxComposite.current.getComponentsOf();

        return components.map((value) => {
            return {
                id: value.id,
                version: value.version,
                path: value.path,
            };
        });
    }

    @connected
    public async uploadComponent(
        compositeId: string,
        file: File,
        path: string,
        componentId = crypto.randomUUID(),
        progressCallback?: ProgressCallback,
    ) {
        const composite = this.getCompositeFromId(compositeId);
        const dcxComposite = this.getDcxCompositeFromComposite(composite);
        const branch = await pullCompositeManifestOnly(
            this.session,
            dcxComposite,
        );
        dcxComposite.resolvePullWithBranch(branch);

        const current = dcxComposite.current;

        const arrBuffer = await file.arrayBuffer();

        const onSliceBuffer = (
            startBuf: number,
            endBuf: number,
        ): Promise<ArrayBuffer> => {
            const slice = arrBuffer.slice(startBuf, endBuf);
            return new Promise((resolve) => {
                resolve(slice);
            });
        };

        const contentType = getContentType(file.name);
        if (!contentType) {
            this._logger.logError({
                errorCode: "2001",
            });

            throw Error(`invalid file type ${file.name}`);
        }

        const findComponent = dcxComposite.current.getComponentWithAbsolutePath(
            "/" + path,
        );

        const uploadPromise =
            findComponent == undefined
                ? uploadNewComponent(
                      this.session,
                      dcxComposite,
                      onSliceBuffer,
                      contentType,
                      componentId,
                      file.size,
                      undefined, // md5
                      progressCallback,
                  )
                : uploadComponent(
                      this.session,
                      dcxComposite,
                      findComponent,
                      onSliceBuffer,
                      file.size,
                      undefined, // md5
                      progressCallback,
                  );

        const cancelFunc = uploadPromise.cancel.bind(uploadPromise);
        const uploadComponentPromise = (async () => {
            const uploadResults = await uploadPromise;
            const component =
                findComponent == undefined
                    ? current.addComponentWithUploadResults(
                          file.name,
                          "primary",
                          path,
                          undefined,
                          uploadResults,
                      )
                    : current.updateComponentWithUploadResults(
                          findComponent,
                          uploadResults,
                      );

            try {
                await pushComposite(this.session, dcxComposite, true);
            } catch (e) {
                this._logger.logError({
                    errorCode: "2000",
                    resources: [{ compositeId: dcxComposite.id }],
                    statusCode: (e as AdobeDCXError)?.response?.statusCode,
                });
                throw new Error(
                    "[dcx-js] Failed to push component to composite.",
                );
            }
            return component;
        })();

        return { uploadComponentPromise, cancelFunc };
    }

    private getDcxCompositeFromComposite(composite: Composite) {
        return newDCXComposite(
            composite.assetId,
            composite.repositoryId,
            composite.name,
            composite.assetId,
            composite.type,
            composite.links,
        );
    }

    private getCompositeFromId(compositeId: string) {
        return new Composite(
            {
                assetId: compositeId,
                repositoryId: this.cloudContent.repositoryId,
            },
            this.service,
        );
    }

    @connected
    public async getProjectFolderCompositeId(projectId: string) {
        const folderPath = `${this._cloudContent.path}/${getSunriseEnv(
            config.env,
            config.hostHermes,
        )}/projects/${projectId}`;

        const folderAssetWithPath = <AssetWithRepoAndPathOrId>{
            repositoryId: this.cloudContent.repositoryId,
            path: folderPath,
            assetClass: "Directory",
        };
        try {
            const { result: directory } = await getDirectory(
                this.service,
                folderAssetWithPath,
            );

            return (directory as unknown as DirectoryResult)[
                Properties.REPO_ASSET_ID
            ];
        } catch (err) {
            this._logger.logError({
                errorCode: "2020",
                resources: [{ assetId: projectId }],
            });
            throw new Error(
                `Failed to get containing directory asset for project ${projectId}.`,
            );
        }
    }
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    Content,
    ContextualHelp,
    DialogTrigger,
    Divider,
    Flex,
    Text,
    View,
} from "@adobe/react-spectrum";
import { camerasForAnalytics, templateForAnalytics } from "@shared/common";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { BaseProjectNavBar } from "./BaseProjectNavBar";
import { ProjectApprovalDialog } from "./ProjectApprovalDialog";
import { ShareProject } from "./ShareProject";
import { ApprovalPicker } from "../../common/components/ApprovalPicker";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";
import { useAppContext } from "@src/contexts/AppContext";
import { useFeatureFlagContext } from "@src/contexts/FeatureFlagContext";
import { rpc } from "@src/contexts/RpcContext";
import { useDoNotShowMeAgain } from "@src/interfaces/common/hooks/useDoNotShowMeAgain";
import { useMessagingContext } from "@src/contexts/MessagingContext";

interface Props {
    projectId: string;
}

export const ProjectNavBar = ({ projectId }: Props) => {
    const { t } = useTranslation(["studio", "common"]);
    const { error, success } = useMessagingContext();
    const { FF_PROJECT_APPROVAL_CONFIRMATION } = useFeatureFlagContext();
    const { logIngestEvent } = useAnalyticsContext();
    const { logger } = useAppContext();

    const { data: project } = rpc.projects.get.useQuery(projectId);
    const { data: approved, isFetched: approvedIsFetched } =
        rpc.projects.isProjectApproved.useQuery(projectId);
    const queryUtils = rpc.useUtils();

    const { data: isOwner, isFetched: isOwnerIsFetched } =
        rpc.projects.isUserOwner.useQuery(projectId);
    const updateApprovalMutation = rpc.projects.setApproval.useMutation();

    const [projectToApprove, setProjectToApprove] = useState<
        string | undefined
    >();
    const [approvedState, setApprovedState] = useState(approved);

    useEffect(() => {
        if (approved !== undefined && approved !== approvedState) {
            setApprovedState(approved);
        }
    }, [approved]);

    const showApprovalToast = (isApproved: boolean) => {
        if (isApproved) {
            success(t("notification.approval.success"));
        }
    };
    const updateApprovalStatus = async (isApproved: boolean) => {
        const prevApprovalStatus = approvedState;
        // update state immediately
        setApprovedState(isApproved);
        try {
            await updateApprovalMutation.mutateAsync({
                id: projectId,
                approved: isApproved,
            });
            showApprovalToast(isApproved);
            queryUtils.projects.getHotspotCount
                .ensureData(projectId)
                .then((hotspotCount) => {
                    if (!project?.template) {
                        // It should not be possible to be able to get into this state,
                        // but we are only using it for analytics, so don't throw.
                        return;
                    }
                    logIngestEvent("projectStatusSetApproved", {
                        "event.context_guid": projectId,
                        "3di.dimensions": {
                            "template-names": templateForAnalytics(
                                project.template,
                            ),
                            cameras: camerasForAnalytics(project.cameras),
                        },
                        "3di.measures": {
                            num_assets: project.assets.length,
                            num_hotspots: hotspotCount,
                        },
                    });
                })
                .catch(() => {
                    logger.logError({ errorCode: "2170" });
                });
        } catch {
            error(
                t("notification.approval.error", {
                    projectName:
                        project?.name ??
                        t("project.defaultName").toLocaleLowerCase(),
                    interpolation: { escapeValue: false },
                }),
            );
            // Presumably the DB update didn't take, so revert the state.
            setApprovedState(prevApprovalStatus);
        }
    };

    const {
        doNotShowProjectApprovalConfirmation: {
            getFn: getDoNotShowProjectApprovalConfirmation,
        },
    } = useDoNotShowMeAgain();

    const onApprovalChange = useCallback(
        (isApproved: boolean) => {
            if (
                !FF_PROJECT_APPROVAL_CONFIRMATION ||
                getDoNotShowProjectApprovalConfirmation()
            ) {
                updateApprovalStatus(isApproved);
            } else {
                isApproved && setProjectToApprove(projectId);
            }
        },
        [
            FF_PROJECT_APPROVAL_CONFIRMATION,
            getDoNotShowProjectApprovalConfirmation,
            projectId,
        ],
    );

    const { data: hasIncompleteJobs } =
        rpc.shots.getIncompleteJobsStatusForProject.useQuery(projectId);

    // If no project is selected, the Project Navbar should not not be shown.
    return (
        <BaseProjectNavBar projectId={projectId}>
            {project && (
                <Flex alignItems="center" columnGap="size-50">
                    <View marginTop="-6px">
                        {hasIncompleteJobs === false &&
                            approvedState !== undefined && (
                                <>
                                    <ApprovalPicker
                                        dataUiaPrefix="projects-"
                                        approved={approvedState}
                                        setApproval={onApprovalChange}
                                    />
                                    {FF_PROJECT_APPROVAL_CONFIRMATION && (
                                        <ProjectApprovalDialog
                                            projectToApprove={projectToApprove}
                                            setProjectToApprove={
                                                setProjectToApprove
                                            }
                                            doApprovalMutation={() =>
                                                updateApprovalStatus(true)
                                            }
                                        />
                                    )}
                                </>
                            )}
                    </View>
                    <Divider
                        size="S"
                        orientation="vertical"
                        marginEnd="size-100"
                    />
                    <Flex alignItems="center" columnGap="size-50">
                        <DialogTrigger type="modal">
                            <Button
                                variant="cta"
                                isDisabled={
                                    !isOwner || approved || !approvedIsFetched
                                }>
                                {t("common:actions.share")}
                            </Button>
                            {(close) => (
                                <ShareProject
                                    projectId={projectId}
                                    closeFunction={close}
                                />
                            )}
                        </DialogTrigger>
                        {isOwnerIsFetched && !isOwner && (
                            <ContextualHelp variant="info">
                                <Content>
                                    <Text>
                                        {t(
                                            "common:actions.shareProject.disabled",
                                        )}
                                    </Text>
                                </Content>
                            </ContextualHelp>
                        )}
                    </Flex>
                </Flex>
            )}
        </BaseProjectNavBar>
    );
};

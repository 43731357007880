/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";

import { DetailViewType } from "../components/common/DetailViewTabs";
import { useAppContext } from "@src/contexts/AppContext";
import { useRedirects } from "@src/interfaces/common/hooks/useRedirects";
import type {
    AnnotatingChangePayload,
    CommentInteractionPayload,
    CommentSupplementalData,
    ValidNode,
} from "@src/types/ccac/CCACData";

import type { ArtifactData } from "@shared/types";

export const useCommentsAction = (
    assetId: string,
    models: ArtifactData[],
    renders: ArtifactData[],
    validNodes: ValidNode[],
    activeView: DetailViewType,
    setCurrentNodeIndex: (index: number) => void,
    commentId?: string,
) => {
    const { ace, logger } = useAppContext();
    const { libraryDetailsRedirect } = useRedirects();
    const location = useLocation();
    const matches = location.pathname.match("/review/");
    const isReviewMode = matches?.length === 1 ? true : false;
    const hasPendingAnnotationRef = useRef<boolean>(false);

    const { webModel, arModel } = useMemo(() => {
        const webModel = models.find((model) => model.type === "web");
        const arModel = models.find((model) => model.type === "ar");
        return { webModel, arModel };
    }, [models]);

    const navigateToViewTab = (
        activeView: DetailViewType,
        toView: DetailViewType,
    ) => {
        if (activeView !== toView || commentId) {
            libraryDetailsRedirect({
                assetId,
                viewType: toView,
                navigateOptions: { replace: true },
                limitedAccess: isReviewMode,
            });
            // Reset current render node index.
            if (toView !== DetailViewType.views2D) {
                setCurrentNodeIndex(-1);
            }
        }
    };

    const handleAnnotatingChanged = (payload: AnnotatingChangePayload) => {
        // eslint-disable-next-line no-console
        console.info(
            `onAnnotatingChange triggered: ${JSON.stringify(payload)}`,
        );
        // hasPendingAnnotationRef keeps track of whether the current pending annotation
        // can be canceled. The pending annotation should be canceled if the view is switched
        // to 3D or AR before the current annotation is completed.
        hasPendingAnnotationRef.current =
            payload.trigger === "start" ||
            payload.trigger === "place" ||
            payload.trigger === "update";
    };

    const handlePendingAnnotation = (activeNodeId?: string) => {
        if (
            ace.commentsApi() &&
            hasPendingAnnotationRef.current &&
            (activeNodeId === webModel?.componentId ||
                activeNodeId === arModel?.componentId) &&
            ace.commentingComponentLoaded
        ) {
            ace.commentsApi().removePendingAnnotation();
        }
    };

    const handleNodeChanged = (
        nodeId: string,
        _sectionId?: string, // eslint-disable-line @typescript-eslint/no-unused-vars
        data?: CommentSupplementalData,
    ) => {
        if (!nodeId && !data?.nodeId) {
            return;
        }
        // which node, 2D render, web, or ar?
        const index = validNodes.findIndex((node) => {
            return node?.id === nodeId;
        });
        if (index < 0) {
            logger.logError({
                errorCode: "2183",
                resources: [{ assetId: data?.assetId, componentId: nodeId }],
            });
            return;
        }

        if (index < renders.length) {
            navigateToViewTab(activeView, DetailViewType.views2D);
            setCurrentNodeIndex(index);
        } else if (nodeId === webModel?.componentId) {
            // 3D web model
            navigateToViewTab(activeView, DetailViewType.views3D);
        } else if (nodeId === arModel?.componentId) {
            // ar model
            navigateToViewTab(activeView, DetailViewType.viewsAR);
        } else {
            logger.logError({
                errorCode: "2183",
                resources: [{ assetId: data?.assetId, componentId: nodeId }],
            });
        }
    };

    /**
     * Called when user interacts with a comment or annotation
     * to scroll the annotation into the visible area.
     * @param {CommentInteractionPayload} payload - data object with info about interacted comment
     * @return {undefined}
     */
    const handleCommentInteraction = (
        payload: CommentInteractionPayload,
        data: CommentSupplementalData,
    ) => {
        switch (payload.trigger) {
            case "select":
                // handle comment selected
                // Note that this callback is not made if selecting the same comment.
                // In the future, ccx is likely to enable selection to be toggled.
                handleNodeChanged(payload.nodeId ?? data.nodeId);
                break;
            case "hover":
                break;
            case "unhover":
                break;
            case "unselect":
                setCurrentNodeIndex(-1);
                break;
            default:
                // Check with commenting component if we get here.
                // eslint-disable-next-line no-console
                console.error(
                    `Unexpected comments interaction trigger, ${payload.trigger}`,
                );
                break;
        }
    };

    return {
        handleCommentInteraction,
        handleNodeChanged,
        handleAnnotatingChanged,
        navigateToViewTab,
        handlePendingAnnotation,
    };
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Grid, Text, View } from "@adobe/react-spectrum";
import { HealthCheckAttribute } from "@shared/types";
import React from "react";
import { useTranslation } from "react-i18next";

import { useFormatHealthCheckValue } from "@src/interfaces/common/hooks/useFormatHealthCheckValue";

import type { HealthCheckResults } from "@shared/types";
import type { FC } from "react";

interface Props {
    results: HealthCheckResults;
    fileType?: string;
    isOptimized?: boolean;
}

const healthCheckAttributes = [
    HealthCheckAttribute.fileSize,
    HealthCheckAttribute.polygons,
    HealthCheckAttribute.drawCalls,
    HealthCheckAttribute.meshes,
    HealthCheckAttribute.materials,
    HealthCheckAttribute.textures,
];

export const AssetTechInfoTable: FC<Props> = ({
    results,
    fileType,
    isOptimized,
}) => {
    const { t } = useTranslation(["library"]);

    return (
        <Grid
            columns={["auto", "auto"]}
            autoRows="size-400"
            rowGap="size-10"
            columnGap={"size-0"}
            alignItems="stretch"
            minWidth="size-3000">
            <View
                backgroundColor={"gray-200"}
                gridColumn={"1 / -1"}
                paddingStart="size-200">
                <Flex
                    justifyContent="center"
                    direction={"column"}
                    height="100%">
                    <Text>
                        {isOptimized
                            ? t("detailView.detailsPanel.sunriseOptimizedFile")
                            : t("detailView.detailsPanel.originalFile")}
                    </Text>
                </Flex>
            </View>
            <View
                gridColumn={"1"}
                gridRow={"2"}
                borderBottomColor="gray-200"
                borderBottomWidth={"thin"}
                paddingStart="size-200"
                paddingEnd="size-200">
                <Flex
                    justifyContent="center"
                    direction={"column"}
                    height="100%">
                    <Text>{t("detailView.detailsPanel.fileFormat")}</Text>
                </Flex>
            </View>
            <View
                gridColumn={"2"}
                gridRow={"2"}
                borderBottomColor="gray-200"
                borderBottomWidth={"thin"}>
                <Flex
                    justifyContent="center"
                    direction={"column"}
                    height="100%">
                    <Text>{fileType?.substring(1).toUpperCase()}</Text>
                </Flex>
            </View>
            <>
                {healthCheckAttributes.map((attribute, index) => {
                    const gridRow = (index + 3).toString(); // row is 1 based, +1 for header +1 file format
                    let prop = useFormatHealthCheckValue(
                        results[attribute].value,
                        attribute,
                    );

                    if (attribute === HealthCheckAttribute.polygons) {
                        prop = t("detailView.detailsPanel.polygons.triangles", {
                            number: prop,
                        });
                    }

                    return (
                        <React.Fragment key={index}>
                            <View
                                gridColumn={"1"}
                                gridRow={gridRow}
                                borderBottomColor="gray-200"
                                borderBottomWidth={"thin"}
                                paddingStart="size-200"
                                paddingEnd="size-200">
                                <Flex
                                    justifyContent="center"
                                    direction={"column"}
                                    height="100%">
                                    <Text>
                                        {t(
                                            "detailView.detailsPanel." +
                                                attribute,
                                        )}
                                    </Text>
                                </Flex>
                            </View>
                            <View
                                gridColumn={"2"}
                                gridRow={gridRow}
                                borderBottomColor="gray-200"
                                borderBottomWidth={"thin"}>
                                <Flex
                                    justifyContent="center"
                                    direction={"column"}
                                    height="100%">
                                    <Text>{prop}</Text>
                                </Flex>
                            </View>
                        </React.Fragment>
                    );
                })}
            </>
        </Grid>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { observable } from "mobx";
import { observer } from "mobx-react";
import { createContext, useContext } from "react";

import { AccessService } from "@src/lib/services/AccessService";
import { ACE } from "@src/lib/services/ACE";
import { ACP } from "@src/lib/services/ACP";
import { HeliosLogger } from "@src/lib/services/HeliosLogger";
import { ImsUser } from "@src/lib/services/ims";
import { NotificationService } from "@src/lib/services/Notifications";
import { RpcConnection } from "@src/lib/services/RpcConnection";

import type { PropsWithChildren } from "react";

const imsUser = new ImsUser();
const rpcConnection = new RpcConnection(imsUser);
const logger = new HeliosLogger(imsUser);
const ace = new ACE(imsUser, logger);
const acp = new ACP(imsUser, logger);
const accessService = new AccessService(imsUser, logger);
const notificationService = new NotificationService();

export const appContext = observable({
    ims: imsUser,
    rpcConnection,
    accessService,
    ace,
    acp,
    logger,
    notificationService,
});

export const AppContext = createContext<typeof appContext>(appContext);

export const AppContextProvider = observer(
    ({ children }: PropsWithChildren) => {
        return (
            <AppContext.Provider value={appContext}>
                {children}
            </AppContext.Provider>
        );
    },
);

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error(
            "useAppContext must be used within a AppContextProvider.",
        );
    }
    return context;
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, Image as SpectrumImage } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

const sunriseSvg = "/assets/images/B_app_ProjectSunrise_64.svg";

export const HeaderLeftSide = () => {
    const { t } = useTranslation("common");

    return (
        <Flex
            justifyContent="start"
            margin="size-100"
            gap="size-100"
            alignItems="center"
            height="size-500">
            <SpectrumImage
                objectFit="contain"
                src={sunriseSvg}
                alt={t("common:header.appIcon")}
            />
            <Heading>{t("common:header.appTitle")}</Heading>
        </Flex>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { BaseProjectLibrary } from "./components/BaseProjectLibrary";
import { BaseProjectNavBar } from "./components/BaseProjectNavBar";
import { DETAIL_PANEL_KEYS } from "../detail/components/common/DetailViewRail";
import { ProjectDetailSidePanels } from "../detail/components/projects/ProjectDetailSidePanels";
import { useUIStateContext } from "@src/contexts/UIStateContext";

export const ProjectLibraryReview = observer(() => {
    const { projectId } = useParams();
    const { setProjectSelection } = useUIStateContext();

    useEffect(() => {
        setProjectSelection(projectId ?? "");
        return () => setProjectSelection("");
    }, [projectId]);

    return (
        <BaseProjectLibrary
            navBar={<BaseProjectNavBar projectId={projectId || ""} />}
            reviewAccess={true}>
            <ProjectDetailSidePanels
                projectId={projectId ?? ""}
                selectedPanelKey={DETAIL_PANEL_KEYS.comment}
            />
        </BaseProjectLibrary>
    );
});

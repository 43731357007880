/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Content, Flex, IllustratedMessage, View } from "@adobe/react-spectrum";
import Heading from "@react/react-spectrum/Heading";
import NotFound from "@spectrum-icons/illustrations/NotFound";
import { useTranslation } from "react-i18next";

export function NotFoundPage() {
    const { t } = useTranslation("common");
    // Grouping the Content into a View tag to avoid having the Heading
    // showing under all of the Content.
    return (
        <Flex width="100%" height="100%" justifyContent="center">
            <IllustratedMessage>
                <NotFound />
                <Heading>{t("not.found.title")}</Heading>
                <View>
                    <Content>{t("not.found.body")}</Content>
                </View>
            </IllustratedMessage>
        </Flex>
    );
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, View } from "@adobe/react-spectrum";
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { ArtifactGridView } from "./ArtifactGridView";
import { DETAIL_PANEL_KEYS } from "./DetailViewRail";
import { DetailViewTabs, DetailViewType } from "./DetailViewTabs";
import { useCommentsAction } from "../../hooks/useCommentsAction";
import { useCommentsSetup } from "../../hooks/useCommentsSetup";
import { useAppContext } from "@src/contexts/AppContext";
import { ThemeContext } from "@src/contexts/ThemeContext";
import { useLocale } from "@src/interfaces/common/hooks/useLocale";

import type { ArtifactGridCustomHandlers } from "./ArtifactGridView";
import type { DisplaySize } from "../projects/DisplaySettingsPicker";
import type { ArtifactData } from "@shared/types";
import type { FC } from "react";

interface Props {
    id: string;
    compositeId: string;
    ownerId: string;
    models: ArtifactData[];
    renders: ArtifactData[];
    selectedPanelKey?: string;
    viewOrientation: "horizontal" | "vertical";
    viewType: DetailViewType;
    artifactRefs: React.RefObject<HTMLDivElement>[];
    enableLimitedZoom: boolean;
    approved?: boolean;
    commentId?: string;
    displaySize?: DisplaySize;
    navigateHorizontal?: (direction: number) => void;
}

export const DetailViewContent: FC<Props> = ({
    id,
    compositeId,
    ownerId,
    models,
    renders,
    selectedPanelKey,
    viewOrientation,
    viewType,
    artifactRefs,
    enableLimitedZoom,
    approved,
    commentId,
    displaySize,
    navigateHorizontal,
}) => {
    const { ace } = useAppContext();
    const { isDarkMode } = useContext(ThemeContext);

    const [reviewCommentId, setReviewCommentId] = useState<string>();
    const [reviewCommentingNodeId, setReviewCommentingNodeId] =
        useState<string>();

    const shouldSetCommentsVisible = useMemo(() => {
        return selectedPanelKey === DETAIL_PANEL_KEYS.comment;
    }, [selectedPanelKey]);

    // Set up for commenting
    const {
        commentsAssetData,
        setCommentsAssetData,
        validCommentingNodes,
        validCommentingNodeIds,
    } = useCommentsSetup(ownerId, compositeId, renders, models);

    const {locale} = useLocale();

    // Active view should be either 2D renders or 3D web models.
    const [activeView, setActiveView] = useState<DetailViewType>(viewType);
    useEffect(() => {
        setActiveView(viewType);
    }, [viewType]);

    const [activeArtifacts, setActiveArtifacts] = useState<ArtifactData[]>(
        activeView === DetailViewType.views2D ? renders : models,
    );

    const canCreateComments = useMemo(() => {
        if (approved) {
            return false;
        }
        const canCommentRenders = renders.every(
            (render) => render?.jobCompleted,
        );
        const canComment3DAR = models.every((model) => model?.jobCompleted);
        return canCommentRenders && canComment3DAR;
    }, [renders, models, approved]);

    useEffect(() => {
        setActiveView(viewType);
        let activeArtifact;
        switch (viewType) {
            case DetailViewType.views2D:
                setActiveArtifacts(renders);
                break;
            case DetailViewType.views3D:
                activeArtifact = models.find((model) => model.type === "web");
                if (activeArtifact) {
                    setActiveArtifacts([activeArtifact]);
                } else {
                    setActiveArtifacts([]);
                }
                break;
            case DetailViewType.viewsAR:
                activeArtifact = models.find((model) => model.type === "ar");
                if (activeArtifact) {
                    setActiveArtifacts([activeArtifact]);
                } else {
                    setActiveArtifacts([]);
                }
                break;
            default:
                throw new Error(`DetailView: Unknown viewType ${viewType}`);
        }
    }, [viewOrientation, viewType, renders, models]);

    // Initialize and keep track of the correct active node for 2D or 3D views.
    const [activeCommentNodeId, setActiveCommentNodeId] = useState<
        string | undefined
    >();

    // State indicating the currently "selected" node in the comments panel.
    const [selectedCommentingNodeIndex, setSelectedCommentingNodeIndex] =
        useState<number>(-1);

    const {
        handleAnnotatingChanged,
        handleNodeChanged,
        handleCommentInteraction,
        handlePendingAnnotation,
    } = useCommentsAction(
        id,
        models,
        renders,
        validCommentingNodes,
        activeView,
        setSelectedCommentingNodeIndex,
        reviewCommentId,
    );

    useEffect(() => {
        switch (activeView) {
            case DetailViewType.views2D:
                {
                    const nodeIndex =
                        selectedCommentingNodeIndex >= 0 &&
                        selectedCommentingNodeIndex < renders.length
                            ? selectedCommentingNodeIndex
                            : 0;
                    setActiveCommentNodeId(renders[nodeIndex]?.componentId);
                }
                break;
            case DetailViewType.views3D: {
                const activeNode = models.find((model) => model.type === "web");
                setActiveCommentNodeId(activeNode?.componentId);
                break;
            }
            case DetailViewType.viewsAR: {
                const activeNode = models.find((model) => model.type === "ar");
                setActiveCommentNodeId(activeNode?.componentId);
                break;
            }
            default:
                throw new Error(`DetailView: Unknown viewType ${viewType}`);
        }
    }, [activeView, renders, models]);

    // Updated comments asset data will dependent on the valid commenting node,
    // active mode Id.
    useEffect(() => {
        if (commentsAssetData && activeCommentNodeId) {
            const updatedData = {
                ...commentsAssetData,
                nodeId: !reviewCommentId ? activeCommentNodeId : undefined, // set initial active node
                validNodes: validCommentingNodes,
            };
            handlePendingAnnotation(activeCommentNodeId);
            setCommentsAssetData(updatedData);
        }
    }, [activeCommentNodeId, validCommentingNodes]);

    // Forward refs
    const artifactGridRef = useRef<ArtifactGridCustomHandlers>(null);

    useEffect(() => {
        if (
            selectedCommentingNodeIndex >= 0 &&
            selectedCommentingNodeIndex < renders.length &&
            activeView === DetailViewType.views2D
        ) {
            artifactGridRef.current?.scrollNodeToView(
                selectedCommentingNodeIndex,
            );
        }
    }, [selectedCommentingNodeIndex, activeView]);

    const [isCommentsVisible, setIsCommentsVisible] = useState<boolean>(false);
    const onViewsLoaded = useCallback(() => {
        setIsCommentsVisible(true);
        setReviewCommentId(commentId);
    }, [activeView]);

    useEffect(() => {
        if (reviewCommentingNodeId) {
            handleNodeChanged(reviewCommentingNodeId);
            setReviewCommentId(undefined);
        }
    }, [reviewCommentingNodeId]);

    // Showing the initial comment from a redirected comment link.
    const showCommentRef = useRef<boolean>(false);
    useEffect(() => {
        if (reviewCommentId && ace.commentsApi && !showCommentRef.current) {
            // Show the initial comment.
            // format the ccx complete commendId, e.g.
            // "/assets/<assetId>/annots/<commentId>"
            const ccxCommentId = `/assets/${compositeId}/annots/${reviewCommentId}`;
            showCommentRef.current = true;
            // This should callback through onCommentsInteraction with "select" trigger
            // and onNodeChange.
            ace.commentsApi()
                .showComment(ccxCommentId)
                .then((result: { nodeId: string }) => {
                    setReviewCommentingNodeId(result.nodeId);
                });
        }
    }, [reviewCommentId, ace.commentingComponentLoaded]);

    // Called when switching views and only when we have resolved to valid asset id and the active node Id,
    useEffect(() => {
        let setOverlaysTimerId: number;
        if (
            shouldSetCommentsVisible &&
            isCommentsVisible &&
            ace.commentingComponentLoaded &&
            commentsAssetData?.id &&
            commentsAssetData?.nodeId
        ) {
            // Gets call twice, first time when active view changed and the second time when the active nodeId is updated.
            // The setTimeout ensures we only sets the overlays when the active nodeId is updated.
            ace.updateCommentingConfig({
                locale,
                disableCommentCreation: !canCreateComments,
                onCommentInteraction: handleCommentInteraction,
                onNodeChange: handleNodeChanged,
                onAnnotatingChange: handleAnnotatingChanged,
                assetData: commentsAssetData,
                theme: isDarkMode ? "darkest" : "light",
            });

            const annotationOverlays = document.getElementsByTagName(
                "cc-comments-annotations-overlay",
            );
            const [commentsList] = document.getElementsByTagName(
                "cc-comments-list",
            ) as any;

            setOverlaysTimerId = window.setTimeout(() => {
                if (annotationOverlays.length) {
                    if (commentsList?.setAnnotationsOverlayElements) {
                        commentsList.setAnnotationsOverlayElements(
                            Array.from(annotationOverlays),
                        );
                    }
                } else if (commentsList?.setAnnotationsOverlayElements) {
                    commentsList.setAnnotationsOverlayElements([]);
                }
            }, 100);
        }
        return () => {
            if (setOverlaysTimerId) {
                clearTimeout(setOverlaysTimerId);
            }
        };
    }, [
        canCreateComments,
        ace.commentingComponentLoaded,
        commentsAssetData,
        shouldSetCommentsVisible,
        isCommentsVisible,
        activeView,
        isDarkMode,
    ]);

    return (
        <Flex width="100%" height="100%">
            {commentsAssetData?.id && (
                <>
                    <Flex direction="column" width="100%">
                        <ArtifactGridView
                            assetId={id}
                            viewType={activeView}
                            ref={artifactGridRef}
                            refs={artifactRefs}
                            activeArtifacts={activeArtifacts}
                            artifactIds={validCommentingNodeIds}
                            showOverlay={
                                activeView === DetailViewType.views2D
                                    ? shouldSetCommentsVisible
                                    : false
                            }
                            viewOrientation={viewOrientation}
                            enableLimitedZoom={enableLimitedZoom}
                            displaySize={displaySize}
                            navigateHorizontal={navigateHorizontal}
                            updateActiveNode={setActiveCommentNodeId}
                            onViewsLoaded={onViewsLoaded}
                        />
                        {viewOrientation === "vertical" && <DetailViewTabs />}
                    </Flex>
                    {shouldSetCommentsVisible && isCommentsVisible && (
                        <View
                            width="size-4600"
                            flex="none"
                            borderStartColor="gray-50"
                            borderStartWidth="thick">
                            {/** This visible attribute is necessary otherwise the list is hidden */}
                            <cc-comments-list visible />
                        </View>
                    )}
                </>
            )}
        </Flex>
    );
};

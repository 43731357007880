/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { WithOptional, WithRequired } from "@shared/types";

//////////////////////////////////////
// Common
//////////////////////////////////////

type ApprovedSubtype = "approved";

type ContextGUIDField = {
    "event.context_guid": string | undefined;
};

type ViewAssetDetailsFields = ContextGUIDField & {
    "content.id"?: string;
};

//////////////////////////////////////
// APP
//////////////////////////////////////

type AppLifeCycleSubtype = "startup";
type LoginSubtype = "login";

//////////////////////////////////////
// ASSETS
//////////////////////////////////////

type AssetDownloadPathToAccess = "asset-card" | "details-view";
type AssetDownloadFields = {
    "ui.path_to_access": AssetDownloadPathToAccess;
};

type AssetStatusSubtype = ApprovedSubtype;

type UploadPathToAccess = "drag-drop" | "file-picker";
type UploadType = "new" | "existing";

/**
 * Represents values that are shared amongst multiple uploads that were
 * initiated simultaneously.
 */
export type UploadAnalyticsBatchFields = ContextGUIDField & {
    "ui.path_to_access": UploadPathToAccess;
    "3di.dimensions": {
        upload_type: UploadType;
    };
};

/**
 * Combines batch values with those that are specific to this individual upload.
 */
export type UploadAnalyticsFields = UploadAnalyticsBatchFields & {
    "content.extension"?: string; // if file has no extension, don't send one.
    "content.id": string;
    "content.name"?: string;
    "content.size": number;
};

export type ViewAssetDetailsOpenPathToAccess = "asset-card" | "asset-thumbnail";

type ViewAssetDetailsOpenFields = ContextGUIDField & {
    "ui.path_to_access": ViewAssetDetailsOpenPathToAccess;
};

type ViewAssetDetailsOpenSubtype = "AR";
type ViewAssetDetailsSelectSubtype = "2D" | "3D" | "AR";

//////////////////////////////////////
// PROJECTS
//////////////////////////////////////

type ProjectStatusSubtype = ApprovedSubtype;

type ProjectFields = ContextGUIDField & {
    "3di.dimensions": {
        cameras: string;
        "template-names": string;
    };
    "3di.measures": {
        num_assets: number;
    };
};

type ProjectHotspotFields = {
    "3di.measures": {
        num_hotspots: number;
    };
};

type ProjectReviewFields = ContextGUIDField & {
    "3di.dimensions": {
        "review-type": string;
    };
};

type ProjectReviewSubtype = "send-invite";

const CreateProjectSubtype = {
    assets: "assets",
    cameras: "cameras",
    template: "template",
};
export type CreateProjectSubtype = keyof typeof CreateProjectSubtype;

type CreateProjectFields = ProjectFields;

type ProjectDownloadFields = ContextGUIDField & {
    "content.extension": string;
    "content.name": string;
};
type ProjectStatusApprovedFields = ProjectFields & ProjectHotspotFields;

type EditProjectSubtype = "assets" | "2d-shot" | "3d-web-experience";

type EditProjectFields = ContextGUIDField &
    WithOptional<ProjectHotspotFields, "3di.measures">;

//////////////////////////////////////
// STORAGE
//////////////////////////////////////

type StoragePathToAccess = "startup";

const StorageReportSubtype = {
    assets: "assets",
    projects: "projects",
};
export type StorageReportSubtype = keyof typeof StorageReportSubtype;

type StorageReportAssetMeasures = {
    size_assets_dir: number;
    num_assets: number;
    size_median_asset: number;
};

type StorageReportProjectMeasures = {
    size_projects_dir: number;
    num_projects: number;
    size_median_project: number;
};

type StorageReportFields = {
    "ui.path_to_access": StoragePathToAccess;
    "3di.measures": StorageReportAssetMeasures | StorageReportProjectMeasures;
};

/////////////////////////////////////////////
// Catalog of workflows, event types, etc.
/////////////////////////////////////////////

const IngestWorkflow = {
    APP: "APP",
    ASSETS: "ASSETS",
    PROJECTS: "PROJECTS",
    STORAGE: "STORAGE",
} as const;
export type IngestWorkflow = keyof typeof IngestWorkflow;

const IngestCategory = {
    WEB: "WEB",
} as const;
type IngestCategory = keyof typeof IngestCategory;

const IngestSubcategory = {
    CreateProject: "CreateProject",
    Download: "Download",
    EditProject: "EditProject",
    Lifecycle: "Lifecycle",
    Report: "Report",
    Review: "Review",
    Status: "Status",
    Upload: "Upload",
    ViewAssetDetails: "ViewAssetDetails",
} as const;
type IngestSubcategory = keyof typeof IngestSubcategory;

const IngestEventType = {
    add: "add",
    cancel: "cancel",
    continue: "continue",
    error: "error",
    info: "info",
    open: "open",
    save: "save",
    select: "select",
    set: "set",
    start: "start",
    submit: "submit",
    success: "success",
} as const;
type IngestEventType = keyof typeof IngestEventType;

type IngestEventSubtype =
    | AppLifeCycleSubtype
    | AssetStatusSubtype
    | CreateProjectSubtype
    | EditProjectSubtype
    | LoginSubtype
    | ProjectReviewSubtype
    | ProjectStatusSubtype
    | StorageReportSubtype
    | ViewAssetDetailsOpenSubtype
    | ViewAssetDetailsSelectSubtype;

export type IngestEventKey =
    | "appStartup"
    | "assetDownloadError"
    | "assetDownloadStart"
    | "assetDownloadSuccess"
    | "assetStatusSetApproved"
    | "createProjectCancel"
    | "createProjectContinue"
    | "createProjectStart"
    | "createProjectSuccess"
    | "editProjectAddAssets"
    | "editProjectOpen"
    | "editProjectSave"
    | "login"
    | "projectDownloadError"
    | "projectDownloadStart"
    | "projectDownloadSuccess"
    | "projectInviteSubmit"
    | "projectStatusSetApproved"
    | "storageReportAssets"
    | "storageReportProjects"
    | "uploadError"
    | "uploadStart"
    | "uploadSuccess"
    | "viewAssetDetailsOpen"
    | "viewAssetDetailsSelect"
    | "viewAssetDetailsStart";

export type IngestEventFields = {
    "event.workflow"?: IngestWorkflow;
    "event.subtype"?: IngestEventSubtype;
} & ( // eslint-disable-next-line @typescript-eslint/ban-types
    | {} // enables types that only want the common fields
    | AssetDownloadFields
    | CreateProjectFields
    | EditProjectFields
    | ProjectDownloadFields
    | ProjectReviewFields
    | ProjectStatusApprovedFields
    | StorageReportFields
    | UploadAnalyticsFields
    | ViewAssetDetailsFields
    | ViewAssetDetailsOpenFields
);

export type IngestUserContext = {
    "event.user_guid": string;
    "event.org_guid": string;
};

type IngestEventData = {
    "event.workflow"?: IngestWorkflow;
    "event.subcategory": IngestSubcategory;
    "event.type": IngestEventType;
    "event.subtype"?: IngestEventSubtype;
};

/**
 * When an event can't be sent straight away, it needs to be added to a queue.
 * It will be timestamped and given a unique identifier. Other context will be
 * added before it is sent to the analytics endpoint.
 */
export type IngestQueueEvent = {
    time: string;
    data: {
        "event.guid": string;
        "event.url": string;
        "event.referrer": string;
        "event.dts_start": string;
    } & WithRequired<IngestEventData, "event.workflow">;
};

/**
 * When an event is ready to be sent, we can take the information off the queue
 * and add more context that is needed to send it to the analytics endpoint.
 */
export type IngestRequestEvent = IngestQueueEvent & {
    project: "sunrise-web-service";
    environment: "prod" | "stage";
    ingesttype: "dunamis";
    data: IngestUserContext & {
        "event.category": IngestCategory;
        "event.language": string;
        "session.guid": string;
        "source.client_id": string;
        "source.name": "Project Sunrise";
        "source.platform": "Web";
    };
};

/**
 * Map of available events to send. Used with `logIngestEvent` and often paired
 * with event-specific fields.
 */
export const IngestEventsMap: Record<IngestEventKey, IngestEventData> = {
    appStartup: {
        "event.workflow": IngestWorkflow.APP,
        "event.subcategory": IngestSubcategory.Lifecycle,
        "event.type": IngestEventType.success,
        "event.subtype": "startup",
    },
    assetDownloadError: {
        "event.workflow": IngestWorkflow.ASSETS,
        "event.subcategory": IngestSubcategory.Download,
        "event.type": IngestEventType.error,
    },
    assetDownloadStart: {
        "event.workflow": IngestWorkflow.ASSETS,
        "event.subcategory": IngestSubcategory.Download,
        "event.type": IngestEventType.start,
    },
    assetDownloadSuccess: {
        "event.workflow": IngestWorkflow.ASSETS,
        "event.subcategory": IngestSubcategory.Download,
        "event.type": IngestEventType.success,
    },
    assetStatusSetApproved: {
        "event.workflow": IngestWorkflow.ASSETS,
        "event.subcategory": IngestSubcategory.Status,
        "event.type": IngestEventType.set,
        "event.subtype": "approved",
    },
    createProjectCancel: {
        "event.workflow": IngestWorkflow.PROJECTS,
        "event.subcategory": IngestSubcategory.CreateProject,
        "event.type": IngestEventType.cancel,
    },
    createProjectContinue: {
        "event.workflow": IngestWorkflow.PROJECTS,
        "event.subcategory": IngestSubcategory.CreateProject,
        "event.type": IngestEventType.continue,
    },
    createProjectStart: {
        "event.workflow": IngestWorkflow.PROJECTS,
        "event.subcategory": IngestSubcategory.CreateProject,
        "event.type": IngestEventType.start,
    },
    createProjectSuccess: {
        "event.workflow": IngestWorkflow.PROJECTS,
        "event.subcategory": IngestSubcategory.CreateProject,
        "event.type": IngestEventType.success,
    },
    editProjectAddAssets: {
        "event.workflow": IngestWorkflow.PROJECTS,
        "event.subcategory": IngestSubcategory.EditProject,
        "event.type": IngestEventType.add,
        "event.subtype": "assets",
    },
    editProjectOpen: {
        "event.workflow": IngestWorkflow.PROJECTS,
        "event.subcategory": IngestSubcategory.EditProject,
        "event.type": IngestEventType.open,
    },
    editProjectSave: {
        "event.workflow": IngestWorkflow.PROJECTS,
        "event.subcategory": IngestSubcategory.EditProject,
        "event.type": IngestEventType.save,
    },
    login: {
        "event.workflow": IngestWorkflow.APP,
        "event.subcategory": IngestSubcategory.Lifecycle,
        "event.type": IngestEventType.success,
        "event.subtype": "login",
    },
    projectDownloadError: {
        "event.workflow": IngestWorkflow.PROJECTS,
        "event.subcategory": IngestSubcategory.Download,
        "event.type": IngestEventType.error,
    },
    projectDownloadStart: {
        "event.workflow": IngestWorkflow.PROJECTS,
        "event.subcategory": IngestSubcategory.Download,
        "event.type": IngestEventType.start,
    },
    projectDownloadSuccess: {
        "event.workflow": IngestWorkflow.PROJECTS,
        "event.subcategory": IngestSubcategory.Download,
        "event.type": IngestEventType.success,
    },
    projectInviteSubmit: {
        "event.workflow": IngestWorkflow.PROJECTS,
        "event.subcategory": IngestSubcategory.Review,
        "event.type": IngestEventType.submit,
        "event.subtype": "send-invite",
    },
    projectStatusSetApproved: {
        "event.workflow": IngestWorkflow.PROJECTS,
        "event.subcategory": IngestSubcategory.Status,
        "event.type": IngestEventType.set,
        "event.subtype": "approved",
    },
    storageReportAssets: {
        "event.workflow": IngestWorkflow.STORAGE,
        "event.subcategory": IngestSubcategory.Report,
        "event.type": IngestEventType.info,
        "event.subtype": "assets",
    },
    storageReportProjects: {
        "event.workflow": IngestWorkflow.STORAGE,
        "event.subcategory": IngestSubcategory.Report,
        "event.type": IngestEventType.info,
        "event.subtype": "projects",
    },
    uploadError: {
        "event.workflow": IngestWorkflow.ASSETS,
        "event.subcategory": IngestSubcategory.Upload,
        "event.type": IngestEventType.error,
    },
    uploadStart: {
        "event.workflow": IngestWorkflow.ASSETS,
        "event.subcategory": IngestSubcategory.Upload,
        "event.type": IngestEventType.start,
    },
    uploadSuccess: {
        "event.workflow": IngestWorkflow.ASSETS,
        "event.subcategory": IngestSubcategory.Upload,
        "event.type": IngestEventType.success,
    },
    viewAssetDetailsOpen: {
        "event.subcategory": IngestSubcategory.ViewAssetDetails,
        "event.type": IngestEventType.open,
    },
    viewAssetDetailsSelect: {
        "event.subcategory": IngestSubcategory.ViewAssetDetails,
        "event.type": IngestEventType.select,
    },
    viewAssetDetailsStart: {
        "event.subcategory": IngestSubcategory.ViewAssetDetails,
        "event.type": IngestEventType.start,
    },
} as const;

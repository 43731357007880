/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, View } from "@adobe/react-spectrum";
import React from "react";

interface Props {
    shotName: string;
}

export function EditorNav({
    shotName,
    children,
}: Props & React.PropsWithChildren) {
    return (
        <View width="100%" height="100%" backgroundColor="gray-100">
            <Flex
                height="100%"
                alignItems="center"
                marginStart="size-250"
                marginEnd="size-250"
                justifyContent="center">
                <Flex flex={1}>
                    {
                        false && (
                            <div />
                        ) /* Ugly hack to get the shot name to center properly */
                    }
                </Flex>
                <Heading level={3} alignSelf="center">
                    {shotName}
                </Heading>
                <Flex flex={1} gap="size-150" justifyContent="end">
                    {children}
                </Flex>
            </Flex>
        </View>
    );
}

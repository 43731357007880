/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Heading,
} from "@adobe/react-spectrum";
import { Card, CardView, GridLayout } from "@react-spectrum/card";
import { Size } from "@react-stately/virtualizer";

import { Thumbnail } from "@src/interfaces/common/components/Thumbnail";

import type { Selection, SelectionMode } from "@react-types/shared";

export interface CardItem {
    id: string;
    thumbnailUrl: string;
    description: string;
}

interface Props {
    items: CardItem[];
    selectedKeys: Selection;
    setSelectedKeys: (selection: Selection) => void;
    selectionMode: SelectionMode;
    isDisabled: boolean;
    isAsset: boolean;
    onLoadMore?: () => any;
}
const cardSize = new Size(100, 100);
const gridLayout = new GridLayout<CardItem>({ maxItemSize: cardSize });

export function AssetInputDialogGrid({
    items,
    selectedKeys,
    setSelectedKeys,
    selectionMode,
    isDisabled,
    onLoadMore,
}: Props) {
    return (
        <CardView
            items={items}
            width="100%"
            height="100%"
            layout={gridLayout}
            selectionMode={selectionMode}
            aria-label="Project Inputs Selection"
            selectedKeys={selectedKeys}
            onSelectionChange={setSelectedKeys}
            disabledKeys={isDisabled ? items.map((item) => item.id) : undefined}
            onLoadMore={onLoadMore}>
            {(item: CardItem) => (
                <Card key={item.id}>
                    <Thumbnail
                        url={item.thumbnailUrl}
                        alt={item.description}
                        showFallbackImage={!item.thumbnailUrl}
                    />
                    <Heading level={4}>{item.description}</Heading>
                </Card>
            )}
        </CardView>
    );
}

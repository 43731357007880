/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import React, { PropsWithChildren, useEffect } from "react";
import { Heading, IllustratedMessage, Flex } from "@adobe/react-spectrum";
import NotFound from "@spectrum-icons/illustrations/NotFound";
import { StatusBasedView } from "../StatusBasedView";

export const AuthStatusScreen = (props: PropsWithChildren<{ imsToken: string, wfInstanceUrl: string }>) => {
    const status = {
        heading: "Waiting for user to authenticate...",
    };

    const [statusScreenProps, setStatusScreenProps] = React.useState<{
        heading: string;
    } | null>(null);

    useEffect(() => {
        if (!props.imsToken) {
            setStatusScreenProps(status);
        } else {
            setStatusScreenProps(null);
        }
    }, [props.imsToken]);

    if (statusScreenProps) {
        return (
            <Flex justifyContent="center" width="100%" height="100%">
                <IllustratedMessage>
                    <NotFound />
                    <Heading>{statusScreenProps.heading}</Heading>
                </IllustratedMessage>
            </Flex>
        );
    }

    if (props.imsToken) {
        return (
            <StatusBasedView
                imsToken={props.imsToken}
                wfInstanceUrl={props.wfInstanceUrl}
            />
        )
    }
    return null;
};

import type { ViewProps } from "@adobe/react-spectrum";
import { Flex, View } from "@adobe/react-spectrum";
import React from "react";

interface Props {
    children: [React.ReactNode, React.ReactNode];
    dataUia?: string /** Attribute for test automation */;
}

export function Card({
    children: [iconArea, metaArea],
    dataUia,
    ...viewProps
}: Props & ViewProps<5>) {
    return (
        <View
            data-uia={`${dataUia}-card`}
            height="100%"
            borderWidth="thin"
            borderColor="mid">
            <Flex direction="column" gap="size-100">
                <View
                    flexGrow={1}
                    position="relative"
                    backgroundColor="gray-200"
                    overflow="hidden"
                    width="100%"
                    paddingBottom="100%"
                    {...viewProps}>
                    <View
                        position="absolute"
                        top={0}
                        bottom={0}
                        left={0}
                        right={0}>
                        {iconArea}
                    </View>
                </View>
                <View minHeight="static-size-450">{metaArea}</View>
            </Flex>
        </View>
    );
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

/* eslint-disable @typescript-eslint/ban-types */

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any
    ? A
    : never;

export function throttle<T extends Function>(fn: T, duration = 100) {
    let lastArgs: ArgumentTypes<T> | undefined;
    let timer: number | undefined;
    let lastCalled = 0;

    const caller = () => {
        // eslint-disable-next-line prefer-spread
        fn.apply(undefined, lastArgs);
        lastCalled = Date.now();
        timer = undefined;
    };

    return (...args: ArgumentTypes<T>) => {
        lastArgs = args;
        const now = Date.now();
        if (lastCalled + duration > now && !timer) {
            timer = window.setTimeout(caller, lastCalled + duration - now);
        }
        if (!timer) {
            caller();
        }
    };
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";

import { ProjectCommentingPanel } from "./ProjectCommentingPanel";
import { ProjectInfoPanel } from "./ProjectInfoPanel";
import { WorkfrontPanel } from "../assets/panel/WorkfrontPanel";
import { DETAIL_PANEL_KEYS } from "../common/DetailViewRail";

import type { DetailPanelKey } from "../common/DetailViewRail";
import type { FC } from "react";

interface Props {
    projectId: string;
    selectedPanelKey: DetailPanelKey;
}

export const ProjectDetailSidePanels: FC<Props> = ({
    projectId,
    selectedPanelKey,
}) => {
    return (
        <View>
            {selectedPanelKey === DETAIL_PANEL_KEYS.info && (
                <ProjectInfoPanel projectId={projectId} />
            )}
            {selectedPanelKey === DETAIL_PANEL_KEYS.comment && (
                <ProjectCommentingPanel projectId={projectId} />
            )}
            {selectedPanelKey === DETAIL_PANEL_KEYS.workfront && (
                <WorkfrontPanel/>
            )}
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, Text, View } from "@adobe/react-spectrum";
import { HeliosRoutes } from "@shared/common";
import Alert from "@spectrum-icons/workflow/Alert";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    generatePath,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import useScript from "react-script-hook";

import { useAppContext } from "../../contexts/AppContext";
import { rpc } from "../../contexts/RpcContext";
import { DelayedSpinner } from "../common/components/DelayedSpinner";
import { DetailViewType } from "../detail/components/common/DetailViewTabs";
import { config } from "@components/helios/src/config";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";
import { IngestWorkflow } from "@src/lib/analytics/AnalyticsConstants";

import type { HeliosLogger } from "../../lib/services/HeliosLogger";
import type { ModelViewerElement } from "@google/model-viewer";

// There isn't a great way currently to get the model-viewer added as a native React element.
// This follows the recommendations from https://github.com/google/model-viewer/discussions/3358#discussioncomment-5449036
// I then OR'd it with the props of a DIV to allow children (in case we want to have a custom "view in my space" button)
// and inline CSS styling.
type ModelViewerType = Partial<
    | ModelViewerElement
    | React.DetailedHTMLProps<
          React.AllHTMLAttributes<
              Partial<globalThis.HTMLElementTagNameMap["div"]>
          >,
          Partial<globalThis.HTMLElementTagNameMap["div"]>
      >
>;

declare global {

    namespace JSX {
        interface IntrinsicElements {
            "model-viewer": ModelViewerType;
        }
    }
}

export const ArViewer = () => {
    const [scriptLoading, scriptError] = useScript({
        src: config.modelViewerScriptUrl,
        type: "module",
        async: true,
    });
    const { t } = useTranslation(["viewer"]);
    const { logger } = useAppContext();
    const { logIngestEvent } = useAnalyticsContext();
    const { assetId = "" } = useParams();
    const [assetIsDeleted, setAssetIsDeleted] = useState<boolean>(false);
    const [showViewer, setShowViewer] = useState<boolean>(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const workflow = (searchParams.get("workflow") ??
        undefined) as unknown as IngestWorkflow;
    const {
        data: urls,
        isLoading: rpcIsLoading,
        isError: rpcIsError,
        error,
        isSuccess,
    } = rpc.libraryAssets.getArUrls.useQuery(assetId);

    useEffect(() => {
        if (isSuccess) {
            setAssetIsDeleted(false);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (error) {
            switch (error.data?.code) {
                case "NOT_FOUND":
                    // Represents a user who has read access to a record that has been marked deleted
                    // Since they have the right permissions, give them a bit more detail
                    setAssetIsDeleted(true);
                    break;
                case "FORBIDDEN":
                    // Cases where a user doesn't have read access or the asset doesn't exist
                    navigate(HeliosRoutes.notFound.path);
                    break;
                case "BAD_REQUEST":
                    // cases where assetId doesn't pass GUID validation
                    navigate(HeliosRoutes.notFound.path);
                    break;
                default:
                    navigate(HeliosRoutes.notFound.path);
                    break;
            }
        }
    }, [error]);

    useEffect(() => {
        if (scriptError) {
            (logger as HeliosLogger).logError({ errorCode: "2200" });
        }
    }, [scriptError]);

    useEffect(() => {
        if (urls && (!urls.glb || !urls.usdz)) {
            // ACP not found error so we don't have actual urls.
            // This could happen if user logs into a different org than the resource org.
            navigate(HeliosRoutes.notFound.path);
            return;
        }
        if (!urls) {
            setShowViewer(false);
            return;
        }
        if (scriptLoading || scriptError) {
            setShowViewer(false);
            return;
        }
        if (rpcIsLoading || rpcIsError) {
            setShowViewer(false);
            return;
        }
        if (assetIsDeleted) {
            setShowViewer(false);
            return;
        }
        setShowViewer(true);
        logIngestEvent("viewAssetDetailsStart", {
            "event.workflow": workflow,
            "event.subtype": "AR",
            "event.context_guid": assetId,
        });
    }, [
        urls,
        scriptLoading,
        scriptError,
        rpcIsLoading,
        rpcIsError,
        assetIsDeleted,
    ]);

    useEffect(() => {
        logIngestEvent("viewAssetDetailsOpen", {
            "event.workflow": workflow,
            "event.subtype": "AR",
            "event.context_guid": assetId,
        });
    }, []);

    const iosPath = useMemo(() => {
        if (!urls?.usdz) {
            return "";
        }
        const path = encodeURI(
            generatePath(HeliosRoutes.libraryDetailView.path, {
                assetId,
                viewType: DetailViewType.viewsAR,
            }),
        );
        const host = encodeURI(window.location.hostname);
        return `${urls.usdz}#canonicalWebPageURL=${host}${path}`;
    }, [assetId, urls?.usdz]);

    return (
        <View height="100%" width="100%">
            <Flex
                direction="column"
                height="100%"
                width="100%"
                alignContent="center"
                alignItems="center"
                justifyContent="center">
                {(scriptLoading || (rpcIsLoading && !assetIsDeleted)) && (
                    <DelayedSpinner size="L" />
                )}
                {scriptError && (
                    <Flex
                        direction="row"
                        height="100%"
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center">
                        <Alert size="L" />
                        <Heading level={2}>{t("script.load.error")}</Heading>
                    </Flex>
                )}
                {assetIsDeleted && (
                    <>
                        <Flex
                            direction="row"
                            justifyContent="center"
                            alignContent="center"
                            alignItems="center">
                            <Alert size="L" />

                            <Heading level={2}>
                                {t("asset.deleted.title")}
                            </Heading>
                        </Flex>
                        <Text>{t("asset.deleted.body")}</Text>
                    </>
                )}
                {showViewer && (
                    <model-viewer
                        style={{ width: "100%", height: "100%" }}
                        className="model-viewer"
                        id="viewer"
                        src={urls?.glb}
                        ios-src={iosPath}
                        alt={t("viewer.alt")}
                        camera-controls
                        ar-modes="webxr quick-look"
                        ar-scale="fixed"
                        ar={true}
                        loading="eager"
                    />
                )}
            </Flex>
        </View>
    );
};

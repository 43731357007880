/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

type ACPFileAccessInfo = {
    type: "acp";
    contentType: string;
} & ACPFileUrls;

type AWSFileAccessInfo = { type: "aws"; contentType: string } & {
    putUrl: string;
    getUrl: string;
};

export type FileAccessInfo = ACPFileAccessInfo | AWSFileAccessInfo;

export interface ACPFileUrls {
    repoChunkBlockSize: number;
    getUrl: string;
    putUrls?: {
        transferUrls: string[];
        extendUrl: string;
        completeUrl: string;
    };
}

// Used in preparation of a component in ACP before the file is uploaded
export interface ACPTargetComponent {
    compositeId: string;
    componentId: string;
    componentName: string;
    componentPath: string;
    fileExtension: string;
    shouldMergeScene?: boolean;
}

import type { IconProps } from "@adobe/react-spectrum";
import { Icon } from "@adobe/react-spectrum";

export const DollySvg = (props: Omit<IconProps, "children">) => {
    return (
        <Icon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 36">
                <path d="M32.79919,24.1001A.50013.50013,0,0,0,32.49908,24H24.42859l-.64826-2.2688A5.99686,5.99686,0,0,0,23.48157,10.192L23.0769,8h5.42218a.5.5,0,0,0,.39161-.81085L22.35358,2.14642a.50009.50009,0,0,0-.70716,0L15.10931,7.18915A.5.5,0,0,0,15.50092,8H20.9231l-.40467,2.192a5.99686,5.99686,0,0,0-.29876,11.53924L19.57141,24H11.50092a.5.5,0,0,0-.39985.80017l10.54535,9.05335a.5.5,0,0,0,.7071.00006l.00006-.00006,10.54535-9.05335A.5.5,0,0,0,32.79919,24.1001Z" />
            </svg>
        </Icon>
    );
};

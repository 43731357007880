/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { TaskDetails } from "../../../types/TaskDetails";
import { fetchWorkfrontTaskById } from "../requests/fetchTaskById";
import { TaskResponse } from "../requests/types/TaskResponse";
import { fetchAvatarImageAsBlob } from "../requests/fetchAvatarImageAsBlob";
import { TaskStatus } from "../../../types/TaskStatus";
import { fetchWorkfrontTaskPossibleStatuses } from "../requests/fetchTaskPossibleStatuses";
import {
    CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX,
    PREVIEW_URL_CUSTOM_FIELD_NAME_WITH_PREFIX,
    PUBLISH_URL_CUSTOM_FIELD_NAME_WITH_PREFIX,
} from "../constants";
import { AbstractStatusResponse } from "../requests/types/AbstractStatusResponse";
import { getTaskStatus } from "./private/util";
import { WorkfrontParams } from "../../../types/wfParams";
import { fetchWorkfrontDocumentsByTaskId } from "../requests/fetchDocuments";
import { WfDocumentList, WfDocumentMappedList } from "../../../types/Documents";

export const fetchTaskById = async (
    wfParams: WorkfrontParams,
    taskId: string,
): Promise<TaskDetails> => {
    const taskPromise: Promise<TaskResponse> = fetchWorkfrontTaskById(wfParams, taskId);

    const taskStatusesPromise: Promise<AbstractStatusResponse[]> =
        fetchWorkfrontTaskPossibleStatuses(wfParams);

    const documentsPromise: Promise<WfDocumentList> = fetchWorkfrontDocumentsByTaskId(
        wfParams,
        taskId,
    );

    const [task, possibleTaskStatuses, documents] = await Promise.all([
        taskPromise,
        taskStatusesPromise,
        documentsPromise,
    ]);

    const assignedAvatar: Blob | null =
        !!task.assignedTo && !!task.assignedTo.avatarDownloadURL
            ? await fetchAvatarImageAsBlob(wfParams, task.assignedTo.avatarDownloadURL)
            : null;

    const taskStatus: TaskStatus = getTaskStatus(task.status, possibleTaskStatuses);

    const mappedDocuments: WfDocumentMappedList = documents?.map((document) => ({
        name: document.name,
        url: `${wfParams.wfInstanceUrl}${document.previewURL}`,
    }));

    const assignedTo = task.assignedTo
        ? {
              ID: task.assignedTo.ID,
              name: task.assignedTo.name,
              title: task.assignedTo.title,
              avatar: assignedAvatar,
          }
        : null;
    return {
        task: {
            ID: task.ID,
            name: task.name,
            description: task.description,
            status: taskStatus,
            assignedTo,
            previewUrl: task[PREVIEW_URL_CUSTOM_FIELD_NAME_WITH_PREFIX] ?? null,
            publishUrl: task[PUBLISH_URL_CUSTOM_FIELD_NAME_WITH_PREFIX] ?? null,
            plannedCompletionDate: task.plannedCompletionDate,
            contentFragment: task[CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX] ?? undefined,
            projectName: task.project.name,
            projectId: task.project.ID,
            documents: mappedDocuments,
        },
    };
};

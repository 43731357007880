/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Button, Flex, Heading, Text, View } from "@adobe/react-spectrum";
import { Studio } from "@components/studio";
import { DEFAULT_RENDER_SETTINGS } from "@shared/common";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

import { useStudioStrings } from "@src/interfaces/common/hooks/useStudioStrings";
import { useUrlQuery } from "@src/interfaces/common/hooks/useUrlQuery";
import { MODEL_CONTENT_TYPES } from "@src/lib/utils/filenameHelper";

export function StudioStandAlone() {
    const strings = useStudioStrings();
    const query = useUrlQuery();
    const queryModelUrl = query.get("model_url");
    const cameraName = query.get("camera_name");
    const optimizerFpsStr = query.get("optimizer_fps");
    const optimizerFps: number | undefined = optimizerFpsStr
        ? +optimizerFpsStr
        : undefined;
    const optimizerFreqStr = query.get("optimizer_freq");
    const optimizerFreq: number | undefined = optimizerFreqStr
        ? +optimizerFreqStr
        : undefined;
    const [isDragOver, setIsDragOver] = useState(false);
    const [modelUrl, setModelUrl] = useState<string | null>(
        queryModelUrl && decodeURIComponent(queryModelUrl),
    );

    const onDragEnter = () => {
        setIsDragOver(true);
    };

    const onDragLeave = () => {
        setIsDragOver(false);
    };

    const onDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles[0]) {
            setModelUrl(URL.createObjectURL(acceptedFiles[0]));
        }
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        accept: {
            ".glb": [MODEL_CONTENT_TYPES.glb],
        },
        onDragEnter,
        onDragLeave,
        multiple: false,
    });

    const optimizerSettings = {
        optimizerFps,
        optimizerFreq,
    };

    return (
        <Flex height="100%" width="100vw" direction="column">
            {modelUrl ? (
                <View width="100%" height="100%">
                    <Studio
                        strings={strings}
                        enableLimitedZoom={false}
                        modelUrl={modelUrl}
                        cameraName={cameraName ?? undefined}
                        renderSettings={DEFAULT_RENDER_SETTINGS}
                        optimizerSettings={optimizerSettings}
                    />
                </View>
            ) : (
                <View
                    height="100%"
                    margin="size-300"
                    padding="size-150"
                    borderRadius="medium"
                    borderWidth="thick"
                    borderColor={isDragOver ? "green-700" : "gray-700"}>
                    <div {...getRootProps()}>
                        <Flex
                            height="100%"
                            direction="column"
                            justifyContent="center"
                            alignItems="center">
                            <input {...getInputProps()} />
                            <Heading marginBottom="size-300">
                                Drag and drop glb
                            </Heading>
                            <Button variant="cta" onPress={open}>
                                <Text>Click to open file picker</Text>
                            </Button>
                        </Flex>
                    </div>
                </View>
            )}
        </Flex>
    );
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

// @ts-ignore
import template_f353e from "./053346335df02354255c411b90c14531001c640c8baa2a6c6737e038df5f353e.json" assert { type: "json" };
// @ts-ignore
import template_70c18 from "./0da94d9f2c64a161e1e262d2862a8cb6c29bdebc60ca44fda951f58f0af70c18.json" assert { type: "json" };
// @ts-ignore
import template_31876 from "./10af7bd74769faa88988cbf22dab2c12cdcb072e292fbcbd4113b1a26b031876.json" assert { type: "json" };
// @ts-ignore
import template_67123 from "./10fa3caa4bb39fbaac72e5845482cf856335e9b50058b8e12b2fb836f7367123.json" assert { type: "json" };
// @ts-ignore
import template_ba595 from "./1340f2255d8f8749dbf3f7a4dcaf10f0240c325ca823a8ac2470c4da2e3ba595.json" assert { type: "json" };
// @ts-ignore
import template_77e14 from "./13f6b73cf13939f45047d85316a936526500aaf8765c478e69b3926a3d977e14.json" assert { type: "json" };
// @ts-ignore
import template_5dbd3 from "./1d609c45d9a33249f98761199ac1260135e7a40d85a6eef8ae342fea0305dbd3.json" assert { type: "json" };
// @ts-ignore
import template_e123e from "./2458819d7ad5df89fbbaf790f76f2dc3927c9f021c1a93563fda9e143e6e123e.json" assert { type: "json" };
// @ts-ignore
import template_ce919 from "./28ff7512ad34c9f8a2b794d7fdbf7f78ee4f90d4a4a2b32453d79bcf62ece919.json" assert { type: "json" };
// @ts-ignore
import template_27dd2 from "./3169ce6442acdc8192ea935cfc09f6110dc31899379717f3b43c3b9045a27dd2.json" assert { type: "json" };
// @ts-ignore
import template_5624f from "./56acb6f16f84e703c8e21391bd8e9e1996b008a0ad3c7f820ee3179ca045624f.json" assert { type: "json" };
// @ts-ignore
import template_83a1d from "./572480ceef9b72ff9b7e2ef1d9f59167e6ced09a4f7485a04d5a4d5e0ea83a1d.json" assert { type: "json" };
// @ts-ignore
import template_b9c5e from "./60281874ce19fd4925e980fd8c34a8a363f9888daa63c7007829271d2cfb9c5e.json" assert { type: "json" };
// @ts-ignore
import template_6d041 from "./7a11e9f99b006a0ad5eb799b0a92440d1640519ccbc3d85f8696196b99f6d041.json" assert { type: "json" };
// @ts-ignore
import template_a845d from "./7f2465ac7cefab2766e6ee0714647089df9364b0ff09858c84b21b8a436a845d.json" assert { type: "json" };
// @ts-ignore
import template_8db9e from "./91d872dab2d1ef2f39d0a970da409b77a20e98bc977bbd62cf7fad515e08db9e.json" assert { type: "json" };
// @ts-ignore
import template_9c7b8 from "./950e6dfd232ba84c2679e7e6d20037ee472258d5dc024d02a1ca9d4b0939c7b8.json" assert { type: "json" };
// @ts-ignore
import template_22709 from "./9932e120bbc47a0d08142e068a57cd94c10f1bbc86c6c391ac724b6e7a022709.json" assert { type: "json" };
// @ts-ignore
import template_asset from "./asset.json" assert { type: "json" };
// @ts-ignore
import template_a8e5e from "./b04c8a58f683a528430d346966f05bc63f68ed6be42e620b23bf4b5c114a8e5e.json" assert { type: "json" };
// @ts-ignore
import template_d70db from "./b08648dbd62b71bd0027809076ca755dbfe4f120553ce6f60d910143082d70db.json" assert { type: "json" };
// @ts-ignore
import template_edb27 from "./b5efa9ec3f5f8d45fc05aa5c0a835177e24211b8fda91dd28f258f765f3edb27.json" assert { type: "json" };
// @ts-ignore
import template_78c50 from "./be257cb26e5fc2320f079374eefeec30eede40fc4fc8bfb4338079d206578c50.json" assert { type: "json" };
// @ts-ignore
import template_75af3 from "./c2901f619cda536096c159bd156ae3ddadc73f3edd9ecde2e177005fcd675af3.json" assert { type: "json" };
// @ts-ignore
import template_d5729 from "./c43612e396baa1918f2cfb8137110d0ca9bf6172f1dfb809be26f3c5d40d5729.json" assert { type: "json" };
// @ts-ignore
import template_fbecd from "./ce88af02db147b2e1fd5df9313fde47e1143e9a0ee18ff25cd8681e28fefbecd.json" assert { type: "json" };
// @ts-ignore
import template_27cfb from "./d77f97aff67fe8ad32aaee16d274c1f2917ff2b09de56ab71ea3afc8c1b27cfb.json" assert { type: "json" };
// @ts-ignore
import template_599b7 from "./ee905e10f67894f6eaedf8eeeb8ed32db1811b330fe7fc8e5fdc52813e7599b7.json" assert { type: "json" };
// @ts-ignore
import template_a1feb from "./f7dd6d3ca0cd20f0e1849a453765f415966180e27873eec4955a7ea0605a1feb.json" assert { type: "json" };

export const templateCatalog = {
    assetTemplate: {
        active: false,
        metadata: template_asset.template.metadata,
        variants: template_asset.template.variants,
        RenderSettings: template_asset.template.RenderSettings,
        iblFilename: "studio_white_soft_light-1k_180_front_comp",
        url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Asset_generic_v009.usdz?sp=r&st=2024-06-11T17:28:02Z&se=2026-07-06T18:00:00Z&spr=https&sv=2022-11-02&sr=b&sig=inrw8R%2BtUUKn45VU%2B8Z%2BwF2DeWfbGFN%2FZIxnE46Bcag%3D",
    },
    projectTemplates: {
        b08648dbd62b71bd0027809076ca755dbfe4f120553ce6f60d910143082d70db: {
            active: false,
            metadata: template_d70db.template.metadata,
            variants: template_d70db.template.variants,
            RenderSettings: template_d70db.template.RenderSettings,
            iblFilename: "Studio_White_Hard_Sunlight",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Accessories_v003.usdz?sp=r&st=2023-10-16T22:28:47Z&se=2025-10-17T06:28:47Z&spr=https&sv=2022-11-02&sr=b&sig=QdgUCa5%2BG45GiSVHTjn9LqZqhLfLdHEPvz00dOGV5Ac%3D",
        },
        "950e6dfd232ba84c2679e7e6d20037ee472258d5dc024d02a1ca9d4b0939c7b8": {
            active: false,
            metadata: template_9c7b8.template.metadata,
            variants: template_9c7b8.template.variants,
            RenderSettings: template_9c7b8.template.RenderSettings,
            iblFilename: "Studio_White_Cosmetics_Hard_Light",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Apparel_detail_v003.usdz?sp=r&st=2023-10-16T22:36:07Z&se=2025-10-17T06:36:07Z&spr=https&sv=2022-11-02&sr=b&sig=e0Vb98c8yLIhJHcftGILoWaAF1CV5ktSVqLAx3By%2FTg%3D",
        },
        c43612e396baa1918f2cfb8137110d0ca9bf6172f1dfb809be26f3c5d40d5729: {
            active: true,
            metadata: template_d5729.template.metadata,
            variants: template_d5729.template.variants,
            RenderSettings: template_d5729.template.RenderSettings,
            iblFilename: "Studio_White_Cosmetics_Packshot_01",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Cosmetics_v007.usdz?sp=r&st=2024-03-04T02:59:05Z&se=2026-03-04T10:59:05Z&spr=https&sv=2022-11-02&sr=b&sig=WhfUnkvo7a%2BhVHnKlQn%2FJL0u6lM2btY85fw35OcqtSw%3D",
        },
        "572480ceef9b72ff9b7e2ef1d9f59167e6ced09a4f7485a04d5a4d5e0ea83a1d": {
            active: false,
            metadata: template_83a1d.template.metadata,
            variants: template_83a1d.template.variants,
            RenderSettings: template_83a1d.template.RenderSettings,
            iblFilename: "Studio_White_Soft_Light_02",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/E-Commerce_v003.usdz?sp=r&st=2023-10-16T22:37:01Z&se=2025-10-17T06:37:01Z&spr=https&sv=2022-11-02&sr=b&sig=cNdvFRljnhGqDm7xTRlA6WZL2v6tBHnJnDayrwxTt70%3D",
        },
        "7f2465ac7cefab2766e6ee0714647089df9364b0ff09858c84b21b8a436a845d": {
            active: false,
            metadata: template_a845d.template.metadata,
            variants: template_a845d.template.variants,
            RenderSettings: template_a845d.template.RenderSettings,
            iblFilename: "Studio_Color_Soft_Light_01",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Equipment_v003.usdz?sp=r&st=2023-10-16T22:37:20Z&se=2025-10-17T06:37:20Z&spr=https&sv=2022-11-02&sr=b&sig=12Y%2F35CW4WJn8eProLTrzmqgi8q8xSIM8YmvxUTAAD8%3D",
        },
        c2901f619cda536096c159bd156ae3ddadc73f3edd9ecde2e177005fcd675af3: {
            active: false,
            metadata: template_75af3.template.metadata,
            variants: template_75af3.template.variants,
            RenderSettings: template_75af3.template.RenderSettings,
            iblFilename: "Studio_White_North_Daylight",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Furniture_north_v004.usdz?sp=r&st=2023-10-20T17:30:33Z&se=2025-10-21T01:30:33Z&spr=https&sv=2022-11-02&sr=b&sig=p1uxJ5V2tP7ojr%2BXIWUAfwBVBrYBZxbx3RwJkSZyEMc%3D",
        },
        "053346335df02354255c411b90c14531001c640c8baa2a6c6737e038df5f353e": {
            active: false,
            metadata: template_f353e.template.metadata,
            variants: template_f353e.template.variants,
            RenderSettings: template_f353e.template.RenderSettings,
            iblFilename: "Studio_White_Soft_Light_05",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Furniture_soft_v004.usdz?sp=r&st=2023-10-20T17:30:54Z&se=2025-10-21T01:30:54Z&spr=https&sv=2022-11-02&sr=b&sig=ybSDTvnEWQzrPWB01vKGNs6n6Z4%2BKV4bECRhWA4AcRY%3D",
        },
        "28ff7512ad34c9f8a2b794d7fdbf7f78ee4f90d4a4a2b32453d79bcf62ece919": {
            active: false,
            metadata: template_ce919.template.metadata,
            variants: template_ce919.template.variants,
            RenderSettings: template_ce919.template.RenderSettings,
            iblFilename: "Studio_White_Soft_Light_05",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Kitchen_equipment_v004.usdz?sp=r&st=2023-10-20T18:22:27Z&se=2025-10-21T02:22:27Z&spr=https&sv=2022-11-02&sr=b&sig=dCpEncKfZv2j3oxWtMvgTk9Hd6DNCZM1QRzmAAczikI%3D",
        },
        f7dd6d3ca0cd20f0e1849a453765f415966180e27873eec4955a7ea0605a1feb: {
            active: false,
            metadata: template_a1feb.template.metadata,
            variants: template_a1feb.template.variants,
            RenderSettings: template_a1feb.template.RenderSettings,
            iblFilename: "Studio_White_North_Daylight",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Leather_goods_v004.usdz?sp=r&st=2023-10-20T17:31:25Z&se=2025-10-21T01:31:25Z&spr=https&sv=2022-11-02&sr=b&sig=JiWl5kLanPex9psHLTFyukQkaHbCBs3M1hxg%2Fu67%2F%2Fs%3D",
        },
        b5efa9ec3f5f8d45fc05aa5c0a835177e24211b8fda91dd28f258f765f3edb27: {
            active: false,
            metadata: template_edb27.template.metadata,
            variants: template_edb27.template.variants,
            RenderSettings: template_edb27.template.RenderSettings,
            iblFilename: "Studio_White_Soft_Light_05",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Shoes_v004.usdz?sp=r&st=2023-10-20T17:31:44Z&se=2025-10-21T01:31:44Z&spr=https&sv=2022-11-02&sr=b&sig=rrIhALKmb717LH%2Fp16b1qA5mWnyI47DyjfB8hrxlxgA%3D",
        },
        // ================================= NEW BETA TEMPLATES
        "0da94d9f2c64a161e1e262d2862a8cb6c29bdebc60ca44fda951f58f0af70c18": {
            active: true,
            metadata: template_70c18.template.metadata,
            variants: template_70c18.template.variants,
            RenderSettings: template_70c18.template.RenderSettings,
            iblFilename: "ibl_bl_product_e_com_jewelry_wh_all_key_global",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Watches_v003.usdz?sp=r&st=2024-03-04T02:59:38Z&se=2026-03-04T10:59:38Z&spr=https&sv=2022-11-02&sr=b&sig=m3b1ScdGOWtNQA2veh%2BjhTpfza4rxBSVhxwWPwGyf20%3D",
        },
        "1d609c45d9a33249f98761199ac1260135e7a40d85a6eef8ae342fea0305dbd3": {
            active: true,
            metadata: template_5dbd3.template.metadata,
            variants: template_5dbd3.template.variants,
            RenderSettings: template_5dbd3.template.RenderSettings,
            iblFilename: "Studio_White_Umbrella_Hard_Light_03",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/ReadyToWearTopLight_v003.usdz?sp=r&st=2024-03-04T03:00:02Z&se=2026-03-04T11:00:02Z&spr=https&sv=2022-11-02&sr=b&sig=L0AjnKrQQB6Dsvd7B8ukXNCdQYN%2BHEaORJwhxbUzDzA%3D",
        },
        "7a11e9f99b006a0ad5eb799b0a92440d1640519ccbc3d85f8696196b99f6d041": {
            active: true,
            metadata: template_6d041.template.metadata,
            variants: template_6d041.template.variants,
            RenderSettings: template_6d041.template.RenderSettings,
            iblFilename: "Studio_White_Soft_Light_02",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/FoodProduce_v003.usdz?sp=r&st=2024-03-04T03:00:24Z&se=2026-03-04T11:00:24Z&spr=https&sv=2022-11-02&sr=b&sig=1NJuj3zS26GMS9k0JScoqhOXewkST7MaknteFaOsBaI%3D",
        },
        "10af7bd74769faa88988cbf22dab2c12cdcb072e292fbcbd4113b1a26b031876": {
            active: true,
            metadata: template_31876.template.metadata,
            variants: template_31876.template.variants,
            RenderSettings: template_31876.template.RenderSettings,
            iblFilename: "Studio_White_Umbrella_Hard_Light_01",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/ReadyToWearSideLight_v003.usdz?sp=r&st=2024-03-04T03:00:50Z&se=2026-03-04T11:00:50Z&spr=https&sv=2022-11-02&sr=b&sig=cpNU%2Be0Z0r%2FDoNvrw1P3MZWPA850WV9H1tjuGX%2F%2FqU0%3D",
        },
        "10fa3caa4bb39fbaac72e5845482cf856335e9b50058b8e12b2fb836f7367123": {
            active: true,
            metadata: template_67123.template.metadata,
            variants: template_67123.template.variants,
            RenderSettings: template_67123.template.RenderSettings,
            iblFilename: "Studio_White_Soft_Light_02",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/GenericPackShot_v003.usdz?sp=r&st=2024-03-04T03:01:10Z&se=2026-03-04T11:01:10Z&spr=https&sv=2022-11-02&sr=b&sig=fwoNtFe3At3MJW%2FtEWbaervN8LdL3oDRxZhAexPBc5I%3D",
        },
        "13f6b73cf13939f45047d85316a936526500aaf8765c478e69b3926a3d977e14": {
            active: true,
            metadata: template_77e14.template.metadata,
            variants: template_77e14.template.variants,
            RenderSettings: template_77e14.template.RenderSettings,
            iblFilename: "Studio_White_Soft_Light_02",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/LayingApparel_v003.usdz?sp=r&st=2024-03-04T03:01:29Z&se=2026-03-04T11:01:29Z&spr=https&sv=2022-11-02&sr=b&sig=lOFX4WqpHvioqEfTMPYXkWMisT9bceTMec0lJSPJRcI%3D",
        },
        "56acb6f16f84e703c8e21391bd8e9e1996b008a0ad3c7f820ee3179ca045624f": {
            active: true,
            metadata: template_5624f.template.metadata,
            variants: template_5624f.template.variants,
            RenderSettings: template_5624f.template.RenderSettings,
            iblFilename: "Studio_White_Soft_Light_05",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/HomeAppliancesAndTools_v003.usdz?sp=r&st=2024-03-04T03:01:48Z&se=2026-03-04T11:01:48Z&spr=https&sv=2022-11-02&sr=b&sig=JW%2FUb09padafGHBsQfEFW96wcqtMuVPFIfluf%2FqhbnI%3D",
        },
        "91d872dab2d1ef2f39d0a970da409b77a20e98bc977bbd62cf7fad515e08db9e": {
            active: true,
            metadata: template_8db9e.template.metadata,
            variants: template_8db9e.template.variants,
            RenderSettings: template_8db9e.template.RenderSettings,
            iblFilename: "ibl_bl_product_sl_large_scale",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/HomeFurnitureTopLight_v003.usdz?sp=r&st=2024-03-04T03:04:46Z&se=2026-03-04T11:04:46Z&spr=https&sv=2022-11-02&sr=b&sig=LTO6zpvThZKBHqoZFJmh8HoFR9cUs1miI8CvAe9BgVs%3D",
        },
        "1340f2255d8f8749dbf3f7a4dcaf10f0240c325ca823a8ac2470c4da2e3ba595": {
            active: true,
            metadata: template_ba595.template.metadata,
            variants: template_ba595.template.variants,
            RenderSettings: template_ba595.template.RenderSettings,
            iblFilename: "Studio_White_Soft_Light_02",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/GenericAsset_v003.usdz?sp=r&st=2024-03-04T03:05:08Z&se=2026-03-04T11:05:08Z&spr=https&sv=2022-11-02&sr=b&sig=KLdgfQfqvGMqvBcbaCYYGhZEXaIFgtb329dUc03Vmzo%3D",
        },
        "3169ce6442acdc8192ea935cfc09f6110dc31899379717f3b43c3b9045a27dd2": {
            active: true,
            metadata: template_27dd2.template.metadata,
            variants: template_27dd2.template.variants,
            RenderSettings: template_27dd2.template.RenderSettings,
            iblFilename: "Studio_Black_Soft_Light_05",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Technology_v003.usdz?sp=r&st=2024-03-04T03:05:29Z&se=2026-03-04T11:05:29Z&spr=https&sv=2022-11-02&sr=b&sig=dzcxxBRBfy%2BcZkxJfje3ccPLSG5DbLSOY8wzq9Rl3DI%3D",
        },
        "9932e120bbc47a0d08142e068a57cd94c10f1bbc86c6c391ac724b6e7a022709": {
            active: true,
            metadata: template_22709.template.metadata,
            variants: template_22709.template.variants,
            RenderSettings: template_22709.template.RenderSettings,
            iblFilename: "ibl_bl_product_fsdl_large_scale_v2",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/HomeFurnitureSideLight_v003.usdz?sp=r&st=2024-03-04T03:05:55Z&se=2026-03-04T11:05:55Z&spr=https&sv=2022-11-02&sr=b&sig=x14TcgcxwF6wE6RL5it3nRWdgi%2B%2BVzUJBU63F4Fy7uI%3D",
        },
        "2458819d7ad5df89fbbaf790f76f2dc3927c9f021c1a93563fda9e143e6e123e": {
            active: true,
            metadata: template_e123e.template.metadata,
            variants: template_e123e.template.variants,
            RenderSettings: template_e123e.template.RenderSettings,
            iblFilename: "Studio_White_Umbrella_Soft_Light",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/ReadyToWearFrontLight_v003.usdz?sp=r&st=2024-03-04T03:06:14Z&se=2026-03-04T11:06:14Z&spr=https&sv=2022-11-02&sr=b&sig=bQ1pSQ2WtJyFDA9MyfD5h6m2BPQUz9wLfw16uLWdii4%3D",
        },
        "60281874ce19fd4925e980fd8c34a8a363f9888daa63c7007829271d2cfb9c5e": {
            active: true,
            metadata: template_b9c5e.template.metadata,
            variants: template_b9c5e.template.variants,
            RenderSettings: template_b9c5e.template.RenderSettings,
            iblFilename: "ibl_bl_product_e_shop_soft_contrast_2",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/LeatherGoodsStandardLight_v003.usdz?sp=r&st=2024-03-04T03:06:32Z&se=2026-03-04T11:06:32Z&spr=https&sv=2022-11-02&sr=b&sig=za2mATBWn0svAEKHdWJ818r5GaWNtBA66Vp8rwuJdQs%3D",
        },
        b04c8a58f683a528430d346966f05bc63f68ed6be42e620b23bf4b5c114a8e5e: {
            active: true,
            metadata: template_a8e5e.template.metadata,
            variants: template_a8e5e.template.variants,
            RenderSettings: template_a8e5e.template.RenderSettings,
            iblFilename: "ibl_bl_product_e_shop_soft_contrast_2",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/FootwearStandardLight_v003.usdz?sp=r&st=2024-03-04T03:06:56Z&se=2026-03-04T11:06:56Z&spr=https&sv=2022-11-02&sr=b&sig=4YUiONY991LoGFrhNazUcwkqNB%2BjMKJOASlfplFpnxg%3D",
        },
        be257cb26e5fc2320f079374eefeec30eede40fc4fc8bfb4338079d206578c50: {
            active: true,
            metadata: template_78c50.template.metadata,
            variants: template_78c50.template.variants,
            RenderSettings: template_78c50.template.RenderSettings,
            iblFilename: "Studio_White_Cosmetics_Hard_Light",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Fragrance_v003.usdz?sp=r&st=2024-03-04T03:07:13Z&se=2026-03-04T11:07:13Z&spr=https&sv=2022-11-02&sr=b&sig=97Xj5aBqXYPNEYU9r6Gs5AFyzIU9tDH%2FRNMEZYH04pg%3D",
        },
        ce88af02db147b2e1fd5df9313fde47e1143e9a0ee18ff25cd8681e28fefbecd: {
            active: true,
            metadata: template_fbecd.template.metadata,
            variants: template_fbecd.template.variants,
            RenderSettings: template_fbecd.template.RenderSettings,
            iblFilename: "ibl_bl_product_e_shop_toplight_contrast_1",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/FootwearTopLight_v003.usdz?sp=r&st=2024-03-04T03:07:39Z&se=2026-03-04T11:07:39Z&spr=https&sv=2022-11-02&sr=b&sig=1Xs%2Fagg3EsCqkVyxcWXCbSNNiCAR9mTg%2FvxyYWaru1w%3D",
        },
        d77f97aff67fe8ad32aaee16d274c1f2917ff2b09de56ab71ea3afc8c1b27cfb: {
            active: true,
            metadata: template_27cfb.template.metadata,
            variants: template_27cfb.template.variants,
            RenderSettings: template_27cfb.template.RenderSettings,
            iblFilename: "ibl_bl_product_e_com_transparency_wh",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/Beverage_v003.usdz?sp=r&st=2024-03-04T03:07:57Z&se=2026-03-04T11:07:57Z&spr=https&sv=2022-11-02&sr=b&sig=b%2BkoroTqGKtOc5GwNPY5wf5JcmU2o0Dtmk%2Fen0ZMhEY%3D",
        },
        ee905e10f67894f6eaedf8eeeb8ed32db1811b330fe7fc8e5fdc52813e7599b7: {
            active: true,
            metadata: template_599b7.template.metadata,
            variants: template_599b7.template.variants,
            RenderSettings: template_599b7.template.RenderSettings,
            iblFilename: "ibl_bl_product_e_shop_toplight_contrast_1",
            url: "https://27ad4423613f4530aee4d2aa.blob.core.windows.net/sunrise-asset/ProductionProjectTemplates/LeatherGoodsTopLight_v003.usdz?sp=r&st=2024-03-04T03:08:18Z&se=2026-03-04T11:08:18Z&spr=https&sv=2022-11-02&sr=b&sig=syj8y%2FCAY7qnNwdHgUbrEiNtcQSCBUMCMNIFlAMLM84%3D",
        },
    },
};

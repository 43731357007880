/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Avatar, Flex, Heading, View } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { DefaultAvatar } from "@src/interfaces/common/components/DefaultAvatar";

import type { FC } from "react";

type Props = {
    avatarUrl?: string;
    displayName?: string;
};

export const CreatedBySection: FC<Props> = ({ avatarUrl, displayName }) => {
    const { t } = useTranslation(["common"]);

    return (
        <>
            <Heading level={4} marginTop="size-300">
                {t("detailView.detailsPanel.createdBy")}
            </Heading>
            <View
                height="size-400"
                borderWidth="thin"
                borderRadius="regular"
                borderColor="gray-75"
                backgroundColor="gray-200"
                paddingX="size-100"
                paddingTop="size-75"
                UNSAFE_style={{
                    fontSize: "12px",
                    width: "fit-content",
                }}>
                <Flex alignItems="center" columnGap="size-100">
                    {avatarUrl ? (
                        <Avatar
                            src={avatarUrl}
                            size="avatar-size-75"
                            alt={t("user.avatar")}
                        />
                    ) : (
                        <DefaultAvatar aria-label={t("user.avatar")} size="S" />
                    )}
                    {displayName ?? ""}
                </Flex>
            </View>
        </>
    );
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { CreateTaskParameters } from "../requests/types/CreateTaskParameters";
import { CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX } from "../constants";
import { createSubTaskAbstract } from "./private/createSubTaskAbstract";
import { WorkfrontParams } from "../../../types/wfParams";
import { SubTaskDetails } from "../../../types/SubTaskDetails";

export const createSubTask = async (
    wfParams: WorkfrontParams,
    name: string,
    description: string,
    parentTaskID: string,
    projectID: string,
    contentFragment: string,
    assignedToID: string | null | undefined,
): Promise<SubTaskDetails> => {
    const createTaskParameters: CreateTaskParameters = {
        name,
        parentID: parentTaskID,
        projectID,
        description,
        [CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX]: contentFragment,
    };
    if (assignedToID) {
        createTaskParameters.assignedToID = assignedToID;
    }
    return createSubTaskAbstract(wfParams, createTaskParameters);
};

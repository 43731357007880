/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Heading, View } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import type { LibraryAssetCardItem } from "@src/contexts/RpcContext";
import {
    AssetProgressBar,
} from "@src/interfaces/common/components/AssetProgressBars";

import type { FC } from "react";

interface Props {
    asset?: LibraryAssetCardItem;
}

export const AssetCardFooter: FC<Props> = ({ asset }) => {
    const { t } = useTranslation("common");

    if (!asset) {
        return (
            <View height="size-700">
                <Heading
                    level={4}
                    marginTop="size-100"
                    alignSelf="auto"
                    UNSAFE_style={{
                        fontWeight: 400,
                    }}>
                    {t("progress.loading")}
                </Heading>
            </View>
        );
    }

    return (
        <View height="size-700">
            <AssetProgressBar asset={asset}/>
        </View>
    );
};

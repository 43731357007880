/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Button, Flex, Heading, Text } from "@adobe/react-spectrum";
import { HeliosRoutes } from "@shared/common";
import { FBX_VERSION_ERROR } from "@shared/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { DetailViewType } from "../common/DetailViewTabs";
import { rpc } from "@src/contexts/RpcContext";
import { InvalidFile } from "@src/interfaces/common/components/InvalidFile";
import { useRedirects } from "@src/interfaces/common/hooks/useRedirects";

type Props = {
    id: string;
    compositeId: string;
    name: string;
    isVersionUpgrade: boolean;
    validationErrors: string[];
};

export const AssetValidationError = ({
    id,
    validationErrors,
    isVersionUpgrade,
}: Props) => {
    const { t } = useTranslation("library");
    const { assetDetailsRedirectToPrevious } = useRedirects();

    const [title, setTitle] = useState<string>();
    const [description, setDescription] = useState<string>();

    const removeAssetMutation = rpc.libraryAssets.delete.useMutation();
    const revertAssetMutation = rpc.libraryAssets.revert.useMutation();
    const navigate = useNavigate();

    const { data: predecessor } =
        rpc.libraryAssets.getAssetPredecessor.useQuery(id);

    useEffect(() => {
        // We have specific error UI when the FBX version is too old. For all other error types show
        // a generic error UI
        const invalidFbx = validationErrors.find((error) =>
            error.startsWith(FBX_VERSION_ERROR),
        );
        if (invalidFbx) {
            const version = invalidFbx.substring(FBX_VERSION_ERROR.length);
            setTitle(t("detailView.validation.invalidFbxVersion.title"));
            setDescription(
                t("detailView.validation.invalidFbxVersion.description", {
                    version,
                }),
            );
            return;
        }
        setTitle(t("detailView.validation.unknown.title"));
        setDescription(t("detailView.validation.unknown.description"));
    }, [validationErrors.join()]);

    function removeAsset(id: string) {
        removeAssetMutation.mutateAsync(id).catch(() => {
            // Any errors will have been logged on Hermes. Do nothing.
        });
        navigate(HeliosRoutes.library.path);
    }

    const revertAsset = () => {
        try {
            revertAssetMutation.mutate(id);
        } catch (err) {
            throw new Error("Failed to revert asset version");
        }
        assetDetailsRedirectToPrevious(DetailViewType.views2D, predecessor?.id);
    };

    return (
        <Flex
            direction={"column"}
            width="100%"
            height="100%"
            alignItems={"center"}
            justifyContent={"center"}
            gap="size-100">
            <InvalidFile size="L" />
            <Heading marginTop="size-400">{title}</Heading>
            <Text>{description}</Text>
            {isVersionUpgrade ? (
                <Button
                    variant="primary"
                    style="outline"
                    marginTop="size-100"
                    onPress={revertAsset}>
                    {t("detailView.validation.button.revert", {
                        version: predecessor?.version ?? 0,
                    })}
                </Button>
            ) : (
                <Button
                    variant="primary"
                    style="outline"
                    marginTop="size-100"
                    onPress={() => removeAsset(id)}>
                    {t("detailView.validation.button.remove")}
                </Button>
            )}
        </Flex>
    );
};

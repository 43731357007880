/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

import {
    PanningArcRotateCameraPointersInput,
    DEFAULT_TOGGLE_MODE,
} from "../scene/PanningArcRotateCameraPointersInput";

import type { ToggleMode } from "../scene/PanningArcRotateCameraPointersInput";
import type { SceneManager } from "../scene/SceneManager";

export function useCameraControlsToggle(sceneManager?: SceneManager) {
    const [
        panningArcRotateCameraPointersInput,
        setPanningArcRotateCameraPointersInput,
    ] = useState<PanningArcRotateCameraPointersInput>();
    const [mode, setMode] = useState<ToggleMode>(DEFAULT_TOGGLE_MODE);

    useEffect(() => {
        if (sceneManager?.camera) {
            setPanningArcRotateCameraPointersInput(
                new PanningArcRotateCameraPointersInput(
                    sceneManager.camera,
                    mode,
                ),
            );
        }
    }, [sceneManager, mode]);

    useEffect(() => {
        if (panningArcRotateCameraPointersInput) {
            if (sceneManager?.camera) {
                sceneManager.camera.inputs.removeByType(
                    "ArcRotateCameraPointersInput",
                );
                sceneManager.camera.inputs.add(
                    panningArcRotateCameraPointersInput,
                );
            }
            panningArcRotateCameraPointersInput.toggleMode = mode;
        }
    }, [panningArcRotateCameraPointersInput, mode]);

    return {
        mode,
        setMode,
    };
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
export const convertDateToFormat = (date: string) => {
    const strippedPlannedCompletionDate = new Date(date.substring(0, 10));

    const year = strippedPlannedCompletionDate.getFullYear();
    let month: string | number = strippedPlannedCompletionDate.getMonth() + 1;
    let dt: string | number = strippedPlannedCompletionDate.getDate();

    if (dt < 10) {
        dt = `0${dt}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }

    const plannedDate = `${month}/${dt}/${year}`;
    return plannedDate;
};

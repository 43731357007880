/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {
    CustomFormResponse,
    FieldFormRelationResponse,
} from "../../requests/types/CustomFormResponse";
import { CustomFieldResponse } from "../../requests/types/CustomFieldResponse";
import { CONTENT_FRAGMENT_CUSTOM_FIELD_NAME } from "../../constants";
import { fetchRequiredCustomFieldContentFragment } from "../../requests/fetchRequiredCustomFieldContentFragment";
import { fetchRequiredCustomFormContentFragment } from "../../requests/fetchRequiredCustomFormContentFragment";
import { WorkfrontParams } from "../../../../types/wfParams";

//TODO: May be a sessionStorage or localStorage???
let requiredCustomFormIdContentFragment: string;

export const resetRequiredCustomFormIdContentFragment = (): void => {
    requiredCustomFormIdContentFragment = "";
};

export const fetchRequiredCustomFormIdContentFragment = async (
    wfParams: WorkfrontParams,
): Promise<string> => {
    if (!requiredCustomFormIdContentFragment) {
        const customFieldsSearchResult: CustomFieldResponse[] =
            await fetchRequiredCustomFieldContentFragment(wfParams);
        if (!customFieldsSearchResult) {
            throw new Error(
                `Required custom field ${CONTENT_FRAGMENT_CUSTOM_FIELD_NAME} was not found`,
            );
        }
        const customFieldContentFragment: CustomFieldResponse | undefined =
            customFieldsSearchResult.find(
                (customField: CustomFieldResponse) =>
                    customField.name === CONTENT_FRAGMENT_CUSTOM_FIELD_NAME,
            );
        if (!customFieldContentFragment) {
            throw new Error(
                `Required custom field ${CONTENT_FRAGMENT_CUSTOM_FIELD_NAME} was not found`,
            );
        }
        const customFieldContentFragmentId: string = customFieldContentFragment.ID;

        const customFormsSearchResult: CustomFormResponse[] =
            await fetchRequiredCustomFormContentFragment(wfParams, customFieldContentFragmentId);

        if (!customFormsSearchResult || !customFormsSearchResult.length) {
            throw new Error(
                `The required custom form with field ${CONTENT_FRAGMENT_CUSTOM_FIELD_NAME} is not found`,
            );
        }

        if (customFormsSearchResult.length > 1) {
            throw new Error(
                `There are ${customFormsSearchResult.length} custom forms with field ${CONTENT_FRAGMENT_CUSTOM_FIELD_NAME}`,
            );
        }
        const customForm: CustomFormResponse = customFormsSearchResult[0];
        const customFieldsInForm: FieldFormRelationResponse[] = customForm.categoryParameters;
        if (
            !customFieldsInForm.find(
                (customFieldInForm: FieldFormRelationResponse) =>
                    customFieldInForm.parameterID === customFieldContentFragmentId,
            )
        ) {
            throw new Error(
                `The custom form ${customForm.name} does not include ${CONTENT_FRAGMENT_CUSTOM_FIELD_NAME} Field`,
            );
        }
        requiredCustomFormIdContentFragment = customForm.ID;
    }
    return requiredCustomFormIdContentFragment;
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {
    CustomFormResponse,
    FieldFormRelationResponse,
} from "../../requests/types/CustomFormResponse";
import { fetchRequiredCustomFormsPreviewAndPublish } from "../../requests/fetchRequiredCustomFormsPreviewAndPublish";
import { CustomFieldResponse } from "../../requests/types/CustomFieldResponse";
import { fetchRequiredCustomFieldsPreviewAndPublish } from "../../requests/fetchRequiredCustomFieldsPreviewAndPublish";
import { PREVIEW_URL_CUSTOM_FIELD_NAME, PUBLISH_URL_CUSTOM_FIELD_NAME } from "../../constants";
import { WorkfrontParams } from "../../../../types/wfParams";

//TODO: May be a sessionStorage or localStorage???
let requiredCustomFormIdPreviewAndPublish: string;

export const resetRequiredCustomFormIdPreviewAndPublish = (): void => {
    requiredCustomFormIdPreviewAndPublish = "";
};

export const fetchRequiredCustomFormIdPreviewAndPublish = async (
    wfParams: WorkfrontParams,
): Promise<string> => {
    if (!requiredCustomFormIdPreviewAndPublish) {
        const customFieldsSearchResult: CustomFieldResponse[] =
            await fetchRequiredCustomFieldsPreviewAndPublish(wfParams);
        if (!customFieldsSearchResult) {
            throw new Error(
                `Required custom fields ${PREVIEW_URL_CUSTOM_FIELD_NAME} AND ${PUBLISH_URL_CUSTOM_FIELD_NAME} are not found`,
            );
        }
        const customFieldPreviewUrl: CustomFieldResponse | undefined =
            customFieldsSearchResult.find(
                (customField: CustomFieldResponse) =>
                    customField.name === PREVIEW_URL_CUSTOM_FIELD_NAME,
            );
        if (!customFieldPreviewUrl) {
            throw new Error(`Required custom field ${PREVIEW_URL_CUSTOM_FIELD_NAME} was not found`);
        }
        const customFieldPreviewUrlId: string = customFieldPreviewUrl.ID;

        const customFieldPublishUrl: CustomFieldResponse | undefined =
            customFieldsSearchResult.find(
                (customField: CustomFieldResponse) =>
                    customField.name === PUBLISH_URL_CUSTOM_FIELD_NAME,
            );
        if (!customFieldPublishUrl) {
            throw new Error(`Required custom field ${PUBLISH_URL_CUSTOM_FIELD_NAME} was not found`);
        }
        const customFieldPublishUrlId: string = customFieldPublishUrl.ID;

        const customFormsSearchResult: CustomFormResponse[] =
            await fetchRequiredCustomFormsPreviewAndPublish(
                wfParams,
                customFieldPreviewUrlId,
                customFieldPublishUrlId,
            );

        if (!customFormsSearchResult || !customFormsSearchResult.length) {
            throw new Error(
                `The required custom form with fields ${PREVIEW_URL_CUSTOM_FIELD_NAME} AND ${PUBLISH_URL_CUSTOM_FIELD_NAME} is not found`,
            );
        }

        if (customFormsSearchResult.length > 1) {
            throw new Error(
                `There are ${customFormsSearchResult.length} custom forms with fields ${PREVIEW_URL_CUSTOM_FIELD_NAME} OR ${PUBLISH_URL_CUSTOM_FIELD_NAME}`,
            );
        }
        const customForm: CustomFormResponse = customFormsSearchResult[0];
        const customFieldsInForm: FieldFormRelationResponse[] = customForm.categoryParameters;
        if (
            !customFieldsInForm.find(
                (customFieldInForm: FieldFormRelationResponse) =>
                    customFieldInForm.parameterID === customFieldPreviewUrlId,
            )
        ) {
            throw new Error(
                `The custom form ${customForm.name} does not include ${PREVIEW_URL_CUSTOM_FIELD_NAME} Field`,
            );
        }

        if (
            !customFieldsInForm.find(
                (customFieldInForm: FieldFormRelationResponse) =>
                    customFieldInForm.parameterID === customFieldPublishUrlId,
            )
        ) {
            throw new Error(
                `The custom form ${customForm.name} does not include ${PUBLISH_URL_CUSTOM_FIELD_NAME} Field`,
            );
        }

        requiredCustomFormIdPreviewAndPublish = customForm.ID;
    }
    return requiredCustomFormIdPreviewAndPublish;
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { AbstractStatusResponse } from "../../requests/types/AbstractStatusResponse";
import { ProjectStatus } from "../../../../types/ProjectStatus";
import { ProjectCondition } from "../../../../types/ProjectCondition";
import { TaskStatus } from "../../../../types/TaskStatus";

export const getProjectStatus = (
    actualProjectStatusCode: string,
    possibleProjectStatuses: AbstractStatusResponse[],
): ProjectStatus => {
    const projectStatusApiResponse: AbstractStatusResponse | undefined =
        possibleProjectStatuses.find(
            (possibleProjectStatus: AbstractStatusResponse) =>
                possibleProjectStatus.value === actualProjectStatusCode,
        );
    if (!projectStatusApiResponse) {
        throw new Error("Can not fetch project status");
    }
    return {
        label: projectStatusApiResponse.label,
        value: projectStatusApiResponse.value,
        color: projectStatusApiResponse.color,
    };
};

export const getProjectCondition = (
    actualProjectConditionCode: string,
    possibleProjectConditions: AbstractStatusResponse[],
): ProjectCondition => {
    const projectConditionApiResponse: AbstractStatusResponse | undefined =
        possibleProjectConditions.find(
            (possibleProjectCondition: AbstractStatusResponse) =>
                possibleProjectCondition.value === actualProjectConditionCode,
        );
    if (!projectConditionApiResponse) {
        throw new Error("Can not fetch project condition");
    }
    return {
        label: projectConditionApiResponse.label,
        value: projectConditionApiResponse.value,
        color: projectConditionApiResponse.color,
    };
};

export const getTaskStatus = (
    actualTaskStatusCode: string,
    possibleTaskStatuses: AbstractStatusResponse[],
): TaskStatus => {
    const taskStatusApiResponse: AbstractStatusResponse | undefined = possibleTaskStatuses.find(
        (possibleTaskStatus: AbstractStatusResponse) =>
            possibleTaskStatus.value === actualTaskStatusCode,
    );
    if (!taskStatusApiResponse) {
        throw new Error("Can not fetch task status");
    }
    return {
        label: taskStatusApiResponse.label,
        value: taskStatusApiResponse.value,
        color: taskStatusApiResponse.color,
    };
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { updateTaskAbstract } from "./private/updateTaskAbstract";
import { TaskUpdateParameter } from "../requests/types/TaskUpdateParameter";
import { WorkfrontParams } from "../../../types/wfParams";

export const changeTaskStatus = async (
    wfParams: WorkfrontParams,
    taskId: string,
    statusCode: string,
) => {
    const taskUpdateParameters: TaskUpdateParameter = {
        name: "status",
        humanReadableName: "Task status",
        value: statusCode,
    };
    return updateTaskAbstract(wfParams, taskId, [taskUpdateParameters]);
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, View } from "@adobe/react-spectrum";
import { matchPath, useLocation } from "react-router-dom";

import { AssetsFilter } from "./AssetsFilter";
import { Upload } from "./Upload";
import { useUIStateContext } from "@src/contexts/UIStateContext";
import { useUpload } from "@src/interfaces/common/hooks/useUpload";
import type { UploadAnalyticsBatchFields } from "@src/lib/analytics/AnalyticsConstants";
import { Routes } from "@src/routes";

import type { FileRejection } from "react-dropzone";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import { useEffect } from "react";

export const AssetLeftNavPanel = () => {
    const { showToastForRejectedFiles, error } = useMessagingContext();
    const { assetFilterSelection, setAssetFilterSelection } =
        useUIStateContext();

    const { uploadFiles: doUploadFiles, notifyRejectedFiles } = useUpload();
    const uploadFiles = async (
        acceptedFiles: File[],
        rejectedFiles: FileRejection[],
        uploadAnalyticsFields: UploadAnalyticsBatchFields,
    ) => {
        if (rejectedFiles.length) {
            notifyRejectedFiles(rejectedFiles);
            // TODO migrate to RS3 toast. Currently placed top center, OK to move to lower right
            showToastForRejectedFiles(rejectedFiles);
        }

        return doUploadFiles(acceptedFiles, uploadAnalyticsFields)
            .then((rejectedResults) => {
                rejectedResults.forEach((rejectedResult) => {
                    if (rejectedResult.reason.message) {
                        error(rejectedResult.reason.message);
                    }
                    console.error(rejectedResult.reason);
                });
            })
            .catch((e) => {
                if (e.message) {
                    error(e.message);
                }
                console.error(e);
            });
    };

    return (
        <View backgroundColor="gray-100" width="100%" flex="none">
            <View paddingTop="size-100" paddingBottom="size-200">
                <Flex direction="row" rowGap="size-150">
                    <Upload handleFiles={uploadFiles} />
                </Flex>
            </View>
            {assetFilterSelection && <AssetsFilter />}
        </View>
    );
};

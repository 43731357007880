/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { t } from "i18next";
import { useMemo } from "react";

export const useFormatDate = (dateString: string) => {
    return useMemo(() => {
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
        } as Intl.DateTimeFormatOptions;
        const date = new Date(dateString);
        return !isNaN(date.valueOf())
            ? t("{{val, datetime}}", {
                  val: date,
                  formatParams: { val: options },
                  interpolation: { escapeValue: false },
              })
            : undefined;
    }, [dateString]);
};

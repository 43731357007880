/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useState } from "react";

import type { HotSpot } from "@shared/types";

const HOT_SPOT_CACHE_PREFIX = "sunrise-hot-spots";

function writeSpots(id: string, hotSpots: HotSpot[]) {
    window.sessionStorage.setItem(
        `${HOT_SPOT_CACHE_PREFIX}-${id}`,
        JSON.stringify(hotSpots),
    );
}

function clearSpots(id: string) {
    window.sessionStorage.removeItem(`${HOT_SPOT_CACHE_PREFIX}-${id}`);
}

export function useHotSpotCache(id: string) {
    const [hotSpots, setHotSpots] = useState<HotSpot[] | undefined>();

    function updateHotSpots(updatedHotSpots: HotSpot[]) {
        writeSpots(id, updatedHotSpots);
        setHotSpots(updatedHotSpots);
    }

    function clearSavedSpots() {
        clearSpots(id);
    }

    function initializeHotSpots(initialHotSpots?: HotSpot[]) {
        if (initialHotSpots) {
            updateHotSpots(initialHotSpots);
        }
    }

    return {
        hotSpots,
        initializeHotSpots,
        updateHotSpots,
        clearSavedSpots,
    };
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Divider, Flex, Text, View } from "@adobe/react-spectrum";
import Home from "@spectrum-icons/workflow/Home";
import { useTranslation } from "react-i18next";

import { useIsChromeless } from "../hooks/useIsChromeless";
import { useFeatureFlagContext } from "@src/contexts/FeatureFlagContext";
import { AssetLeftNavPanel } from "@src/interfaces/library/components/AssetLeftNavPanel";
import { CreateProject } from "@src/interfaces/projects/components/CreateProject";
import { ProjectsLeftNav } from "@src/interfaces/projects/components/ProjectsLeftNav";

export const AppLeftNavPanel = () => {
    const { FF_LEFT_NAV_HOME } = useFeatureFlagContext();

    const { t } = useTranslation(["common"]);

    const { isChromeless } = useIsChromeless();

    return (
        <View
            isHidden={isChromeless}
            backgroundColor="gray-100"
            width="size-3600"
            height="100%"
            flex="none"
            paddingX="size-100"
            paddingY="size-200"
            borderEndColor="gray-50"
            borderEndWidth="thick">
            <Flex
                direction="column"
                height="100%"
                rowGap="size-200"
                alignItems="start">
                {FF_LEFT_NAV_HOME && (
                    <>
                        <Flex
                            direction="row"
                            marginStart="size-100"
                            columnGap="size-100"
                            justifyContent="center">
                            <Home size="S" />
                            <Text>{t("common:leftNav.home")}</Text>
                        </Flex>
                        <Divider size="M" />
                    </>
                )}
                <Flex direction="column" width="100%" height="100%">
                    <AssetLeftNavPanel />
                    <Divider size="M" marginY="size-200" />
                    <Flex>
                        <CreateProject />
                    </Flex>
                    <ProjectsLeftNav />
                </Flex>
            </Flex>
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { EventEmitter } from "events";

import { UploadStatusEvent } from "./Upload";

import type { Upload } from "./Upload";
import type { HeliosLogger } from "../services/HeliosLogger";

export interface UploadEmitEventData {
    uploadId: string;
    libraryAssetId: string;
    fileName: string;
    error: { message: string };
    progress: number;
    [key: string]: any;
}
export class UploadManager extends EventEmitter {
    uploads: Record<string, Upload> = {};
    get hasUploads() {
        return Object.keys(this.uploads).length > 0;
    }
    private _logger?: HeliosLogger;

    set logger(newLogger: HeliosLogger) {
        this._logger = newLogger;
    }

    addUpload(upload: Upload) {
        this.uploads[upload.id] = upload;
        this.handleEmittedEvents(upload);
        return upload.start();
    }


    cancelUploads(uploadIds: string[]) {
        uploadIds.forEach((uploadId) => {
            const upload = this.uploads[uploadId];
            if (!upload) {
                this._logger?.logError({
                    errorCode: "2050",
                    resources: [{ uploadId }],
                });
                return;
            }
            upload.cancel();
        });
    }

    deleteUpload(uploadId: string) {
        if (!this.uploads[uploadId]) {
            return;
        }
        this.uploads[uploadId].removeAllListeners();
        delete this.uploads[uploadId];
    }

    onError(data: UploadEmitEventData): void {
        this.deleteUpload(data.uploadId);
    }

    handleEmittedEvents(upload: Upload) {
        Object.keys(UploadStatusEvent).forEach((status) => {
            upload.on(status, (data) => {
                if (status === UploadStatusEvent.ERROR) {
                    this.onError(data);
                }
                this.emit(status, {
                    uploadId: upload.id,
                    libraryAssetId: upload.libraryAssetId,
                    fileName: upload.fileName,
                    ...data,
                } as UploadEmitEventData);
            });
        });
    }
}

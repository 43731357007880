/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";
import {
    assetTemplate,
    getAssetTemplateCameraData,
    getRenderSettings,
    WEBVIEWER_VARIANT,
} from "@shared/common";
import { useEffect, useMemo, useRef } from "react";

import { DetailViewContent } from "../common/DetailViewContent";
import { OrthoTypes, rpc } from "@src/contexts/RpcContext";

import { DetailViewType } from "../common/DetailViewTabs";
import type { ArtifactData } from "@shared/types";
import type { FC } from "react";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";

/**
 * Common setup for full and review access to DetailViewContent
 */
export interface AssetDetailContentProps {
    id: string;
    compositeId: string;
    viewType: DetailViewType;
    selectedPanelKey: string;
    commentId?: string;
}

export const AssetDetailContent: FC<AssetDetailContentProps> = ({
    id,
    compositeId,
    viewType,
    selectedPanelKey,
    commentId,
}) => {
    const artifactRefs = OrthoTypes.map(() => {
        return useRef<HTMLDivElement>(null);
    });

    const { data: webModel } = rpc.libraryAssets.getModelForViewer.useQuery(id);

    const { data: arModel } = rpc.libraryAssets.getArModelInfo.useQuery(id);

    const { data: orthoRenders } =
        rpc.libraryAssets.getOrthoRenders.useQuery(id);

    const { logIngestEvent } = useAnalyticsContext();
  
    const models: ArtifactData[] = useMemo(() => {
        const modelArtifacts: ArtifactData[] = [];
        if (webModel) {
            const renderSettings = getRenderSettings(
                assetTemplate,
                WEBVIEWER_VARIANT,
            );

            const aspectRatio =
                getAssetTemplateCameraData(WEBVIEWER_VARIANT).aspectRatio;
            modelArtifacts.push({
                ...webModel,
                iblFilename: assetTemplate.iblFilename,
                aspectRatio,
                renderSettings,
            });
        }
        if (arModel) modelArtifacts.push(arModel);
        return modelArtifacts;
    }, [webModel, arModel]);

    const renders: ArtifactData[] = useMemo(() => {
        return orthoRenders ? OrthoTypes.map((type) => orthoRenders[type]) : [];
    }, [orthoRenders]);

    const { data: userProfile } =
        rpc.libraryAssets.getAssetWithOwner.useQuery(id);
    const { data: approved } = rpc.libraryAssets.isAssetApproved.useQuery(id);

    useEffect(() => {
        logIngestEvent("viewAssetDetailsSelect", {
            "event.workflow": "ASSETS",
            "event.subtype": viewType,
            "event.context_guid": id,
        });
    }, [viewType]);

    return (
        <View width="100%">
            {userProfile && (
                <DetailViewContent
                    id={id}
                    compositeId={compositeId}
                    ownerId={userProfile.ownerUser}
                    commentId={commentId}
                    models={models}
                    renders={renders}
                    selectedPanelKey={selectedPanelKey}
                    viewType={viewType as DetailViewType}
                    viewOrientation="vertical"
                    artifactRefs={artifactRefs}
                    enableLimitedZoom={false} // Zoom as much as needed to view asset
                    approved={approved}
                />
            )}
        </View>
    );
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import React, { useContext, useEffect } from "react";
import { View, Flex, ProgressCircle, Content, Text, Heading } from "@adobe/react-spectrum";
import { TaskList } from "../TaskList";
import { BackToProject } from "../BackToProject";
import { MemoizedTaskDetails } from "../TaskDetails";
import "./TaskManagement.css";
import { useActiveTask } from "../hooks/useActiveTask";
import { TaskManagementProps } from "./types";
import { refetchData, useFetchTaskById } from "../queries";
import { SharedContext } from "../context";

export const TaskManagement = (props: TaskManagementProps) => {
    const { tasks, possibleTaskStatuses, setFetchDataTrigger, imsToken, wfInstanceUrl } = props;

    const { isTaskActive, taskId, setTaskView } = useActiveTask(false);
    const [isRefetching, setIsRefetching] = React.useState(false);
    const [updateStatus, setUpdateStatus] = React.useState(false);

    const {
        data: taskDetails,
        status,
        refetch,
    } = useFetchTaskById({ imsToken, wfInstanceUrl }, taskId);

    useEffect(() => {
        if (taskId) {
            refetchData(refetch, setIsRefetching);
            setUpdateStatus(false);
        }
    }, [taskId, updateStatus]);

    function getTaskData() {
        return (
            <Content>
                {(status === "loading" || isRefetching) && (
                    <Flex justifyContent="center" width="100%" height="100%">
                        {
                            <ProgressCircle
                                aria-label="Loading…"
                                isIndeterminate={true}
                                alignSelf="center"
                            />
                        }
                    </Flex>
                )}
                {status === "success" && (
                    <>
                        <BackToProject
                            setTaskView={setTaskView}
                            setFetchDataTrigger={setFetchDataTrigger}
                        />
                        <MemoizedTaskDetails
                            task={taskDetails.task}
                            wfInstanceUrl={wfInstanceUrl}
                            statusData={possibleTaskStatuses}
                            imsToken={imsToken}
                            setUpdateStatus={setUpdateStatus}
                            setIsRefetching={setIsRefetching}
                            setTaskView={setTaskView}
                            isRefetching={isRefetching}
                        />
                    </>
                )}
            </Content>
        );
    }

    return (
        <View height="100%" UNSAFE_className="task-management-wrapper">
            {isTaskActive ? (
                getTaskData()
            ) : (
                <>
                    <Content UNSAFE_className="widget-section panel-top-action">
                        <Heading width="100%" level={4}>
                            My Work
                        </Heading>
                    </Content>
                    <TaskList tasks={tasks} setTaskView={setTaskView} />
                </>
            )}
        </View>
    );
};

export const MemoizedTaskManagement = React.memo(TaskManagement);

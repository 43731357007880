/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { makeObservable, observable } from "mobx";

import { ConnectedService } from "./ConnectedService";
import { createTrpcClient } from "../api/createTrpcClient";

import type { ImsUser } from "./ims";

export type TrpcConnection = ReturnType<typeof createTrpcClient>;

export class RpcConnection extends ConnectedService {
    private imsUser: ImsUser;
    private connection: TrpcConnection;
    private context = "RPC Connection";
    @observable online = false;

    wsLink: TrpcConnection["wsLink"];

    constructor(imsUser: ImsUser, context?: string) {
        super();
        makeObservable(this);
        this.imsUser = imsUser;
        this.context = context || this.context;
        this.connection = createTrpcClient(
            imsUser,
            () => this.connectionOpened(),
            (cause) => this.connectionClosed(cause),
        );

        this.wsLink = this.connection.wsLink;
        this.connect().catch((err) =>
            console.error(
                `${this.context}: Connection initialization error`,
                err,
            ),
        );
    }

    async initialize() {
        await this.imsUser.connect();
    }

    connectTime?: number;

    closeConnection() {
        this.online = false;
        this.connection.wsClient.close();
    }

    private connectionOpened() {
        console.log(`${this.context}: connected`);
        this.online = true;
        this.connectTime = Date.now();
    }

    private connectionClosed(cause?: { code?: number }) {
        console.log(
            `${this.context}: Connection open for: `,
            Date.now() - (this.connectTime || 0),
        );
        console.log(`${this.context}: Connection closed due to`, cause);
        this.online = false;
    }
}

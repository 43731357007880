/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Badge, Flex, ProgressCircle } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { CardErrorBadge } from "./CardErrorBadge";
import { useFeatureFlagContext } from "@src/contexts/FeatureFlagContext";

import type { SpectrumBadgeProps } from "@adobe/react-spectrum";
import type { JobErrors } from "@shared/types";
import type { FC } from "react";

interface Props {
    content?: string;
    variant?: SpectrumBadgeProps["variant"];
    errors?: JobErrors;
    showSpinner?: boolean;
}

export const CardBadge: FC<Props> = ({
    content,
    variant = "neutral",
    errors,
    showSpinner,
}) => {
    const { DFF_JOB_ERRORS_UI } = useFeatureFlagContext();
    const { t } = useTranslation("common");
    return (
        <>
            <Flex
                position="absolute"
                top={0}
                right={0}
                alignItems="center"
                justifyContent="end"
                gap="size-100"
                margin="size-150">
                {content && <Badge variant={variant}>{content}</Badge>}
                {DFF_JOB_ERRORS_UI && !!errors?.length && (
                    <CardErrorBadge errors={errors} />
                )}
                {showSpinner && (
                    <Badge variant="neutral">
                        <ProgressCircle
                            marginStart="size-100"
                            marginEnd="size-100"
                            marginTop="size-40"
                            marginBottom="size-40"
                            aria-label={t("common:progress.loading")}
                            isIndeterminate
                            size="S"
                        />
                    </Badge>
                )}
            </Flex>
        </>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { AlertDialog, DialogContainer, Text } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import type { FC } from "react";

interface Props {
    name: string;
    actionHandler: () => void;
    onDismiss: () => void;
}

export const DeleteConfirmDialog: FC<Props> = ({
    name,
    actionHandler,
    onDismiss,
}) => {
    const { t } = useTranslation(["common"]);
    return (
        <DialogContainer
            onDismiss={() => {
                onDismiss();
            }}>
            <AlertDialog
                title={t("dialogs.delete.title")}
                variant="destructive"
                primaryActionLabel={t("actions.delete.confirm")}
                onPrimaryAction={() => actionHandler()}
                cancelLabel={t("actions.delete.cancel")}>
                <Text>
                    {t("dialogs.delete.description", {
                        name,
                        interpolation: {
                            escapeValue: false,
                        },
                    })}
                </Text>
            </AlertDialog>
        </DialogContainer>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Flex,
    TextArea,
    TextField,
    Tooltip,
    TooltipTrigger,
    View,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import Checkmark from "@spectrum-icons/workflow/Checkmark";
import Delete from "@spectrum-icons/workflow/Delete";
import Move from "@spectrum-icons/workflow/Move";
import Target from "@spectrum-icons/workflow/Target";
import React, { useEffect, useState } from "react";

import { useDialogStyles } from "../hooks/useDialogStyles";
import { useHotSpots } from "../hooks/useHotSpots";

import type { SceneManager } from "../scene/SceneManager";
import type { HotSpot } from "@shared/types";

export interface HotSpotEditorProps {
    sceneManager?: SceneManager;
    overlayRef: React.RefObject<HTMLDivElement>;
    strings: {
        addHotSpot: string;
        moveHotSpot: string;
        deleteHotSpot: string;
        close: string;
        placeHolderTitle: string;
        placeHolderText: string;
        titleLabel: string;
        textLabel: string;
        toastHotSpotAddMessage: string;
        toastHotSpotMoveMessage: string;
        toastHotSpotAction: string;
    };
    initialHotSpots?: HotSpot[];
    onHotSpotsUpdate?: (hotSpots: HotSpot[]) => void;
}

export function HotSpotEditor({
    sceneManager,
    overlayRef,
    strings,
    initialHotSpots,
    onHotSpotsUpdate,
}: HotSpotEditorProps) {
    const [dialogEl, setDialogEl] = useState<HTMLDivElement | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [closeToast, setCloseToast] = useState<() => void>();
    const {
        activeHotSpot,
        clearActiveHotSpot,
        hotSpotToEdit,
        clearHotSpotToEdit,
        OverlayPortal,
        activePosition,
        addOrUpdateHotSpot,
        deleteHotSpot,
        updateHotSpotTitle,
        updateHotSpotText,
    } = useHotSpots(
        sceneManager,
        overlayRef,
        dialogEl,
        {
            offsetPadding: 10,
        },
        initialHotSpots,
        onHotSpotsUpdate,
    );

    useEffect(() => {
        if (hotSpotToEdit) {
            const close = ToastQueue.info(
                hotSpotToEdit.pin
                    ? strings.toastHotSpotMoveMessage
                    : strings.toastHotSpotAddMessage,
                {
                    actionLabel: strings.toastHotSpotAction,
                    onAction: () => clearHotSpotToEdit(),
                    shouldCloseOnAction: true,
                    onClose: () => setCloseToast(undefined),
                },
            );
            setCloseToast(() => close);
        } else {
            setCloseToast((close) => {
                if (close) {
                    close();
                }
                return undefined;
            });
        }
    }, [hotSpotToEdit]);

    const dialogStyles = useDialogStyles(activePosition);

    return (
        <div>
            <TooltipTrigger placement="left">
                <Button
                    variant={hotSpotToEdit ? "primary" : "secondary"}
                    onPress={() => {
                        if (hotSpotToEdit) {
                            clearHotSpotToEdit();
                        } else {
                            addOrUpdateHotSpot();
                        }
                    }}>
                    <Target />
                </Button>
                <Tooltip>{strings.addHotSpot}</Tooltip>
            </TooltipTrigger>
            {OverlayPortal && (
                <OverlayPortal>
                    {activeHotSpot && (
                        <div style={dialogStyles} ref={(el) => setDialogEl(el)}>
                            <View
                                key={activeHotSpot.id}
                                backgroundColor="gray-100"
                                borderRadius="medium"
                                padding="size-150"
                                UNSAFE_style={{ pointerEvents: "auto" }}>
                                <Flex direction="column" gap="size-150">
                                    <TextField
                                        width="size-3600"
                                        label={strings.titleLabel}
                                        defaultValue={activeHotSpot.title}
                                        aria-label={strings.placeHolderTitle}
                                        maxLength={50}
                                        autoFocus
                                        onChange={(title: string) =>
                                            updateHotSpotTitle(
                                                activeHotSpot.id,
                                                title,
                                            )
                                        }
                                    />
                                    <TextArea
                                        width="size-3600"
                                        label={strings.textLabel}
                                        defaultValue={activeHotSpot.text}
                                        aria-label={strings.placeHolderText}
                                        maxLength={120}
                                        autoFocus
                                        onChange={(text: string) =>
                                            updateHotSpotText(
                                                activeHotSpot.id,
                                                text,
                                            )
                                        }
                                    />
                                </Flex>
                                <Flex
                                    marginTop="size-150"
                                    justifyContent="space-between">
                                    <TooltipTrigger>
                                        <Button
                                            variant="primary"
                                            onPress={() =>
                                                addOrUpdateHotSpot(
                                                    activeHotSpot,
                                                )
                                            }>
                                            <Move />
                                        </Button>
                                        <Tooltip>{strings.moveHotSpot}</Tooltip>
                                    </TooltipTrigger>
                                    <ButtonGroup>
                                        <TooltipTrigger>
                                            <Button
                                                variant="primary"
                                                onPress={() => {
                                                    clearActiveHotSpot();
                                                    deleteHotSpot(
                                                        activeHotSpot.id,
                                                    );
                                                }}>
                                                <Delete />
                                            </Button>
                                            <Tooltip>
                                                {strings.deleteHotSpot}
                                            </Tooltip>
                                        </TooltipTrigger>

                                        <TooltipTrigger>
                                            <Button
                                                variant="primary"
                                                marginStart="size-150"
                                                onPress={() =>
                                                    clearActiveHotSpot()
                                                }>
                                                <Checkmark />
                                            </Button>
                                            <Tooltip>{strings.close}</Tooltip>
                                        </TooltipTrigger>
                                    </ButtonGroup>
                                </Flex>
                            </View>
                        </div>
                    )}
                </OverlayPortal>
            )}
        </div>
    );
}

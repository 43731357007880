/* eslint-disable no-undef */
export function loadOneTrust(env) {
    var hadPerformancePermissions = false;
    function hasPerformancePermissions() {
        const activeGroups = adobePrivacy.activeCookieGroups();

        // Check for group permissions
        // C0001 - core cookies, enabled by default, cannot disable
        // C0002 - performance <- This is the group we care about for satelite performance tracking
        // C0003 - extended functionality
        // C0004 - advertising
        return activeGroups.indexOf("C0002") !== -1;
    }

    function enablePerformance() {
        if (hasPerformancePermissions()) {
            console.debug("Performance permissions granted");
            hadPerformancePermissions = true;
            window._nrCookiesEnabled = true;
        } else {
            console.debug("Performance permissions blocked");
        }
    }

    function disablePerformance() {
        if (!hasPerformancePermissions()) {
            if (hadPerformancePermissions) {
                window.location.reload();
            }
        }
    }

    // Event for when all premissions are granted
    window.addEventListener("adobePrivacy:PrivacyConsent", function () {
        enablePerformance();
    });

    // Event for when a subset of permissions are granted
    window.addEventListener("adobePrivacy:PrivacyCustom", function () {
        enablePerformance();
    });

    // Event for when a subset of permissions are granted
    window.addEventListener("adobePrivacy:PrivacyReject", function () {
        disablePerformance();
    });

    switch (env) {
        case "local":
            import("./OneTrust.local.js");
            break;
        case "feature":
            import("./OneTrust.feature.js");
            break;
        case "dev":
            import("./OneTrust.dev.js");
            break;
        case "stage":
            import("./OneTrust.stage.js");
            break;
        case "prod":
            import("./OneTrust.prod.js");
            break;
    }
}

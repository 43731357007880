/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { DialogContainer, Flex, View } from "@adobe/react-spectrum";
import { observer } from "mobx-react";
import { createContext, useContext, useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { AssetListKey } from "./components/AssetsFilter";
import { useVersionUpgrade } from "../common/hooks/useVersionUpgrade";
import type { LibraryAssetCardItem } from "@src/contexts/RpcContext";
import { useUIStateContext } from "@src/contexts/UIStateContext";
import { AllAssetsWithFilter } from "@src/interfaces/library/components/AllAssetsWithFilter";
import { UploadDialog } from "@src/interfaces/library/components/UploadDialog";
import { UserLibraryHeader } from "@src/interfaces/library/components/UserLibraryHeader";
import { Routes } from "@src/routes";

import type { AssetOrderFields, SortOrder } from "@shared/types";

interface UserLibraryContextValue {
    searchValue: string;
    filterKey: AssetListKey;
    setAssetToUpgrade: (asset: LibraryAssetCardItem) => void;
    setSelectedAsset: (asset: {
        assetId: string | null;
        viewType: string | null;
    }) => void;
    sortField: AssetOrderFields;
    sortDirection: SortOrder;
}

export const UserLibraryContext = createContext<UserLibraryContextValue>(
    {} as UserLibraryContextValue,
);

export function useUserLibraryContext() {
    const context = useContext(UserLibraryContext);
    if (!context) {
        throw new Error(
            "useUserLibraryContext must be used within a UserLibraryContext.Provider",
        );
    }
    return context;
}

export const UserLibrary = observer(() => {
    const { assetFilterSelection } = useUIStateContext();
    const { searchValue = "" } = useParams();
    const [sortType] = useState<AssetOrderFields>("createdAt");
    const [sortDirection] = useState<SortOrder>("desc");

    const { assetToUpgrade, setAssetToUpgrade, handleUploadFile } =
        useVersionUpgrade();

    const navigate = useNavigate();
    const [selectedAsset, setSelectedAsset] = useState<{
        assetId: string | null;
        viewType: string | null;
    }>({
        assetId: null,
        viewType: null,
    });

    useEffect(() => {
        if (selectedAsset?.assetId) {
            navigate(
                generatePath(Routes.libraryDetailView.path, {
                    assetId: selectedAsset.assetId,
                    viewType: selectedAsset.viewType,
                }),
                {state: {from: searchValue ? "search" : "library"}}
            );
        }
    }, [selectedAsset]);

    return (
        <View
            width="100%"
            height="100%"
            backgroundColor="gray-75"
            overflow="hidden">
            <UserLibraryContext.Provider
                value={{
                    searchValue,
                    filterKey: assetFilterSelection,
                    sortDirection: sortDirection,
                    sortField: sortType,
                    setAssetToUpgrade: setAssetToUpgrade,
                    setSelectedAsset: setSelectedAsset,
                }}>
                <Flex height="100%" direction="column">
                    <Flex height="100%">
                        <Flex direction="column" flexGrow={1} height="100%">
                            <UserLibraryHeader />
                            <AllAssetsWithFilter />
                        </Flex>
                    </Flex>
                </Flex>

                {assetToUpgrade?.compositeId && (
                    <DialogContainer
                        onDismiss={() => setAssetToUpgrade(undefined)}>
                        <UploadDialog
                            multiSelect={false}
                            isRevision
                            close={() => setAssetToUpgrade(undefined)}
                            handleFiles={handleUploadFile}
                        />
                    </DialogContainer>
                )}
            </UserLibraryContext.Provider>
        </View>
    );
});

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Heading, Text } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { useFormatDate } from "@src/interfaces/common/hooks/useFormatDate";

import type { FC } from "react";

type TimestampType = "createdAt" | "uploadedAt";

type Props = {
    timestamp: string;
    timestampType: TimestampType;
};

export const TimestampSection: FC<Props> = ({ timestamp, timestampType }) => {
    const { t } = useTranslation(["common"]);
    const timestampString = useFormatDate(timestamp);

    return (
        <>
            <Heading level={4} marginTop="size-300">
                {timestampType === "createdAt" &&
                    t("detailView.detailsPanel.dateCreated")}
                {timestampType === "uploadedAt" &&
                    t("detailView.detailsPanel.dateUploaded")}
            </Heading>
            <Text>{timestampString}</Text>
        </>
    );
};

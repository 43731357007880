/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { PinEvents, PinManager } from "@3di/adobe-3d-viewer";
import { useCallback, useEffect, useState } from "react";

import { pinSpriteGenerator } from "../utils/pinSpriteGenerator";

import type { SceneManager } from "../scene/SceneManager";
import type { PinSpriteConfig } from "../utils/pinSpriteGenerator";
import type {
    PinEvent,
    SerializedPin,
    PinDisplayConfig,
} from "@3di/adobe-3d-viewer";

type SpriteUrls = Pick<
    PinDisplayConfig,
    "headOccludedUrl" | "headHoverUrl" | "headUrl" | "spriteSize"
>;

const SPRITE_CONFIG: PinSpriteConfig = {
    width: 96,
    height: 96,
    fillColor: "rgb(29,29,29)",
    strokeColor: "rgb(255,255,255)",
    opacity: 0.75,
    strokeWidth: 20,
};

let pinSpriteUrls: SpriteUrls;
async function getPinSprite() {
    if (pinSpriteUrls) {
        return pinSpriteUrls;
    }
    const [headUrl, headHoverUrl, headOccludedUrl] = (await Promise.all([
        pinSpriteGenerator({ ...SPRITE_CONFIG }),
        pinSpriteGenerator({
            ...SPRITE_CONFIG,
            fillColor: "rgba(31, 133, 255, 0.75)",
        }),
        pinSpriteGenerator({ ...SPRITE_CONFIG, opacity: 0.2 }),
    ])) as [string, string, string];
    pinSpriteUrls = {
        spriteSize: SPRITE_CONFIG.width,
        headUrl,
        headHoverUrl,
        headOccludedUrl,
    };
    return pinSpriteUrls;
}

export function use3dPins(pins: SerializedPin[], sceneManager?: SceneManager) {
    const [pinManager, setPinManager] = useState<PinManager>();

    useEffect(() => {
        if (sceneManager) {
            getPinSprite().then((sprite) => {
                const newPinManager = new PinManager(sceneManager.viewer, {
                    pinDisplayConfig: sprite,
                    modelBounds: sceneManager.modelBounds,
                });
                setPinManager(newPinManager);
            });
        }
        return () => {
            pinManager?.dispose();
        };
    }, [sceneManager]);

    useEffect(() => {
        if (pinManager) {
            pinManager.setPins(pins);
        }
    }, [pins, pinManager]);

    const addOrUpdatePin = (
        id: string,
        onComplete: (pin: SerializedPin) => void,
        onCancel: (createMode: boolean) => void,
    ) => {
        if (pinManager) {
            if (pins.find((pin) => pin.id === id)) {
                pinManager.updatePin(id, onComplete, onCancel);
            } else {
                pinManager.addPin(id, onComplete, onCancel);
            }
        }
    };

    const cancelAddOrEditPin = () => {
        if (pinManager) {
            pinManager.cancelAddOrUpdate();
        }
    };

    const onPinSelected = useCallback(
        (handler: (pinEvent: PinEvent) => void) => {
            if (pinManager) {
                pinManager.on(PinEvents.pinSelected, handler);
            }
        },
        [pinManager],
    );

    const onPinDeselected = useCallback(
        (handler: () => void) => {
            if (pinManager) {
                pinManager.on(PinEvents.pinDeselected, handler);
            }
        },
        [pinManager],
    );

    const onCameraMove = useCallback(
        (handler: () => void) => {
            if (pinManager) {
                pinManager.on(PinEvents.cameraMove, handler);
            }
        },
        [pinManager],
    );

    const onPinHoverStart = useCallback(
        (handler: (pin: SerializedPin) => void) => {
            if (pinManager) {
                pinManager.on(PinEvents.pinHoverStart, handler);
            }
        },
        [pinManager],
    );

    const onPinHoverEnd = useCallback(
        (handler: (pin: SerializedPin) => void) => {
            if (pinManager) {
                pinManager.on(PinEvents.pinHoverEnd, handler);
            }
        },
        [pinManager],
    );

    const getPinBoundingBox = useCallback(
        (id: string) => {
            return pinManager?.getPinBoundingBox(id);
        },
        [pinManager],
    );
    const deselectPins = useCallback(() => {
        return pinManager?.deselectPins();
    }, [pinManager]);

    return {
        addOrUpdatePin,
        onPinSelected,
        onPinDeselected,
        onCameraMove,
        onPinHoverStart,
        onPinHoverEnd,
        deselectPins,
        getPinBoundingBox,
        cancelAddOrEditPin,
    };
}

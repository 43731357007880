/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useState } from "react";

import type { DOMRefValue, ValidationState } from "@react-types/shared";
import type { RefObject } from "react";

type FeedbackKeyType = {
    key: string;
    required?: boolean;
    type?: "bug" | "feature" | "both"; // "both" if not specified
    defaultValue?: string;
};

// If ux is not required, the fields are auto-populated
export const FeedbackUIKeys: FeedbackKeyType[] = [
    { key: "issueType", required: true }, // radio group, required, UX,
    { key: "summary", required: true }, // long text, required, UX
    {
        key: "details",
        required: true,
    }, // long text, required, bug only, UX
    { key: "notes" }, // long text, optional, everything else, UX
    { key: "attachments" }, // files, optional, UX
];

export type FeedbackFormData = {
    [key: string]: any;
};

export type FeedbackValidation = {
    [key: string]: ValidationState;
};

export const useFeedbackForm = (
    formRef: RefObject<DOMRefValue<HTMLFormElement>>,
) => {
    const [validState, setValidState] = useState<
        FeedbackValidation | undefined
    >();

    const formElement = () => {
        if (!formRef.current) {
            return;
        }
        return formRef.current.UNSAFE_getDOMNode();
    };
    const getFormElementValue = (name: string) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const namedElement = formElement()?.elements.namedItem(name) as any;
        return namedElement?.value;
    };

    const setFormElementValue = (name: string, value: string) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const namedElement = formElement()?.elements.namedItem(name) as any;
        namedElement.value = value;
    };

    const validateFormField = (name: string) => {
        const value = getFormElementValue(name);
        const found = FeedbackUIKeys.find((entry) => entry.key === name);
        if (
            found?.required &&
            (!value.length || value === found?.defaultValue)
        ) {
            setValidState({ ...validState, [name]: "invalid" });
        } else {
            setValidState({ ...validState, [name]: "valid" });
        }
    };

    return {
        getFormElement: formElement,
        getFormElementValue,
        setFormElementValue,
        validateFormField,
        validState,
        setValidState,
    };
};

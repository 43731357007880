/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Content,
    Dialog,
    DialogTrigger,
    Divider,
    Flex,
    Heading,
    ProgressCircle,
    Text,
    View,
} from "@adobe/react-spectrum";
import { camerasForAnalytics, templateForAnalytics } from "@shared/common";
import { toSpearCase } from "@shared/common/src/util/strings";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AssetInputDialogGrid } from "./AssetInputDialogGrid";
import { AssetInputDialogListGrid } from "./AssetInputDialogListGrid";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import type { LibraryAssetsFilters } from "@src/contexts/RpcContext";
import { rpc } from "@src/contexts/RpcContext";
import { SearchControl } from "@src/interfaces/common/components/SearchControl";
import { AssetListKey } from "@src/interfaces/library/components/AssetsFilter";
import type { CreateProjectSubtype } from "@src/lib/analytics/AnalyticsConstants";

import type { CardItem } from "./AssetInputDialogGrid";
import type { Selection, Key } from "@react-types/shared";
import type { TemplateData } from "@shared/types";
import type { FC, ReactElement } from "react";

export const AssetInputDialogState = {
    SelectAssets: 0,
    SelectTemplate: 1,
    SelectTemplateCameras: 2,
} as const;
export type AssetInputDialogState = keyof typeof AssetInputDialogState;
type AssetInputDialogStateValues =
    (typeof AssetInputDialogState)[AssetInputDialogState];
type AssetInputDialogStateSequence = Array<AssetInputDialogStateValues>;

const DialogStateAnalyticsMap: Record<
    AssetInputDialogStateValues,
    CreateProjectSubtype
> = {
    [AssetInputDialogState.SelectAssets]: "assets",
    [AssetInputDialogState.SelectTemplate]: "template",
    [AssetInputDialogState.SelectTemplateCameras]: "cameras",
} as const;

export type AssetInputDialogResult = {
    modelAssetIds: string[];
    selectedTemplateId: string;
    selectedTemplateCameras: string[];
    newProjectId?: string;
};

export type AssetInputDialogProps = {
    triggerButton: ReactElement;
    templates?: TemplateData[];
    onSubmit: (inputs: AssetInputDialogResult) => void;
    dialogStateSequence: AssetInputDialogStateSequence;
    createProject?: () => Promise<{ id: string }>;
    existingAssets?: Set<Key>;
    dataUiaPrefix: string;
};

export type ReactSpectrumMapKey = {
    label: string;
};

type CloseFn = () => void;

const AllCategoryKey = "All";
const PAGE_SIZE = 20;

export const AssetInputDialog: FC<AssetInputDialogProps> = ({
    triggerButton,
    templates = [],
    onSubmit,
    dialogStateSequence,
    createProject,
    existingAssets,
    dataUiaPrefix,
}) => {
    const { logIngestEvent } = useAnalyticsContext();
    const { t } = useTranslation(["studio", "common"]);
    const { error } = useMessagingContext();
    const MODEL_KEYS: ReactSpectrumMapKey[] = [
        { label: t("dialogs.input.models.allAssets") },
        { label: t("dialogs.input.models.myAssets") },
    ];

    const [assetFilterSelection, setAssetFilterSelection] =
        useState<AssetListKey>("all");
    const [searchValue, setSearchValue] = useState("");
    const {
        data: approvedAssets,
        hasNextPage: hasMoreAssets,
        isFetching: isLoadingAssets,
        fetchNextPage: fetchMoreAssets,
    } = rpc.libraryAssets.getApprovedList.useInfiniteQuery(
        {
            count: PAGE_SIZE,
            filters:
                assetFilterSelection !== "all"
                    ? ([assetFilterSelection] as LibraryAssetsFilters)
                    : undefined,
            searchValue,
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
        },
    );
    const [approvedAssetsList, setApprovedAssetsList] = useState<CardItem[]>(
        [],
    );
    const [templatesList, setTemplatesList] = useState<CardItem[]>([]);
    const [camerasList, setCamerasList] = useState<CardItem[]>([]);

    const [dialogStateIndex, setDialogStateIndex] = useState<number>(0);

    const [selectedModelListKey, setSelectedModelListKey] = useState<Selection>(
        new Set([MODEL_KEYS[0].label]),
    );

    const [selectedModelIds, setSelectedModelIds] = useState<Selection>(
        new Set<Key>(),
    );

    const [selectedTemplateListKey, setSelectedTemplateListKey] =
        useState<Selection>(new Set<Key>());
    const [templateMap, setTemplateMap] = useState<
        Map<ReactSpectrumMapKey, TemplateData[]>
    >(new Map());
    const [selectedTemplateId, setSelectedTemplateId] = useState<Selection>(
        new Set<Key>(),
    );

    const [selectedTemplate, setSelectedTemplate] = useState<TemplateData>();
    const [selectedCameras, setSelectedCameras] = useState<Selection>(
        new Set<Key>(),
    );

    const [stateSelectionEmpty, setStateSelectionEmpty] =
        useState<boolean>(true);

    const [creatingProject, setCreatingProject] = useState<boolean>(false);

    // TODO: remove this as it shouldn't be necessary if this component isn't mounted until it's opened
    function clearDialogState() {
        const initialKey = [...templateMap.keys()][0];
        setSelectedTemplateListKey(new Set([initialKey?.label]));
        setSelectedTemplateId(new Set<Key>());
        setSelectedTemplate(undefined);
        setSelectedCameras(new Set<Key>());
        setDialogStateIndex(0);
        setSelectedModelListKey(new Set([MODEL_KEYS[0].label]));
        setSelectedModelIds(new Set<Key>());
        setCreatingProject(false);
        setSearchValue("");
    }

    async function onOpenChange(isOpen: boolean) {
        if (!isOpen) {
            clearDialogState();
        } else if (!createProject) {
            setSelectedModelIds(new Set<Key>(existingAssets));
        } else {
            logIngestEvent("createProjectStart");
        }
    }

    /**
     * Determines the models for the selected category and returns them in a format understood by the grid view
     *
     * @returns an array of models in the selected category
     */
    useEffect(() => {
        setApprovedAssetsList(
            approvedAssets?.pages
                .flatMap((page) => page.items)
                .map((asset) => {
                    return {
                        id: asset.id,
                        thumbnailUrl: asset.thumbnail?.url ?? "",
                        description: asset.name,
                    };
                }) ?? [],
        );
    }, [approvedAssets]);

    useEffect(() => {
        const selectedKey: string =
            selectedModelListKey && selectedModelListKey !== "all"
                ? ([...selectedModelListKey][0] as string)
                : "all";
        if (selectedKey === MODEL_KEYS[1].label) {
            setAssetFilterSelection("myAssets");
        } else {
            setAssetFilterSelection("all");
        }
    }, [selectedModelListKey]);

    /**
     * Finds the list of templates in the selected category and returns them in a format understood by the grid view
     *
     * @returns an array of templates in the current category
     */
    useEffect(() => {
        let foundItems: TemplateData[] = [];
        if (selectedTemplateListKey && selectedTemplateListKey !== "all") {
            const selectedKey: string = [
                ...selectedTemplateListKey,
            ][0] as string;
            const found = [...templateMap.entries()].find(
                (entry) => entry[0].label === selectedKey,
            );
            if (found) {
                foundItems = found[1];
            }
        }
        setTemplatesList(
            foundItems.map((item) => {
                return {
                    id: item.id,
                    thumbnailUrl: item.thumbnailUrl,
                    description: item.title,
                };
            }),
        );
    }, [selectedTemplateListKey, templateMap]);

    /**
     * Gets the cameras from the current template and returns them in a format understood by the grid view
     *
     * @returns an array of the cameras in the current template
     */
    useEffect(() => {
        let updatedCamerasList: CardItem[] = [];
        if (selectedTemplate) {
            updatedCamerasList = [...selectedTemplate.cameras.entries()]
                .reduce((acc, [camera, data]) => {
                    if (data.hideFromTemplateUI) {
                        return acc;
                    }
                    acc[data.displayOrder] = {
                        id: camera,
                        thumbnailUrl: data.thumbnailUrl,
                        description: data.displayName ?? "",
                    };
                    return acc;
                }, [] as CardItem[])
                .filter((item) => !!item);
        }
        setSelectedCameras(new Set());
        setCamerasList(updatedCamerasList);
    }, [selectedTemplate]);

    /**
     * Handler for the "next" button being clicked. Advances to the next screen if available,
     * otherwise reports selected values and closes the dialog.
     *
     * @param close - The close function provided by React Spectrum
     */
    const goForward = useCallback(
        async (close: CloseFn) => {
            if (dialogStateIndex < dialogStateSequence.length - 1) {
                logIngestEvent("createProjectContinue", {
                    "event.subtype":
                        DialogStateAnalyticsMap[
                            dialogStateSequence[dialogStateIndex]
                        ],
                });
                setDialogStateIndex(dialogStateIndex + 1);
            } else {
                let modelAssetIds: string[] = [];
                const selectedTemplateId = selectedTemplate?.id ?? "";
                let selectedTemplateCameras: string[] = [];
                if (selectedModelIds === "all") {
                    modelAssetIds = approvedAssetsList.map((asset) => asset.id);
                } else {
                    modelAssetIds = [...selectedModelIds] as string[];
                }
                if (selectedTemplate && selectedCameras === "all") {
                    selectedTemplateCameras = [
                        ...selectedTemplate.cameras.keys(),
                    ];
                } else {
                    selectedTemplateCameras = [...selectedCameras] as string[];
                }

                let newProjectId: string | undefined;
                if (createProject) {
                    setCreatingProject(true);
                    try {
                        if (!selectedTemplate) {
                            throw new Error(
                                "Cannot create a project without a template.",
                            );
                        }
                        newProjectId = (await createProject()).id;
                        if (!newProjectId) {
                            throw new Error(
                                "Create project failed to yield an id.",
                            );
                        }
                        logIngestEvent("createProjectSuccess", {
                            "event.context_guid": newProjectId,
                            "event.subtype":
                                DialogStateAnalyticsMap[
                                    dialogStateSequence[dialogStateIndex]
                                ],
                            "3di.dimensions": {
                                cameras: camerasForAnalytics(
                                    selectedTemplateCameras,
                                ),
                                // Even though we only have a single template
                                // per project, we need to escape the commas in
                                // case a project has multiple templates in the
                                // future.
                                "template-names":
                                    templateForAnalytics(selectedTemplate),
                            },
                            "3di.measures": {
                                num_assets: modelAssetIds.length,
                            },
                        });
                    } catch {
                        close();
                        error(t("error.creatingNewProject"));
                        return;
                    }
                }

                close();
                onSubmit({
                    modelAssetIds: modelAssetIds.filter(
                        (id) => !existingAssets?.has(id),
                    ),
                    selectedTemplateId,
                    selectedTemplateCameras,
                    newProjectId,
                });
            }
        },
        [
            selectedModelListKey,
            dialogStateIndex,
            dialogStateSequence,
            selectedModelIds,
            selectedTemplate?.id,
            selectedCameras,
        ],
    );

    /**
     * Handler for the "previous" button being clicked. Returns to the previous screen if available,
     * otherwise closes the dialog
     *
     * @param close - the close function provided by React Spectrum
     */
    const goBack = useCallback(
        (close: CloseFn) => {
            logIngestEvent("createProjectCancel", {
                "event.subtype":
                    DialogStateAnalyticsMap[
                        dialogStateSequence[dialogStateIndex]
                    ],
            });
            if (dialogStateIndex <= 0) {
                close();
            } else {
                setDialogStateIndex(dialogStateIndex - 1);
            }
        },
        [dialogStateIndex, dialogStateSequence],
    );

    // Reset dialog state when the sequence changes
    useEffect(() => {
        setDialogStateIndex(0);
    }, [dialogStateSequence.toString()]);

    /**
     * Processes the raw list of templates and sorts them into categories
     */
    useEffect(() => {
        if (!templates) return;
        const newMap = new Map<ReactSpectrumMapKey, TemplateData[]>();
        const categoryToKeyMap = new Map<string, ReactSpectrumMapKey>();
        // Add a "All" category
        const allKey = { label: t("studio:dialogs.input.templates.all") };
        categoryToKeyMap.set(AllCategoryKey, allKey);
        const allKeyArray: TemplateData[] = [];
        newMap.set(allKey, allKeyArray);
        templates.forEach((template) => {
            allKeyArray.push(template);
            template.categories.forEach((category) => {
                let key = categoryToKeyMap.get(category);
                if (!key) {
                    key = { label: t(`project.tags.${toSpearCase(category)}`) };
                    categoryToKeyMap.set(category, key);
                    newMap.set(key, []);
                }
                // Technically newMap.get(key) should never return null.
                // This extra code is to avoid using "?" for "Object is possibly 'undefined'" error,
                // or "!" which violates eslint rule of no null-assertion.
                const arrayAtKey = newMap.get(key);
                if (arrayAtKey) {
                    arrayAtKey.push(template);
                } else {
                    throw new Error(`Unexpected null array at key: ${key}`);
                }
            });
        });
        setTemplateMap(newMap);
        const initialKey = [...newMap.keys()][0];
        setSelectedTemplateListKey(new Set([initialKey?.label]));
    }, [templates.toString()]);

    /**
     * Updates the selectedTemplate when the selectedTemplateId changes
     */
    useEffect(() => {
        if (selectedTemplateId === "all") {
            setSelectedTemplate(templates[0]);
        } else {
            const template = templates.find(
                (aTemplate) =>
                    aTemplate.id.toString() ===
                    [...selectedTemplateId][0]?.toString(),
            );
            setSelectedTemplate(template);
        }
    }, [selectedTemplateId]);

    /**
     * @returns the correct title for the current dialog state
     */
    // TODO remove all of this and move to route based state
    const getTranslatedTitle = useCallback(() => {
        let key = "";
        switch (dialogStateSequence[dialogStateIndex]) {
            case AssetInputDialogState.SelectAssets:
                key = "dialogs.input.assets.title";
                break;
            case AssetInputDialogState.SelectTemplate:
                key = "dialogs.input.templates.title";
                break;
            case AssetInputDialogState.SelectTemplateCameras:
                key = "dialogs.input.templateCameras.title";
                break;
        }
        return key ? t(key) : "";
    }, [dialogStateIndex, dialogStateSequence]);

    // TODO remove all of this and move to route based state
    useEffect(() => {
        const hasSelection = (sel: Selection) => {
            return sel === "all" || sel.size > 0;
        };
        switch (dialogStateSequence[dialogStateIndex]) {
            case AssetInputDialogState.SelectTemplate:
                setStateSelectionEmpty(!hasSelection(selectedTemplateId));
                return;
            case AssetInputDialogState.SelectTemplateCameras:
                setStateSelectionEmpty(!hasSelection(selectedCameras));
                return;
            case AssetInputDialogState.SelectAssets:
                setStateSelectionEmpty(!hasSelection(selectedModelIds));
                return;
        }
    }, [
        dialogStateIndex,
        selectedModelIds,
        selectedTemplateId,
        selectedCameras,
    ]);

    const computeSelectedModels = (models: Selection) => {
        const modelList: Set<Key> =
            models === "all"
                ? new Set<Key>(approvedAssetsList.map(({ id }) => id))
                : models;
        return existingAssets
            ? new Set<Key>([...modelList, ...existingAssets])
            : modelList;
    };

    // TODO: Move these to their own components
    const getContent = () => {
        switch (dialogStateSequence[dialogStateIndex]) {
            case AssetInputDialogState.SelectAssets:
                return (
                    selectedModelListKey &&
                    selectedModelIds && (
                        <AssetInputDialogListGrid
                            key="SelectAssets"
                            dataUiaGrid={`${dataUiaPrefix}assets-grid`}
                            listItems={MODEL_KEYS}
                            selectedListKeys={selectedModelListKey}
                            setSelectedListKeys={setSelectedModelListKey}
                            gridItems={approvedAssetsList}
                            selectedGridKeys={selectedModelIds}
                            setSelectedGridKeys={(models) =>
                                setSelectedModelIds(
                                    computeSelectedModels(models),
                                )
                            }
                            gridSelectionMode="multiple"
                            isDisabled={creatingProject}
                            isAsset={true}
                            onLoadMore={() => {
                                if (hasMoreAssets && !isLoadingAssets) {
                                    fetchMoreAssets();
                                }
                            }}>
                            {dialogStateIndex === 0 && (
                                <View width="100%" marginBottom="size-250">
                                    <SearchControl
                                        width="100%"
                                        placeholder={t(
                                            "common:actions.searchApprovedAssets",
                                        )}
                                        initialValue={searchValue}
                                        submitOnChange
                                        onSubmit={setSearchValue}
                                    />
                                </View>
                            )}
                        </AssetInputDialogListGrid>
                    )
                );
            case AssetInputDialogState.SelectTemplate:
                return (
                    templateMap &&
                    selectedTemplateListKey &&
                    selectedTemplateId && (
                        <AssetInputDialogListGrid
                            key="SelectTemplate"
                            dataUiaGrid={`${dataUiaPrefix}template-grid`}
                            listItems={[...templateMap.keys()]}
                            selectedListKeys={selectedTemplateListKey}
                            setSelectedListKeys={(key) =>
                                setSelectedTemplateListKey(key)
                            }
                            gridItems={templatesList}
                            selectedGridKeys={selectedTemplateId}
                            setSelectedGridKeys={(key) =>
                                setSelectedTemplateId(key)
                            }
                            gridSelectionMode="single"
                            isDisabled={creatingProject}
                            isAsset={false}
                        />
                    )
                );
            case AssetInputDialogState.SelectTemplateCameras:
                return (
                    selectedTemplate && (
                        <Flex key="SelectTemplateCameras" height="100%">
                            <Flex
                                width="30%"
                                direction={"column"}
                                gap="size-300">
                                {/* left pane */}
                                <Heading level={3}>
                                    {selectedTemplate.title}
                                </Heading>
                                <Text>{selectedTemplate.description}</Text>
                            </Flex>
                            <Flex width="70%" height="100%">
                                {/* Camera view grid */}
                                <AssetInputDialogGrid
                                    data-uia={`${dataUiaPrefix}cameras-grid`}
                                    items={camerasList}
                                    selectedKeys={selectedCameras}
                                    setSelectedKeys={(selected) =>
                                        setSelectedCameras(selected)
                                    }
                                    selectionMode="multiple"
                                    isDisabled={creatingProject}
                                    isAsset={false}
                                />
                            </Flex>
                        </Flex>
                    )
                );
        }
    };

    return (
        <DialogTrigger
            type="modal"
            onOpenChange={onOpenChange}
            isKeyboardDismissDisabled>
            {triggerButton}
            {(close) => (
                <Dialog width="80vw" height="80vh">
                    {/* TODO: Move all the stateful code to it's own components so it only exists when mounted */}
                    <Heading>
                        <Flex justifyContent="space-between">
                            <Text>{getTranslatedTitle()}</Text>
                        </Flex>
                    </Heading>
                    <Divider />
                    <Content>{getContent()}</Content>
                    <ButtonGroup>
                        <Button
                            data-uia={`${dataUiaPrefix}back-btn`}
                            variant="secondary"
                            onPress={() => goBack(close)}
                            isDisabled={creatingProject}>
                            {t("actions.back")}
                        </Button>
                        <Button
                            data-uia={`${dataUiaPrefix}next-btn`}
                            variant="cta"
                            onPress={() => goForward(close)}
                            isDisabled={stateSelectionEmpty || creatingProject}>
                            {creatingProject ? (
                                <ProgressCircle
                                    aria-label={t(
                                        "dialogs.input.spinner.label",
                                    )}
                                    isIndeterminate
                                    size="S"
                                />
                            ) : dialogStateIndex ===
                              dialogStateSequence.length - 1 ? (
                                t("actions.done")
                            ) : (
                                t("actions.next")
                            )}
                        </Button>
                    </ButtonGroup>
                </Dialog>
            )}
        </DialogTrigger>
    );
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { TaskStatus } from "../../../types/TaskStatus";
import { WorkfrontParams } from "../../../types/wfParams";
import { fetchWorkfrontTaskPossibleStatuses } from "../requests/fetchTaskPossibleStatuses";
import { AbstractStatusResponse } from "../requests/types/AbstractStatusResponse";

export const fetchPossibleTaskStatuses = async (
    wfParams: WorkfrontParams,
): Promise<TaskStatus[]> => {
    const possibleTaskStatuses: AbstractStatusResponse[] =
        await fetchWorkfrontTaskPossibleStatuses(wfParams);

    return possibleTaskStatuses.map((ts: AbstractStatusResponse) => ({
        label: ts.label,
        color: ts.color,
        value: ts.value,
    }));
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Badge, Flex } from "@adobe/react-spectrum";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFeatureFlagContext } from "@src/contexts/FeatureFlagContext";
import { rpc } from "@src/contexts/RpcContext";
import { CardErrorBadge } from "@src/interfaces/common/components/CardErrorBadge";

import type { SpectrumBadgeProps } from "@adobe/react-spectrum";
import type { JobErrors } from "@shared/types";

interface Props {
    shotId: string;
    errors?: JobErrors;
}

enum BadgeColors {
    "draft" = "indigo",
    "medium" = "purple",
    "high" = "fuchsia",
}

export const ShotBadges = ({ shotId, errors }: Props) => {
    const { DFF_JOB_ERRORS_UI } = useFeatureFlagContext();
    const { data: shot } = rpc.shots.getShot.useQuery(shotId);
    const { t } = useTranslation(["studio"]);

    const [qualityText, setQualityText] = useState<string | undefined>();
    const [qualityVariant, setQualityVariant] =
        useState<SpectrumBadgeProps["variant"]>("neutral");

    useEffect(() => {
        if (shot?.type === "SHOT_2D") {
            if (shot?.hasNewPreview || shot?.isPreview) {
                setQualityText(t("studio:card.labels.preview"));
                setQualityVariant("neutral");
            } else if (shot?.quality) {
                setQualityText(t(`studio:card.labels.${shot.quality}`));
                setQualityVariant(BadgeColors[shot.quality]);
            } else {
                setQualityText(undefined);
                setQualityVariant("neutral");
            }
        } else {
            setQualityText(undefined);
            setQualityVariant("neutral");
        }
    }, [shot?.hasNewPreview, shot?.isPreview, shot?.type, shot?.quality]);

    return (
        <Flex columnGap="size-100" alignItems="center">
            {!!shot?.overrides && (
                <Badge variant="yellow">
                    {t("studio:card.labels.modified")}
                </Badge>
            )}
            {qualityText && (
                <Badge variant={qualityVariant}>{qualityText}</Badge>
            )}
            {shot?.type === "SHOT_2D" &&
                shot?.scale &&
                shot.scale !== "full" && (
                    <Badge variant="neutral">
                        {t(`studio:card.labels.${shot.scale}`.toString())}
                    </Badge>
                )}
            {DFF_JOB_ERRORS_UI && <CardErrorBadge errors={errors} />}
        </Flex>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

import type { ShareSheetAssetData } from "@src/types/ccac/CCACData";

/**
 * Hook for setting share-for-review for the given asset
 * @param assetId - The model asset id or the project asset id for the share sheet component.
 * @param assetName - Name of the asset
 * @returns { shareAssetData: ShareSheetAssetData }
 */
export const useShareSheet = (assetId: string | null, assetName: string) => {
    const [shareAssetData, setShareAssetData] = useState<ShareSheetAssetData>();

    useEffect(() => {
        if (assetId && shareAssetData?.id !== assetId) {
            const assetData: ShareSheetAssetData = {
                id: assetId as string,
                name: assetName,
                // resourceId is the shared resource's storage url.
                // resourceViewUrl is the custom link that take the invitees to the asset.
                // This default to asset web view.
                // resourceViewUrl: `https://assets-stage.adobecc.com/files?dialog=notifications`,
                useProductViewUrl: true,
            };
            setShareAssetData(assetData);
        }
    }, [assetId]);

    return { shareAssetData };
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { createHeaders, getWorkfrontApiSearchUrl } from "../util";
import { CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX, WF_OBJECT_CODE_TASK } from "../constants";
import { SubTaskListResponse, SubTaskResponse } from "./types/SubTaskResponse";
import { WorkfrontParams } from "../../../types/wfParams";

const WF_TASK_FIELDS_TO_BE_LOADED =
    "status,name,ID,description,plannedStartDate,plannedCompletionDate," +
    `assignedTo:avatarDownloadURL,assignedTo:title,percentComplete,${CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX}`;

export const fetchWorkfrontSubTasksByParentTaskId = async (
    wfParams: WorkfrontParams,
    parentTaskId: string,
): Promise<SubTaskResponse[]> => {
    try {
        const WF_FETCH_SUBTASKS_URL = `${getWorkfrontApiSearchUrl({
            objCode: WF_OBJECT_CODE_TASK,
            wfInstanceUrl: wfParams.wfInstanceUrl,
        })}?parentID=${parentTaskId}&fields=${WF_TASK_FIELDS_TO_BE_LOADED}`;
        const headers = createHeaders(wfParams.imsToken);
        const res = await fetch(WF_FETCH_SUBTASKS_URL, {
            headers,
        });
        const taskListResponse: SubTaskListResponse = await res.json();
        return taskListResponse.data;
    } catch (e) {
        throw new Error("Was not able to fetch sub tasks");
    }
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type { FC } from "react";

export const FailedToLoadIcon: FC = () => {
    return (
        <svg
            width="93"
            height="75"
            viewBox="0 0 93 75"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.2818 66.4752C29.2044 66.4752 29.127 66.4666 29.0582 66.4409C28.6024 66.321 28.3272 65.8499 28.4562 65.3874L30.0386 59.6061C30.159 59.1521 30.6321 58.878 31.0879 58.9979C31.5437 59.1179 31.8189 59.5889 31.6985 60.0429C31.6985 60.0514 31.6985 60.0514 31.6985 60.06L30.116 65.8414C30.0128 66.2182 29.6688 66.4752 29.2818 66.4752ZM32.2231 55.7347C32.1457 55.7347 32.0683 55.7261 31.9995 55.7004C31.5437 55.5719 31.2685 55.1094 31.3975 54.6469L32.98 48.8655C33.109 48.4116 33.582 48.1461 34.0378 48.2746C34.4936 48.403 34.7602 48.8655 34.6398 49.3195L33.0574 55.1009C32.9542 55.4777 32.6101 55.7347 32.2231 55.7347Z"
                fill="#1373E6"
            />
            <path
                d="M49.6813 66.1136C49.2083 66.1136 48.8213 65.7367 48.8213 65.2571C48.8213 65.18 48.8299 65.0943 48.8557 65.0172L50.4898 59.2444C50.6188 58.7905 51.0918 58.525 51.5476 58.6534C52.0034 58.7819 52.27 59.253 52.141 59.7069L50.507 65.4797C50.4038 65.8566 50.0683 66.1136 49.6813 66.1136ZM52.7172 55.3987C52.2442 55.3987 51.8572 55.0219 51.8572 54.5422C51.8572 54.4652 51.8658 54.3795 51.8916 54.3024L53.5257 48.5296C53.6547 48.0757 54.1277 47.8101 54.5835 47.9386C55.0393 48.0671 55.306 48.5382 55.1769 48.9921L53.5429 54.7649C53.4397 55.1418 53.1043 55.3987 52.7172 55.3987Z"
                fill="#1373E6"
            />
            <path
                d="M55.0236 70.6407C54.5506 70.6407 54.1636 70.2552 54.1636 69.7842C54.1636 69.7156 54.1722 69.6471 54.1894 69.5786L55.6514 63.763C55.7632 63.3005 56.2363 63.0264 56.6921 63.1377C57.1565 63.2491 57.4317 63.7201 57.3199 64.1741L55.8578 69.9897C55.7632 70.3751 55.4192 70.6407 55.0236 70.6407ZM57.7327 59.8402C57.2597 59.8402 56.8727 59.4548 56.8727 58.9837C56.8727 58.9152 56.8813 58.8467 56.8985 58.7781L58.3605 52.9625C58.4723 52.5 58.9454 52.2259 59.4012 52.3373C59.8656 52.4486 60.1408 52.9197 60.029 53.3736L58.567 59.1893C58.4723 59.5661 58.1283 59.8402 57.7327 59.8402Z"
                fill="#1373E6"
            />
            <path
                d="M43.1984 66.4831C42.7254 66.4831 42.3384 66.1062 42.3384 65.6266C42.3384 65.5495 42.347 65.4724 42.3642 65.4039L43.938 59.614C44.0584 59.16 44.5315 58.8859 44.9959 59.0059C45.4517 59.1258 45.7269 59.5968 45.6065 60.0594L44.0326 65.8493C43.9208 66.2261 43.5854 66.4831 43.1984 66.4831ZM46.1139 55.7426C46.0365 55.7426 45.9591 55.734 45.8903 55.7083C45.4345 55.5884 45.1593 55.1173 45.2883 54.6634L46.8622 48.8735C46.9912 48.4195 47.4556 48.1454 47.92 48.2739C48.3758 48.3938 48.6424 48.8649 48.522 49.3188L46.9482 55.1088C46.845 55.4771 46.5009 55.734 46.1139 55.7426Z"
                fill="#1373E6"
            />
            <path
                d="M33.9937 74.6777C33.9163 74.6777 33.8389 74.6691 33.7701 74.6434C33.3143 74.5235 33.0391 74.0525 33.1681 73.5899L34.7505 67.8086C34.8796 67.3546 35.3526 67.0891 35.8084 67.2176C36.2642 67.3461 36.5308 67.8086 36.4104 68.2625L34.828 74.0439C34.7161 74.4208 34.3807 74.6777 33.9937 74.6777ZM36.935 63.9372C36.8576 63.9372 36.7802 63.9286 36.7114 63.9029C36.2556 63.783 35.9804 63.3119 36.1094 62.8494L37.6919 57.0681C37.8123 56.6141 38.2853 56.34 38.7411 56.4599C39.1969 56.5799 39.4721 57.0509 39.3517 57.5049C39.3517 57.5134 39.3517 57.5134 39.3517 57.522L37.7693 63.3034C37.6575 63.6802 37.3221 63.9372 36.935 63.9372ZM39.8764 53.1967C39.4033 53.1967 39.0163 52.8113 39.0163 52.3402C39.0163 52.2631 39.0249 52.186 39.0507 52.1089L39.6613 49.8735C39.7904 49.4195 40.2634 49.1454 40.7192 49.2739C41.175 49.4024 41.4502 49.8735 41.3212 50.3274L40.7106 52.5629C40.5988 52.9312 40.2634 53.1967 39.8764 53.1967Z"
                fill="#1373E6"
            />
            <path
                d="M75.7863 58.5504H64.4424C63.7286 58.5504 63.1524 57.9765 63.1524 57.2656C63.1524 56.5547 63.7286 55.9809 64.4424 55.9809H75.7863C80.8261 55.9809 89.564 52.8375 90.3897 41.0521C90.9315 32.7012 84.5844 25.4894 76.2077 24.9242C75.7089 24.8899 75.1928 24.8813 74.6854 24.8985C74.2898 24.9156 73.9114 24.7529 73.6534 24.4445C73.3954 24.1447 73.2922 23.7422 73.3782 23.3568C73.5588 22.4831 73.6448 21.5924 73.6534 20.7016C73.6792 10.7405 65.6035 2.62946 55.5927 2.5695H55.4722C39.8196 2.5695 35.8032 16.8816 35.6398 17.4897C35.4592 18.1749 34.7454 18.5861 34.0574 18.3976C32.3459 17.9351 30.5914 17.7039 28.8198 17.7039C20.1506 17.7039 13.1069 24.0591 13.1069 31.8704C13.1155 33.1037 13.3219 34.32 13.7434 35.4848C13.9842 36.1529 13.6316 36.8895 12.9607 37.1293C12.7887 37.1892 12.6081 37.2149 12.4361 37.1978C12.3329 37.1892 12.2383 37.1807 12.1351 37.1635C12.0319 37.1464 11.9287 37.1378 11.8255 37.1378C6.54487 37.1378 2.5801 42.9535 2.5801 46.7221C2.5801 50.1567 4.72159 55.9723 12.7457 55.9723H24.8378C25.5516 55.9723 26.1279 56.5461 26.1279 57.257C26.1279 57.9679 25.5516 58.5418 24.8378 58.5418H12.7457C3.99056 58.5418 0 52.4178 0 46.7221C0 42.1141 4.27437 35.2535 10.8192 34.6112C10.63 33.7118 10.5268 32.7954 10.5268 31.8704C10.5268 22.6373 18.7316 15.1344 28.8198 15.1344C30.4022 15.1344 31.9761 15.2971 33.5156 15.6226C35.6656 9.31016 42.2879 0 55.4808 0H55.6099C67.0483 0.05139 76.2851 9.32729 76.2335 20.7273C76.2249 21.2669 76.1991 21.8151 76.1389 22.3547C76.2163 22.3547 76.2937 22.3632 76.3797 22.3718C86.1841 23.0313 93.6062 31.4678 92.9698 41.2319C92.5655 47.0733 90.2779 51.784 86.3647 54.8674C83.3374 57.2142 79.622 58.5161 75.7863 58.5504Z"
                fill="#B3B3B3"
            />
        </svg>
    );
};

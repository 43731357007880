/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { generatePath, useNavigate } from "react-router-dom";

import type { DetailViewType } from "@src/interfaces/detail/components/common/DetailViewTabs";
import { Routes } from "@src/routes";

import type { NavigateOptions } from "react-router-dom";

export interface RedirectOptions {
    pathOnly?: boolean;
    navigateOptions?: NavigateOptions;
    search?: Record<string, string | undefined | null>;
}

function getPathWithSearch(path: string, search?: RedirectOptions["search"]) {
    if (search) {
        const cleanSearch: Record<string, string> = {}
        for (const key in search) {
            if (search[key] !== undefined && search[key] !== null) {
                cleanSearch[key] = search[key] as string
            }
        }
        return `${path}?${new URLSearchParams(cleanSearch).toString()}`;
    }
    return path;
}

export function useRedirects() {
    const navigate = useNavigate();

    const goBackOr = (orCallback: () => void) => {
        if (window.history.state?.idx > 0) {
            navigate(-1);
        } else {
            orCallback();
        }
    };

    const projectRedirect = ({
        projectId,
        pathOnly,
        navigateOptions,
        search,
        limitedAccess,
    }: { projectId?: string; limitedAccess?: boolean } & RedirectOptions) => {
        const path = generatePath(
            limitedAccess
                ? Routes.limitedProjectReview.path
                : Routes.projects.path,
            {
                projectId,
            },
        );
        if (!pathOnly) {
            navigate(getPathWithSearch(path, search), navigateOptions);
        }
        return path;
    };

    const modelEditorRedirect = ({
        projectId,
        shotId,
        pathOnly,
        navigateOptions,
    }: { projectId: string; shotId: string } & RedirectOptions) => {
        const path = generatePath(Routes.modelEditor.path, {
            projectId,
            shotId,
        });
        if (!pathOnly) {
            navigate(path, navigateOptions);
        }
        return path;
    };

    const libraryDetailsRedirect = ({
        assetId,
        viewType,
        navigateOptions,
        search,
        pathOnly,
        limitedAccess,
    }: {
        assetId: string;
        viewType: `${DetailViewType}`;
        limitedAccess?: boolean;
    } & RedirectOptions) => {
        const route = limitedAccess
            ? Routes.limitedAssetReview.path
            : Routes.libraryDetailView.path;
        const path = generatePath(route, {
            assetId,
            viewType,
        });
        if (!pathOnly) {
            navigate(getPathWithSearch(path, search), navigateOptions);
        }
        return path;
    };

    const assetsLibraryRedirect = (options?: NavigateOptions) => {
        navigate(generatePath(Routes.library.path), options);
    };

    const projectOutputDetailsRedirect = ({
        projectId,
        shotId,
        navigateOptions,
        search,
        pathOnly,
        limitedAccess,
    }: {
        projectId: string;
        shotId: string;
        limitedAccess?: boolean;
    } & RedirectOptions) => {
        const route = limitedAccess
            ? Routes.limitedProjectDetailReview.path
            : Routes.projectOutputDetailView.path;
        const path = generatePath(route, {
            projectId,
            shotId,
        });
        if (!pathOnly) {
            navigate(getPathWithSearch(path, search), navigateOptions);
        }
        return path;
    };

    /**
     * Redirect to the previous version of an asset or fall back to the asset library if it doesn't exist,
     * for example after a deletion of a v1 asset.
     * @param viewType - Detail view type
     * @param previousId - The id of the asset to redirect to
     */
    const assetDetailsRedirectToPrevious = (
        viewType: DetailViewType,
        previousId: string | undefined,
    ) => {
        if (previousId) {
            libraryDetailsRedirect({
                assetId: previousId,
                viewType,
                navigateOptions: { replace: true },
            });
        } else {
            assetsLibraryRedirect({ replace: true });
        }
    };

    return {
        goBackOr,
        modelEditorRedirect,
        libraryDetailsRedirect,
        projectOutputDetailsRedirect,
        projectRedirect,
        assetsLibraryRedirect,
        assetDetailsRedirectToPrevious,
    };
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export type JobsApiConfig = {
    url: string
    key: string
}

export async function getCompletedJob(accessToken: string, job: object, apiConfig: JobsApiConfig): Promise<{state: string}> {
    const url = `${apiConfig.url}/jobs`;
    const headers = {
        "content-type": "application/json",
        authorization: "Bearer " + accessToken,
        "x-api-key": apiConfig.key,
    };

    const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(job), // body data type must match "Content-Type" header
    });

    const data = await response.json();
    if (data) {
        if (data.jobId) {
            return new Promise((res, rej) => {
                const statusUrl = `${url}/${data.jobId}`;

                const jobCheckInterval = setInterval(async function () {
                    const statusResponse = await fetch(statusUrl, {headers});
                    const statusData = await statusResponse.json();
                    if (statusData) {
                        if (statusData.status && statusData.status.state) {
                            console.log(
                                "Generating thumbnail: " + statusData.status.state,
                            );
                            if (
                                statusData.status.state === "COMPLETED" ||
                                statusData.status.state === "FAILED"
                            ) {
                                clearInterval(jobCheckInterval);
                                if (statusData.status.state === "COMPLETED") {
                                    return res(statusData.status);
                                } else {
                                    return rej(statusData.status);
                                }
                            }
                        } else {
                            // This shouldn't happen. No response from the server. But if it does happen, don't bother to keep asking.
                            clearInterval(jobCheckInterval);
                        }
                    }
                }, 3000);

            })
        }
    }
    return {state: "FAILED"};
}

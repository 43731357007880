/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { createHeaders, getWorkfrontApiObjectBaseUrl } from "../util";
import {
    CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX,
    PREVIEW_URL_CUSTOM_FIELD_NAME_WITH_PREFIX,
    PUBLISH_URL_CUSTOM_FIELD_NAME_WITH_PREFIX,
    WF_OBJECT_CODE_TASK,
} from "../constants";
import { TaskResponse, TaskSingleResponse } from "./types/TaskResponse";
import { WorkfrontParams } from "../../../types/wfParams";

const WF_TASK_FIELDS_TO_BE_LOADED = `status,name,ID,description,assignedTo:avatarDownloadURL,assignedTo:title,project:ID,plannedCompletionDate,${PUBLISH_URL_CUSTOM_FIELD_NAME_WITH_PREFIX},${PREVIEW_URL_CUSTOM_FIELD_NAME_WITH_PREFIX},${CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX}`;

export const fetchWorkfrontTaskById = async function (
    wfParams: WorkfrontParams,
    taskWfId: string,
): Promise<TaskResponse> {
    try {
        const headers = createHeaders(wfParams.imsToken);

        const WF_FETCH_TASK_BY_ID_URL = `${getWorkfrontApiObjectBaseUrl({
            objCode: WF_OBJECT_CODE_TASK,
            wfInstanceUrl: wfParams.wfInstanceUrl,
        })}/${taskWfId}?fields=${WF_TASK_FIELDS_TO_BE_LOADED}`;

        const res = await fetch(WF_FETCH_TASK_BY_ID_URL, {
            headers,
        });

        const apiResponse: TaskSingleResponse = await res.json();
        return apiResponse.data;
    } catch (e) {
        throw new Error("Was not able to fetch the task");
    }
};

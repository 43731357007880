/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Button,
    ButtonGroup,
    Content,
    Dialog,
    DialogTrigger,
    Divider,
    Flex,
    Heading,
    Item,
    Picker,
    Text,
} from "@adobe/react-spectrum";
import { getTemplate } from "@shared/common";
import Transparency from "@spectrum-icons/workflow/Transparency";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ProjectRendersAcknowledgment } from "./ProjectRendersAcknowledgment";
import type { Shot, ShotThumbnail } from "@src/contexts/RpcContext";
import { rpc } from "@src/contexts/RpcContext";

import type { HeliosApi } from "@components/hermes";
import type { TRPCClientError } from "@trpc/client";
import type { AnyRouter } from "@trpc/server";
import { useMessagingContext } from "@src/contexts/MessagingContext";

interface Props {
    projectId: string;
    canUserModify: boolean;
    selectedRenders: string[];
    setSelectedRenders: (renders: string[]) => void;
}

export const RenderTrigger = ({
    projectId,
    canUserModify,
    selectedRenders,
    setSelectedRenders,
}: Props) => {
    const { t } = useTranslation(["studio", "common"]);
    const { showToastForInvalidPermissions, error } = useMessagingContext();

    const { data: project } =
        rpc.projects.getProjectWithOwner.useQuery(projectId);

    const [showRendersPopup, setShowRendersPopup] = useState(false);
    const [renderQuality, setRenderQuality] =
        useState<HeliosApi.RenderQuality>("draft");
    const [renderScale, setRenderScale] =
        useState<HeliosApi.RenderScale>("full");

    const [resolution, setResolution] = useState<number[]>();

    useEffect(() => {
        if (project?.template) {
            const { template } = getTemplate(project.template);
            // In theory, each camera has its own resolution that is independent of all the other
            // cameras in the template. For alpha/beta however, the resolution is the same for all
            // cameras so just grab the first one and report its resolution.
            // Tracked by https://jira.corp.adobe.com/browse/SRV3D-6044
            const parsedResolution: number[] = Object.values(
                template.variants[0],
            )[0]?.render_product?.resolution;
            setResolution(parsedResolution);
        } else {
            setResolution(undefined);
        }
    }, [project?.template]);

    const rpcContext = rpc.useContext();
    const createRenderMutation = rpc.projects.createRender.useMutation({
        onMutate: async (shotRenderData) => {
            let previous: {
                shot: Shot | undefined;
                thumbnail: ShotThumbnail | undefined;
            } = { shot: undefined, thumbnail: undefined };
            const shotId = shotRenderData.shotId;

            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await rpcContext.shots.getShot.cancel(shotId);
            await rpcContext.shots.getShotThumbnailForCard.cancel(shotId);

            // Snapshot the previous values
            const previousShot = rpcContext.shots.getShot.getData(shotId);
            const previousThumbnail =
                rpcContext.shots.getShotThumbnailForCard.getData(shotId);
            previous = {
                shot: previousShot,
                thumbnail: previousThumbnail,
            };

            // Optimistically update to the new values
            rpcContext.shots.getShot.setData(
                shotId,
                (oldQueryData: Shot | undefined) => {
                    return oldQueryData
                        ? {
                              ...oldQueryData,
                              quality:
                                  shotRenderData.quality as HeliosApi.RenderQuality,
                              scale: shotRenderData.scale as HeliosApi.RenderScale,
                              isPreview: false,
                              hasNewPreview: false,
                              submittingJobs: true,
                              jobsSubmitted: false,
                              jobsCompleted: false,
                          }
                        : oldQueryData;
                },
            );
            rpcContext.shots.getShotThumbnailForCard.setData(shotId, () => {
                return {
                    jobCompleted: false,
                    jobFailed: false,
                    url: undefined,
                    isPreview: false,
                };
            });

            // Return a context object with the snapshotted value
            return { previous };
        },
        onError: (e, _shotRenderData, context) => {
            // Rollback to the previous values if mutation fails
            rpcContext.shots.getShot.setData(
                _shotRenderData.shotId,
                context?.previous.shot,
            );
            rpcContext.shots.getShotThumbnailForCard.setData(
                _shotRenderData.shotId,
                context?.previous.thumbnail,
            );
        },
        onSettled: (shotRenderData) => {
            void rpcContext.shots.getShot.invalidate(shotRenderData?.shotId);
            void rpcContext.shots.getShotThumbnailForCard.invalidate(
                shotRenderData?.shotId,
            );
        },
    });

    const { data: approved } =
        rpc.projects.isProjectApproved.useQuery(projectId);

    const handleRenderSubmit = (close: () => void) => {
        close();
        let invalidPermissionToastShown = false;
        selectedRenders.map((shotId) => {
            createRenderMutation
                .mutateAsync({
                    shotId,
                    quality: renderQuality,
                    scale: renderScale,
                })
                .catch((e) => {
                    if (
                        (e as TRPCClientError<AnyRouter>)?.data?.code ===
                        "FORBIDDEN"
                    ) {
                        if (!invalidPermissionToastShown) {
                            showToastForInvalidPermissions(true);
                            invalidPermissionToastShown = true;
                        }
                    } else {
                        error(t("common:error.render"));
                    }
                });
        });
        setShowRendersPopup(Boolean(selectedRenders.length));
        setSelectedRenders([]);
    };

    return (
        <>
            {showRendersPopup && (
                <ProjectRendersAcknowledgment
                    clearPopupState={() => {
                        setShowRendersPopup(false);
                    }}
                />
            )}
            <DialogTrigger>
                <ActionButton
                    isDisabled={approved || selectedRenders.length <= 0}
                    isHidden={!canUserModify}>
                    <Transparency />
                    <Text>{t("studio:actions.render")}</Text>
                </ActionButton>
                {(close) => (
                    <Dialog size="S">
                        <Heading>{t("studio:dialogs.output.render")}</Heading>
                        <Divider />
                        <ButtonGroup>
                            <Button variant="secondary" onPress={close}>
                                {t("common:actions.cancel")}
                            </Button>
                            <Button
                                variant="cta"
                                onPress={() => handleRenderSubmit(close)}>
                                {t("studio:actions.create")}
                            </Button>
                        </ButtonGroup>
                        <Content>
                            <Flex direction="column" gap="size-125">
                                <Text>{t("dialogs.output.render.text")}</Text>
                                <Picker
                                    label={t(
                                        "studio:input.renderQuality.label",
                                    )}
                                    aria-label={t(
                                        "studio:input.renderQuality.label",
                                    )}
                                    defaultSelectedKey={renderQuality}
                                    onSelectionChange={(key) =>
                                        setRenderQuality(
                                            key as HeliosApi.RenderQuality,
                                        )
                                    }
                                    width="100%">
                                    <Item key="draft">
                                        {t(
                                            "studio:input.renderQuality.option.draft",
                                        )}
                                    </Item>
                                    <Item key="medium">
                                        {t(
                                            "studio:input.renderQuality.option.medium",
                                        )}
                                    </Item>
                                    <Item key="high">
                                        {t(
                                            "studio:input.renderQuality.option.high",
                                        )}
                                    </Item>
                                </Picker>
                                <Picker
                                    label={t("studio:input.renderScale.label")}
                                    aria-label={t(
                                        "studio:input.renderScale.label",
                                    )}
                                    defaultSelectedKey={renderScale}
                                    onSelectionChange={(key) =>
                                        setRenderScale(
                                            key as HeliosApi.RenderScale,
                                        )
                                    }
                                    width="100%">
                                    <Item key="full">
                                        {t(
                                            "studio:input.renderScale.option.full",
                                            {
                                                width: resolution?.[0]
                                                    ? resolution[0]
                                                    : "",
                                                height: resolution?.[1]
                                                    ? resolution[1]
                                                    : "",
                                            },
                                        )}
                                    </Item>
                                    <Item key="half">
                                        {t(
                                            "studio:input.renderScale.option.half",
                                            {
                                                width: resolution?.[0]
                                                    ? Math.floor(
                                                          resolution[0] / 2,
                                                      )
                                                    : "",
                                                height: resolution?.[1]
                                                    ? Math.floor(
                                                          resolution[1] / 2,
                                                      )
                                                    : "",
                                            },
                                        )}
                                    </Item>
                                    <Item key="quarter">
                                        {t(
                                            "studio:input.renderScale.option.quarter",
                                            {
                                                width: resolution?.[0]
                                                    ? Math.floor(
                                                          resolution[0] / 4,
                                                      )
                                                    : "",
                                                height: resolution?.[1]
                                                    ? Math.floor(
                                                          resolution[1] / 4,
                                                      )
                                                    : "",
                                            },
                                        )}
                                    </Item>
                                </Picker>
                            </Flex>
                        </Content>
                    </Dialog>
                )}
            </DialogTrigger>
        </>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { HealthCheckStatus } from "@shared/types";
import AlertCircleFilled from "@spectrum-icons/workflow/AlertCircleFilled";
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";
import CloseCircle from "@spectrum-icons/workflow/CloseCircle";

import type { FC } from "react";

interface Props {
    status: HealthCheckStatus;
}

export const HealthCheckStatusIcon: FC<Props> = ({ status }) => {
    return (
        <>
            {status === HealthCheckStatus.pass && (
                <CheckmarkCircle color="positive" size="S" />
            )}
            {status === HealthCheckStatus.warn && (
                <AlertCircleFilled color="notice" size="S" />
            )}
            {status === HealthCheckStatus.fail && (
                <CloseCircle color="negative" size="S" />
            )}
        </>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { HealthCheckStatus } from "@shared/types";
import { useMemo } from "react";

import { LibraryAsset } from "@src/contexts/RpcContext";

export function useIsOptimized(asset: LibraryAsset) {
    const isOptimized = useMemo(() => {
        const sourceResults = asset.modelMetaData.source?.healthCheckResults;
        if (!asset.modelMetaData.source?.jobCompleted && !sourceResults) {
            return false;
        }
        // If for some reason the source job fails, we may not have the source results.
        // In that case, fall back to the optimized results, which aligns with
        // the models we use for web/ar.
        // Example bug: https://jira.corp.adobe.com/browse/SRV3D-6673
        if (!sourceResults) {
            return true;
        }
        return sourceResults.formatVersion === 1
            ? sourceResults.cumulativeStatus === HealthCheckStatus.fail
            : sourceResults.cumulativeStatus !== HealthCheckStatus.pass;
    }, [
        asset.modelMetaData.source?.healthCheckResults,
        asset.modelMetaData.source?.jobCompleted,
    ]);

    return {
        isOptimized
    }
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    DialogContainer,
    SpectrumDialogContainerProps,
} from "@adobe/react-spectrum";
import {
    SpectrumToastOptions,
    ToastContainer,
    ToastQueue,
} from "@react-spectrum/toast";
import {
    PropsWithChildren,
    ReactNode,
    createContext,
    useContext,
    useState,
} from "react";
import { FileRejection } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { useLocalStorage } from "@src/interfaces/common/hooks/useLocalStorage";

export interface ModalDef {
    node: ReactNode;
    containerProps: Partial<SpectrumDialogContainerProps>;
}

export interface MessagingAppContext {
    id: string;
    type: "project" | "asset" | "file";
}

export interface PersistentMessage {
    id: string;
    context: MessagingAppContext;
    message: string;
    type: "error" | "warning" | "info"
}

interface MessagingContextType {
    error: (text: string, options?: Partial<SpectrumToastOptions>, context?: MessagingAppContext) => void;
    info: (text: string, options?: Partial<SpectrumToastOptions>) => void;
    warn: (text: string, options?: Partial<SpectrumToastOptions>) => void;
    success: (text: string, options?: Partial<SpectrumToastOptions>) => void;
    showToastForAssetUsedInProjects: () => void;
    showToastForInvalidPermissions: (forProject?: boolean) => void;
    showToastForRejectedFiles: (rejectedFiles: FileRejection[]) => void;
    showModal: (
        node: ModalDef["node"],
        containerProps?: ModalDef["containerProps"],
    ) => void;
    closeModal: () => void;
    persistentMessages: PersistentMessage[];
    addPersistentMessage: (
        message: string,
        context: MessagingAppContext,
        type: PersistentMessage["type"]
    ) => void;
    removePersistentMessage: (id: string) => void;
}

const MessagingContext = createContext<MessagingContextType>(
    {} as MessagingContextType,
);

export function useMessagingContext() {
    const context = useContext(MessagingContext);
    if (!context.error) {
        throw new Error(
            "You must use messaging context within a <MessagingContextProvider>",
        );
    }
    return context;
}

const defaultToastOptions: Partial<SpectrumToastOptions> = {
    timeout: 8_000, // 8 seconds
};

function parseMessages(val: string | null): PersistentMessage[] {
    if (!val) {
        return [];
    }
    try {
        return JSON.parse(val);
    } catch (e) {
        return [];
    }
}

export function MessagingContextProvider({ children }: PropsWithChildren) {
    const { t } = useTranslation(["library"]);
    const [persistentMessages, setPersistentMessages] = useLocalStorage<
        PersistentMessage[]
    >("persistentMessages", [], parseMessages);

    const [modal, setModal] = useState<ModalDef>();

    const showModal = (
        node: ModalDef["node"],
        containerProps: ModalDef["containerProps"] = {},
    ) => {
        setModal({
            node,
            containerProps,
        });
    };

    const closeModal = () => {
        setModal(undefined);
    };

    const addPersistentMessage = (
        message: string,
        context: MessagingAppContext,
        type: PersistentMessage["type"]
    ) => {
        setPersistentMessages((currentPersistentMessages) => ([
            {
                id: window.crypto.randomUUID(),
                context,
                message,
                type,
            },
            ...currentPersistentMessages,
        ]));
    };

    // @ts-ignore
    window.addPersistentMessage = addPersistentMessage;

    const removePersistentMessage = (id: string) => {
        setPersistentMessages((currentPersistentMessages) => {
            return currentPersistentMessages.filter(
                (message) => message.id !== id,
            );
        });
    };

    const error = (
        text: string,
        options: Partial<SpectrumToastOptions> = {},
        context?: MessagingAppContext,
    ) => {
        ToastQueue.negative(text, { ...defaultToastOptions, ...options });
        if (context) {
            addPersistentMessage(text, context, "error");
        }
    };
    const info = (
        text: string,
        options: Partial<SpectrumToastOptions> = {},
    ) => {
        ToastQueue.info(text, { ...defaultToastOptions, ...options });
    };
    const warn = (
        text: string,
        options: Partial<SpectrumToastOptions> = {},
    ) => {
        ToastQueue.neutral(text, { ...defaultToastOptions, ...options });
    };
    const success = (
        text: string,
        options: Partial<SpectrumToastOptions> = {},
    ) => {
        ToastQueue.positive(text, { ...defaultToastOptions, ...options });
    };

    const showToastForAssetUsedInProjects = () => {
        ToastQueue.negative(t("notification.delete.assetUsedInProject"), {
            timeout: 10_000,
        });
    };

    const showToastForInvalidPermissions = (forProject?: boolean) => {
        if (forProject) {
            ToastQueue.neutral(
                forProject
                    ? t("notifications.invalidPermissions")
                    : t("notification.upload.invalidPermissions"),
                { timeout: 10_000 },
            );
        }
    };

    const showToastForRejectedFiles = (rejectedFiles: FileRejection[]) => {
        ToastQueue.negative(
            rejectedFiles.length === 1
                ? t("notification.upload.initialFailure")
                : t("notification.upload.initialFailures", {
                      count: rejectedFiles.length,
                  }),
            {
                // Eventually this should open the notifications pane
                // actionLabel: t("notification.upload.initialFailureAction"),
                // onAction: () => {
                //     TODO: show notifications panel
                // },
                // shouldCloseOnAction: true,
                timeout: 10_000,
            },
        );
    };

    return (
        <MessagingContext.Provider
            value={{
                error,
                info,
                warn,
                success,
                showToastForAssetUsedInProjects,
                showToastForInvalidPermissions,
                showToastForRejectedFiles,
                showModal,
                closeModal,
                persistentMessages,
                addPersistentMessage,
                removePersistentMessage,
            }}>
            {children}
            {modal && (
                <DialogContainer
                    {...modal.containerProps}
                    onDismiss={() => {
                        modal.containerProps.onDismiss &&
                            modal.containerProps.onDismiss();
                        closeModal();
                    }}>
                    {modal.node}
                </DialogContainer>
            )}
            <ToastContainer />
        </MessagingContext.Provider>
    );
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionButton, Flex } from "@adobe/react-spectrum";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import ChevronRight from "@spectrum-icons/workflow/ChevronRight";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

import { Artifact3DQRCode } from "./Artifact3DQRCode";
import { Artifact3DView } from "./Artifact3DView";
import { DetailViewType } from "./DetailViewTabs";
import { useDetailGridViewScroll } from "../../hooks/useDetailGridViewScroll";
import { DISPLAY_SIZE } from "../projects/DisplaySettingsPicker";
import { DelayedSpinner } from "@src/interfaces/common/components/DelayedSpinner";
import { ScrollingContainer } from "@src/interfaces/common/components/ScrollingContainer";
import { ArtifactViewOverlay } from "@src/interfaces/detail/components/common/Artifact2DView";

import type { DisplaySize } from "../projects/DisplaySettingsPicker";
import type { ArtifactData } from "@shared/types";

interface ArtifactGridProps {
    assetId: string;
    activeArtifacts: ArtifactData[];
    artifactIds: string[];
    viewOrientation: "horizontal" | "vertical";
    viewType: DetailViewType;
    showOverlay: boolean;
    refs: React.RefObject<HTMLDivElement>[];
    enableLimitedZoom: boolean;
    displaySize?: DisplaySize;
    updateActiveNode?: (node: string) => void;
    navigateHorizontal?: (direction: number) => void;
    onViewsLoaded: () => void;
}

/** type definition for the custom handles from ArtifactGridView */
export type ArtifactGridCustomHandlers = {
    scrollNodeToView: (index: number) => void;
};

// A small delay to wait for the scrolling to settle when scrolling
// a 2D artifact into view on a comment selection.
// An alternate solution would be to listen for scrollend event but apparently not
// all browsers support this event, specifically Safari on mac and ios.
const SCROLL_TIMEOUT_DELAY = 50;
const IdGridContainer = "IdArtifactGridContainer";

const findScrollContainer = (element: HTMLElement) => {
    let parent: HTMLElement | null | undefined = element;
    while (parent?.id !== IdGridContainer) {
        parent = parent?.parentElement;
    }
    return parent;
};

export const ArtifactGridView = forwardRef<
    ArtifactGridCustomHandlers,
    ArtifactGridProps
>(function ArtifactGridView(
    {
        activeArtifacts,
        artifactIds,
        assetId,
        showOverlay,
        viewOrientation,
        viewType,
        refs,
        enableLimitedZoom,
        displaySize,
        updateActiveNode,
        navigateHorizontal,
        onViewsLoaded,
    }: ArtifactGridProps,

    forwardedRef: any,
) {
    if (
        viewType !== DetailViewType.views2D &&
        viewType !== DetailViewType.views3D &&
        viewType !== DetailViewType.viewsAR
    ) {
        throw new Error(`Unexpected node array type, ${viewType}`);
    }

    const { scrollRef, shouldPauseScrolling } = useDetailGridViewScroll(
        refs,
        viewOrientation === "vertical",
        artifactIds,
        updateActiveNode,
    );

    const gridContainerRef = useRef<HTMLElement>();
    const scrollEndListenerRef = useRef<VoidFunction>();
    useImperativeHandle(
        forwardedRef,
        () => ({
            scrollNodeToView: (nodeIndex: number) => {
                const artifactRef = refs[nodeIndex];
                if (artifactRef.current) {
                    let scrollTimeout: NodeJS.Timeout | undefined;
                    shouldPauseScrolling.current = true; // prevent interfering with scrolling
                    const artifactElement = artifactRef.current as HTMLElement;

                    // Set a timer to ensure the scrolling settles before unpause the grid scrolling
                    // to avoid the two scrolling actions from interfering with each other.
                    if (!gridContainerRef.current) {
                        gridContainerRef.current =
                            findScrollContainer(artifactElement);
                    }
                    if (!scrollEndListenerRef.current) {
                        scrollEndListenerRef.current = () => {
                            if (scrollTimeout) {
                                clearTimeout(scrollTimeout);
                            }
                            scrollTimeout = setTimeout(() => {
                                shouldPauseScrolling.current = false;
                            }, SCROLL_TIMEOUT_DELAY) as unknown as NodeJS.Timeout;
                        };
                    }
                    gridContainerRef.current?.addEventListener(
                        "scroll",
                        scrollEndListenerRef.current,
                    );

                    artifactElement.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "center",
                    });
                }
            },
        }),
        [refs],
    );

    useEffect(() => {
        // Remove listener only if view tab is changed either by clicking on the
        // view tab or selecting a comment.
        return () => {
            if (gridContainerRef.current && scrollEndListenerRef.current) {
                gridContainerRef.current.removeEventListener(
                    "scroll",
                    scrollEndListenerRef.current,
                );
            }
            gridContainerRef.current = undefined;
            scrollEndListenerRef.current = undefined;
        };
    }, [viewType]);

    // Ensure all artifacts views are loaded.
    const artifactViewsLoaded = useRef<number>(0);
    const handleViewLoaded = () => {
        artifactViewsLoaded.current += 1;
        if (
            artifactViewsLoaded.current === activeArtifacts.length &&
            onViewsLoaded
        ) {
            onViewsLoaded();
        }
    };

    const renderArtifact = (
        viewType: DetailViewType,
        artifact: ArtifactData,
        index: number,
        displaySize?: DisplaySize,
    ) => {
        switch (viewType) {
            case DetailViewType.views2D:
                return (
                    <ArtifactViewOverlay
                        key={artifact.type}
                        artifact={artifact}
                        showOverlay={showOverlay}
                        displaySize={displaySize}
                        ref={refs[index]}
                        onViewLoaded={handleViewLoaded}
                    />
                );
            case DetailViewType.views3D:
                return (
                    <Artifact3DView
                        key={index}
                        artifact={artifact}
                        enableLimitedZoom={enableLimitedZoom}
                        ref={refs[index]}
                        onViewLoaded={handleViewLoaded}
                    />
                );
            case DetailViewType.viewsAR:
                return <Artifact3DQRCode key={index} assetId={assetId} />;
            default:
                throw new Error(`Expecting only 3D or AR view type!`);
        }
    };

    return (
        <Flex width="100%" height="100%" alignItems="center" UNSAFE_style={{overflow: "hidden"}}>
            {viewOrientation === "horizontal" && navigateHorizontal && (
                <ActionButton
                    zIndex={2}
                    aria-label="Navigate left"
                    isQuiet
                    onPress={() => {
                        navigateHorizontal(-1);
                    }}>
                    <ChevronLeft />
                </ActionButton>
            )}

            {activeArtifacts.length < 2 ? (
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "var(--spectrum-global-dimension-size-400)",
                    paddingBottom: "var(--spectrum-global-dimension-size-400)",
                }}>
                    {activeArtifacts.length ? (
                        renderArtifact(
                            viewType,
                            activeArtifacts[0],
                            0,
                            displaySize,
                        )
                    ) : (
                        <DelayedSpinner delayTime={250} size="L" />
                    )}
                </div>
            ) : (
                <ScrollingContainer
                    id={IdGridContainer}
                    forwardedRef={scrollRef}
                    overflowX={
                        displaySize === DISPLAY_SIZE.fullSize
                            ? "auto"
                            : undefined
                    }>
                    <Flex
                        direction="column"
                        alignItems="center"
                        margin="size-400"
                        gap="size-400">
                        {Object.values(activeArtifacts).map((artifact, index) =>
                            renderArtifact(viewType, artifact, index),
                        )}
                    </Flex>
                </ScrollingContainer>
            )}

            {viewOrientation === "horizontal" && navigateHorizontal && (
                <ActionButton
                    aria-label="Navigate right"
                    isQuiet
                    onPress={() => {
                        navigateHorizontal(1);
                    }}>
                    <ChevronRight />
                </ActionButton>
            )}
        </Flex>
    );
});

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useTranslation } from "react-i18next";

import { useMessagingContext } from "@src/contexts/MessagingContext";
import { rpc } from "@src/contexts/RpcContext";
import { RenameDialog } from "@src/interfaces/common/components/RenameDialog";

export function useRenameAssets(assetId: string, assetName: string) {
    const {t} = useTranslation(["library", "common"]);

    const { showModal } = useMessagingContext();

    const nameExistsMutation = rpc.libraryAssets.doesAssetNameExist.useMutation();
    const renameAssetMutation = rpc.libraryAssets.rename.useMutation();

    function rename() {
        showModal(
            <RenameDialog
                originalName={assetName}
                title={t("dialogs.rename.title")}
                onValidate={async (newName) => {
                    if (newName === assetName) {
                        return;
                    }
                    const nameExists = await nameExistsMutation.mutateAsync({
                        name: newName,
                    });
                    if (nameExists) {
                        return t("common:errors.strings.exists");
                    }
                    return;
                }}
                onRename={(newName) => {
                    renameAssetMutation.mutateAsync({
                        name: newName,
                        id: assetId,
                    });
                }}
            />,
        );
    }

    return {
        rename
    }
}

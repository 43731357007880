/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Link as LinkSpectrum } from "@adobe/react-spectrum";
import LinkOutLight from "@spectrum-icons/workflow/LinkOutLight";

import type { FC } from "react";

interface Props {
    dataUia: string;
    label: string;
    onPress: () => void;
}

export const HelpLinkItem: FC<Props> = ({ dataUia, label, onPress }) => {
    return (
        <Flex width="100%" justifyContent="space-between" alignItems="center">
            <LinkSpectrum
                data-uia={dataUia}
                variant="secondary"
                isQuiet
                onPress={onPress}
                aria-label={label}>
                {label}
            </LinkSpectrum>
            <LinkOutLight size="XS" />
        </Flex>
    );
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import React from "react";
import { Content, Flex, Text } from "@adobe/react-spectrum";
import { TaskProps } from "./types";
import { convertDateToFormat } from "../utils/convertDateToFormat";
import "./Task.css";

const dateNotSet = "N/A";

export const Task = (props: TaskProps) => {
    const { name, plannedCompletionDate, status, setTaskView, ID, projectName, parentId } = props;
    const completionDate = plannedCompletionDate
        ? convertDateToFormat(plannedCompletionDate)
        : dateNotSet;

    return (
        <section
            className="task-item"
            onClick={() => setTaskView(true, ID)}
            onKeyDown={() => setTaskView(true, ID)}
        >
            <Flex>
                <Content marginEnd="auto">
                    <Content>{projectName}</Content>
                    <Content UNSAFE_className="task-label">{parentId ? "Subtask" : "Task"}</Content>
                    <Content UNSAFE_className="task-name">{name}</Content>
                    <Flex>
                        <Content UNSAFE_className="due-date-label">Due date</Content>
                        <Content>{completionDate}</Content>
                    </Flex>
                    <Flex>
                        <Content
                            marginStart="0px"
                            marginTop="8px"
                            marginEnd="6px"
                            UNSAFE_className={"status-light--custom"}
                            UNSAFE_style={{ backgroundColor: `#${status.color}` }}
                        >
                            <></>
                        </Content>
                        <Content UNSAFE_className="status-option--italic">{status.label}</Content>
                    </Flex>
                </Content>
            </Flex>
        </section>
    );
};

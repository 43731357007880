/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Form,
    Text,
    RadioGroup,
    Radio,
    Flex,
    Heading,
} from "@adobe/react-spectrum";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ErrorCode as FileRejectionErrorCode } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { FeedbackAttachments } from "./FeedbackAttachments";
import { FormTextArea, FormTextField } from "./FormTextField";
import { FeedbackUIKeys, useFeedbackForm } from "./useFeedbackForm";
import { MAX_FILE_SIZE } from "./useReadAttachment";
import { useSentryContext } from "@src/contexts/SentryContext";

import type { DOMRefValue } from "@react-types/shared";
import type { RefObject } from "react";
import type { FileRejection } from "react-dropzone";

export const ISSUE_TYPE_KEYS = {
    feature: "FEATURE",
    bug: "BUG",
};

export type FeedbackFormData = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
};

/** type definition for the custom handles from ArtifactGridView */
export type FormHandlers = {
    validateForm: (showErrorState: boolean) => FeedbackFormData | undefined;
    scrollToInvalidElement: (name: string) => void;
};

export type AttachmentsData = {
    [key: string]: Uint8Array;
};

export type FeedbackUserContext = {
    userId: string;
    userOrgId: string;
    email: string;
};

interface Props {
    formRef: RefObject<DOMRefValue<HTMLFormElement>>;
}

export const FeedbackForm = forwardRef<FormHandlers, Props>(
    function FeedbackForm(
        { formRef },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        forwardedRef: any,
    ) {
        const { t } = useTranslation("common");
        const [issueType, setIssueType] = useState<string>(ISSUE_TYPE_KEYS.bug);

        const [accumulatedAttachmentSize, setAccumulatedAttachmentSize] =
            useState(0);
        const [attachmentsErrorState, setAttachmentsErrorState] =
            useState<string>();
        const [currentAttachments, setCurrentAttachments] = useState<File[]>(
            [],
        );
        const [allAttachments, setAllAttachments] = useState<File[]>([]);
        const { addActiveAttachment, removeActiveAttachment } =
            useSentryContext();

        const { getFormElement, validateFormField, validState, setValidState } =
            useFeedbackForm(formRef);

        const handleAttachments = (
            acceptedFiles: File[],
            rejectedFiles: FileRejection[],
        ) => {
            // Clear error state with each drop
            setAttachmentsErrorState(undefined);

            // Remove duplicates first
            const dedupedFiles = acceptedFiles.reduce((acc, file) => {
                if (
                    !allAttachments.find(
                        (existing) => existing.name === file.name,
                    )
                ) {
                    acc.push(file);
                }
                return acc;
            }, [] as File[]);

            const totalSize = dedupedFiles.reduce(
                (total, file) => (total += file.size),
                0,
            );
            if (totalSize + accumulatedAttachmentSize > MAX_FILE_SIZE) {
                setAttachmentsErrorState(
                    t("feedback.form.attachments.error.tooLarge"),
                );
                return;
            }

            if (rejectedFiles.length) {
                if (
                    rejectedFiles[0].errors[0].code ===
                    FileRejectionErrorCode.FileTooLarge
                ) {
                    setAttachmentsErrorState(
                        t("feedback.form.attachments.error.tooLarge"),
                    );
                } else {
                    setAttachmentsErrorState(
                        rejectedFiles[0].errors[0].message,
                    );
                }
                return;
            }
            setAccumulatedAttachmentSize(totalSize + accumulatedAttachmentSize);
            const all = allAttachments.concat(dedupedFiles);
            setAllAttachments(all);
            setCurrentAttachments(dedupedFiles);
        };

        useImperativeHandle(
            forwardedRef,
            () => ({
                validateForm: () => {
                    // Get data from Form
                    const data = new FormData(getFormElement());
                    const feedbackData = {} as FeedbackFormData;

                    const updatedState = validState || {};
                    for (const entry of data.entries()) {
                        const key = entry[0] as string;
                        const value = entry[1];
                        const found = FeedbackUIKeys.find(
                            (field) => field.key === key,
                        );
                        if (found?.required && !value) {
                            updatedState[key] = "invalid";
                        } else {
                            updatedState[key] = "valid";
                            feedbackData[key] = value;
                        }
                    }

                    setValidState(updatedState);
                    if (
                        Object.values(updatedState).some(
                            (state) => state === "invalid",
                        )
                    ) {
                        return;
                    }

                    return feedbackData;
                },
                scrollToNamedElement: (name: string) => {
                    const formElement = getFormElement();
                    const namedElement = formElement?.elements.namedItem(
                        name,
                    ) as HTMLElement;

                    const parent = formElement?.parentElement;
                    if (parent) {
                        const top =
                            namedElement.clientTop + namedElement.clientHeight;
                        parent.scrollTo({ top, behavior: "smooth" });
                    }
                },
            }),
            [formRef],
        );

        const [verboseError, setVerboseError] = useState(false);
        useEffect(() => {
            if (validState) {
                const invalidEntries = Object.keys(validState).reduce(
                    (acc, key) => {
                        if (validState[key] === "invalid") {
                            acc.push(key);
                        }
                        return acc;
                    },
                    [] as string[],
                );
                setVerboseError(invalidEntries.length > 1);
                if (invalidEntries.length) {
                    forwardedRef.current.scrollToNamedElement(
                        invalidEntries[0],
                    );
                }
            }
        }, [validState]);

        const updateAttachment = (
            name: string,
            data: Uint8Array | null,
            toDelete = false,
        ) => {
            // An user action should clear the error state.
            setAttachmentsErrorState(undefined);
            if (data) {
                addActiveAttachment(name, data);
            } else {
                removeActiveAttachment(name);
                const updated = currentAttachments.reduce((acc, file) => {
                    if (file.name !== name) {
                        acc.push(file);
                    } else {
                        // decrement the total accumulated file size.
                        setAccumulatedAttachmentSize(
                            accumulatedAttachmentSize - file.size,
                        );
                    }
                    return acc;
                }, [] as File[]);

                setCurrentAttachments(updated);
                if (toDelete) {
                    const index = allAttachments.findIndex(
                        (file) => file.name === name,
                    );
                    if (index >= 0) {
                        const all = [...allAttachments];
                        all.splice(index, 1);
                        setAllAttachments(all);
                    } else {
                        // This should not happen
                        throw new Error(
                            `Trying to remove a attached file, ${name} not in the list.`,
                        );
                    }
                }
            }
        };

        const handleKeydown = (e: KeyboardEvent) => {
            if ((e.key === "z" || e.key === "Z") && (e.metaKey || e.ctrlKey)) {
                // disable undo and redo
                e.preventDefault();
            }
        }

        useEffect(() => {
            window.addEventListener("keydown", handleKeydown);
            return(() => {
                window.removeEventListener("keydown", handleKeydown)
            });
        },[]);

        return (
            <Form
                ref={formRef}
                width="100%"
                validationBehavior="native"
                autoComplete="off"
                aria-label={t("feedback.form.title")}
                labelPosition="top"
                labelAlign="start">
                <RadioGroup
                    isRequired
                    orientation="horizontal"
                    name="issueType"
                    label={t("feedback.form.issueType")}
                    value={issueType}
                    onChange={(value: string) => {
                        setIssueType(value);
                    }}>
                    <Radio value={ISSUE_TYPE_KEYS.bug}>
                        {t("feedback.form.issueType.bug")}
                    </Radio>
                    <Radio value={ISSUE_TYPE_KEYS.feature}>
                        {t("feedback.form.issueType.feature")}
                    </Radio>
                </RadioGroup>
                <Flex direction="column" rowGap="size-100" width="100%">
                    <FormTextField
                        width="100%"
                        isRequired
                        validationState={validState?.["summary"]}
                        necessityIndicator="icon"
                        name="summary"
                        labelPosition="top"
                        labelAlign="start"
                        label={
                            issueType === ISSUE_TYPE_KEYS.bug
                                ? t("feedback.form.summary.bug")
                                : t("feedback.form.summary.feature")
                        }
                        errorMessage={
                            !verboseError
                                ? t("feedback.form.summary.error")
                                : issueType === ISSUE_TYPE_KEYS.bug
                                ? t("feedback.form.summary.bug")
                                : t("feedback.form.summary.feature")
                        }
                        onChange={() => {
                            validateFormField("summary");
                        }}
                    />
                    <FormTextArea
                        width="100%"
                        isRequired
                        validationState={validState?.["details"]}
                        necessityIndicator="icon"
                        name="details"
                        label={t("feedback.form.details")}
                        description={
                            issueType === ISSUE_TYPE_KEYS.bug
                                ? t("feedback.form.details.description.bug")
                                : undefined
                        }
                        errorMessage={
                            !verboseError
                                ? t("feedback.form.details.error")
                                : issueType === ISSUE_TYPE_KEYS.bug
                                ? t("feedback.form.details.description.bug")
                                : t("feedback.form.details.error")
                        }
                        onChange={() => {
                            validateFormField("details");
                        }}
                    />
                    <Flex direction="column">
                        <Heading level={4}>
                            {t("feedback.form.notes.title")}
                        </Heading>
                        <Text
                            UNSAFE_style={{
                                fontSize: "12px",
                                lineHeight: "20px",
                                color: "#8c8c8c",
                            }}>
                            {issueType === ISSUE_TYPE_KEYS.bug
                                ? t("feedback.form.notes.description.bug")
                                : t("feedback.form.notes.description.feature")}
                        </Text>
                        <FormTextArea
                            width="100%"
                            necessityIndicator="icon"
                            name="notes"
                            aria-label={t("feedback.form.notes")}
                            onChange={() => {
                                validateFormField("notes");
                            }}
                        />
                    </Flex>
                    <FeedbackAttachments
                        handleAttachments={handleAttachments}
                        updateAttachment={updateAttachment}
                        attachedFiles={allAttachments}
                        errorState={attachmentsErrorState}
                    />
                </Flex>
            </Form>
        );
    },
);

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { fileExtension, formatName } from "../utils/filenameHelper";

import type {
    UploadAnalyticsBatchFields,
    UploadAnalyticsFields,
} from "./AnalyticsConstants";

export const combineFileWithBatchFields = (
    batchFields: UploadAnalyticsBatchFields,
    file: File,
    contentId: string,
) => {
    const fields: UploadAnalyticsFields = {
        ...batchFields,
        "content.id": contentId,
        "content.size": Math.round(file.size / 1024), // in kB
    };
    const name = formatName(undefined, file.name);
    if (name !== "") {
        fields["content.name"] = name;
    }
    const extension = fileExtension(file.name, "");
    if (extension !== "") {
        fields["content.extension"] = extension;
    }
    return fields;
};

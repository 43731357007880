/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Avatar,
    DialogTrigger,
    Flex,
    View,
} from "@adobe/react-spectrum";
import Help from "@spectrum-icons/workflow/Help";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActivityPanel } from "./ActivityPanel";
import { ActivityPanelIcon } from "./ActivityPanelIcon";
import { DefaultAvatar } from "./DefaultAvatar";
import { HelpOverlay } from "./HelpOverlay";
import { LegalNotices } from "./LegalNotices";
import { UserAccountOverlay } from "./UserAccountOverlay";
import { useFeatureFlagContext } from "@src/contexts/FeatureFlagContext";
import { rpc } from "@src/contexts/RpcContext";
import { useUploadDownloadContext } from "@src/contexts/UploadDownloadContext";
import { PostFeedbackMessage } from "@src/interfaces/sentry/PostFeedbackMessage";
import { SentryUserFeedback } from "@src/interfaces/sentry/SentryUserFeedback";


interface Props {
    isReviewOnly: boolean;
}
export function HeaderRightSide({ isReviewOnly }: Props) {
    const { t } = useTranslation("common");

    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const { uploading } = useUploadDownloadContext();

    const {
        FF_HEADER_CONTEXTUAL_HELP,
        FF_SENTRY_INTEGRATION,
    } = useFeatureFlagContext();

    // Returns the current user profile data.
    const { data } = rpc.users.getLoggedInUserProfileData.useQuery();

    const [showLegalNotices, setShowLegalNotices] = useState(false);
    const [showUserFeedback, setShowUserFeedback] = useState(false);
    const [showMessageToUser, setShowMessageToUser] = useState("");


    useEffect(() => {
        if (uploading && !notificationsOpen) {
            setNotificationsOpen(true);
        }
        // purposely leave notificationsOpen out of deps
    }, [uploading]);

    return (
        <View>
            <Flex
                justifyContent="end"
                marginStart="size-100"
                marginEnd="size-100"
                alignItems="center"
                gap="size-100"
                height="100%">
                {FF_HEADER_CONTEXTUAL_HELP && !isReviewOnly && (
                    <DialogTrigger
                        type="popover"
                        hideArrow
                        placement="bottom end">
                        <ActionButton
                            aria-label={t("common:header.contextHelp")}
                            isQuiet>
                            <Help size="S" />
                        </ActionButton>
                        {(close) => (
                            <HelpOverlay
                                onClose={close}
                                onShowUserFeedback={() => {
                                    close();
                                    setShowUserFeedback(true);
                                }}
                            />
                        )}
                    </DialogTrigger>
                )}
                <DialogTrigger type="popover" hideArrow isOpen={notificationsOpen} onOpenChange={setNotificationsOpen}>
                    <ActionButton isQuiet>
                        <ActivityPanelIcon />
                    </ActionButton>
                    {() => (
                        <ActivityPanel/>
                    )}
                </DialogTrigger>
                <DialogTrigger type="popover" hideArrow>
                    <ActionButton isQuiet>
                        {data?.avatarLink ? (
                            <Avatar
                                src={data?.avatarLink}
                                size="avatar-size-200"
                                alt={t("common:user.avatar")}
                            />
                        ) : (
                            <DefaultAvatar
                                aria-label={t("common:user.avatar")}
                                size="M"
                            />
                        )}
                    </ActionButton>
                    {(close) => (
                        <UserAccountOverlay
                            userDisplayName={data?.displayName || ""}
                            userAvatarLink={data?.avatarLink}
                            company={data?.company || ""}
                            onShowLegalNotices={() => {
                                close();
                                setShowLegalNotices(true);
                            }}
                        />
                    )}
                </DialogTrigger>
            </Flex>
            {showLegalNotices && (
                <LegalNotices
                    onLegalNoticesClose={() => {
                        setShowLegalNotices(false);
                    }}
                />
            )}
            {FF_SENTRY_INTEGRATION && showUserFeedback && (
                <SentryUserFeedback
                    onClose={() => {
                        setShowUserFeedback(false);
                    }}
                    setShowMessageToUser={setShowMessageToUser}
                />
            )}
            {FF_SENTRY_INTEGRATION && showMessageToUser && (
                <PostFeedbackMessage
                    showMessageType={showMessageToUser}
                    onClose={() => {
                        setShowMessageToUser("");
                    }}
                />
            )}
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";

import { ScrollingContainer } from "@src/interfaces/common/components/ScrollingContainer";

import type { FC, PropsWithChildren } from "react";

export const BaseInfoPanel: FC<PropsWithChildren> = ({ children }) => {
    return (
        <View
            /* TODO: why isn't it respecting width alone? */
            minWidth="size-4600"
            height="100%"
            backgroundColor="gray-100"
            borderStartColor="gray-50"
            borderStartWidth="thick">
            <ScrollingContainer>{children}</ScrollingContainer>
        </View>
    );
};

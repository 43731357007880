/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, Text, View } from "@adobe/react-spectrum";
import QRCodeStyling from "qr-code-styling";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    createSearchParams,
    generatePath,
    matchPath,
    useLocation,
} from "react-router-dom";

import { GenericJobError } from "./GenericJobError";
import { rpc } from "@src/contexts/RpcContext";
import { DelayedSpinner } from "@src/interfaces/common/components/DelayedSpinner";
import { Routes } from "@src/routes";

import type { JobErrors } from "@shared/types";
import type { Options } from "qr-code-styling";
import type { FC } from "react";

const QR_DIMENSION = 202 as const;

const qrCodeOptions: Options = {
    type: "svg",
    image: "/assets/images/B_app_ProjectSunrise_64.svg",
    width: QR_DIMENSION,
    height: QR_DIMENSION,
    dotsOptions: {
        color: "#000000",
        type: "square",
    },
    imageOptions: {
        margin: 5,
    },
};

type Props = {
    assetId: string;
};

export const Artifact3DQRCode: FC<Props> = ({ assetId }) => {
    const { data: arInfo } = rpc.libraryAssets.getArInfo.useQuery(assetId);

    const { t } = useTranslation("library");
    const qrDiv = useRef<HTMLDivElement>(null);

    const [qrCode] = useState<QRCodeStyling>(new QRCodeStyling(qrCodeOptions));
    const [jobErrors, setJobErrors] = useState<JobErrors>([]);

    useEffect(() => {
        setJobErrors([
            ...(arInfo?.glbInfo?.jobErrors ?? []),
            ...(arInfo?.usdzInfo?.jobErrors ?? []),
        ]);
    }, [arInfo?.glbInfo, arInfo?.usdzInfo]);

    const isArReady = useMemo(() => {
        if (arInfo?.deletedDiscarded) {
            return false;
        }
        return arInfo?.glbInfo?.jobSuccess && arInfo.usdzInfo?.jobSuccess;
    }, [
        arInfo?.deletedDiscarded,
        arInfo?.glbInfo?.jobSuccess,
        arInfo?.usdzInfo?.jobSuccess,
    ]);

    useEffect(() => {
        if (qrDiv.current) {
            qrCode.append(qrDiv.current);
        }
    }, [qrCode, qrDiv, isArReady]);

    const location = useLocation();

    useEffect(() => {
        if (!qrCode) {
            return;
        }

        let workflow = "ASSETS";
        if (
            matchPath(Routes.projectOutputDetailView, location.pathname) ||
            matchPath(Routes.limitedProjectDetailReview, location.pathname)
        ) {
            workflow = "PROJECTS";
        }

        qrCode.update({
            data: `${new URL(
                generatePath(Routes.arViewer.path, { assetId }),
                window.location.origin,
            ).toString()}?${createSearchParams({ workflow }).toString()}`,
        });
    }, [assetId, location.pathname]);

    return (
        <View
            aria-label="QR Code"
            height="100%"
            padding="size-300"
            margin="size-300">
            {isArReady ? (
                <Flex
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    alignContent="center"
                    gap="size-300"
                    height="100%">
                    <View
                        borderRadius="medium"
                        backgroundColor="static-white"
                        padding="size-300">
                        <div ref={qrDiv} />
                    </View>
                    <Flex
                        gap="size-200"
                        direction="column"
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center"
                        width={
                            "calc(2 * size-300 + " +
                            QR_DIMENSION +
                            "px)" /* size of the QR code + padding */
                        }>
                        <Heading level={4}>
                            {t("detailView.views3D.qrcode.title")}
                        </Heading>
                        <Text
                            UNSAFE_style={{
                                fontSize: "10px",
                                textAlign: "center",
                            }}>
                            {t("detailView.views3D.qrcode.detail")}
                        </Text>
                    </Flex>
                </Flex>
            ) : (
                <Flex width="100%" height="100%">
                    {jobErrors.length ? (
                        <GenericJobError
                            jobErrors={jobErrors}
                            titleKey="error.model"
                        />
                    ) : (
                        <DelayedSpinner size="L" />
                    )}
                </Flex>
            )}
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    defaultTheme,
    Provider as SpectrumV3Provider,
} from "@adobe/react-spectrum";
import { Provider as SpectrumV3InternalProvider } from "@react-spectrum/provider";
import { createContext, useEffect, useState } from "react";

import { useLocale } from "@src/interfaces/common/hooks/useLocale";

import type { FC, PropsWithChildren } from "react";


const SpectrumV2Provider = require("@react/react-spectrum/Provider").default;
const key = "sunriseIsDarkMode";

interface ThemeContextProps {
    isDarkMode: boolean;
    handleThemeToggle: () => void;
}

const ThemeContext = createContext<ThemeContextProps>({
    isDarkMode: false,
    handleThemeToggle: () => {},
});

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const {locale} = useLocale();

    useEffect(() => {
        document.documentElement.lang = locale
    }, [locale]);

    useEffect(() => {
        const item = window.localStorage.getItem(key);
        if (item) {
            setIsDarkMode(item === "true");
            return;
        }

        const isSystemThemeDark = window.matchMedia(
            "(prefers-color-scheme: dark)",
        ).matches;
        setIsDarkMode(isSystemThemeDark);
    }, []);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
        const handler = (e: MediaQueryListEvent) => {
            const item = window.localStorage.getItem(key);
            if (item) {
                return;
            }
            setIsDarkMode(e.matches);
        };
        mediaQuery.addEventListener("change", handler);
        return () => mediaQuery.removeEventListener("change", handler);
    }, []);

    const handleThemeToggle = () => {
        window.localStorage.setItem(key, (!isDarkMode).toString());
        setIsDarkMode(!isDarkMode);
    };

    /**
     * In react spectrum v3, `theme` set to `defaultTheme` means that we use the "darkest" color theme in dark mode
     * and the "light" color theme in light mode. `colorScheme` is used to override the operating system light vs dark mode.
     * This allows us to have the sun/moon toggle button in the upper right corner.
     *
     * In react spectrum v2, we only have the theme prop, which we use to pass in the name of the color theme.
     * If we are in dark mode, we want to pass in "darkest" directly in order to match the rest of the v3 UI.
     * And "light" if we are in light mode. */

    return (
        <ThemeContext.Provider value={{ isDarkMode, handleThemeToggle }}>
            <SpectrumV3InternalProvider
                theme={defaultTheme}
                locale={locale}
                colorScheme={isDarkMode ? "dark" : "light"}>
                <SpectrumV3Provider
                    theme={defaultTheme}
                    locale={locale}
                    colorScheme={isDarkMode ? "dark" : "light"}>
                    <SpectrumV2Provider
                        theme={isDarkMode ? "darkest" : "light"}>
                        {children}
                    </SpectrumV2Provider>
                </SpectrumV3Provider>
            </SpectrumV3InternalProvider>
        </ThemeContext.Provider>
    );
};

export { ThemeContext, ThemeProvider };

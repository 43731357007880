/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const ns = ["common", "library", "studio", "editor", "viewer"] as const;
type NS = typeof ns[number];

const supportedLngs = ["en-US", "fr-FR", "de-DE", "ja-JP"] as const;
type Languages = typeof supportedLngs[number];

function cleanPath(result: string) {
    return result.replace('./', "/");
}

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['cookie'],
            caches: ['cookie'],
        },
        fallbackLng: ["en-US", "en"],
        supportedLngs,
        ns,
        backend: {
            loadPath: ([ln]: Languages[], [ns]: NS[]) => {
                return cleanPath(require(`./locales/${ln}/${ns}.json`));
            }
        },
        returnNull: false,
    });


export default i18n;

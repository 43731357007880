/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Checkbox,
    Content,
    Dialog,
    Divider,
    Footer,
    Heading,
} from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import type { FC, PropsWithChildren, Dispatch, SetStateAction } from "react";

type Props = {
    title: string;
    size?: "S" | "M" | "L";
    onCancel?: () => void;
    onConfirm: () => void;
    confirmBtnText: string;
    setDoNotAskAgain?: Dispatch<SetStateAction<boolean>>;
};

/**
 * Component for displaying a confirmation dialog to the user. Should be a child
 * of a <DialogContainer>.
 * TODO: Rewrite this in a React-y JSDoc way
 *
 * A checkbox for "Don't show again" will be displayed if a state setter is
 * passed into the props as `setDoNotAskAgain`.
 *
 * The `children` prop represents the <Content> area of the <Dialog>.
 *
 * @example
 * return (
 *   <DialogContainer onDismiss={closeDialog}>
 *     {!!projectToDownload && (
 *       <ConfirmDialog
 *         title={t("dialogs.download.title")}
 *         setDoNotAskAgain={setDoNotAskAgain}
 *         onCancel={closeDialog}
 *         onConfirm={doDownload}>
 *           {t("dialogs.download.body")}
 *       </ConfirmDialog>
 *     )}
 *   </DialogContainer>
 * );
 */
export const ConfirmDialog: FC<PropsWithChildren<Props>> = ({
    title,
    size,
    onCancel,
    onConfirm,
    confirmBtnText,
    setDoNotAskAgain,
    children,
}) => {
    const { t } = useTranslation("common");

    return (
        <Dialog size={size ?? "M"}>
            <Heading>{title}</Heading>
            <Divider />
            <Content>{children}</Content>
            {!!setDoNotAskAgain && (
                <Footer>
                    <Checkbox onChange={setDoNotAskAgain}>
                        {t("dialogs.doNotShowAgain")}
                    </Checkbox>
                </Footer>
            )}
            <ButtonGroup>
                {!!onCancel && (
                    <Button variant="secondary" onPress={onCancel}>
                        {t("actions.cancel")}
                    </Button>
                )}
                <Button variant="accent" onPress={onConfirm} autoFocus>
                    {confirmBtnText}
                </Button>
            </ButtonGroup>
        </Dialog>
    );
};

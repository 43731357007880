/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    Flex,
    Heading,
    Item,
    TagGroup,
    View,
} from "@adobe/react-spectrum";
import Add from "@spectrum-icons/workflow/Add";
import { useTranslation } from "react-i18next";

import { useMessagingContext } from "@src/contexts/MessagingContext";
import { rpc } from "@src/contexts/RpcContext";
import { RenameDialog } from "@src/interfaces/common/components/RenameDialog";

interface Props {
    assetId: string;
    tags: string[];
}

export const AssetTagSection = ({ assetId, tags }: Props) => {
    const { t } = useTranslation(["library", "common"]);
    const setTagsMutation = rpc.libraryAssets.setTags.useMutation();
    const { showModal } = useMessagingContext();


    function updateTags(keys: string[]) {
        setTagsMutation.mutate({
            id: assetId,
            tags: [...keys],
        });
    }

    function remove(tag: string) {
        const tagSet = new Set(tags);
        tagSet.delete(tag);
        updateTags([...tagSet])
    }

    function addTag() {
        showModal(
            <RenameDialog
                title={t("detailView.detailsPanel.addTag")}
                data-uia-text-field="asset-detail-add-tag-field"
                originalName=""
                onRename={(name) => {
                    updateTags([...new Set([name, ...tags])]);
                }}
            />,
        );
    }

    return (
        <View>
            <Heading level={4} marginTop="size-300">
                {t("detailView.detailsPanel.tags")}
            </Heading>
            <Flex alignItems="center" columnGap={"size-50"}>
                <ActionButton width="size-400" marginTop="3px" onPress={addTag}>
                    <Add size="XS" />
                </ActionButton>
                <TagGroup
                    aria-label={t("detailView.detailsPanel.tagsLabel")}
                    onRemove={(keys) => remove(keys.values().next().value)}
                >
                    {tags.map((tagItem) => <Item key={tagItem}>{tagItem}</Item>)}
                </TagGroup>
            </Flex>
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useRef, useState } from "react";

import { generateUuid } from "../utils/generateUuid";

import type { SerializedPin } from "@3di/adobe-3d-viewer";
import type { HotSpot } from "@shared/types";

export function use3dHotSpotData(
    initialHotSpots: HotSpot[] = [],
    onHotSpotsUpdate?: (hotSpots: HotSpot[]) => void,
) {
    const [hotSpots, setHotSpots] = useState(initialHotSpots);
    const hotSpotsRef = useRef<HotSpot[]>(hotSpots);

    // Allows access to current hot spot data outside of react state
    useEffect(() => {
        hotSpotsRef.current = hotSpots;
        if (hotSpots !== initialHotSpots && onHotSpotsUpdate) {
            onHotSpotsUpdate(hotSpots);
        }
    }, [hotSpots, initialHotSpots]);

    const createHotSpot = () => {
        const newHotSpot: HotSpot = {
            id: generateUuid(),
            text: "",
        };
        setHotSpots((currentHotSpots) => [...currentHotSpots, newHotSpot]);
        return newHotSpot;
    };

    const updateHotSpotTitle = (id: string, title: string) => {
        setHotSpots((currentHotSpots) => {
            const hotSpot = currentHotSpots.find(
                (hotSpot) => hotSpot.id === id,
            );
            if (hotSpot) {
                hotSpot.title = title;
            }
            return [...currentHotSpots];
        });
    };

    const updateHotSpotText = (id: string, text: string) => {
        setHotSpots((currentHotSpots) => {
            const hotSpot = currentHotSpots.find(
                (hotSpot) => hotSpot.id === id,
            );
            if (hotSpot) {
                hotSpot.text = text;
            }
            return [...currentHotSpots];
        });
    };

    const updateHotSpotPin = (id: string, pin: SerializedPin) => {
        setHotSpots((currentHotSpots) => {
            const hotSpot = currentHotSpots.find(
                (hotSpot) => hotSpot.id === id,
            );
            if (hotSpot) {
                hotSpot.pin = pin;
            }
            return [...currentHotSpots];
        });
    };

    const deleteHotSpot = (id: string) => {
        setHotSpots((currentHotSpots) =>
            currentHotSpots.filter((hotSpot) => hotSpot.id !== id),
        );
    };

    return {
        hotSpots,
        hotSpotsRef,
        createHotSpot,
        updateHotSpotPin,
        updateHotSpotTitle,
        updateHotSpotText,
        deleteHotSpot,
    };
}

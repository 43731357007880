/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { HeliosRoutes } from "@shared/common";
import { useEffect, useState } from "react";
import { matchPath, useLocation, useSearchParams } from "react-router-dom";

export function useIsChromeless() {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const isChromeless = !!searchParams.get("chromeless");
    const [suppressHeader, setSuppressHeader] = useState(false);

    useEffect(() => {
        setSuppressHeader(
            isChromeless && (
                !matchPath(HeliosRoutes.libraryDetailView.path, location.pathname)
            )
        )

    }, [isChromeless, location.pathname])

    return {
        isChromeless,
        suppressHeader
    }
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useTranslation } from "react-i18next";

import type { StudioStrings } from "@components/studio";

let strings: StudioStrings;

export function useStudioStrings() {
    const { t } = useTranslation("editor");

    if (!strings) {
        strings = {
            cameraOrbit: t("cameraControls.button.orbit"),
            cameraPan: t("cameraControls.button.pan"),
            cameraDolly: t("cameraControls.button.dolly"),

            addHotSpot: t("hotSpot.toolbar.button.addHotSpot"),
            moveHotSpot: t("hotSpot.overlay.button.moveHotSpot"),
            deleteHotSpot: t("hotSpot.overlay.button.deleteHotSpot"),
            close: t("hotSpot.overlay.button.close"),

            placeHolderTitle: t("hotSpot.overlay.placeHolderTitle"),
            placeHolderText: t("hotSpot.overlay.placeHolderText"),

            titleLabel: t("hotSpot.overlay.titleLabel"),
            textLabel: t("hotSpot.overlay.textLabel"),

            toastHotSpotAddMessage: t("hotSpot.toast.add.message"),
            toastHotSpotMoveMessage: t("hotSpot.toast.move.message"),
            toastHotSpotAction: t("hotSpot.toast.action"),

            frameButton: t("frameButton"),
        };
    }

    return strings;
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { AlertDialog, TextField } from "@adobe/react-spectrum";
import { TextFieldRef } from "@react-types/textfield";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    title: string;
    originalName: string;
    onValidate?: (newName: string) => Promise<string | undefined>;
    onRename: (newName: string) => void;
    "data-uia-text-field"?: string
}

export function RenameDialog({
    title,
    originalName,
    onRename,
    onValidate,
    "data-uia-text-field": dataUiaTextField,
}: Props) {
    const ref = useRef<TextFieldRef>(null);
    const [name, setName] = useState(originalName);
    const [error, setError] = useState<string>();

    const changed = name !== originalName;

    const { t } = useTranslation("common");

    useEffect(() => {
        if (ref.current && name === originalName) {
            ref.current.select();
        }
    }, [ref.current]);

    useEffect(() => {
        if (name === originalName) {
            return;
        }
        const trimmedName = name.trim();
        if (!trimmedName.length) {
            setError(t("errors.strings.short"));
            return;
        }

        if (trimmedName.length > 50) {
            setError(t("errors.strings.long"));
            return;
        }

        if (name.match(/\:.+\:/)) {
            setError(t("errors.strings.emoji"));
            return;
        }

        if (name.match(/[\\/*?"|:<>]/)) {
            setError(t("errors.strings.illegalCharacters"));
            return;
        }

        if (onValidate) {
            onValidate(name).then((error) => {
                setName((currentName) => {
                    if (name === currentName && !!error) {
                        setError(error);
                    }
                    return currentName;
                });
            });
        }

        setError(undefined);
    }, [name]);

    return (
        <AlertDialog
            variant="confirmation"
            isPrimaryActionDisabled={!!error || !changed}
            onPrimaryAction={() => onRename(name.trim())}
            cancelLabel={t("actions.cancel")}
            primaryActionLabel={t("actions.done")}
            title={title}>
                <style>{`
                    /* hack for spectrum icon size bug */
                    section[role="alertdialog"] svg {
                        width: 24px !important;
                    }
                `}</style>
                <TextField
                    data-uia={dataUiaTextField}
                    ref={ref}
                    width="100%"
                    validationState={!error ? "valid" : "invalid"}
                    errorMessage={error}
                    value={name}
                    onChange={name => setName(name)}

                />
        </AlertDialog>
    );
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import React from "react";

interface Props {
    forwardedRef?: React.Ref<HTMLDivElement>;
    onScroll?: React.UIEventHandler<HTMLDivElement>;
    overflowX?: "visible" | "hidden" | "clip" | "scroll" | "auto";
    id?: string;
}

export function ScrollingContainer({
    children,
    forwardedRef,
    onScroll,
    overflowX,
    id,
}: React.PropsWithChildren<Props>) {
    return (
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
            <div
                id={id}
                onScroll={onScroll}
                ref={forwardedRef}
                style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    overflowY: "auto",
                    overflowX: overflowX ?? "hidden",
                }}>
                {children}
            </div>
        </div>
    );
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionButton, Text } from "@adobe/react-spectrum";
import { CAMERA_ID, projectTemplates, WEBVIEWER_VARIANT } from "@shared/common";
import { toSpearCase } from "@shared/common/src/util/strings";
import AddCircle from "@spectrum-icons/workflow/AddCircle";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useFoldersProjects } from "../hooks/useFoldersProjects";
import { useOpenProject } from "../hooks/useOpenProject";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import { rpc } from "@src/contexts/RpcContext";
import type { AssetInputDialogResult } from "@src/interfaces/projects/components/AssetInputDialog";
import {
    AssetInputDialog,
    AssetInputDialogState,
} from "@src/interfaces/projects/components/AssetInputDialog";

import type { CameraData, TemplateData } from "@shared/types";
import type { TRPCClientError } from "@trpc/client";
import type { AnyRouter } from "@trpc/server";

export const CreateProject = () => {
    const { t } = useTranslation(["studio"]);
    const { showToastForInvalidPermissions } = useMessagingContext();
    const { generateNewAssetName } = useFoldersProjects();
    const { openProject } = useOpenProject();

    const [templates, setTemplates] = useState<TemplateData[]>();
    const [existingProjectNames, setExistingProjectNames] =
        useState<string[]>();
    const {
        data: projects,
        hasNextPage: hasMoreProjects,
        isFetching: isFetchingProjects,
        fetchNextPage: fetchMoreProjects,
        isSuccess: isProjectsFetchSuccess,
    } = rpc.projects.getAll.useInfiniteQuery(
        {
            count: 100,
            nameFilter: t("project.defaultName"),
        },
        {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
        },
    );
    const createProjectMutation = rpc.projects.create.useMutation();
    const createShotsMutation = rpc.projects.createShots.useMutation();

    useEffect(() => {
        if (hasMoreProjects && !isFetchingProjects) {
            fetchMoreProjects();
        } else if (
            isProjectsFetchSuccess &&
            !isFetchingProjects &&
            !hasMoreProjects
        ) {
            setExistingProjectNames(
                projects?.pages
                    .flatMap((page) => page.items)
                    .map((item) => item.name) ?? [],
            );
        }
    }, [hasMoreProjects, isFetchingProjects, isProjectsFetchSuccess]);

    useEffect(() => {
        const templateData = (
            Object.entries(projectTemplates).map(([key, val]) => {
                const adobeCustomLayerData = val.metadata.customLayerData.adobe;
                const templateSafeName = toSpearCase(adobeCustomLayerData.templateName)
                return {
                    id: key,
                    title: t(`project.template.${templateSafeName}.name`),
                    description: t(`project.template.${templateSafeName}.description`),
                    thumbnailUrl: `/assets/images/templates/${key}/DefaultThumbnails/Webviewer.png`,
                    categories: [...new Set(adobeCustomLayerData.templateTags)],
                    active: val.active,
                    cameras: new Map<string, CameraData>(
                        val.variants.map((entry) => {
                            const [cameraKey, variant] =
                                Object.entries(entry)[0];

                            const safeCameraKey = toSpearCase(variant.camera.customData.adobe.displayName);

                            const aspectRatio =
                                variant.render_product.resolution[0] /
                                variant.render_product.resolution[1];
                            const displayOrder =
                                variant.camera.customData.adobe.displayOrder;
                            return [
                                cameraKey,
                                {
                                    id: CAMERA_ID,
                                    variant: cameraKey,
                                    aspectRatio,
                                    resolution:
                                        variant.render_product.resolution,
                                    hideFromTemplateUI:
                                        cameraKey === WEBVIEWER_VARIANT,
                                    thumbnailUrl: `/assets/images/templates/${key}/CameraThumbnails/${cameraKey}.png`,
                                    templateUrl: val.url,
                                    displayName: t(`project.template.${templateSafeName}.cameras.${safeCameraKey}.name`),
                                    displayOrder,
                                },
                            ];
                        }),
                    ),
                };
            }) as unknown[] as TemplateData[]
        ).filter((template) => template.active);
        setTemplates(templateData);
    }, []);

    const createProject = useCallback(
        async (existingProjectNames: string[]) => {
            const name = generateNewAssetName(
                existingProjectNames,
                t("project.defaultName"),
            );

            const newProject = await createProjectMutation
                .mutateAsync({
                    name,
                })
                .catch(() => {
                    return Promise.reject();
                });

            if (!newProject?.id) {
                return Promise.reject();
            }
            return newProject;
        },
        [],
    );

    const onSubmitProjectCreate = useCallback(
        async (inputs: AssetInputDialogResult) => {
            if (!inputs.newProjectId) return; // TODO: Should we at least be warning here?
            createShotsMutation
                .mutateAsync({
                    projectId: inputs.newProjectId,
                    template: inputs.selectedTemplateId,
                    assets: inputs.modelAssetIds,
                    cameras: inputs.selectedTemplateCameras,
                })
                .catch((e) => {
                    if (
                        (e as TRPCClientError<AnyRouter>)?.data?.code ===
                        "FORBIDDEN"
                    ) {
                        // this case should never be possible as the current user just created the project,
                        // but it's here for completeness.
                        showToastForInvalidPermissions(true);
                    } else {
                        throw e;
                    }
                });
            openProject(inputs.newProjectId);
        },
        [],
    );

    return (
        <AssetInputDialog
            dataUiaPrefix="projects-create"
            triggerButton={
                <ActionButton
                    isQuiet
                    isDisabled={!existingProjectNames}
                    marginBottom="size-200">
                    <AddCircle size="M" />
                    <Text
                        UNSAFE_style={{
                            fontWeight: "bold",
                        }}>
                        {t("leftNav.newProject")}
                    </Text>
                </ActionButton>
            }
            templates={templates}
            dialogStateSequence={[
                AssetInputDialogState.SelectAssets,
                AssetInputDialogState.SelectTemplate,
                AssetInputDialogState.SelectTemplateCameras,
            ]}
            createProject={async () => {
                if (existingProjectNames) {
                    return createProject(existingProjectNames);
                }
                return Promise.reject();
            }}
            onSubmit={onSubmitProjectCreate}
        />
    );
};

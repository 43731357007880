/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, View } from "@adobe/react-spectrum";
import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useCommentsAssetData } from "../../hooks/useCommentsAssetData";
import { useAppContext } from "@src/contexts/AppContext";
import { rpc } from "@src/contexts/RpcContext";
import { ThemeContext } from "@src/contexts/ThemeContext";
import { useLocale } from "@src/interfaces/common/hooks/useLocale";
import type { CommentsAssetData } from "@src/types/ccac/CCACData";

import type { FC } from "react";
import { ScrollingContainer } from "@src/interfaces/common/components/ScrollingContainer";

interface Props {
    projectId: string;
}

export const ProjectCommentingPanel: FC<Props> = ({ projectId }) => {
    const [searchParams] = useSearchParams();
    const commentId = searchParams.get("commentId");
    const { ace } = useAppContext();
    const { isDarkMode } = useContext(ThemeContext);

    const { data: project } =
        rpc.projects.getProjectWithOwner.useQuery(projectId);
    const { data: approved } =
        rpc.projects.isProjectApproved.useQuery(projectId);
    const { data: projectName } =
        rpc.projects.getProjectName.useQuery(projectId);

    // Set up for commenting
    const { commentsAssetData, setCommentsAssetData } = useCommentsAssetData(
        project?.ownerUser ?? "",
        project?.compositeId ?? "",
    );

    const { locale } = useLocale();

    useEffect(() => {
        if (commentsAssetData?.id === project?.compositeId && projectName) {
            const nodeId = project?.compositeId.split(":").pop() ?? "";
            setCommentsAssetData({
                ...(commentsAssetData as CommentsAssetData),
                nodeId,
                validNodes: [{ id: nodeId, title: projectName }],
            });
        }
    }, [commentsAssetData?.id, project?.compositeId, projectName]);

    useEffect(() => {
        if (ace.commentingComponentLoaded && commentsAssetData?.nodeId) {
            ace.updateCommentingConfig({
                locale,
                disableCommentCreation: !!approved,
                annotationsEnabled: false,
                // This is required to hide "All screen comments" and show the cloud icon.
                // Need to also ensure true single-node page.
                annotationsMultiPageEnabled: true,
                onCommentInteraction: undefined,
                onNodeChange: undefined,
                onAnnotatingChange: undefined,
                assetData: commentsAssetData,
                theme: isDarkMode ? "darkest" : "light",
            });
        }
    }, [
        ace.commentingComponentLoaded,
        commentsAssetData?.nodeId,
        isDarkMode,
        approved,
    ]);

    const [reviewCommentId, setReviewCommentId] = useState<string>();
    useEffect(() => {
        if (commentId && commentsAssetData?.id) {
            setReviewCommentId(commentId);
        }
    }, [commentId, commentsAssetData?.id]);

    // Showing the initial comment from a redirected comment link.
    const showCommentRef = useRef<boolean>(false);
    useEffect(() => {
        if (
            reviewCommentId &&
            ace.commentingComponentLoaded &&
            !showCommentRef.current
        ) {
            // Show the initial comment.
            // format the ccx complete commendId, e.g.
            // "/assets/<compositeId>/annots/<commentId>"
            const ccxCommentId = `/assets/${commentsAssetData?.id}/annots/${reviewCommentId}`;
            showCommentRef.current = true;
            ace.commentsApi().showComment(ccxCommentId);

            const commentsList = document.querySelector(
                "cc-comments-list",
            ) as HTMLElement;
            commentsList.setAttribute("visible", "");
        }
    }, [reviewCommentId, ace.commentingComponentLoaded]);

    // The key setting in View forces the entire commenting panel to reload so that
    // un-submitted comment is reset when switching projects.
    return (
        <Flex height="100%">
            {commentsAssetData?.id && (
                <View
                    key={commentsAssetData?.id}
                    width="size-4600"
                    flex="none"
                    borderStartColor="gray-50"
                    borderStartWidth="thick">
                    {/** This visible attribute is necessary otherwise the list is hidden */}
                    <ScrollingContainer>
                        <cc-comments-list visible />
                    </ScrollingContainer>
                </View>
            )}
        </Flex>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionMenu, Flex, Item, Text } from "@adobe/react-spectrum";
import Document from "@spectrum-icons/workflow/Document";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useOpenProject } from "../hooks/useOpenProject";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import type { ProjectListItem } from "@src/contexts/RpcContext";
import { rpc } from "@src/contexts/RpcContext";
import { useUIStateContext } from "@src/contexts/UIStateContext";
import { LeftNavListItem } from "@src/interfaces/common/components/LeftNavListItem";
import { RenameDialog } from "@src/interfaces/common/components/RenameDialog";

type Props = {
    project: ProjectListItem;
    deleteProject: (project: ProjectListItem) => void;
};

export const ProjectsListItem = ({ project, deleteProject }: Props) => {
    const { projectSelection } = useUIStateContext();
    const { showModal } = useMessagingContext();
    const [isProjectSelected, setIsProjectSelected] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [canEditProject, setCanEditProject] = useState(false);

    const { openProject } = useOpenProject();

    const renameProjectMutation = rpc.projects.rename.useMutation();
    const nameExistsMutation = rpc.projects.doesProjectNameExist.useMutation();
    const rpcContext = rpc.useContext();
    const { t } = useTranslation(["studio", "common"]);

    useEffect(() => {
        if (isHovered) {
            // Only fetch this data on demand so we don't fetch it for every project all the time
            rpcContext.client.projects.canUserModify
                .query(project.id)
                .then((canEdit) => setCanEditProject(canEdit ?? false));
        }
    }, [isHovered]);

    useEffect(() => {
        setIsProjectSelected(project.id === projectSelection);
    }, [project.id, projectSelection]);

    function onClickHandler() {
        openProject(project.id);
    }

    function rename() {
        showModal(
            <RenameDialog
                originalName={project.name}
                title={t("dialogs.rename")}
                onValidate={async (newName) => {
                    if (newName === project.name) {
                        return;
                    }
                    const nameExists = await nameExistsMutation.mutateAsync({
                        name: newName,
                    });
                    if (nameExists) {
                        return t("common:errors.strings.exists");
                    }
                    return;
                }}
                onRename={(newName) => {
                    renameProjectMutation.mutateAsync({
                        name: newName,
                        id: project.id,
                    });
                }}
            />,
        );
    }

    return (
        <LeftNavListItem
            isSelected={isProjectSelected}
            onClickHandler={onClickHandler}
            setIsHovered={setIsHovered}
            key={project.id}>
            <Flex columnGap="size-100" alignItems="center" height="100%">
                <Document size="S" flex="none" marginStart="size-150" />
                <Text
                    UNSAFE_style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                    flex={1}
                    marginEnd="size-100">
                    {project.name}
                </Text>
                <ActionMenu
                    direction="bottom"
                    shouldFlip
                    flex="none"
                    isHidden={!isHovered}
                    isQuiet
                    disabledKeys={!canEditProject ? ["delete"] : undefined}
                    onAction={(key) => {
                        switch (key) {
                            case "rename":
                                rename();
                                break;
                            case "delete":
                                deleteProject(project);
                                break;
                        }
                    }}>
                    <Item key="rename">{t("list.action.menu.rename")}</Item>
                    <Item key="delete">{t("list.action.menu.delete")}</Item>
                </ActionMenu>
            </Flex>
        </LeftNavListItem>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { parseUserAgent } from "detect-browser";
import { observable } from "mobx";
import { v4 as uuidV4 } from "uuid";

import { connected, ConnectedService } from "./ConnectedService";
import { config } from "@src/config";

import type { HeliosLogger } from "./HeliosLogger";
import type { ImsUser } from "./ims";

const UNKNOWN_BROWSER_VERSION = "Unknown browser version";
const FI_SUNRISE = "sbst_sunrise";

type ControlProfile = {
    nglAppId: string;
    requesterNglAppId: string;
    deviceId: string;
    deviceDate: string;
    deviceTokenId: string;
    nglLibRuntimeMode: string;
    creationTimestamp: number;
    cacheLifetime: number;
    validUptoTimestamp: number;
    eTag: string;
    cacheRefreshControl: {
        appRefreshInterval: number;
        nglLibRefreshInterval: number;
    };
    deviceTokenHash: string;
    sessionId: string;
    osUserId: string;
    createdForVdi: boolean;
    cacheExpiryWarningControl?: object;
    appUsageTrackingControl?: object;
    logControl?: object;
    imsThrottlingConfig?: object;
};

/**
 * Access Profile Services. see detail about API here,
 * https://developers.corp.adobe.com/Access-Platform/docs/api-reference/cops.yaml#/operations/getwebappAccessProfile
 * This API returns a cache-able user access profile.
 */
export class APS extends ConnectedService {
    private _imsUser: ImsUser;
    private _logger: HeliosLogger;
    private _apsUrl = `${config.apsEndpoint}/webapps/access_profile/v3`;
    private _userControlProfile: ControlProfile | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _userAccessibleItems: any[] = [];
    private _serviceUnavailable = false;

    constructor(imsUser: ImsUser, logger: HeliosLogger) {
        super();
        this._logger = logger;
        this._imsUser = imsUser;
        this.connect();
    }

    @observable
    public serviceIsUnavailable() {
        return this._serviceUnavailable;
    }

    /**
     * Returns true if user is entitled to Sunrise.
     * For type1 users, userAccessibleItems is undefined in the response.
     * For type2 users who are not entitled, sbst_sunrise is undefined in the fulfillable items.
     * For type3 users (> 5 profiles), not entitled, sbst_sunrise is undefined.
     * This should only be called if APS has been connected.
     */
    @connected
    public async isUserEntitled() {
        if (!this.connected) {
            return undefined;
        }
        if (!this._userAccessibleItems.length && !this._userControlProfile) {
            return false;
        }

        return this._userAccessibleItems.some(
            (item) => item.fulfillable_items[FI_SUNRISE]?.enabled,
        );
    }

    // [SRV3D-6729]: refresh access profile using the cached controlProfile when profile expires.
    // For now, we won't refresh the access profile since we don't expect this to change.

    async initialize() {
        await this._imsUser.connect();
        if (this._imsUser.accessToken === undefined) {
            this._logger.logError({
                errorCode: "2013",
            });
            throw new Error("Access token for user does not exist");
        }

        try {
            const { authorization, "x-api-key": apiKey } =
                await this._imsUser.getAuthHeaders();

            // "x-dunamis-ic" header is not required though the spec said otherwise.
            const headers = {
                authorization,
                "x-api-key": apiKey,
                "content-type": "application/json",
            };

            // [SRV3D-6729]: We will need to fine tune what we passed to the request body.
            // These fields need updates: sessionId, deviceId, osUserId, deviceName, osVersion,
            const browserInfo = parseUserAgent(window.navigator.userAgent);
            const body = {
                deviceTokenHash: "SHA-512 hash of device token", // [SRV3D-6729]
                appDetails: {
                    nglAppId: config.apsAppId,
                    nglAppVersion: config.appVersion,
                    currentAsnpId: null, // This is null for the first time, subsequent call should set this to the Id from cached profile.
                    sessionId: `session-${uuidV4()}`, // [SRV3D-6729]
                    nglLibRuntimeMode: "NAMED_USER_ONLINE",
                    locale: "en_US",
                    appNameForLocale: config.appName,
                    appVersionForLocale: config.appVersion,
                    nglAppLaunchState: null,
                    eTag: null,
                },
                deviceDetails: {
                    deviceId: `device-${uuidV4()}`, // [SRV3D-6729]
                    osUserId: "sunrise-user-os-id", // [SRV3D-6729]
                    deviceName: browserInfo?.os, // [SRV3D-6729]
                    osName: browserInfo?.os,
                    osVersion: "os-version", // [SRV3D-6729]
                    currentDate: new Date().toISOString(),
                    enableVdiMarkerExists: false,
                    embeddedBrowserVersion:
                        browserInfo?.version || UNKNOWN_BROWSER_VERSION,
                    currentTimestamp: Date.now(),
                },
            };
            const response = await fetch(this._apsUrl, {
                method: "POST",
                headers,
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                this._logger?.logError({
                    errorCode: "2201",
                    statusCode: response.status,
                });
                if (response.status >= 500) {
                    this._serviceUnavailable = true;
                } else if (response.status !== 304) {
                    throw new Error(
                        `APS request for user access profile failed`,
                        { cause: response.status },
                    );
                }
            }

            const result = await response.json();
            // If we don't get a version for asnp, we will block access.
            if ((result.asnp.asnpSpecVersion as number) > 0) {
                // Decode the payload
                const payloadString = atob(result.asnp.payload);
                const accessProfile = JSON.parse(payloadString);
                this._userControlProfile = accessProfile.controlProfile;
                this._userAccessibleItems =
                    accessProfile.appProfile.accessibleItems || [];

                // We cache this control profile so that we can refresh profile when needed.
                const controlProfile = this._userControlProfile;
                // eslint-disable-next-line no-console
                console.log(
                    `Access profile creationTime: ${controlProfile?.creationTimestamp}, cacheLifeTime: ${controlProfile?.cacheLifetime}, validUptoTime: ${controlProfile?.validUptoTimestamp}`,
                );
            }
        } catch (err) {
            const responseStatus = (err as Error)?.cause as number;
            this._logger?.logError({
                errorCode: "2201",
                statusCode: responseStatus,
            });
        }
    }
}

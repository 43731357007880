/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

import { useUpload } from "./useUpload";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import type { LibraryAssetCardItem } from "@src/contexts/RpcContext";
import type { UploadAnalyticsBatchFields } from "@src/lib/analytics/AnalyticsConstants";
import { combineFileWithBatchFields } from "@src/lib/analytics/UploadAnalyticsUtil";

import type { TRPCClientError } from "@trpc/client";
import type { AnyRouter } from "@trpc/server";
import type { FileRejection } from "react-dropzone";

export function useVersionUpgrade() {
    const { t } = useTranslation("library");
    const { showToastForInvalidPermissions, showToastForRejectedFiles, error } =
        useMessagingContext();
    const [uploadId, setUploadId] = useState<string>();
    const [assetToUpgrade, setAssetToUpgrade] =
        useState<LibraryAssetCardItem>();
    const { logIngestEvent } = useAnalyticsContext();
    const { uploadNewMajorVersion, notifyRejectedFiles } = useUpload();

    const handleUploadFile = async (
        acceptedFiles: File[],
        rejectedFiles: FileRejection[],
        batchAnalyticsFields: UploadAnalyticsBatchFields,
    ) => {
        if (!assetToUpgrade) {
            [
                ...acceptedFiles,
                ...rejectedFiles.map(({ file }) => file),
            ].forEach((file) => {
                // Synthesize a start event and a content ID since we will not
                // add a DB record for this failure.
                const contentId = v4();
                const fields = combineFileWithBatchFields(
                    batchAnalyticsFields,
                    file,
                    contentId,
                );
                logIngestEvent("uploadStart", fields);
                logIngestEvent("uploadError", fields);
            });
            console.error("Expected an asset to upgrade");
            return;
        }
        if (acceptedFiles.length + rejectedFiles.length !== 1) {
            // If there is an incorrect number, we shouldn't even be getting to
            // this handler. An uploadError will have been filed with analytics
            // from the onDrop handler in UploadDialog.
            console.error("Expected exactly one file to upload");
            return;
        }
        if (acceptedFiles.length) {
            const file = acceptedFiles[0];
            return uploadNewMajorVersion(
                assetToUpgrade.id,
                assetToUpgrade.compositeId as string,
                assetToUpgrade.name,
                file,
                batchAnalyticsFields,
            )
                .then((uploadId) => {
                    setUploadId(uploadId || "");
                })
                .catch((e) => {
                    setUploadId("");
                    if (
                        (e as TRPCClientError<AnyRouter>)?.data?.code ===
                        "FORBIDDEN"
                    ) {
                        showToastForInvalidPermissions();
                    } else {
                        error(
                            t("notification.upload.newMajorVersion.error", {
                                assetName: assetToUpgrade.name,
                                interpolation: { escapeValue: false },
                            }),
                        );
                    }
                });
        } else {
            // We know that we are in a case where there is exactly one rejected
            // file, and analytics were filed in UploadDialog.
            notifyRejectedFiles(rejectedFiles);
            // TODO migrate to RS3 toast. Currently placed top center, OK to move to lower right
            showToastForRejectedFiles(rejectedFiles);
        }
    };

    return {
        handleUploadFile,
        assetToUpgrade,
        setAssetToUpgrade,
        uploadId,
        setUploadId,
    };
}

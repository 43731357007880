import { useEffect, useState } from "react";
import { portalFactory } from "../utils/portalFactory";

export function usePortal() {
    const [portalDiv, setPortalDiv] = useState<HTMLDivElement | null>(null);
    const [Portal, setPortal] = useState<ReturnType<typeof portalFactory>>();

    useEffect(() => {
        if (portalDiv) {
            const newOverlayPortal = portalFactory(portalDiv);
            setPortal(() => newOverlayPortal);
        }
    }, [portalDiv]);

    return { Portal, setPortalDiv };
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import Comment from "@spectrum-icons/workflow/Comment";
import Info from "@spectrum-icons/workflow/Info";
import WorkFlow from "@spectrum-icons/workflow/Workflow";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { BaseProjectLibrary } from "./components/BaseProjectLibrary";
import { ProjectNavBar } from "./components/ProjectNavBar";
import {
    DetailViewRail,
    DETAIL_PANEL_KEYS,
} from "../detail/components/common/DetailViewRail";
import { ProjectDetailSidePanels } from "../detail/components/projects/ProjectDetailSidePanels";
import { rpc } from "@src/contexts/RpcContext";
import { useUIStateContext } from "@src/contexts/UIStateContext";

import type { DetailPanelKey, DetailViewRailButton } from "../detail/components/common/DetailViewRail";

export const ProjectLibrary = observer(() => {
    const { t } = useTranslation(["studio"]);
    const { projectId } = useParams();
    const { setProjectSelection } = useUIStateContext();

    const { projectViewPanelSelection, setProjectViewPanelSelection } =
        useUIStateContext();
    const handleToggleSidePanel = (selectedKey: DetailPanelKey) => {
        setProjectViewPanelSelection(selectedKey);
    };
    const {data: orgConfigData} = rpc.orgs.getConfigs.useQuery();

    useEffect(() => {
        setProjectSelection(projectId ?? "");
        return () => setProjectSelection("");
    }, [projectId]);

    const sidePanelButtons: DetailViewRailButton[] = [
        {
            key: DETAIL_PANEL_KEYS.info,
            label: t("sidePanel.label.info"),
            icon: <Info size="S" />,
        },
        {
            key: DETAIL_PANEL_KEYS.comment,
            label: t("sidePanel.label.comment"),
            icon: <Comment size="S" />,
        },
    ];

    if (orgConfigData?.aemConfig?.enabled) {
        sidePanelButtons.push({
            key: DETAIL_PANEL_KEYS.workfront,
            label: t("library:detailView.sidePanel.label.workfront"),
            icon: <WorkFlow size="S" />,
        });
    }

    return (
        <BaseProjectLibrary
            navBar={<ProjectNavBar projectId={projectId || ""} />}>
            <ProjectDetailSidePanels
                projectId={projectId ?? ""}
                selectedPanelKey={projectViewPanelSelection}
            />
            <DetailViewRail
                selected={projectViewPanelSelection}
                handleToggleSidePanel={handleToggleSidePanel}
                buttons={sidePanelButtons}
            />
        </BaseProjectLibrary>
    );
});

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { getTaskStatus } from "./util";

import {
    DEFAULT_PROJECT_ID,
    DEFAULT_PROJECT_NAME,
    PREVIEW_URL_CUSTOM_FIELD_NAME_WITH_PREFIX,
    PUBLISH_URL_CUSTOM_FIELD_NAME_WITH_PREFIX,
} from "../../constants";

import { AbstractStatusResponse } from "../../requests/types/AbstractStatusResponse";
import { TaskUpdateParameter } from "../../requests/types/TaskUpdateParameter";
import { TaskResponse } from "../../requests/types/TaskResponse";

import { ensureTaskHasCustomForms } from "../../requests/ensureTaskHasCustomForms";
import { fetchAvatarImageAsBlob } from "../../requests/fetchAvatarImageAsBlob";
import { fetchWorkfrontTaskPossibleStatuses } from "../../requests/fetchTaskPossibleStatuses";
import { updateWorkfrontTask } from "../../requests/updateTask";

import { TaskDetails } from "../../../../types/TaskDetails";
import { TaskStatus } from "../../../../types/TaskStatus";
import { WorkfrontParams } from "../../../../types/wfParams";

export const updateTaskAbstract = async (
    wfParams: WorkfrontParams,
    taskId: string,
    fieldsToBeUpdated: TaskUpdateParameter[],
) => {
    await ensureTaskHasCustomForms(wfParams, taskId);

    const taskPromise: Promise<TaskResponse> = updateWorkfrontTask(
        wfParams,
        taskId,
        fieldsToBeUpdated,
    );
    const taskStatusesPromise: Promise<AbstractStatusResponse[]> =
        fetchWorkfrontTaskPossibleStatuses(wfParams);
    const [task, possibleTaskStatuses] = await Promise.all([taskPromise, taskStatusesPromise]);

    const assignedAvatar: Blob | null =
        !!task?.assignedTo && !!task.assignedTo.avatarDownloadURL
            ? await fetchAvatarImageAsBlob(wfParams, task?.assignedTo.avatarDownloadURL)
            : null;

    const taskStatus: TaskStatus = getTaskStatus(task.status, possibleTaskStatuses);

    const assignedTo = task.assignedTo
        ? {
              ID: task.assignedTo.ID,
              name: task.assignedTo.name,
              title: task.assignedTo.title,
              avatar: assignedAvatar,
          }
        : null;
    const result: TaskDetails = {
        task: {
            ID: task.ID,
            name: task.name,
            description: task.description,
            status: taskStatus,
            assignedTo,
            previewUrl: task[PREVIEW_URL_CUSTOM_FIELD_NAME_WITH_PREFIX] ?? null,
            publishUrl: task[PUBLISH_URL_CUSTOM_FIELD_NAME_WITH_PREFIX] ?? null,
            plannedCompletionDate: task.plannedCompletionDate,
            projectName: DEFAULT_PROJECT_NAME,
            projectId: DEFAULT_PROJECT_ID,
            documents: task.documents,
        },
    };
    return result;
};

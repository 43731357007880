/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Content,
    Heading,
    IllustratedMessage,
    Text,
} from "@adobe/react-spectrum";
import Alert from "@spectrum-icons/workflow/Alert";
import { useTranslation } from "react-i18next";

import { useFeatureFlagContext } from "@src/contexts/FeatureFlagContext";

import type { JobErrors } from "@shared/types";
import type { FC } from "react";

type Props = {
    jobErrors?: JobErrors;
    titleKey: string;
};

export const GenericJobError: FC<Props> = ({ jobErrors, titleKey }) => {
    const { DFF_JOB_ERRORS_UI } = useFeatureFlagContext();
    const { t } = useTranslation("common");
    return (
        <IllustratedMessage height="100%" width="100%">
            <Alert size="XXL" />
            <Heading>{t(titleKey)}</Heading>
            <Content>
                {DFF_JOB_ERRORS_UI && (
                    <Text>
                        {jobErrors
                            ? jobErrors.flatMap((jobError) => jobError.messages)
                            : t("error.unknown")}
                    </Text>
                )}
            </Content>
        </IllustratedMessage>
    );
};

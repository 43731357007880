/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useState } from "react";
import { useTranslation } from "react-i18next";

// We don't set any limits on the number of files users can attach.
// Setting attachment limit much lower since feedbacks were being dropped with larger attachments.
// We don't know how Sentry computes the total event payload and the payload can be dropped without any notification.
// 100 MB is the documented uncompressed upper limit including all payloads.
export const MAX_FILE_SIZE = 1024 * 1024 * 20;
export const ONE_MB_SIZE = 1024 * 1024;

export type FetchProgress = {
    [key: string]: number;
};

export type AttachmentStatus = {
    data?: Uint8Array;
    errorState?: string;
};

export const useReadAttachment = () => {
    const { t } = useTranslation("common");
    const [attachmentProgress, setAttachmentProgress] = useState<number>(0);
    const [attachmentContentStatus, setAttachmentContentStatus] =
        useState<AttachmentStatus>();

    const fetchFile = async (aFile: File) => {
        // This throws if passing an invalid optional mode.
        // We use the default mode (undefined) so we should not throw here.
        const reader = aFile.stream().getReader();
        if (!reader) {
            setAttachmentContentStatus({
                errorState: t("feedback.form.attachments.error.read", {
                    name: aFile.name,
                    interpolation: {
                        escapeValue: false,
                    },
                }),
            });
            return;
        }

        let receivedLength = 0;
        const dataBlocks: Uint8Array[] = []; // array of received binary chunks (comprises the body)
        const contentLength = aFile.size;

        return (
            reader
                .read()
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then(function processData({ done, value }): any {
                    if (done) {
                        // Concatenate chunks into single Uint8Array
                        const fileContentData = new Uint8Array(receivedLength);
                        let blockPosition = 0;
                        for (const block of dataBlocks) {
                            fileContentData.set(block, blockPosition);
                            blockPosition += block.length;
                        }
                        return { data: fileContentData, name: aFile.name };
                    }
                    dataBlocks.push(value);
                    receivedLength += value.length;
                    setAttachmentProgress(
                        (receivedLength / contentLength) * 100,
                    );

                    return reader.read().then(processData);
                })
                .catch(() => {
                    setAttachmentContentStatus({
                        errorState: t("feedback.form.attachments.error.read", {
                            name: aFile.name,
                            interpolation: {
                                escapeValue: false,
                            },
                        }),
                    });

                    return undefined;
                })
        );
    };

    const handleReadAttachment = async (file: File) => {
        return fetchFile(file).then((result) => {
            if (result) {
                setAttachmentContentStatus({ data: result.data });
                return;
            }
        });
    };

    return {
        handleReadAttachment,
        attachmentContentStatus,
        attachmentProgress,
    };
};

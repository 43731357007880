/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, View } from "@adobe/react-spectrum";
import "@src/styles/reset.css";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AppRoutes } from "./appRoutes";
import { useAppContext } from "./contexts/AppContext";
import { useHeartbeat, useQueryInvalidations } from "./contexts/RpcContext";
import { AppHeader } from "./interfaces/common/components/AppHeader";
import { ChromePopup } from "./interfaces/common/components/ChromePopup";
import { useIsChromeless } from "./interfaces/common/hooks/useIsChromeless";
import { usePortal } from "./interfaces/common/hooks/usePortal";
import { useRecentlyFailedAssets } from "./interfaces/common/hooks/useRecentlyFailedAssets";
import { useVersionCheck } from "./interfaces/common/hooks/useVersionCheck";
import { useStudioAssetPrecache } from "./interfaces/editor/hooks/useStudioAssetPrecache";
import { Routes } from "./routes";

export type DefaultPageProps = {
    AppBarPortal: ReturnType<typeof usePortal>["Portal"];
};

export const App = observer(() => {
    const { ims } = useAppContext();

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/") {
            navigate(Routes.library.path);
        }
    }, [ims.hasAPIAccess]);

    const { suppressHeader } = useIsChromeless();

    useQueryInvalidations();

    useHeartbeat();

    useVersionCheck();

    useStudioAssetPrecache();

    useRecentlyFailedAssets();

    const { Portal: AppBarPortal, setPortalDiv: setAppBarPortal } = usePortal();

    return (
        // There is a dummy version of the site wrapper in AccessContext.tsx
        <View backgroundColor="gray-50" height="100dvh">
            <AppHeader setPortal={setAppBarPortal} />
            <ChromePopup />
            <Flex
                direction="row"
                width="100%"
                height={suppressHeader ? "100%" : "calc(100% - size-800)"}>
                    <AppRoutes AppBarPortal={AppBarPortal} />
            </Flex>
        </View>
    );
});

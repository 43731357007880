/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    AlertDialog,
    Flex,
    Link as LinkSpectrum,
    Text,
} from "@adobe/react-spectrum";
import { SUNRISE_PRERELEASE_TERMS_HASH } from "@shared/common";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";

import { useOpenInNewTab } from "../hooks/useOpenInNewTab";
import { rpc } from "@src/contexts/RpcContext";


const { openInNewTab } = useOpenInNewTab();

// This link is part of the Adobe prerelease terms.
// In order to show the clickable link inlined, the notice text is broken into
// two parts. The notice is formatted as translated as follows:
// "common:account.legal.prereleaseTerms1" + link +
// "common:account.legal.prereleaseTerms2" below.
// ******** WARNING ********
// If you change this file, run the `hash-terms` script.
// From the `components/helios` directory run (for example):
// $ bun hash-terms
// Then update the SUNRISE_PRERELEASE_TERMS_HASH in shared/common/index.ts
const ADOBE_PRERELEASE_TERMS_URL = generatePath(
    "/Adobe Prerelease Software License Agreement Project Sunrise 03.04.2024.pdf",
);

export const LegalNoticePopup = () => {
    const { t } = useTranslation("common");

    const rpcContext = rpc.useContext();
    const setAcceptedTermsMutation = rpc.users.setAcceptedTermsHash.useMutation(
        {
            onMutate: async () => {
                // Cancel any outgoing refetches
                // (so they don't overwrite our optimistic update)
                await rpcContext.users.didUserAcceptTerms.cancel();

                // Optimistically set to true. We don't need an invalidation
                // because accepting the terms is the end of this user flow. We
                // also don't need to worry about this getting clobbered as the
                // only way we'd be past it would be for it to have been
                // accepted and written to the DB as `true`.
                rpcContext.users.didUserAcceptTerms.setData(
                    undefined,
                    () => true,
                );
            },
            onError: async () => {
                // We are only here because someone attempted to accept the
                // terms, so try to set it to true.
                rpcContext.users.didUserAcceptTerms.setData(
                    undefined,
                    () => true,
                );
            },
        },
    );


    const accept = () => {
        setAcceptedTermsMutation.mutate(SUNRISE_PRERELEASE_TERMS_HASH);
    };

    return (
        <AlertDialog
            variant="confirmation"
            title={t("header.appTitle")}
            primaryActionLabel={t("actions.agree")}
            onPrimaryAction={accept}>
            <Flex
                direction="row"
                data-testid="prerelease-terms-popup"
                UNSAFE_style={{
                    display: "inline-block",
                }}>
                <Text>{t("account.legal.prereleaseTerms1")}</Text>
                <LinkSpectrum
                    isQuiet
                    UNSAFE_style={{
                        color: "blue-900",
                    }}
                    onPress={() => {
                        openInNewTab(ADOBE_PRERELEASE_TERMS_URL);
                    }}
                    variant="primary">
                    {t("account.legal.prereleaseTerms")}
                </LinkSpectrum>
                <Text>{t("account.legal.prereleaseTerms2")}</Text>
            </Flex>
        </AlertDialog>
    );
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
export const WF_API_VERSION = "v18.0";

export const WF_WILDCARD_CURRENT_USER_ID = "$$USER.ID";

export const WF_OBJECT_CODE_TASK = "TASK";
export const WF_OBJECT_CODE_PROJECT = "PROJ";
export const WF_OBJECT_CODE_USER = "USER";
export const WF_OBJECT_CODE_STATUSES = "CSTEM";
export const WF_OBJECT_CODE_CUSTOM_FORM = "CTGY";
export const WF_OBJECT_CODE_CUSTOM_FIELD = "PARAM";
export const WF_OBJECT_CODE_DOCUMENT = "DOCU";

export const PREVIEW_URL_CUSTOM_FIELD_NAME = "aem_workfront_integration_preview_url";
export const PUBLISH_URL_CUSTOM_FIELD_NAME = "aem_workfront_integration_publish_url";
export const PREVIEW_URL_CUSTOM_FIELD_NAME_WITH_PREFIX = `DE:aem_workfront_integration_preview_url`;
export const PUBLISH_URL_CUSTOM_FIELD_NAME_WITH_PREFIX = `DE:aem_workfront_integration_publish_url`;

export const CONTENT_FRAGMENT_CUSTOM_FIELD_NAME = "aem_workfront_integration_content_fragment";
export const CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX =
    "DE:aem_workfront_integration_content_fragment";

export const WF_API_METHOD_PUT = "PUT";
export const WF_API_METHOD_POST = "POST";

export const DEFAULT_PROJECT_NAME = "projectName";
export const DEFAULT_PROJECT_ID = "projectId";

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

/**
 * This file contains the integration data types and setup required for sharing for review (ccx-sharing & ccx-comments)
 * See this readme for detail about integrating with ccx-sharing
 * https://git.corp.adobe.com/CCX/ccx-sharing/blob/main/packages/component-web/integration.md
 *
 * Integration with ccx-comments
 * https://git.corp.adobe.com/CCX/ccx-comments/blob/main/ONBOARDING.
 * API and integration guide
 * https://git.corp.adobe.com/CCX/ccx-comments/tree/main/packages/web-component
 *
 * For production invitation to work, we will need the Cloud Platform team to whitelist our api key and host names.
 */

/**
 * For sharing for review integration.
 */
export const SHARE_SHEET_ARTIFACT_ID = "artifact";
export const SHARE_SHEET_SHARE_ID = "share";
export const IMS_SERVICE_ACCOUNT_CODE = "creative_cloud";
export const IMS_SERVICE_LEVEL = "CS_LVL_2";

export const CCX_SHARING_VERSION = "";

export const ASSET_INVITE_NOTIFICATION =
    "cc_collab_sunrise_asset_invite_notification";
export const PROJECT_INVITE_NOTIFICATION =
    "cc_collab_sunrise_project_invite_notification";

/**
 * Analytics data for sharing and comments.
 * Both data structures are very similar with minor differences.
 * AnalyticsData is required but enablement is optional.
 *
 * Analytics Data for ccx-sharing
 * https://git.corp.adobe.com/CCX/ccx-sharing/blob/main/packages/component-react/integration.md#analytics
 * *
 * Analytics Data for ccx-comments
 * From https://git.corp.adobe.com/CCX/ccx-comments/blob/main/ONBOARDING.md#Analytics-Registration
 */
export const ACE_ANALYTICS_PLATFORMS = {
    mac: "mac",
    ios: "ios",
    win: "win",
    web: "web",
} as const;
export type AceAnalyticsPlatform =
    (typeof ACE_ANALYTICS_PLATFORMS)[keyof typeof ACE_ANALYTICS_PLATFORMS];
export interface ShareSheetAnalyticsDataSource {
    // eslint-disable-next-line camelcase -- External API
    client_id: string; // Sunrise client Id
    name: string; // SAP codes for Sunrise. 'SUNR' is just an example.
    os_version?: string; // Current platform OS version
    platform: AceAnalyticsPlatform;
    version: string; // (TODO: Verify)
}

interface CommentsAnalyticsDataSource {
    // eslint-disable-next-line camelcase -- External API
    client_id: string;
    name: string;
    platform: AceAnalyticsPlatform;
    device?: string;
}

export interface ShareSheetAnalyticsDataEvent {
    // eslint-disable-next-line camelcase -- External API
    device_guid?: string;
    category: "WEB" | "TABLET" | "DESKTOP";
}

interface CommentsAnalyticsDataEvent {
    // eslint-disable-next-line camelcase -- External API
    session_guid: string;
    workflow?: "space" | "non-space";
}

/**
 * Analytics data type shared by ccx-sharing and ccx-comments
 */
interface AnalyticsDataUI {
    // eslint-disable-next-line camelcase -- External API
    view_type: string;
}

export interface AnalyticsData {
    source: ShareSheetAnalyticsDataSource | CommentsAnalyticsDataSource;
    event: ShareSheetAnalyticsDataEvent | CommentsAnalyticsDataEvent;
    ui?: AnalyticsDataUI;
}

/**
 * ACE ABTest Config
 */
export interface ABTestConfig {
    floodgateClientId: string;
    floodgateReleaseGroup: string;
    floodgateReleaseFeature: string;
    sophiaSurfaceId: string;
}

/**
 * ShareSheet Asset Data format.
 * Visit these links for more detail:
 * https://git.corp.adobe.com/CCX/ccx-sharing/tree/main/packages/component-react#assetdata-format
 * https://git.corp.adobe.com/CCX/ccx-sharing/blob/main/packages/component-react/README.md#assetdata-prop-details
 */
export interface ShareSheetAssetData {
    id: string;
    resourceId?: string;
    resourceViewUrl?: string;
    isReadOnly?: boolean;
    isLoading?: boolean;
    isSaving?: boolean;
    isShareForReviewDisabled?: boolean;
    mimeType?: string;
    modifiedDate?: number;
    name?: string;
    type?: string;
    useProductViewUrl?: boolean;
}

/**
 * ShareSheet Space data for AssetData 2.0
 */
export interface ShareSheetSpaceData {
    assetId: string;
    color: string;
    name: string;
}

interface EmailNotificationSharing {
    viewer?: string;
    editor?: string;
}

export interface EmailNotificationAcceptance {
    viewer?: string;
    editor?: string;
}

export interface EmailNotificationAccessRequest {
    grant?: string;
    reject?: string;
}

export interface EmailNotification {
    sharing?: EmailNotificationSharing;
    acceptance?: EmailNotificationAcceptance;
    accessRequest?: EmailNotificationAccessRequest;
}

/**
 * Minimum required payload data for invitation callbacks:
 * onInviteFulfilled, onRemoveFulfilled
 */
export interface ShareSheetRecipientData {
    id: string;
    email: string;
    statusCode: number; // indicate the result of the invitation
}

export interface ShareSheetCallbackData {
    context: { assetData: ShareSheetAssetData };
    recipients?: ShareSheetRecipientData[]; // onInviteFulfilled
    data?: ShareSheetRecipientData[]; // onRemoveFulfilled
}

/**
 * Preferred format: AssetData 2.0 format.
 * Correspond to CCX.Asset
 */
export interface ShareSheetAsset {
    assetId: string; // for AssetData 2.0 replacing id
    name: string;
    resourceId: string; // should be an id-based access url. This is to access a resource in CC Storage (CCStorageUrl)
    category?: string; // analytics
    extension?: string; // analytics
    emailNotification?: EmailNotification;
    canComment?: boolean;
    // targetUrl used by AssetData 2.0 replacing resourceViewUrl.
    // If not passed in, it is constructed from resourceId based on workflow, assetType, mimeType...
    targetUrl?: string;
    groupTargetUrl?: string;
    isAncestorShared?: boolean;
    isReadOnly?: boolean;
    isLoading?: boolean;
    isSaving?: boolean;
    mimeType?: string;
    modifiedDate?: number;
    size?: number;
    roles?: Array<string | object>;
    space?: ShareSheetSpaceData;
    team?: string;
    type?: string;
    useProductViewUrl?: boolean; // PS and AI only
}

/**
 * IMS data for share for review.
 * Used in ccx-comments and ccx-sharing.
 */
export interface CCACImsData {
    id: string;
    token: string;
    isPaid?: boolean;
    countryCode?: string;
    email?: string;
    name?: string;
    serviceCode?: string;
    serviceLevel?: string;
}

export interface StringKeyedObject {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Unspecified
    [key: string]: any;
}
export interface TeamData {
    name: string;
}

export interface WizardDataFormat {
    enable?: boolean;
    onSkip?: VoidFunction;
    currentStep?: number;
    totalSteps?: number;
    showFolderInfoBox?: boolean;
}

/**
 * ShareSheet view configuration options
 * From https://git.corp.adobe.com/CCX/ccx-sharing/blob/main/packages/component-web/README.md#configurable-options
 * See this link for additional information regarding ShareDialog:
 * https://git.corp.adobe.com/CCX/ccx-sharing/blob/main/packages/component-web/README.md#cc-share-dialog
 */
export interface ShareSheetProviderProps {
    // Required props
    analyticsData: AnalyticsData;
    apiKey: string;
    assetData: ShareSheetAssetData | ShareSheetAsset;
    autoConfigure?: boolean;
    idpClientId: string;
    imsData: CCACImsData;

    // Optional props
    abTestConfig?: ABTestConfig;
    acplModel?: object; // used only in Artifact dialog
    badgeS4R?: "beta" | "new" | "none"; // used only in Artifact dialog

    analyticsEnabled?: boolean;
    compactLayout?: boolean;
    enableCuration?: boolean; // artifact dialog only
    enableFacepile?: boolean; // for share dialog
    environment?: string; // default is "stage"
    externalNotification?: boolean;
    features?: StringKeyedObject;
    inviteApiVersion?: string;
    linkPrefix?: string;
    linksApiVersion?: string;
    isAncestorShared?: boolean;
    isPreloaded?: boolean;
    locale?: string; // default is "en_US"
    openedDialog?: string;
    reCaptchaSiteKey?: string;
    requestAccessRole?: "editor" | "editorShare" | "viewer" | "viewerShare";
    showFullWidthCopyButton?: boolean;
    showSwitchFooter?: boolean;
    showTour?: boolean;
    spaceDocumentOneOffInvites?: boolean;
    suppressEmailNotification?: boolean;
    team?: TeamData;
    theme?: string; // default is "light"
    themeType?: "spectrum" | "express"; // default is "spectrum"
    typekitId?: string; // default is "pwg1jsp"
    wizardData?: WizardDataFormat;
}

/**
 * ShareSheet invite dialog web component attributes.
 * Unless the asset is a folder, both inviteCreateAcceptanceRequired and inviteCreateUseMounts must be false.
 */
export interface ShareDialogBooleanAttributes {
    [key: string]: boolean;
    disablelinkaccess: boolean;
    disablelinkcopy: boolean;
    disablepublicaccess: boolean;
    displaylinkcopy: boolean;
    hideclosebutton: boolean;
    hideheader: boolean;
    invitecreateacceptancerequired: boolean;
    invitecreateusemounts: boolean;
    invitemessageenabled: boolean;
    readonlylinkaccess: boolean;
    sendmessagerequestevent: boolean;
    showsettingsbutton: boolean;
    touchmode: boolean;
}

export interface ShareDialogStringAttributes {
    [key: string]: string;
    assetid: string;
    assetname: string;
    assettype: string;
    emailnotification: string;
    messageconfig: string;
    notificationlinkurl: string;
    projectdata: string;
    roles: string;
    targeturl: string;
    title: string;
    wizarddata: string;
}

/**
 * Minimal Default share sheet invite dialog data
 */
export const DEFAULT_SHARE_INVITATION_DIALOG_DATA = {
    emailNotification: {
        sharing: {
            viewer: ASSET_INVITE_NOTIFICATION,
            editor: ASSET_INVITE_NOTIFICATION,
        },
        acceptance: {
            viewer: "cc_collab_invite_v2_viewer",
            editor: "cc_collab_invite_v2_editor",
        },
        accessRequest: {
            grant: "cc_collab_clouddoc_request_granted",
            reject: "cc_collab_clouddoc_request_denied",
        },
    },
    inviteCreateAcceptanceRequired: false,
    inviteCreateUseMounts: false,
};

export interface ValidNode {
    id: string;
    title: string;
    subTitle?: string;
}

// Determines suggestions for @mention
export type ShareStatus = "unshared" | "shared" | "orgShared" | "public";

/**
 * Asset data used by ccx-comments.
 * From https://git.corp.adobe.com/CCX/ccx-comments/tree/main/packages/ccx-comments#assetdata-prop-details
 */
export interface CommentsAssetData {
    id: string; // asset id.
    isEMS?: boolean; // determine if showing "owner" label in the commenting panel
    isSaving?: boolean;
    mimeType?: string;
    nodeId?: string; // active node id.
    ownerId: string;
    shareStatus: ShareStatus; // affects the @mention suggestion
    region?: string;
    validNodes: ValidNode[]; // valid nodes for this asset.
}

export interface AnnotationTypesEnabled {
    draw?: boolean;
    pin?: boolean;
    strikethrough?: boolean;
    insert?: boolean;
    replace?: boolean;
    highlight?: boolean;
}

export interface AnnotationCoordinates {
    noteX: number;
    noteY: number;
    subtype: string;
}

export type AnnotationTrigger =
    | "start"
    | "cancel"
    | "place"
    | "update"
    | "submit";

/** A subset of supported annotation types */
export type AnnotationType = "pin" | "draw" | "text";

export type CommentsEnvironment = "stage" | "prod";

export type GroupBy = "node" | "none";

export type CommentInteractionTriggerType =
    | "select"
    | "unselect"
    | "hover"
    | "unhover";

export interface CommentInteractionPayload {
    trigger: CommentInteractionTriggerType; // one of select, unselect, hover, unhover.
    id: string; // comment id.
    nodeId: string; // node id of the comment interacted with.
    sectionId?: string; // section id of the comment interacted with.
    selector?: string; // DOM query to select annotation vent.
    hasAnnotation: boolean; // indicates if comments contains an annotation.
}

export interface CommentSupplementalData {
    assetId: string; // asset id of the asset.
    sourceId: string; // asset id of the source asset for the shared artifact.
    nodeId?: string; // node id of the comment.
    sectionId?: string; // section id of the comment.
}

export interface AnnotatingChangePayload {
    color?: string;
    trigger: AnnotationTrigger;
    location: AnnotationCoordinates;
    type: AnnotationType;
}

/**
 * Comments provider props.
 * From https://git.corp.adobe.com/CCX/ccx-comments/blob/main/packages/ccx-comments/README.md#props-overview
 */
export interface CommentsProviderProps {
    // Required props
    analyticsData: AnalyticsData;
    appKey: string;
    appName: string;
    assetData: CommentsAssetData;
    imsData: CCACImsData;

    // Optional props
    abTestConfig?: ABTestConfig;
    annotationsEnabled?: boolean;
    annotationsMultiPageEnabled?: boolean;
    annotationTypes?: AnnotationTypesEnabled;
    compactLayout?: boolean;
    disableCommentCreation?: boolean;
    disableNetworkIndicator?: boolean;
    enableEmojiPicker?: boolean;
    enableReadUnread?: boolean;
    environment?: CommentsEnvironment;
    globalComments?: boolean;
    globalCommentsUnmapped?: boolean;
    globalCommentsViewAll?: boolean;
    groupBy?: GroupBy;
    hasReviewAccess?: boolean;
    latestMobileEnabled?: boolean;
    locale?: string;
    mentionsEnabled?: boolean;
    peopleGraphAPIKey?: string;
    pollForUpdates?: boolean;
    reCaptchaSiteKey?: string;
    reCaptchaEnabled?: boolean;
    richTextEntry?: boolean;
    spectrumUIScale?: string;
    theme?: string;

    // Callbacks
    onAnnotatingChange?: () => void;
    onNodeChange?: (
        nodeId: string,
        _sectionId?: string,
        supplementaryData?: CommentSupplementalData,
    ) => void;
    onCommentInteraction?: (
        payload: CommentInteractionPayload,
        supplementalData: CommentSupplementalData,
    ) => void;
}

/**
 * AnnotationsOverlay props https://git.corp.adobe.com/CCX/ccx-comments/tree/main/packages/ccx-comments#props-overview-1
 **/
export interface AnnotationsOverlayProps {
    disableInteraction?: boolean;
    maskTextOverride?: string;
    nodeId?: string;
    scaleFactorX?: number;
    scaleFactorY?: number;
    sectionId?: string;
    showHighlight?: boolean;
    useMask?: boolean;
}

/**
 * AnnotationsMask props https://git.corp.adobe.com/CCX/ccx-comments/blob/main/packages/ccx-comments/README.md#annotationsmask
 */
export interface AnnotationsMaskProps {
    maskTextOverride: string;
    hovered: boolean;
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { v4 } from "uuid";

import { useAppContext } from "./AppContext";
import { config } from "@src/config";
import type {
    IngestEventKey,
    IngestRequestEvent,
    IngestQueueEvent,
    IngestUserContext,
    IngestEventFields,
} from "@src/lib/analytics/AnalyticsConstants";
import { IngestEventsMap } from "@src/lib/analytics/AnalyticsConstants";

import type { PropsWithChildren } from "react";

const sessionGuid = v4();

interface AnalyticsContextProps {
    logIngestEvent: (
        eventKey: IngestEventKey,
        eventFields?: IngestEventFields,
    ) => Promise<void>;
}

export const AnalyticsContext = createContext<AnalyticsContextProps>(
    {} as AnalyticsContextProps,
);

export const AnalyticsContextProvider = ({ children }: PropsWithChildren) => {
    const { acp, ims, logger } = useAppContext();

    const eventQueue = useRef<IngestQueueEvent[]>([]);
    const [eventsPending, setEventsPending] = useState<number>(0);

    const userContext: IngestUserContext | undefined = useMemo(() => {
        const userId = ims.userProfile?.userId?.split("@", 1)[0] ?? "";
        return ims.userProfile
            ? {
                  "event.user_guid": userId,
                  "event.org_guid": ims.userProfile.ownerOrg ?? "",
              }
            : undefined;
    }, [ims.userProfile]);

    const tokenSessionId = useRef("");

    const logLoginEvent = async () => {
        if (ims.sid === tokenSessionId.current) {
            // A login for this token has occurred or is in progress.
            return;
        } else {
            // Prevent a race where useEffect was being triggered twice in quick
            // succession, resulting in two login events.
            tokenSessionId.current = ims.sid ?? "";
        }
        const localStorageKey = `loginTokenSid_${
            ims.userProfile?.userId ?? ""
        }`;
        const loggedTokenSid =
            window.localStorage.getItem(localStorageKey) ?? "";
        if (loggedTokenSid === ims.sid) {
            // A login for this token has been recorded, possibly in a different
            // browser session or prior to a refresh.
            return;
        }

        if (ims.sid) {
            window.localStorage.setItem(localStorageKey, ims.sid);
        }
        logIngestEvent("login");
    };

    useEffect(() => {
        if (
            ims.authState === "authorized" &&
            ims.accessToken &&
            ims.userProfile?.userId &&
            window.document.referrer === config.imsReferrerUrl
        ) {
            logLoginEvent();
        }
    }, [ims.accessToken, ims.authState, ims.userProfile]);

    const convertQueueEventToRequestEvent = useCallback(
        (event: IngestQueueEvent) => {
            return {
                ...event,
                project: config.ingestProjectKey,
                environment: config.adobeEnv,
                ingesttype: "dunamis",
                data: {
                    ...event.data,
                    ...userContext,
                    "event.category": "WEB",
                    "event.language": navigator.language,
                    "session.guid": sessionGuid,
                    "source.client_id": config.imsClientId,
                    "source.name": "Project Sunrise",
                    "source.platform": "Web",
                },
            } as IngestRequestEvent;
        },
        [userContext],
    );

    const sendIngestEvents = async () => {
        const bodyObj = {
            events: eventQueue.current.map((event) =>
                convertQueueEventToRequestEvent(event),
            ),
        };
        const body = JSON.stringify(bodyObj);
        const queued = [...eventQueue.current];
        eventQueue.current = [];

        try {
            const { authorization } = await ims.getAuthHeaders();
            await fetch(config.ingestEndpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization,
                    "x-api-key": config.ingestApiKey,
                },
                body,
            });
        } catch (err) {
            logger.logError({ errorCode: "2170" });
            // Failed to send, put them back in the queue.
            eventQueue.current = [...eventQueue.current, ...queued];
        } finally {
            setEventsPending(eventQueue.current.length);
        }
    };

    useEffect(() => {
        if (!userContext) {
            return;
        }
        if (eventsPending > 0 && eventQueue.current.length) {
            // Send what's in the queue.
            sendIngestEvents();
        }
    }, [eventsPending, userContext]);

    const logIngestEvent = async (
        eventKey: IngestEventKey,
        eventFields?: IngestEventFields,
        time: string = new Date().toISOString(),
    ) => {
        const eventData = IngestEventsMap[eventKey];
        const event: IngestQueueEvent = {
            time,
            data: {
                "event.guid": v4(),
                "event.url": location.href,
                "event.referrer": window.document.referrer,
                "event.dts_start": time,
                ...eventData,
                ...eventFields,
            },
        };

        eventQueue.current.push(event);
        setEventsPending(eventQueue.current.length);
    };

    const logAppStartupEvents = async () => {
        const startupTime = new Date().toISOString();
        logIngestEvent("appStartup", undefined, startupTime);

        const assetsStats = await acp.getAssetsDirStats();
        logIngestEvent("storageReportAssets", {
            "ui.path_to_access": "startup",
            "3di.measures": {
                size_assets_dir: assetsStats.size,
                num_assets: assetsStats.count,
                size_median_asset: assetsStats.medianSize,
            },
        });

        const projectsStats = await acp.getProjectsDirStats();
        logIngestEvent("storageReportProjects", {
            "ui.path_to_access": "startup",
            "3di.measures": {
                size_projects_dir: projectsStats.size,
                num_projects: projectsStats.count,
                size_median_project: projectsStats.medianSize,
            },
        });
    };

    useEffect(() => {
        logAppStartupEvents().catch(() => {
            logger.logError({ errorCode: "2172" });
        });
        return () => {
            if (eventQueue.current.length > 0) {
                logger.logError({ errorCode: "2171" });
            }
        };
    }, []);

    return (
        <AnalyticsContext.Provider
            value={{
                logIngestEvent,
            }}>
            {children}
        </AnalyticsContext.Provider>
    );
};

export const useAnalyticsContext = () => {
    const context = useContext(AnalyticsContext);
    if (!context) {
        throw new Error(
            "useAnalyticsContext must be used within a AnalyticsContextProvider.",
        );
    }
    return context;
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useTranslation } from "react-i18next";
import { Shot } from "@src/contexts/RpcContext";

export const useFormatShotCameraName = () => {
    const { t } = useTranslation(["studio", "common"]);

    function formatShotCameraName(
        camera?: string,
        cameraName?: string,
        type?: Shot["type"],
    ) {
        if (type === "SHOT_2D") {
            return cameraName ?? t(`studio:${camera}`);
        }
        return t("output.web.player");
    }

    return { formatShotCameraName };
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, ToggleButton } from "@adobe/react-spectrum";
import { usePress } from "@react-aria/interactions";
import ChevronDown from "@spectrum-icons/workflow/ChevronDown";
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft";
import ChevronRight from "@spectrum-icons/workflow/ChevronRight";
import React, { useState } from "react";

import type { SpectrumActionButtonProps } from "@react-types/button";
import type { FC } from "react";

interface DetailsDisclosureProps {
    openByDefault: boolean;
    orientation: string;
    children: [React.ReactNode, React.ReactNode];
}

export const DetailsDisclosure: FC<
    DetailsDisclosureProps & SpectrumActionButtonProps
> = ({ openByDefault, orientation, children: [summaryArea, contentArea] }) => {
    const [isOpen, setIsOpen] = useState(openByDefault);

    // Use RS onPress so that this component plays well with nested RS components.
    const { pressProps } = usePress({
        onPress: () => {
            setIsOpen(!isOpen);
        },
        preventFocusOnPress: true,
    });

    return (
        /** Couldn't find a good component from react-spectrum for a complex disclosure,
        so I rolled my own with the details element: 
        https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details 
        Another option is the v2 Accordion, but I had some doubts. v3 Accordion is not released yet.*/
        <details open={isOpen}>
            <summary
                {...pressProps}
                style={{ listStyle: "none" }}
                onClick={(e) => e.preventDefault()}>
                {
                    <Flex
                        alignItems="center"
                        justifyContent={
                            orientation === "right" ? "space-between" : "start"
                        }>
                        {orientation === "right" && summaryArea}
                        <ToggleButton
                            isQuiet
                            isSelected={isOpen}
                            onChange={setIsOpen}
                            aria-label="Open health check section">
                            {isOpen ? (
                                <ChevronDown size="S" marginEnd="size-25" />
                            ) : orientation === "right" ? (
                                <ChevronLeft size="S" marginEnd="size-25" />
                            ) : (
                                <ChevronRight size="S" marginEnd="size-25" />
                            )}
                        </ToggleButton>
                        {orientation === "left" && summaryArea}
                    </Flex>
                }
            </summary>
            {contentArea}
        </details>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, Text, View } from "@adobe/react-spectrum";
import Alert from "@spectrum-icons/workflow/Alert";
import Info from "@spectrum-icons/workflow/Info";
import React from "react";

import type { FC, PropsWithChildren } from "react";
interface Props {
    description: string;
    title: string;
    type: "warning" | "info";
}

export const MessagesWell: FC<PropsWithChildren<Props>> = ({
    description,
    title,
    type,
    children,
}) => {
    return (
        <View
            backgroundColor="gray-50"
            padding="size-100"
            marginTop="size-200"
            borderRadius="large">
            <Flex
                alignItems="center"
                columnGap="size-100"
                marginStart="size-50"
                marginY="size-100">
                {type === "info" ? (
                    <Info size="S" color="informative" />
                ) : (
                    <Alert size="S" color="notice" />
                )}
                <Heading level={4}>{title}</Heading>
            </Flex>
            <View marginStart="size-400" marginBottom="size-100">
                <Text>{description}</Text>
            </View>
            {children}
        </View>
    );
};

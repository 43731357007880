/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import FileSaver from "file-saver";
import JSZip from "jszip";

export interface FolderConfig {
    fileName: string;
    children: DownloadZipConfig[];
}

export interface BlobConfig {
    blobData: Promise<globalThis.Blob | undefined>;
    fileName: string;
}

export interface DownloadConfig {
    url: string;
    fileName: string;
}

export interface SaveConfig {
    fileName: string;
    data: string;
}

export type DownloadZipConfig =
    | BlobConfig
    | DownloadConfig
    | SaveConfig
    | FolderConfig;

interface InternalFolderConfig {
    fileName: string;
    children: InternalConfig[];
}

interface InternalFileConfig {
    fileName: string;
    data?: globalThis.Blob | Promise<ArrayBuffer> | string;
    isBinary?: boolean;
}

type InternalConfig = InternalFolderConfig | InternalFileConfig;

export interface DownloadZipProgress {
    state: string;
    downloadProgress: number;
}

export type DownloadZipState =
    | "downloading"
    | "generating"
    | "saving"
    | "completed";

export type DownloadError = "blobError" | "fetchError" | "folderError";

export type DownloadZipOptions = {
    errorPrompt?: string;
    onProgress?: (progress: DownloadZipProgress) => void;
    logError?: (errorType: DownloadError) => void;
};

export async function downloadZip(
    zipName: string,
    configs: DownloadZipConfig[],
    errorFiles: string[] = [],
    options?: DownloadZipOptions,
) {
    if (configs.length === 0) {
        throw new Error("No configs provided to create zip.");
    }
    const zip = new JSZip();
    let state: DownloadZipState = "downloading";
    let downloadProgress = 0;
    let completedSteps = 0;
    const countSteps: (
        config: DownloadZipConfig | DownloadZipConfig[],
    ) => number = (config) => {
        if (config instanceof Array) {
            return config.reduce(
                (prev, current) => prev + countSteps(current),
                0,
            );
        }
        if ("children" in config) {
            return countSteps(config.children);
        }
        return 1;
    };
    const totalSteps = countSteps(configs) * 2; // One step to download, one step to stream into zip

    function updateProgress(
        stateUpdate: DownloadZipState,
        progressUpdate: number,
    ) {
        state = stateUpdate;
        downloadProgress = progressUpdate;
        options?.onProgress?.({ state, downloadProgress });
    }

    const resolveConfig: (
        config: DownloadZipConfig,
    ) => Promise<InternalConfig> = async (config) => {
        if ("children" in config) {
            // Any errors in resolveConfig are caught, so Promise.all is desired
            return Promise.all(
                config.children.map(async (child) => resolveConfig(child)),
            ).then((resolvedChildren) => {
                return {
                    fileName: config.fileName,
                    children: resolvedChildren,
                };
            });
        }
        let resolvedConfig: InternalFileConfig;
        if ("url" in config) {
            try {
                const req = await window.fetch(config.url, {
                    cache: "no-cache",
                });
                resolvedConfig = {
                    fileName: config.fileName,
                    data: req.arrayBuffer(),
                    isBinary: true,
                };
            } catch {
                options?.logError?.("fetchError");
                resolvedConfig = { fileName: config.fileName };
            }
        } else if ("blobData" in config) {
            try {
                const blob = await config.blobData;
                if (!blob) {
                    throw new Error("Could not get blob for asset");
                }
                resolvedConfig = { fileName: config.fileName, data: blob };
            } catch {
                options?.logError?.("blobError");
                resolvedConfig = { fileName: config.fileName };
            }
        } else {
            resolvedConfig = config;
        }
        updateProgress(state, ++completedSteps / totalSteps);
        return resolvedConfig;
    };

    const addConfigToZip: (
        config: InternalConfig,
        parent: JSZip,
        parentPath: string,
    ) => void = (config, parent, parentPath) => {
        if ("children" in config) {
            const folder = parent.folder(config.fileName);
            if (!folder) {
                options?.logError?.("folderError");
                throw new Error(`could not create folder ${config.fileName}`);
            }
            config.children.forEach((child) =>
                addConfigToZip(
                    child,
                    folder,
                    `${parentPath}/${config.fileName}`,
                ),
            );
            return;
        } else if (!config.data) {
            errorFiles.push(`${parent.name}/${config.fileName}`);
            updateProgress(state, ++completedSteps / totalSteps);
            return;
        }
        try {
            parent.file(
                config.fileName,
                config.data,
                config.isBinary ? { binary: true } : undefined,
            );
        } catch {
            errorFiles.push(`${parentPath}/${config.fileName}`);
        }
        updateProgress(state, ++completedSteps / totalSteps);
    };

    // Any errors in the map function are caught, so Promise.all is desired
    const zipConfigs = await Promise.all(
        configs.map(
            async (config): Promise<InternalConfig> => resolveConfig(config),
        ),
    );

    zipConfigs.forEach((config) => addConfigToZip(config, zip, ""));
    if (errorFiles.length) {
        zip.file(
            "errors.txt",
            `${options?.errorPrompt ?? ""}\n${errorFiles.join("\n")}`,
        );
    }
    updateProgress("generating", downloadProgress);
    return zip.generateAsync({ type: "blob" }).then((blob) => {
        updateProgress("saving", downloadProgress);
        FileSaver.saveAs(blob, zipName);
        updateProgress("completed", downloadProgress);
    });
}

export async function downloadFile(config: DownloadConfig | BlobConfig) {
    let fileBlob: globalThis.Blob;
    if ("url" in config) {
        const req = await window.fetch(config.url);
        fileBlob = await req.blob();
    } else {
        const blob = await config.blobData;
        if (!blob) {
            throw new Error("Could not download blob");
        }
        fileBlob = blob;
    }

    FileSaver.saveAs(fileBlob, config.fileName);
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useState } from "react";

import type { LoggerType } from "../types/logger";

export type PPSProfile = {
    displayName: string;
    avatarUrl: string;
};

export function useAvatar(
    clientId: string,
    isProd: boolean,
    logger: LoggerType = console,
    size = "50",
) {
    const defaultAvatar = "https://i.imgur.com/kJOwAdv.png";
    const [avatarUrl, setAvatarUrl] = useState<string>(defaultAvatar);

    const getPPSProfile = async (userId: string, accessToken: string) => {
        if (!userId) return;
        const response = await fetch(
            `https://${
                isProd ? "pps.adobe.io" : "pps-stage.adobe.io"
            }/api/profile/${userId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    "x-api-key": clientId,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            },
        );
        if (!response.ok) {
            const statusCode = response.status;
            const errorText = await response
                .text()
                .catch((error) => logger.error("getUserAvatar", { error }));
            const msg = `Failed to fetch profile data for user ${userId}`;
            logger.error(msg, { statusCode, errorText });
            return;
        } else {
            const profileData = JSON.parse(await response.text());
            if (!profileData) {
                logger.error(
                    `no user profile data for the requested user ${userId}`,
                );
            } else if (profileData.id && profileData.id !== userId) {
                logger.error(
                    `user profile id ${profileData.id} does not match the requested user ${userId}`,
                );
            } else if (response.status > 200 && response.status < 300) {
                logger.info(
                    `status (${response.status}: ${response.statusText} for the requested user ${userId}`,
                );
            }
            const ppsProfile: PPSProfile = {
                displayName: profileData.display_name,
                avatarUrl:
                    (profileData.images && profileData.images[size]) ||
                    defaultAvatar,
            };
            return ppsProfile;
        }
    };

    const getUserAvatar = async (userId: string, accessToken: string) => {
        const ppsProfile = await getPPSProfile(userId, accessToken);
        if (ppsProfile) setAvatarUrl(ppsProfile.avatarUrl);
    };

    return { getPPSProfile, getUserAvatar, avatarUrl };
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { TaskResponse } from "../requests/types/TaskResponse";
import { fetchWorkfrontTasksAssignedToMe } from "../requests/fetchTasksAssignedToMe";
import { TasksAssignedToMe, Task } from "../../../types/TaskAssignedToMe";
import { TaskStatus } from "../../../types/TaskStatus";
import { fetchWorkfrontTaskPossibleStatuses } from "../requests/fetchTaskPossibleStatuses";
import { AbstractStatusResponse } from "../requests/types/AbstractStatusResponse";
import { getTaskStatus } from "./private/util";

export const aggregateResponse = (
    taskList: TaskResponse[],
    possibleTaskStatuses: AbstractStatusResponse[],
): TasksAssignedToMe => {
    return {
        tasks: taskList.map((task: TaskResponse): Task => {
            const assignedTo = task.assignedTo
                ? {
                      ID: task.assignedTo.ID,
                      name: task.assignedTo.name,
                  }
                : null;
            const taskStatus: TaskStatus = getTaskStatus(task.status, possibleTaskStatuses);
            return {
                ID: task.ID,
                name: task.name,
                status: taskStatus,
                description: task.description,
                plannedStartDate: task.plannedStartDate,
                plannedCompletionDate: task.plannedCompletionDate,
                percentComplete: task.percentComplete,
                assignedTo: assignedTo,
                projectName: task.project.name,
                parentId: task.parentID,
            };
        }),
        possibleTaskStatuses,
    };
};

export const fetchTasksAssignedToMe = async (wfParams: {
    imsToken: string;
    wfInstanceUrl: string;
}): Promise<any> => {
    const taskListPromise: Promise<TaskResponse[]> = fetchWorkfrontTasksAssignedToMe(wfParams);
    const taskStatusesPromise: Promise<AbstractStatusResponse[]> =
        fetchWorkfrontTaskPossibleStatuses(wfParams);
    const [taskList, possibleTaskStatuses] = await Promise.all([
        taskListPromise,
        taskStatusesPromise,
    ]);

    return aggregateResponse(taskList, possibleTaskStatuses);
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

// If this interface is updated, "hotSpotProperties" in Studio's index.tsx must be correspondingly updated as well
export interface HotSpot {
    id: string;
    title?: string;
    text?: string;
    pin?: SerializedPin;
}

export interface ShotOverrides {
    camera: CameraOverride;
}

export interface CameraOverride {
    translation: SerializedVector3;
    target: SerializedVector3;
}

export type SerializedVector3 = [number, number, number];

export interface SerializedPin {
    id: string;
    cameraPosition: SerializedVector3;
    pinWorldPosition: SerializedVector3;
    pinNormal: SerializedVector3;
    modelPath?: string[];
    pinModelLocalPosition?: SerializedVector3;
    meta?: { [key: string]: unknown };
}

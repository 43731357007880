/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useCallback, useEffect, useState } from "react";

import { useAppContext } from "@src/contexts/AppContext";

type DoNotShowInitializer = {
    readonly key: string;
};

type DoNotShowEntry = {
    state: boolean;
    saveFn: () => void;
    getFn: () => boolean | undefined;
};

type DoNotShowExportObj = {
    [key: string]: DoNotShowEntry;
};

/**
 * Records user preference to hide specific items in the UI.
 * To add a new item, add an entry to DO_NOT_SHOW_DECLARATIONS below. Entries
 * are recorded in local storage and are specific to an IMS user. By default,
 * an entry is recorded with a "true" value.
 */
export const useDoNotShowMeAgain = () => {
    const { ims } = useAppContext();

    const initDoNotShowEntry = ({ key }: DoNotShowInitializer) => {
        const doNotShow = useCallback(() => {
            if (!ims.userProfile?.userId) {
                // Do not display until we definitively have a user profile.
                return ims.authState === "verifying";
            }

            const item =
                window.localStorage.getItem(
                    `${key}_${ims.userProfile.userId}`,
                ) ?? "";

            return item === "true";
        }, [ims.userProfile?.userId]);

        const [state, setState] = useState<boolean>(() => doNotShow());

        useEffect(() => {
            setState(doNotShow());
        }, [ims.userProfile?.userId]);

        const saveFn = useCallback(() => {
            if (!ims.userProfile?.userId) {
                return;
            }
            setState(true);
            window.localStorage.setItem(
                `${key}_${ims.userProfile.userId}`,
                "true",
            );
        }, [ims.userProfile?.userId]);

        const getFn = useCallback(() => {
            if (!ims.userProfile?.userId) {
                return undefined;
            }
            const item =
                window.localStorage.getItem(
                    `${key}_${ims.userProfile.userId}`,
                ) ?? "";

            return item === "true";
        }, [ims.userProfile?.userId]);

        return {
            state,
            saveFn,
            getFn,
        } as DoNotShowEntry;
    };

    // Try to keep these in alphabetical order.
    const DO_NOT_SHOW_DECLARATIONS: {
        [key: string]: DoNotShowInitializer;
    } = {
        doNotShowAssetApprovalConfirmation: {
            key: "doNotShowAssetApprovalConfirmation",
        },
        doNotShowChromeIsPreferredBrowser: {
            key: "doNotShowChromeIsPreferredBrowser",
        },
        doNotShowProjectApprovalConfirmation: {
            key: "doNotShowProjectApprovalConfirmation",
        },
        doNotShowProjectDownloadDialog: {
            key: "doNotShowProjectDownloadDialog",
        },
        doNotShowProjectRendersAcknowledgment: {
            key: "doNotShowProjectRendersAcknowledgment",
        },
    } as const;

    const exportObj = {} as DoNotShowExportObj;
    Object.entries(DO_NOT_SHOW_DECLARATIONS).forEach(([key, val]) => {
        exportObj[key] = initDoNotShowEntry(val);
    });

    return exportObj;
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, View } from "@adobe/react-spectrum";
import {
    getRenderSettings,
    getTemplate,
    WEBVIEWER_VARIANT,
} from "@shared/common";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useOpenShot } from "../../../projects/hooks/useOpenShot";
import { DetailViewType } from "../common/DetailViewTabs";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";
import { rpc } from "@src/contexts/RpcContext";
import { DetailViewContent } from "@src/interfaces/detail/components/common/DetailViewContent";

import type { DisplaySize } from "./DisplaySettingsPicker";
import type { ArtifactData, TemplateEntry } from "@shared/types";
import type { FC } from "react";

interface Props {
    projectId: string;
    shotId: string;
    ownerId: string;
    displaySize: DisplaySize;
    reviewAccess?: boolean;
}

export const ProjectOutputDetailContent: FC<Props> = ({
    projectId,
    shotId,
    ownerId,
    displaySize,
    reviewAccess,
}) => {
    const { t } = useTranslation("studio");

    const { openShotDetail } = useOpenShot(projectId);
    const artifactRefs = [useRef<HTMLDivElement>(null)];

    const { data: shot } = rpc.shots.getShot.useQuery(shotId);
    const { data: shotModel } = rpc.shots.getShotModel.useQuery(shotId);
    const { data: shotRender } = rpc.shots.getShotRender.useQuery(shotId);
    const { data: assetShots } = rpc.shots.getSiblingShots.useQuery({
        projectId,
        shotId,
    });

    const navigateHorizontal = useCallback(
        (direction: number) => {
            if (!assetShots) return;
            const currentIndex = assetShots.findIndex((assetShot) => {
                return assetShot.id === shot?.id;
            });
            const newIndex =
                currentIndex >= 0
                    ? (currentIndex + assetShots.length + direction) %
                      assetShots.length
                    : 0;
            openShotDetail(assetShots[newIndex].id, reviewAccess, true);
        },
        [assetShots, assetShots?.length],
    );

    let renderSettings = undefined;
    let iblFilename = undefined;
    if (shot?.template) {
        const template = getTemplate(shot.template).template;
        renderSettings = getRenderSettings(
            template as unknown as TemplateEntry,
            WEBVIEWER_VARIANT,
        );

        iblFilename = template.iblFilename;
    }

    let models: ArtifactData[] = [];
    if (shotModel) {
        const model = {
            componentId: shotModel.componentId,
            jobCompleted: shotModel.jobCompleted,
            jobFailed: shotModel.jobFailed,
            jobErrors: shotModel.jobErrors,
            type: "web",
            url: shotModel.url,
            iblFilename: iblFilename,
            aspectRatio: shot?.cameraAspect,
            isShot: true,
            renderSettings,
        } as ArtifactData;
        models = [model];
    }

    let renders: ArtifactData[] = [];

    if (shotRender && shot) {
        renders = [
            {
                jobCompleted: shotRender.jobCompleted,
                jobFailed: shotRender.jobFailed,
                jobErrors: shotRender.jobErrors,
                type: t(`${shot.camera}`),
                url: shotRender.url,
                aspectRatio: shot.cameraAspect,
                isShot: true,
                isRendition: shotRender.isRendition,
                resolution: shotRender.resolution,
            } as ArtifactData,
        ];
    }

    const is3D = shot?.type === "SHOT_3D";
    const viewType = is3D ? DetailViewType.views3D : DetailViewType.views2D;
    const { logIngestEvent } = useAnalyticsContext();

    useEffect(() => {
        logIngestEvent("viewAssetDetailsSelect", {
            "event.workflow": "PROJECTS",
            "event.subtype": viewType,
            "event.context_guid": projectId,
            "content.id": shotId,
        });
    }, [viewType]);

    return (
        <View height="100%" width="100%" backgroundColor="gray-75">
            {shot?.compositeId && assetShots && ownerId && (
                <Flex
                    direction="row"
                    justifyContent="space-between"
                    height="100%">
                    <DetailViewContent
                        id={shot.id}
                        compositeId={shot.compositeId}
                        ownerId={ownerId}
                        models={models}
                        renders={renders}
                        viewType={viewType as DetailViewType}
                        viewOrientation={"horizontal"}
                        artifactRefs={artifactRefs}
                        enableLimitedZoom={true} // Zoom is limited in project view
                        displaySize={displaySize}
                        navigateHorizontal={navigateHorizontal}
                    />
                </Flex>
            )}
        </View>
    );
};

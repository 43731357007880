/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { TaskResponse } from "../../requests/types/TaskResponse";
import { fetchAvatarImageAsBlob } from "../../requests/fetchAvatarImageAsBlob";
import { fetchWorkfrontTaskPossibleStatuses } from "../../requests/fetchTaskPossibleStatuses";
import { CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX } from "../../constants";
import { AbstractStatusResponse } from "../../requests/types/AbstractStatusResponse";
import { CreateTaskParameters } from "../../requests/types/CreateTaskParameters";
import { createWorkfrontTask } from "../../requests/createTask";
import { getTaskStatus } from "./util";
import { fetchRequiredCustomFormIdContentFragment } from "./fetchRequiredCustomFormIdContentFragment";
import { SubTaskDetails } from "../../../../types/SubTaskDetails";
import { WorkfrontParams } from "../../../../types/wfParams";
import { TaskStatus } from "../../../../types/TaskStatus";

export const createSubTaskAbstract = async (
    wfParams: WorkfrontParams,
    createTaskParameters: CreateTaskParameters,
): Promise<SubTaskDetails> => {
    createTaskParameters.categoryID = await fetchRequiredCustomFormIdContentFragment(wfParams);
    const taskPromise: Promise<TaskResponse> = createWorkfrontTask(wfParams, createTaskParameters);
    const taskStatusesPromise: Promise<AbstractStatusResponse[]> =
        fetchWorkfrontTaskPossibleStatuses(wfParams);
    const [subTask, possibleTaskStatuses] = await Promise.all([taskPromise, taskStatusesPromise]);

    const assignedAvatar: Blob | null =
        !!subTask.assignedTo && !!subTask.assignedTo.avatarDownloadURL
            ? await fetchAvatarImageAsBlob(wfParams, subTask.assignedTo.avatarDownloadURL)
            : null;

    const taskStatus: TaskStatus = getTaskStatus(subTask.status, possibleTaskStatuses);

    const assignedTo = subTask.assignedTo
        ? {
              ID: subTask.assignedTo.ID,
              name: subTask.assignedTo.name,
              title: subTask.assignedTo.title,
              avatar: assignedAvatar,
          }
        : null;
    return {
        subTask: {
            ID: subTask.ID,
            name: subTask.name,
            description: subTask.description,
            status: taskStatus,
            assignedTo,
            contentFragment: subTask[CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX] ?? undefined,
            plannedCompletionDate: subTask.plannedCompletionDate,
        },
    };
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

export function useSessionState<T>(key: string, initialState: T) {
    function getSavedState<T>(savedKey: string) {
        const saved = sessionStorage.getItem(savedKey);
        if (saved) {
            return JSON.parse(saved) as T;
        }
        return initialState;
    }

    function setSavedState(newState: T) {
        sessionStorage.setItem(key, JSON.stringify(newState));
    }

    const [sessionState, setSessionState] = useState<T>(getSavedState(key));

    useEffect(() => {
        setSessionState(getSavedState(key));
    }, [key]);

    useEffect(() => {
        setSavedState(sessionState);
    }, [sessionState]);

    return {
        sessionState, setSessionState
    }
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { createHeaders, getWorkfrontApiSearchUrl } from "../util";
import { CONTENT_FRAGMENT_CUSTOM_FIELD_NAME, WF_OBJECT_CODE_CUSTOM_FIELD } from "../constants";
import { CustomFieldListResponse, CustomFieldResponse } from "./types/CustomFieldResponse";
import { WorkfrontParams } from "../../../types/wfParams";

const WF_CUSTOM_FIELD_FIELDS_TO_BE_LOADED = `name,ID`;

export const fetchRequiredCustomFieldContentFragment = async function (
    wfParams: WorkfrontParams,
): Promise<CustomFieldResponse[]> {
    try {
        const headers = createHeaders(wfParams.imsToken);

        const WF_FETCH_CUSTOM_FIELDS_URL = `${getWorkfrontApiSearchUrl({
            objCode: WF_OBJECT_CODE_CUSTOM_FIELD,
            wfInstanceUrl: wfParams.wfInstanceUrl,
        })}?fields=${WF_CUSTOM_FIELD_FIELDS_TO_BE_LOADED}
            &name=${CONTENT_FRAGMENT_CUSTOM_FIELD_NAME}`;

        const res = await fetch(WF_FETCH_CUSTOM_FIELDS_URL, {
            headers,
        });
        const apiResponse: CustomFieldListResponse = await res.json();
        return apiResponse.data;
    } catch (e) {
        throw new Error(
            `Was not able to fetch the required custom field ${CONTENT_FRAGMENT_CUSTOM_FIELD_NAME}.`,
        );
    }
};

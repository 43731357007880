/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Item,
    TabList,
    TabPanels,
    Tabs,
    Text,
    View,
} from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { MessagesTab } from "./MessagesTab";
import { useFeatureFlagContext } from "@src/contexts/FeatureFlagContext";
import type { LibraryAsset } from "@src/contexts/RpcContext";
import { ScrollingContainer } from "@src/interfaces/common/components/ScrollingContainer";

import type { FC } from "react";

interface Props {
    asset: LibraryAsset;
}

export const MessagesAndLogsPanel: FC<Props> = ({ asset }) => {
    const { t } = useTranslation(["library"]);
    const { FF_ASSET_LOGS_PANEL } = useFeatureFlagContext();

    return (
        <View
            minWidth="size-4600"
            height="100%"
            backgroundColor="gray-100"
            borderStartColor="gray-50"
            borderStartWidth="thick">
            <ScrollingContainer>
                <View padding="size-200">
                    <Tabs aria-label={t("detailView.messagesPanel.label.tabs")}>
                        <TabList marginBottom="size-100">
                            <Item
                                key="messages"
                                textValue={t(
                                    "detailView.sidePanel.label.messages",
                                )}>
                                <Text>
                                    {t("detailView.sidePanel.label.messages")}
                                </Text>
                            </Item>
                            <Item
                                key="logs"
                                textValue={t(
                                    "detailView.sidePanel.label.logs",
                                )}>
                                {FF_ASSET_LOGS_PANEL && (
                                    <Text>
                                        {t("detailView.sidePanel.label.logs")}
                                    </Text>
                                )}
                            </Item>
                        </TabList>
                        <TabPanels>
                            <Item
                                key="messages"
                                textValue={t(
                                    "detailView.sidePanel.label.messages",
                                )}>
                                <MessagesTab asset={asset} />
                            </Item>
                            <Item
                                key="logs"
                                textValue={t(
                                    "detailView.sidePanel.label.logs",
                                )}>
                                <View />
                            </Item>
                        </TabPanels>
                    </Tabs>
                </View>
            </ScrollingContainer>
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { StatusLight, Text, View } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import type { LibraryAssetCardItem } from "@src/contexts/RpcContext";
import {
    statusLightVariant,
    statusToDisplayString,
} from "@src/lib/utils/statusLightHelper";

import type { FC } from "react";

export type AssetStatusData = Pick<
    LibraryAssetCardItem,
    | "approved"
    | "jobsCompleted"
    | "jobsFailed"
    | "jobErrors"
    | "uploadComplete"
    | "uploadFailed"
    | "hasValidationErrors"
    | "version"
>;

interface AssetStatusProps {
    assetStatus: AssetStatusData;
    marginStart?: string;
    marginTop?: string;
    maxHeight?: string;
}

export const AssetStatusLight: FC<AssetStatusProps> = ({
    assetStatus: asset,
    marginStart,
    marginTop,
    maxHeight,
}) => {
    const { t } = useTranslation("common");
    // 1. Need to wrap StatusLight with <Text> as the outermost component to achieve the proper item
    //    selection appearance when the list is opened.
    // 2. Need to wrap with <View> so that the status "dot" and text for selected item are aligned properly
    //    when list is collapsed.
    // 3. Setting width to anything less than size-1400 will cause the text to wrap.
    return (
        <Text maxHeight={maxHeight ? maxHeight : "single-line-height"}>
            <View>
                <StatusLight
                    aria-label={statusToDisplayString(asset)}
                    role="status"
                    alignSelf="auto"
                    marginStart={marginStart ? marginStart : "size-0"}
                    marginTop={marginTop ? marginTop : "size-0"}
                    variant={statusLightVariant(asset)}>
                    <Text>{t(statusToDisplayString(asset))}</Text>
                </StatusLight>
            </View>
        </Text>
    );
};

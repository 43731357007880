/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import React from "react";
import { TaskListProps } from "./types";
import { Task } from "../Task";
import { TaskAssignedToMe } from "../types/TasksAssignedToMeWithSingleProject";
import { Content } from "@adobe/react-spectrum";
import { SubTaskListItem } from "../types/SubTaskList";

export const TaskList = (props: TaskListProps) => {
    const { tasks, setTaskView } = props;

    return (
        <Content>
            {tasks?.map((task: TaskAssignedToMe | SubTaskListItem) => (
                <Task {...task} key={task.ID} setTaskView={setTaskView} />
            ))}
        </Content>
    );
};

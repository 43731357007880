/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { createContext, useContext, useRef, useState } from "react";

import type { PropsWithChildren } from "react";

interface CachingContextType {
    preCacheUrls: (urls: string[]) => void;
    preCacheUrlsOnIdle: (urls: string[]) => void;
    loadStarted: () => void;
    loadComplete: () => void;
    loadingIdle: boolean;
}

const CachingContext = createContext<CachingContextType>(
    {} as CachingContextType,
);

export function useCachingContext() {
    const context = useContext(CachingContext);
    if (!context.preCacheUrls) {
        throw new Error(
            "You must use messaging context within a <CachingContextProvider>",
        );
    }
    return context;
}

const IDLE_TIMEOUT = 500;

const cachedUrls = new Set();

export function CachingContextProvider({ children }: PropsWithChildren) {
    const loadingCount = useRef(0);
    const idlePrecacheQueue = useRef<string[]>([]);
    const idleTimer = useRef<number | undefined>();
    const [loadingIdle, setLoadingIdle] = useState(false);

    function preCacheUrls(urls: string[]) {
        urls.forEach((url) => {
            if (!cachedUrls.has(url)) {
                if (url.includes("content-type=image")) {
                    const img = new Image();
                    img.src = url;
                } else {
                    cachedUrls.add(url);
                    fetch(url);
                }
            }
        });
    }

    function preCacheUrlsOnIdle(urls: string[]) {
        if (loadingIdle) {
            preCacheUrls(urls);
        } else {
            idlePrecacheQueue.current.push(...urls);
        }
    }

    function clearIdleTimer() {
        if (idleTimer.current) {
            window.clearTimeout(idleTimer.current);
            idleTimer.current = undefined;
        }
    }

    function loadStarted() {
        loadingCount.current++;
        clearIdleTimer();
        if (loadingIdle) {
            setLoadingIdle(false);
        }
    }

    function loadComplete() {
        loadingCount.current--;

        if (loadingCount.current < 1) {
            loadingCount.current = 0;
            clearIdleTimer();
            idleTimer.current = window.setTimeout(() => {
                preCacheUrls(idlePrecacheQueue.current);
                idlePrecacheQueue.current = [];
                setLoadingIdle(true);
            }, IDLE_TIMEOUT);
        }
    }

    return (
        <CachingContext.Provider
            value={{
                preCacheUrls,
                preCacheUrlsOnIdle,
                loadStarted,
                loadComplete,
                loadingIdle,
            }}>
            {children}
        </CachingContext.Provider>
    );
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, ProgressCircle } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { useDelayedDisplay } from "../hooks/useDelayedDisplay";

interface Props {
    delayTime?: number;
    description?: string;
    size?: "S" | "M" | "L";
}

export const DelayedSpinner = ({
    delayTime = 1000,
    description,
    size,
}: Props) => {
    const { t } = useTranslation("common");

    const showSpinner = useDelayedDisplay(delayTime);

    return showSpinner ? (
        <Flex
            width="100%"
            height="100%"
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap="size-250">
            <ProgressCircle
                isIndeterminate
                size={size}
                aria-label={t("progress.loading")}
            />
            {description && <Heading>{description}</Heading>}
        </Flex>
    ) : null;
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { WorkfrontParams } from "../../../types/wfParams";
import { createHeaders } from "../util";
import {
    TaskRecommendedAssigneeSingleResponse,
    TaskRecommendedAssigneeResponse,
} from "./types/TaskRecommendedAssigneeResponse";

export const fetchWorkfrontTaskRecommendedAssignees = async function (
    wfParams: WorkfrontParams,
    query: string,
): Promise<TaskRecommendedAssigneeResponse> {
    try {
        const headers = createHeaders(wfParams.imsToken);

        //TODO use the API instead the internal endpoint
        const WF_FETCH_TASK_RECOMMENDED_ASSIGNEES_URL = `${wfParams.wfInstanceUrl}/internal/users/recommended?${query}`;

        const res = await fetch(WF_FETCH_TASK_RECOMMENDED_ASSIGNEES_URL, {
            headers,
        });
        const apiResponse: TaskRecommendedAssigneeSingleResponse = await res.json();
        return apiResponse.data;
    } catch (e) {
        throw new Error("Was not able to fetch recommendations");
    }
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { getPreloadedAvatar, preloadAvatars } from "../requests/fetchAvatarImageAsBlob";
import { fetchWorkfrontTaskPossibleStatuses } from "../requests/fetchTaskPossibleStatuses";
import { AbstractStatusResponse } from "../requests/types/AbstractStatusResponse";
import { SubTaskList } from "../../../types/SubTaskList";
import { fetchWorkfrontSubTasksByParentTaskId } from "../requests/fetchSubTasksByParentTaskId";
import { SubTaskResponse } from "../requests/types/SubTaskResponse";
import { getTaskStatus } from "./private/util";
import { CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX } from "../constants";
import { WorkfrontParams } from "../../../types/wfParams";

export const fetchSubTasksByParentTaskId = async (
    wfParams: WorkfrontParams,
    parentTaskId: string,
): Promise<SubTaskList> => {
    const subTaskListPromise: Promise<SubTaskResponse[]> = fetchWorkfrontSubTasksByParentTaskId(
        wfParams,
        parentTaskId,
    );
    const taskStatusesPromise: Promise<AbstractStatusResponse[]> =
        fetchWorkfrontTaskPossibleStatuses(wfParams);
    const [subTaskList, possibleTaskStatuses] = await Promise.all([
        subTaskListPromise,
        taskStatusesPromise,
    ]);

    const avatarUrls: (string | null | undefined)[] = subTaskList.map(
        (subTask: SubTaskResponse) => subTask.assignedTo?.avatarDownloadURL,
    );
    await preloadAvatars(wfParams, avatarUrls);
    return {
        subTasks: subTaskList.map((subTask: SubTaskResponse) => {
            const assignedTo = subTask.assignedTo
                ? {
                      ID: subTask.assignedTo.ID,
                      name: subTask.assignedTo.name,
                      title: subTask.assignedTo.title,
                      avatar: getPreloadedAvatar(subTask.assignedTo?.avatarDownloadURL),
                  }
                : null;
            return {
                ID: subTask.ID,
                name: subTask.name,
                status: getTaskStatus(subTask.status, possibleTaskStatuses),
                description: subTask.description,
                assignedTo,
                contentFragment:
                    subTask[CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX] ?? undefined,
                plannedCompletionDate: subTask.plannedCompletionDate,
            };
        }),
    };
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    ActionButton,
    ProgressBar,
    Text,
    Divider,
    ContextualHelp,
    Content,
    View,
} from "@adobe/react-spectrum";
import Alert from "@spectrum-icons/workflow/Alert";
import CheckmarkCircle from "@spectrum-icons/workflow/CheckmarkCircle";
import Close from "@spectrum-icons/workflow/Close";
import Delete from "@spectrum-icons/workflow/Delete";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";

import { useReadAttachment } from "./useReadAttachment";

import type { FC } from "react";

type FileStatus = "none" | "inprogress" | "complete" | "error";

interface ProgressProps {
    name: string;
    attachmentProgress: number;
    cancel: () => void;
}

const ItemProgressView: FC<ProgressProps> = ({
    name,
    attachmentProgress,
    cancel,
}) => {
    return (
        <Flex direction="row" height="size-400" columnGap="size-50">
            <Flex
                direction="column"
                justifyContent="start"
                alignItems="center"
                width="100%">
                <ProgressBar
                    size="S"
                    width="100%"
                    label={name}
                    value={attachmentProgress}
                />
            </Flex>
            <ActionButton
                isQuiet
                width="size-200"
                height="size-200"
                onPress={cancel}>
                <Close size="XS" />
            </ActionButton>
        </Flex>
    );
};

interface ItemCompletedProps {
    name: string;
    status: string;
    errorString: string;
    handleRemoveAttachment: () => void;
}

const ItemCompletedView: FC<ItemCompletedProps> = ({
    name,
    status,
    errorString,
    handleRemoveAttachment,
}) => {
    return (
        <Flex direction="column" height="size-400" width="100%">
            <Flex
                direction="row"
                marginBottom="size-75"
                alignItems="center"
                alignContent="center"
                justifyContent="space-between">
                <Text UNSAFE_style={{ fontSize: "12px" }}>{name}</Text>
                <Flex
                    direction="row"
                    columnGap="size-20"
                    alignItems="center"
                    justifyContent="end">
                    {status === "complete" ? (
                        <>
                            <CheckmarkCircle size="XS" color="positive" />
                            <ActionButton
                                width="size-200"
                                height="size-200"
                                isQuiet
                                onPress={handleRemoveAttachment}>
                                <Delete size="XXS" />
                            </ActionButton>
                        </>
                    ) : status === "error" ? (
                        <>
                            <Alert size="XS" color="negative" />
                            <View
                                width="size-200"
                                height="size-200"
                                marginStart="size-75"
                                marginEnd="size-100">
                                <ContextualHelp variant="info">
                                    <Content>
                                        <Text>{t(errorString)}</Text>
                                    </Content>
                                </ContextualHelp>
                            </View>
                        </>
                    ) : null}
                </Flex>
            </Flex>
            <Divider size="M" />
        </Flex>
    );
};

type UpdateAttachment = (
    name: string,
    data: Uint8Array | null,
    toDelete?: boolean,
) => void;
interface Props {
    file: File;
    updateAttachment: UpdateAttachment;
}

export const AttachmentItemView: FC<Props> = ({ file, updateAttachment }) => {
    const {
        handleReadAttachment,
        attachmentProgress,
        attachmentContentStatus,
    } = useReadAttachment();

    const [status, setStatus] = useState<FileStatus>("none");
    useEffect(() => {
        if (attachmentProgress === 0) {
            handleReadAttachment(file);
        }
    }, [attachmentProgress]);

    useEffect(() => {
        if (attachmentContentStatus?.data && attachmentProgress === 100) {
            setStatus("complete");
            updateAttachment(file.name, attachmentContentStatus?.data);
        } else if (attachmentContentStatus?.errorState) {
            setStatus("error");
            updateAttachment(file.name, null);
        } else if (attachmentProgress >= 0) {
            setStatus("inprogress");
        }
    }, [
        attachmentProgress,
        attachmentContentStatus?.data,
        attachmentContentStatus?.errorState,
    ]);

    const [showCompletion, setShowCompletion] = useState(false);
    const completionTimer = useRef<number>();
    useEffect(() => {
        if (status !== "complete" && status !== "error") {
            return;
        }
        completionTimer.current = window.setTimeout(() => {
            setShowCompletion(true);
            completionTimer.current = undefined;
        }, 1000);
        return () => {
            if (completionTimer?.current) {
                clearTimeout(completionTimer.current);
            }
        };
    }, [status]);

    const handleRemoveAttachment = () => {
        // mark this attachment for deletion.
        updateAttachment(file.name, null, true);
    };

    return (
        <Flex
            direction="column"
            marginTop="size-100"
            justifyContent="start"
            width="100%"
            height="size-400">
            {showCompletion ? (
                <ItemCompletedView
                    name={file?.name}
                    status={status}
                    handleRemoveAttachment={handleRemoveAttachment}
                    errorString={attachmentContentStatus?.errorState || ""}
                />
            ) : (
                <ItemProgressView
                    name={file?.name}
                    attachmentProgress={attachmentProgress}
                    cancel={handleRemoveAttachment}
                />
            )}
        </Flex>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ASSET_COMPOSITE_TYPE } from "@shared/types";
import { useEffect, useState } from "react";
import { generatePath, useParams } from "react-router-dom";

import { config } from "@src/config";
import { Routes } from "@src/routes";

export const useShareReviewPath = (assetId: string, assetType: string) => {
    const isAssetComposite = assetType === ASSET_COMPOSITE_TYPE;
    const [resourceId, setResourceId] = useState<string>("");
    const [resourceViewUrl, setResourceViewUrl] = useState<string>("");

    const { viewType, projectId } = useParams();
    useEffect(() => {
        if (assetId) {
            const searchParams = new URLSearchParams();
            searchParams.append("urn", assetId);
            searchParams.append("type", isAssetComposite ? "asset" : "project");

            if (isAssetComposite) {
                searchParams.append("viewType", viewType ?? "2D");
            } else if (!projectId) {
                throw new Error("Expecting a projectId for the project route");
            }

            const reviewPath = `${generatePath(
                Routes.shareAssetLanding.path,
            )}?${searchParams.toString()}`;

            setResourceId(`${config.hostCcApi}/id/${assetId}`);
            setResourceViewUrl(`${origin}${reviewPath}`);
        }
    }, [assetId]);

    return { resourceId, resourceViewUrl };
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { DialogContainer, Text } from "@adobe/react-spectrum";
import { HeliosRoutes } from "@shared/common";
import { detect } from "detect-browser";
import { useMemo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMatch } from "react-router-dom";

import { ConfirmDialog } from "../../common/components/ConfirmDialog";
import { useFeatureFlagContext } from "@src/contexts/FeatureFlagContext";
import { useDoNotShowMeAgain } from "@src/interfaces/common/hooks/useDoNotShowMeAgain";

import type { FC } from "react";

export const ChromePopup: FC = () => {
    const { t } = useTranslation("common");

    const { FF_CHROME_POPUP } = useFeatureFlagContext();

    const isArViewer = useMatch(HeliosRoutes.arViewer.path);
    const shouldShowDialog = useMemo(() => {
        const detected = detect();
        const isChrome = detected?.name === "chrome";
        const isIosBrowser =
            detected?.name === "ios" ||
            detected?.name === "ios-webview" ||
            detected?.name === "crios" || // Chrome on iOS
            (detected?.os === "iOS" && detected?.name === "safari");
        return !(isChrome || (isIosBrowser && isArViewer));
    }, [isArViewer]);

    const {
        doNotShowChromeIsPreferredBrowser: {
            state: doNotShowChromeIsPreferredBrowser,
            saveFn: saveDoNotShowChromeIsPreferredBrowser,
        },
    } = useDoNotShowMeAgain();

    const [isOpen, setOpen] = useState(
        !doNotShowChromeIsPreferredBrowser && shouldShowDialog,
    );
    useEffect(() => {
        setOpen(!doNotShowChromeIsPreferredBrowser && shouldShowDialog);
    }, [doNotShowChromeIsPreferredBrowser]);

    const [doNotAskAgain, setDoNotAskAgain] = useState<boolean>(false);

    const closeDialog = () => {
        setOpen(false);
    };

    const handleOk = useCallback(() => {
        if (doNotAskAgain) {
            saveDoNotShowChromeIsPreferredBrowser();
        }
        closeDialog();
    }, [saveDoNotShowChromeIsPreferredBrowser, doNotAskAgain]);

    return (
        <DialogContainer onDismiss={closeDialog} isKeyboardDismissDisabled>
            {FF_CHROME_POPUP && isOpen && (
                <ConfirmDialog
                    title={t("dialogs.chromeBrowser.title")}
                    setDoNotAskAgain={setDoNotAskAgain}
                    onConfirm={handleOk}
                    confirmBtnText={t("actions.ok")}>
                    <Text>{t("dialogs.chromeBrowser.body")}</Text>
                </ConfirmDialog>
            )}
        </DialogContainer>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Divider, Flex, Link, View } from "@adobe/react-spectrum";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { HelpLinkItem } from "./HelpLinkItem";
import { useOpenInNewTab } from "../hooks/useOpenInNewTab";
import { config } from "@src/config";
import { useFeatureFlagContext } from "@src/contexts/FeatureFlagContext";
import { ThemeContext } from "@src/contexts/ThemeContext";

import type { FC } from "react";

const CREATE_ISSUE_BASE_URL =
    "https://jira.corp.adobe.com//secure/CreateIssueDetails!init.jspa";

interface Props {
    onClose: () => void;
    onShowUserFeedback?: VoidFunction;
}

export const HelpOverlay: FC<Props> = ({ onClose, onShowUserFeedback }) => {
    const { FF_SENTRY_INTEGRATION } = useFeatureFlagContext();
    const { isDarkMode } = useContext(ThemeContext);
    const { t } = useTranslation(["common"]);
    const { openInNewTab } = useOpenInNewTab();

    const newBugUrl = useMemo(() => {
        const newBugUrl = new URL(CREATE_ISSUE_BASE_URL);
        newBugUrl.searchParams.append("pid", "61602");
        newBugUrl.searchParams.append("issuetype", "1");
        newBugUrl.searchParams.append("priority", "8");
        newBugUrl.searchParams.append("customfield_12900", "155506");
        newBugUrl.searchParams.append("components", "212741");
        newBugUrl.searchParams.append("versions", "255401");
        newBugUrl.searchParams.append("assignee", "dscott");
        newBugUrl.searchParams.append("reporter", "Your LDAP");
        newBugUrl.searchParams.append(
            "description",
            "*Description*\r\nShort summary of issue.\r\n\r\n*Reproduction Steps*\r\n # Detailed steps to reproduce\r\n # ...\r\n\r\n*Actual behavior*\r\nShort summary of current results\r\n\r\n*Expected behavior*\r\nShort summary of expected results\r\n\r\n*Workaround*\r\n # Detailed steps to workaround, if available\r\n # ...\r\n\r\n*Operating System*\r\nE.g., macOS 12.6\r\n\r\n*Browser*\r\nE.g., Chrome Version 117.0.5938.149 (Official Build) (arm64)\r\n\r\n*Attachments/Links*\r\nAttach or link any files used in the reproduction of the bug.\r\n\r\n*Screenshot(s)*\r\nScreenshots exhibiting the issue.\r\n\r\n*Content Origin*\r\nWhere was the asset created? What app/version? (E.g., Maya 2023.1)\r\n\r\n*Additional Notes*\r\nAnything else here.\r\n",
        );
        newBugUrl.searchParams.append("labels", "UserFeedback");
        return newBugUrl;
    }, []);

    const onPressLink = (url: string | URL) => {
        openInNewTab(url);
        onClose();
    };

    return (
        <View
            width="size-2400"
            borderColor="gray-100"
            borderWidth="thick"
            borderRadius="small"
            UNSAFE_style={{
                backgroundColor: isDarkMode ? "#1e1e1e" : "#ffffff",
            }}>
            <Flex
                direction="column"
                alignItems="start"
                columnGap="size-200"
                margin="size-125">
                <HelpLinkItem
                    dataUia="help-tutorial"
                    label={t("help.tutorial")}
                    onPress={() => {
                        onPressLink(config.helpTutorialUrl);
                    }}
                />
                <HelpLinkItem
                    dataUia="help-get-started"
                    label={t("help.getStarted")}
                    onPress={() => {
                        onPressLink(config.helpGetStartedUrl);
                    }}
                />
                <HelpLinkItem
                    dataUia="help-starter-assets"
                    label={t("help.starterAssets")}
                    onPress={() => {
                        onPressLink(config.helpStarterAssetsUrl);
                    }}
                />
                <HelpLinkItem
                    dataUia="help-documentation"
                    label={t("help.documentation")}
                    onPress={() => {
                        onPressLink(config.helpDocumentationUrl);
                    }}
                />
                <HelpLinkItem
                    dataUia="help-faq"
                    label={t("help.faq")}
                    onPress={() => {
                        onPressLink(config.helpFAQUrl);
                    }}
                />
                {!FF_SENTRY_INTEGRATION && (
                    <HelpLinkItem
                        dataUia="help-file-bug"
                        label={t("help.fileABug")}
                        onPress={() => {
                            onPressLink(newBugUrl);
                        }}
                    />
                )}
                {FF_SENTRY_INTEGRATION && (
                    <>
                        <Divider
                            marginTop="size-50"
                            marginBottom="size-50"
                            size="S"
                        />
                        <Link
                            variant="secondary"
                            isQuiet
                            onPress={onShowUserFeedback}>
                            {t("help.sentryUserFeedback")}
                        </Link>
                    </>
                )}
            </Flex>
        </View>
    );
};

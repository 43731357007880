/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export const PROJECT_COMPOSITE_TYPE =
    "application/vnd.adobe.sunrise.project+dcx" as const;
export const SHOT_COMPOSITE_TYPE =
    "application/vnd.adobe.sunrise.shot+dcx" as const;

/**
 * Project camera data specification.
 * All camera variants for a specific templates are defined in the template.
 * There isn't a need to specify a separate url for each camera variant.
 */
export type CameraData = {
    templateUrl?: string;
    thumbnailUrl: string;
    id: string;
    variant: string;
    hideFromTemplateUI: boolean;
    aspectRatio: number;
    resolution: number[];
    displayName?: string;
    domeLightRotation?: number;
    domeLightIntensity?: number;
    displayOrder: number;
};

export type TemplateData = {
    id: string;
    title: string;
    description: string;
    version: string;
    fileName?: string;
    templateUrl: string;
    thumbnailUrl: string;
    categories: string[];
    cameras: Map<string, CameraData>;
    active: boolean;
};

export const PROJECT_FILTER_VALUES = [
    "inProgress",
    "approved",
    "myProjects",
] as const;
export const ALL_PROJECTS_FILTER_VALUE = "allProjects" as const;

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex } from "@adobe/react-spectrum";
import Alert from "@spectrum-icons/workflow/Alert";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CardBadge } from "../../common/components/CardBadge";
import { rpc } from "@src/contexts/RpcContext";
import { InvalidFile } from "@src/interfaces/common/components/InvalidFile";
import { Thumbnail } from "@src/interfaces/common/components/Thumbnail";

import type { FC } from "react";

interface Props {
    assetId: string;
    name: string;
    version?: number;
    jobsCompleted: boolean;
    uploadComplete: boolean;
    uploadFailed?: boolean;
    hasValidationErrors?: boolean;
}

export const AssetCardPreview: FC<Props> = ({
    assetId,
    name,
    version,
    jobsCompleted,
    uploadComplete,
    uploadFailed,
    hasValidationErrors,
}) => {
    const { t } = useTranslation(["library", "common"]);
    const {
        data: assetThumbnailData,
        isError,
    } = rpc.libraryAssets.getAssetThumbnail.useQuery(assetId);
    const { data: cardItemErrors } =
        rpc.libraryAssets.getAssetCardItemErrors.useQuery(assetId);

    const errors = useMemo(() => {
        if (uploadFailed) {
            return cardItemErrors?.uploadErrorMessages ?? [];
        }
        const errors = assetThumbnailData?.errors ?? [];
        return errors.concat(cardItemErrors?.errors ?? []);
    }, [
        uploadFailed,
        cardItemErrors?.uploadErrorMessages,
        cardItemErrors?.errors,
        assetThumbnailData?.errors,
    ]);

    const showJobErrors = useMemo(() => {
        return !uploadComplete && uploadFailed && errors?.length;
    }, [uploadComplete, uploadFailed, errors]);

    const enableBadgeSpinner = useMemo(() => {
        return !!assetThumbnailData?.jobCompleted && !jobsCompleted;
    }, [assetThumbnailData?.jobCompleted, jobsCompleted]);

    const renderCardBadge = () => {
        if (
            !jobsCompleted ||
            (hasValidationErrors && (!version || version === 1))
        ) {
            return null;
        }
        if (showJobErrors) {
            return <CardBadge errors={errors} />;
        }
        if (enableBadgeSpinner) {
            return <CardBadge showSpinner />;
        }

        if (version) {
            return (
                <CardBadge
                    content={t("card.labels.version", {
                        version,
                    })}
                    errors={errors}
                />
            );
        }
        return null;
    };

    const invalidImage =
        hasValidationErrors ||
        isError ||
        !!assetThumbnailData?.errors.length;

    return (
        <>
            <Flex
                data-uia="library-asset-card-preview"
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap="size-150"
                height="100%">
                {uploadFailed || assetThumbnailData?.jobFailed ? (
                    <Alert size="L" />
                ) : invalidImage ? (
                    <InvalidFile size="S" />
                ) : (
                    <Thumbnail
                        alt={name}
                        url={assetThumbnailData?.url}
                        showFallbackImage
                    />
                )}
            </Flex>
            {renderCardBadge()}
        </>
    );
};

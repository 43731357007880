/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { DialogContainer, Text } from "@adobe/react-spectrum";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmDialog } from "../../common/components/ConfirmDialog";
import { useDoNotShowMeAgain } from "@src/interfaces/common/hooks/useDoNotShowMeAgain";

import type { FC } from "react";

type Props = {
    projectToApprove?: string;
    setProjectToApprove: ReturnType<typeof useState<string>>[1];
    doApprovalMutation: () => void;
};

export const ProjectApprovalDialog: FC<Props> = ({
    projectToApprove,
    setProjectToApprove,
    doApprovalMutation,
}) => {
    const { t } = useTranslation(["studio", "common"]);
    const {
        doNotShowProjectApprovalConfirmation: {
            saveFn: saveDoNotShowProjectApprovalConfirmation,
        },
    } = useDoNotShowMeAgain();
    const [doNotAskAgain, setDoNotAskAgain] = useState<boolean>(false);

    const closeDialog = () => {
        setProjectToApprove(undefined);
    };

    const doApproval = useCallback(() => {
        if (!projectToApprove) {
            throw new Error("The dialog should not be open without an id");
        }
        if (doNotAskAgain) {
            saveDoNotShowProjectApprovalConfirmation();
        }
        doApprovalMutation();
        closeDialog();
    }, [projectToApprove, doNotAskAgain]);

    return (
        <DialogContainer onDismiss={closeDialog}>
            {!!projectToApprove && (
                <ConfirmDialog
                    title={t("dialogs.approvalConfirmation.title")}
                    setDoNotAskAgain={setDoNotAskAgain}
                    onCancel={closeDialog}
                    onConfirm={doApproval}
                    confirmBtnText={t("common:actions.approve")}>
                    <Text>{t("dialogs.approvalConfirmation.body1")}</Text>
                    <br />
                    <br />
                    <Text>{t("dialogs.approvalConfirmation.body2")}</Text>
                </ConfirmDialog>
            )}
        </DialogContainer>
    );
};

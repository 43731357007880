/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import React, { useContext, useEffect } from "react";
import {
    ProgressCircle,
    Flex,
    IllustratedMessage,
    Heading,
    View,
    Content,
} from "@adobe/react-spectrum";
import { TaskManagement } from "../TaskManagement";
import NotFound from "@spectrum-icons/illustrations/NotFound";
import { SharedContext } from "../context";
import { StatusBasedViewProps } from "./types";
import { useFetchTasksAssignedToMe, refetchData } from "../queries";

export const StatusBasedView = (props: StatusBasedViewProps) => {
    const { imsToken, wfInstanceUrl } = props;

    const [fetchDataTrigger, setFetchDataTrigger] = React.useState(true);
    const [isRefetching, setIsRefetching] = React.useState(false);

    const { data, status, error, refetch } = useFetchTasksAssignedToMe({
        imsToken,
        wfInstanceUrl,
    });

    useEffect(() => {
        if (fetchDataTrigger) {
            refetchData(refetch, setIsRefetching);
            setFetchDataTrigger(false);
        }
    }, [fetchDataTrigger]);

    const loadingState = (isRefetching || status === "loading") && (
        <Flex justifyContent="center" width="100%" height="100%">
            {<ProgressCircle aria-label="Loading…" isIndeterminate={true} alignSelf="center" />}
        </Flex>
    );

    return (
        <View height="100%">
            {loadingState}
            {status === "success" && data && (
                <TaskManagement
                    {...data}
                    wfInstanceUrl={wfInstanceUrl}
                    imsToken={imsToken}
                    setFetchDataTrigger={setFetchDataTrigger}
                />
            )}
            {status === "error" && (
                <IllustratedMessage>
                    <NotFound />
                    <Heading>Network Error</Heading>
                    <Content UNSAFE_style={{ padding: "0 20px" }}>
                        {(error as Error).message}
                    </Content>
                </IllustratedMessage>
            )}
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type { HealthCheckAttribute } from "@shared/types";
import _3DMaterials from "@spectrum-icons/workflow/3DMaterials";
import Algorithm from "@spectrum-icons/workflow/Algorithm";
import FileSpace from "@spectrum-icons/workflow/FileSpace";
import Box from "@spectrum-icons/workflow/Box";
import GraphicProfitCurve from "@spectrum-icons/workflow/GraphProfitCurve";
import Transparency from "@spectrum-icons/workflow/Transparency";
import type { FC } from "react";

interface Props {
    type: HealthCheckAttribute;
}

const icons = {
    fileSize: FileSpace,
    drawCalls: GraphicProfitCurve,
    polygons: Algorithm,
    materials: _3DMaterials,
    meshes: Box,
    textures: Transparency,
};

export const HealthCheckIcon: FC<Props> = ({ type }) => {
    const TheIcon = icons[type];
    return <TheIcon size="S" />;
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, ProgressCircle } from "@adobe/react-spectrum";
import { Studio, StudioTool } from "@components/studio";
import { constructIblPath } from "@shared/common";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ARTIFACT_LAYOUT_PROPS } from "./Artifact2DView";
import { GenericJobError } from "./GenericJobError";
import { useStudioStrings } from "../../../common/hooks/useStudioStrings";
import { rpc } from "@src/contexts/RpcContext";
import { ViewerError } from "@src/interfaces/common/components/ViewerError";

import type { HeliosApi } from "@components/hermes";
import type { ArtifactData, HotSpot } from "@shared/types";

const GENERIC_CAMERA_NAME = "camera";

interface Artifact3DViewProps {
    artifact: ArtifactData;
    enableLimitedZoom: boolean;
    onViewLoaded: () => void;
}

// Currently, this 3D Web + AR view is based on a fixed size at 768 x 768. We
// bound the viewer by this size, but use the aspect ratio for the exact dimensions
export const Artifact3DView = forwardRef(function Artifact3DView(
    { artifact, enableLimitedZoom, onViewLoaded }: Artifact3DViewProps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any,
) {
    const type = artifact.type as HeliosApi.ModelType;
    if (type !== "web" && type !== "ar") {
        throw new Error(
            `Unexpected model type ${artifact.type} for 3D viewer.`,
        );
    }
    const { t } = useTranslation(["common"]);

    const studioStrings = useStudioStrings();

    const url = artifact.url;
    const iblFilename = artifact.iblFilename;

    const { client } = rpc.useContext();
    const { shotId } = useParams();
    const [hotSpotsData, setHotSpotsData] = useState<{
        currentHotSpots: HotSpot[];
        studioTools: StudioTool[];
    }>({
        currentHotSpots: [],
        studioTools: [StudioTool.cameraControls, StudioTool.frameButton],
    });
    useEffect(() => {
        if (shotId) {
            client.shots.getShot.query(shotId).then((shot) => {
                return setHotSpotsData({
                    currentHotSpots: shot.hotSpots || [],
                    studioTools: [
                        StudioTool.cameraControls,
                        StudioTool.frameButton,
                        StudioTool.hotSpotsViewer,
                    ],
                });
            });
        }
    }, [shotId]);

    // If this is true, BabylonJS has given an error and is unable to load the model. We give the studio a callback
    // to this set function, so the webviewer can set this to true if an error is enountered, and we show an error page
    const [hasStudioError, setHasStudioError] = useState(false);

    // Since Spectrum View is a virtual react DOM element, we need to use a div
    // in order to be able to call the element's scrollIntoView method.
    return (
        <div
            ref={ref}
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minHeight: "100%",
                maxWidth: "100%",
            }}>
            {artifact.jobFailed || url === undefined ? (
                <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%">
                    {artifact.jobFailed ? (
                        <GenericJobError
                            jobErrors={artifact.jobErrors}
                            titleKey="error.model"
                        />
                    ) : (
                        <ProgressCircle
                            aria-label={t("progress.loading")}
                            isIndeterminate
                            size="L"
                        />
                    )}
                </Flex>
            ) : hasStudioError ? (
                <ViewerError />
            ) : (
                <Studio
                    initialHotSpots={hotSpotsData.currentHotSpots}
                    enableLimitedZoom={enableLimitedZoom}
                    modelUrl={url}
                    iblUrl={constructIblPath(iblFilename)}
                    cameraName={
                        (artifact.type as HeliosApi.ModelType) === "web"
                            ? GENERIC_CAMERA_NAME
                            : undefined
                    }
                    tools={hotSpotsData.studioTools}
                    strings={studioStrings}
                    aspectRatio={artifact.aspectRatio}
                    renderSettings={artifact.renderSettings}
                    onViewLoaded={onViewLoaded}
                    onModelLoadError={() => setHasStudioError(true)}
                />
            )}
        </div>
    );
});

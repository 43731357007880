/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import React, { useContext, useState } from "react";
import { Form, TextField, ButtonGroup, Button, Picker, Item } from "@adobe/react-spectrum";

import { AsigneeForSubTask } from "../AsigneeForSubTask";
import { createSubTask } from "../../persistence/workfront/aggregations/createSubTask";
import { CreateSubTaskFormProps } from "./types";
import { SharedContext } from "../../context";

export const CreateSubTaskForm = (props: CreateSubTaskFormProps) => {
    const {
        imsToken,
        ID,
        projectID,
        showSubTaskForm,
        setSubtaskIsRefetching,
        setFetchDataTrigger,
        toggleChecked,
        wfInstanceUrl
    } = props;

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [contentFragment, setContentFragment] = useState("");
    const [assignedToId, setAssignedToId] = useState("");

    const { editorState } = useContext(SharedContext);
    const editables = editorState?.editables ?? [];

    const contentFragmentEditables = editables.filter((item) => item.type === "reference");

    let editorContentFragment = "";

    if (!editables.length) {
        const url = (window.location != window.parent.location)
            ? document.referrer
            : document.location.href;
        const cfEditorURL = url.split("/editor").pop();

        editorContentFragment = cfEditorURL?.substring(0, cfEditorURL.indexOf("?")) ?? "";
    }

    return (
        showSubTaskForm && (
            <Form
                validationBehavior="native"
                onSubmit={async (e) => {
                    e.preventDefault();
                    setSubtaskIsRefetching(true);
                    toggleChecked();
                    await createSubTask(
                        { imsToken, wfInstanceUrl },
                        name,
                        description,
                        ID,
                        projectID,
                        contentFragment || editorContentFragment,
                        assignedToId,
                    );
                    setName("");
                    setDescription("");
                    setContentFragment("");
                    setAssignedToId("");
                    setFetchDataTrigger(true);
                }}
                onReset={(e) => {
                    e.preventDefault();
                    setName("");
                    setDescription("");
                    setContentFragment("");
                    setAssignedToId("");
                    toggleChecked();
                }}
            >
                <TextField
                    isRequired={true}
                    type="string"
                    label="Subtask name"
                    value={name}
                    onChange={setName}
                />
                <TextField
                    isRequired={true}
                    type="string"
                    label="Subtask description"
                    value={description}
                    onChange={setDescription}
                />
                {editorState && (
                    <Picker
                        aria-label="Content Fragment Picker"
                        label="Content fragment"
                        onSelectionChange={(selected) =>
                            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                            // @ts-ignore
                            setContentFragment(selected)
                        }
                    >
                        {contentFragmentEditables.map((item) => {
                            const connection = "urn:aemconnection:";

                            const itemResource = item.resource.substring(
                                item.resource.indexOf("urn:aemconnection:") + connection.length,
                                item.resource.indexOf("jcr:content/data/master"),
                            );

                            return (
                                <Item key={itemResource} textValue={itemResource}>
                                    {itemResource}
                                </Item>
                            );
                        })}
                    </Picker>
                )}
                <AsigneeForSubTask imsToken={imsToken} ID={ID} wfInstanceUrl={wfInstanceUrl} setAssignedToId={setAssignedToId} />
                <ButtonGroup>
                    <Button type="reset" variant="secondary">
                        Cancel
                    </Button>
                    <Button type="submit" variant="primary">
                        Save
                    </Button>
                </ButtonGroup>
            </Form>
        )
    );
};

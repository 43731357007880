/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useMemo, useState } from "react";

import { useCommentsAssetData } from "./useCommentsAssetData";
import type { ValidNode } from "@src/types/ccac/CCACData";

import type { ArtifactData } from "@shared/types";

export const useCommentsSetup = (
    ownerId: string,
    compositeId: string,
    renders?: ArtifactData[],
    models?: ArtifactData[],
) => {
    const { commentsAssetData, setCommentsAssetData } = useCommentsAssetData(
        ownerId,
        compositeId,
    );

    const [validCommentingNodes, setValidCommentingNodes] = useState<
        ValidNode[]
    >([]);

    useEffect(() => {
        // Valid commenting nodes should include all 2D renders and 3D web models.
        const commentingNodes: ValidNode[] = renders
            ? renders.map((node) => {
                  return { id: node.componentId, title: node.type };
              })
            : [];
        models?.forEach((node) => {
            if (node?.type === "web" || node?.type === "ar") {
                commentingNodes.push({
                    id: node.componentId,
                    title: node.type,
                });
            }
        });
        setValidCommentingNodes(commentingNodes);
    }, [renders?.toString(), models?.toString()]);

    const validCommentingNodeIds = useMemo(() => {
        return Object.values(validCommentingNodes).map((node) => {
            return node.id;
        });
    }, [validCommentingNodes.toString()]);

    return {
        commentsAssetData,
        setCommentsAssetData,
        validCommentingNodes,
        validCommentingNodeIds,
    };
};

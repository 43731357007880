/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    TextArea as TextAreaV3,
    TextField as TextFieldV3,
} from "@adobe/react-spectrum";
import React from "react";

import type { SpectrumTextFieldProps } from "@adobe/react-spectrum";
import type { TextFieldRef } from "@react-types/textfield";
import type { FC } from "react";

import "./form-textfield.css";

/**
 * This is a workaround for the css reset that we are loading at the root of app.tsx
 * The css reset propagates box-sizing: box-border and is "extremely far reaching".
 * This can cause unpredictable rendering from react-spectrum components and affect third-party library components.
 */

export const FormTextField: FC<
    SpectrumTextFieldProps & React.RefAttributes<TextFieldRef>
> = ({ ...props }) => (
    <TextFieldV3 UNSAFE_className="feedback-form-textfield" {...props} />
);

export const FormTextArea: FC<
    SpectrumTextFieldProps & React.RefAttributes<TextFieldRef>
> = ({ ...props }) => (
    <TextAreaV3 UNSAFE_className="feedback-form-textfield" {...props} />
);

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

const ARRAY_PROPERTY_KEY = "sunriseArrayKey" as const;

export function storeArray<T>(key: string, objectsToStore: T[]) {
    sessionStorage.setItem(
        key,
        JSON.stringify({ sunriseArrayKey: objectsToStore }),
    );
}

export function getArray<T>(key: string) {
    const itemAsJson = sessionStorage.getItem(key);
    if (!itemAsJson) {
        return undefined;
    }
    const item = JSON.parse(itemAsJson);
    const storedArray = item[ARRAY_PROPERTY_KEY];

    return storedArray ? (storedArray as T[]) : undefined;
}

export function deleteKeyFromStorage(key: string) {
    sessionStorage.removeItem(key);
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, View } from "@adobe/react-spectrum";
import { useParams, useSearchParams } from "react-router-dom";

import { DetailReviewHeader } from "./DetailReviewHeader";
import { AssetDetailContent } from "../assets/AssetDetailContent";
import { DETAIL_PANEL_KEYS } from "../common/DetailViewRail";
import type { DefaultPageProps } from "@src/app";
import { rpc } from "@src/contexts/RpcContext";

import type { DetailViewType } from "../common/DetailViewTabs";
import type { FC } from "react";

/**
 * This is the limited access detail asset review page.
 * It shows all of the view tabs for 2D, 3D web, and AR views.
 * Default to the opened comments panel.
 */
export const DetailAssetReview: FC<DefaultPageProps> = ({ AppBarPortal }) => {
    const { assetId: dataId = "", viewType } = useParams();
    const [searchParams] = useSearchParams();
    const commentId = searchParams.get("commentId");

    // dataId is the data id for the library asset.
    const { data: asset } = rpc.libraryAssets.get.useQuery(dataId);
    // TODO: should allow invitee to be able to toggle comments panel?
    // TODO: should show health check information?
    return (
        <View height="100%" width="100%">
            {asset && (
                <View height="100%" backgroundColor="gray-75">
                    {AppBarPortal && (
                        <AppBarPortal>
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                                height="100%">
                                <DetailReviewHeader name={asset.name} />
                            </Flex>
                        </AppBarPortal>
                    )}
                    <Flex
                        direction="row"
                        justifyContent="space-between"
                        height="100%">
                        <AssetDetailContent
                            id={asset.id}
                            compositeId={asset.compositeId}
                            selectedPanelKey={DETAIL_PANEL_KEYS.comment}
                            viewType={viewType as DetailViewType}
                            commentId={commentId ?? undefined}
                        />
                    </Flex>
                </View>
            )}
        </View>
    );
};

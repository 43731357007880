/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { Env, ImsAuthProps, IMSConfig} from "../types/imsAuthTypes";

export const generateImsSusiData = ({clientId, imsScope, imsEnv}: IMSConfig): ImsAuthProps => ({
    imsClientId: clientId,
    imsScope: imsScope,
    redirectUrl: window.location.href,
    adobeImsOptions: {
        logsEnabled: true,
        useLocalStorage: true,
    },
    modalMode: true,
    env: imsEnv,
});

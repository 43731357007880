/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, View } from "@adobe/react-spectrum";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { ProjectView } from "./ProjectView";
import { usePortal } from "../../common/hooks/usePortal";
import { rpc } from "@src/contexts/RpcContext";
import { useUIStateContext } from "@src/contexts/UIStateContext";
import { DETAIL_PANEL_KEYS } from "@src/interfaces/detail/components/common/DetailViewRail";

import type { PropsWithChildren } from "react";

export interface ProjectsPanelProps {
    ControlsPortal: ReturnType<typeof usePortal>["Portal"];
    SelectionPortal?: ReturnType<typeof usePortal>["Portal"];
}

interface Props {
    navBar: React.ReactNode;
    reviewAccess?: boolean;
}

export const BaseProjectLibrary = observer(
    ({ navBar, reviewAccess, children }: PropsWithChildren<Props>) => {
        const [searchParams] = useSearchParams();
        const commentId = searchParams.get("commentId");
        const showComments = searchParams.get("showComments");

        const { projectId } = useParams();
        const { setProjectSelection } =
            useUIStateContext();
        const { setProjectViewPanelSelection } = useUIStateContext();

        const { Portal: ControlsPortal, setPortalDiv: setControlsPortal } =
            usePortal();
        const { Portal: SelectionPortal, setPortalDiv: setSelectionPortal } =
            usePortal();

        useEffect(() => {
            setProjectSelection(projectId ?? "");
            return () => setProjectSelection("");
        }, [projectId]);

        useEffect(() => {
            if (commentId || showComments) {
                setProjectViewPanelSelection(DETAIL_PANEL_KEYS.comment);
            }
        }, [commentId, showComments]);

        const { data: canUserModify } =
            rpc.projects.canUserModify.useQuery(projectId);

        return (
            <Flex direction="column" width="100%" height="100%">
                <View
                    height="size-800"
                    width="100%"
                    backgroundColor="gray-100"
                    borderBottomColor="gray-50"
                    borderBottomWidth="thick">
                    {navBar}
                </View>
                <Flex direction="row" width="100%" height="100%">
                    <View
                        backgroundColor="gray-75"
                        width="100%"
                        height="100%"
                        paddingTop="size-125"
                        position="relative">
                        <Flex direction="column" height="100%" width="100%">
                            <Flex
                                marginStart="size-300"
                                marginEnd="size-300"
                                justifyContent="space-between"
                                alignItems="center"
                                height="size-600">
                                <div ref={setSelectionPortal} />
                                <div ref={setControlsPortal} />
                            </Flex>
                            {projectId && (
                                <ProjectView
                                    projectId={projectId}
                                    ControlsPortal={ControlsPortal}
                                    SelectionPortal={SelectionPortal}
                                    canUserModify={!!canUserModify}
                                    reviewAccess={!!reviewAccess}
                                />
                            )}
                        </Flex>
                    </View>
                    {children}
                </Flex>
            </Flex>
        );
    },
);

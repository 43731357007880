/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { createHeaders } from "../util";
import { WorkfrontParams } from "../../../types/wfParams";

//TODO: May be a sessionStorage or localStorage???
const avatarMap: Map<string, Blob | null> = new Map();

export const getPreloadedAvatar = (
    downloadUrlWithoutHost: string | null | undefined,
): Blob | null => {
    if (!downloadUrlWithoutHost) return null;
    return avatarMap.get(downloadUrlWithoutHost) || null;
};

export const fetchAvatarImageAsBlob = async (
    wfParams: WorkfrontParams,
    downloadUrlWithoutHost: string,
): Promise<Blob | null> => {
    try {
        const headers = createHeaders(wfParams.imsToken);
        const downloadFullUrl = wfParams.wfInstanceUrl + downloadUrlWithoutHost;
        const res = await fetch(downloadFullUrl, {
            headers,
        });
        avatarMap.set(downloadUrlWithoutHost, await res.blob());
    } catch (e) {
        avatarMap.set(downloadUrlWithoutHost, null);
        return null;
    }
    return getPreloadedAvatar(downloadUrlWithoutHost);
};

export const preloadAvatars = async (
    wfParams: WorkfrontParams,
    downloadUrlWithoutHosts: (string | null | undefined)[],
): Promise<void> => {
    const avatarsNeedToBeLoaded = downloadUrlWithoutHosts.filter(
        (url: string | null | undefined) => !!url,
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const promises: Promise<Blob | null>[] = avatarsNeedToBeLoaded.map(
        (url: string | null | undefined) => fetchAvatarImageAsBlob(wfParams, url!),
    );
    await Promise.all(promises);
};

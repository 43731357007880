/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useCallback } from "react";

import type { ShareSheetCallbackData } from "@src/types/ccac/CCACData";

type ShareSheetCallbacks = {
    onDialogOpen?: (e: Event) => void;
    onDialogClose: () => void;
    onInviteFulfilled?: (data: ShareSheetCallbackData) => void;
    onRemoveFulfilled?: (data: ShareSheetCallbackData) => void;
};

export const useShareSheetCallbacks = (callbacks: ShareSheetCallbacks) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleClose = (_e: Event) => {
        callbacks.onDialogClose();
    };

    const handleOpened = (e: Event) => {
        if (callbacks.onDialogOpen) {
            callbacks.onDialogOpen((e as CustomEvent).detail);
        }
    };

    const handleInviteFulfilled = (e: Event) => {
        if (callbacks.onInviteFulfilled) {
            callbacks.onInviteFulfilled(
                (e as CustomEvent).detail as ShareSheetCallbackData,
            );
        }
    };
    const handleRemoveFulfilled = (e: Event) => {
        if (callbacks.onRemoveFulfilled) {
            callbacks.onRemoveFulfilled(
                (e as CustomEvent).detail as ShareSheetCallbackData,
            );
        }
    };

    const attachCallbacks = useCallback((shareDialog: HTMLElement) => {
        if (shareDialog) {
            // Attach to custom events
            shareDialog.addEventListener("dialogOpen", handleOpened);
            shareDialog.addEventListener("dialogClose", handleClose);
            shareDialog.addEventListener("close", handleClose);
            shareDialog.addEventListener(
                "inviteFulfilled",
                handleInviteFulfilled,
            );
            shareDialog.addEventListener(
                "removeFulfilled",
                handleRemoveFulfilled,
            );
        }
    }, []);

    const removeCallbacks = useCallback((shareDialog: HTMLElement) => {
        if (shareDialog) {
            shareDialog.removeEventListener("dialogOpen", handleOpened);
            shareDialog.removeEventListener("dialogClose", handleClose);
            shareDialog.removeEventListener("close", handleClose);
            shareDialog.removeEventListener(
                "inviteFulfilled",
                handleInviteFulfilled,
            );
            shareDialog.removeEventListener(
                "removeFulfilled",
                handleRemoveFulfilled,
            );
        }
    }, []);

    return { attachCallbacks, removeCallbacks };
};

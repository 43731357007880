/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";
import { throttle } from "@components/studio/src/utils/throttle";
import { useEffect, useRef, useState } from "react";

import { AssetGridSection } from "./AssetGridSection";
import { ScrollingContainer } from "../../common/components/ScrollingContainer";
import { useUserLibraryContext } from "../UserLibrary";
import { useSavedScrollPosition } from "@src/interfaces/common/hooks/useSavedScrollPosition";
import { useSpectrumCssVarValue } from "@src/interfaces/common/hooks/useSpectrumCssVarValue";

interface Props {
    assetCount: number;
}

const SPECTRUM_VAR_PREFIX = "--spectrum-global-dimension-";

export const GRID_STYLE_SIZES = {
    width: {
        spectrumParam: "size-3000",
        fallbackSize: "240px",
    },
    gap: {
        spectrumParam: "size-250",
        fallbackSize: "20px",
    },
    sidePadding: {
        spectrumParam: "size-800",
        fallbackSize: "64px",
    },
};

type SpectrumQuery = (typeof GRID_STYLE_SIZES)["width"];

const TARGET_NUMBER_OF_ITEMS_IN_A_PAGE = 20;

export function AssetGridPaged({ assetCount }: Props) {
    const observerRootRef = useRef<HTMLDivElement | null>(null);
    const [viewWidth, setViewWidth] = useState<number>();
    const [itemsPerPageQuery, setItemsPerPageQuery] = useState(
        TARGET_NUMBER_OF_ITEMS_IN_A_PAGE,
    );

    const { filterKey } = useUserLibraryContext();

    const { getSpectrumCssVarValue } = useSpectrumCssVarValue();

    function querySpectrumSize({ spectrumParam, fallbackSize }: SpectrumQuery) {
        return getSpectrumCssVarValue(
            SPECTRUM_VAR_PREFIX + spectrumParam,
            fallbackSize,
        );
    }

    const { handleScroll } =
        useSavedScrollPosition(`asset-grid-${filterKey}`, observerRootRef);


    const sections = [];
    for (let i = 0; i < assetCount; i = i + itemsPerPageQuery) {
        const itemCount = Math.min(assetCount - i, itemsPerPageQuery);
        sections.push(
            <AssetGridSection
                key={`${i}-${i + itemCount}/${assetCount}`}
                offset={i}
                itemCount={itemCount}
                observerRootRef={observerRootRef}
            />,
        );
    }

    const updateWidth = throttle(() => {
        if (observerRootRef.current) {
            const { width } = observerRootRef.current.getBoundingClientRect();
            setViewWidth(width);
        }
    }, 100);

    useEffect(() => {
        updateWidth();
    }, [observerRootRef]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(updateWidth);
        if (observerRootRef.current) {
            resizeObserver.observe(observerRootRef.current);
        }
        return () => {
            resizeObserver.disconnect();
        };
    }, [observerRootRef]);

    // We need to know how many columns the current layout has because we use placeholder pages
    // The pages allow us to use fewer intersection observers and to only render visible section without altering
    // a massive list. If we don't show even multiples of columns the pages will have incomplete rows and not
    // appear contiguous
    useEffect(() => {
        if (viewWidth) {
            // Use grid spectrum style --spectrum-global-dimension-size-3000 and fallback to 240
            const cardWidth = parseInt(
                querySpectrumSize(GRID_STYLE_SIZES.width),
                10,
            );

            // Use grid spectrum style --spectrum-global-dimension-size-250 and fallback to 20
            const gapWidth = parseInt(
                querySpectrumSize(GRID_STYLE_SIZES.gap),
                10,
            );

            // Use grid spectrum style --spectrum-global-dimension-size-800 and fallback to 64
            const paddingWidth = parseInt(
                querySpectrumSize(GRID_STYLE_SIZES.sidePadding),
                10,
            );

            const columns = Math.max(
                1,
                Math.floor(
                    (viewWidth + gapWidth - 2 * paddingWidth - 17) /
                        (cardWidth + gapWidth),
                ),
            );
            const rows = Math.ceil(TARGET_NUMBER_OF_ITEMS_IN_A_PAGE / columns);
            setItemsPerPageQuery(columns * rows);
        }
    }, [viewWidth]);

    return (
        <ScrollingContainer
            forwardedRef={observerRootRef}
            onScroll={handleScroll}>
            <View
                paddingStart={GRID_STYLE_SIZES.sidePadding.spectrumParam}
                paddingEnd={GRID_STYLE_SIZES.sidePadding.spectrumParam}
                paddingBottom="size-200">
                {sections}
            </View>
        </ScrollingContainer>
    );
}

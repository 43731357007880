/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { createHeaders, getWorkfrontApiObjectBaseUrl } from "../util";
import { WF_OBJECT_CODE_DOCUMENT } from "../constants";
import { WorkfrontParams } from "../../../types/wfParams";
import { WfDocumentList } from "../../../types/Documents";

export const fetchWorkfrontDocumentsByTaskId = async function (
    wfParams: WorkfrontParams,
    taskID: string,
): Promise<WfDocumentList> {
    try {
        const headers = createHeaders(wfParams.imsToken);

        const WF_FETCH_DOCUMENTS_BY_TASK_ID = `${getWorkfrontApiObjectBaseUrl({
            objCode: WF_OBJECT_CODE_DOCUMENT,
            wfInstanceUrl: wfParams.wfInstanceUrl,
        })}/search?taskID=${taskID}&fields=previewURL`;

        const res = await fetch(WF_FETCH_DOCUMENTS_BY_TASK_ID, {
            headers,
        });

        const apiResponse: { data: WfDocumentList } = await res.json();
        return apiResponse.data;
    } catch (e) {
        throw new Error("Was not able to fetch the document");
    }
};

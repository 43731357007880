/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Checkbox,
    Form,
    Heading,
    TextField,
    View,
} from "@adobe/react-spectrum";
import { FormEvent, useEffect, useState } from "react";

import { AemConfigInput, rpc } from "@src/contexts/RpcContext";

const imsClientIdDefault = "cm-p115476-e1135027-integration-1";
const imsOrgDefault = "28260E2056581D3B7F000101@AdobeOrg";
const imsScopeDefault = "additional_info.projectedProductContext,openid,read_organizations,AdobeID,read_pc.dma_aem_ams, exchange_jwt";
const aemOrgNameDefault = "ags050";
const aemRepoIdDefault = "author-p115476-e1135027.adobeaemcloud.com";
const aemRootPathDefault = "/content/dam";

export function Config() {
    const [aemConfigEnabled, setAemConfigEnabled] = useState(false);
    const { data: configs, refetch } = rpc.orgs.getConfigs.useQuery();
    const saveAemConfig = rpc.orgs.setAemConfig.useMutation();

    async function onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const params = Object.fromEntries(
            new FormData(event.target as HTMLFormElement),
        ) as Omit<AemConfigInput, "enabled">;
        await saveAemConfig.mutateAsync({
            ...params,
            enabled: aemConfigEnabled,
        });
        refetch();
    }

    useEffect(() => {
        if (configs?.aemConfig && !aemConfigEnabled) {
            setAemConfigEnabled(true);
        }
    }, [configs]);

    return (
        <View height="100%" paddingStart="size-600">
            <Heading level={1}>Configure Organization</Heading>
            <p>If you don't know what this is don't touch anything</p>
            <Checkbox
                onChange={setAemConfigEnabled}
                isSelected={aemConfigEnabled}>
                Enable AEM as a project target
            </Checkbox>
            <Form
                validationBehavior="native"
                onSubmit={onSubmit}>
                <TextField
                    isRequired
                    isDisabled={!aemConfigEnabled}
                    name="imsClientId"
                    label="imsClientId"
                    defaultValue={
                        configs?.aemConfig?.imsClientId || imsClientIdDefault
                    }
                />
                <TextField
                    isRequired
                    isDisabled={!aemConfigEnabled}
                    name="imsOrg"
                    label="imsOrg"
                    defaultValue={configs?.aemConfig?.imsOrg || imsOrgDefault}
                />
                <TextField
                    isRequired
                    isDisabled={!aemConfigEnabled}
                    name="imsScope"
                    label="imsScope"
                    defaultValue={
                        configs?.aemConfig?.imsScope || imsScopeDefault
                    }
                />
                <TextField
                    isRequired
                    isDisabled={!aemConfigEnabled}
                    name="aemOrgName"
                    label="aemOrgName"
                    defaultValue={
                        configs?.aemConfig?.aemOrgName || aemOrgNameDefault
                    }
                />
                <TextField
                    isRequired
                    isDisabled={!aemConfigEnabled}
                    name="aemRepoId"
                    label="aemRepoId"
                    defaultValue={
                        configs?.aemConfig?.aemRepoId || aemRepoIdDefault
                    }
                />
                <TextField
                    isRequired
                    isDisabled={!aemConfigEnabled}
                    name="aemRootPath"
                    label="aemRootPath"
                    defaultValue={
                        configs?.aemConfig?.aemRootPath || aemRootPathDefault
                    }
                />
                <ButtonGroup marginTop="size-500">
                    <Button type="submit" variant="primary">
                        Submit
                    </Button>
                    <Button type="reset" variant="secondary">
                        Reset
                    </Button>
                </ButtonGroup>
            </Form>
        </View>
    );
}

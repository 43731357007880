/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Flex,
    Heading,
    Link as SpectrumLink,
    Text,
    View,
} from "@adobe/react-spectrum";
import Alert from "@spectrum-icons/workflow/Alert";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { AttachmentItemView } from "./AttachmentItemView";
import { FeedbackDropzoneIcon } from "./FeedbackDropzoneIcon";
import { MAX_FILE_SIZE } from "./useReadAttachment";
import { ThemeContext } from "@src/contexts/ThemeContext";

import type { FC } from "react";
import type { FileRejection } from "react-dropzone";

interface AttachmentViewProps {
    onPressHandler: () => void;
}
const AttachmentView: FC<AttachmentViewProps> = ({ onPressHandler }) => {
    const { t } = useTranslation("common");

    return (
        <Flex
            height="size-1700"
            minWidth="size-900"
            direction="column"
            alignItems="center"
            justifyContent="center"
            margin="size-100">
            <FeedbackDropzoneIcon />
            <Heading level={4}>
                {t("feedback.form.attachments.input.title")}
            </Heading>
            <Flex
                direction="row"
                justifyContent="center"
                columnGap="size-50"
                UNSAFE_style={{ fontSize: "12px", lineHeight: "21px" }}>
                <Text>
                    <SpectrumLink
                        variant="primary"
                        onPress={onPressHandler}
                        order={1}>
                        {t("feedback.form.attachments.input.action")}
                    </SpectrumLink>
                </Text>
                <Text>{t("feedback.form.attachments.input.subtitle")}</Text>
            </Flex>
        </Flex>
    );
};

interface Props {
    attachedFiles?: File[];
    errorState?: string;
    handleAttachments: (
        acceptedFiles: File[],
        rejectedFiles: FileRejection[],
    ) => void;
    updateAttachment: (name: string, data: Uint8Array | null) => void;
}

export const FeedbackAttachments: FC<Props> = ({
    attachedFiles,
    handleAttachments,
    errorState,
    updateAttachment,
}) => {
    const { t } = useTranslation("common");
    const { isDarkMode } = useContext(ThemeContext);

    const [dragErrorState, setDragErrorState] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);
    const [showAttachments, setShowAttachments] = useState(false);
    const [attachmentsError, setAttachmentsError] = useState<
        string | undefined
    >();

    const onDragEnter = () => {
        setDragErrorState(false);
        setIsDragOver(true);
    };

    const onDragLeave = () => {
        setIsDragOver(false);
    };

    const onDrop = useCallback(
        (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
            const finalAcceptedFiles: File[] = [];
            const finalRejectedFiles: FileRejection[] = [...rejectedFiles];
            acceptedFiles.forEach((file) => {
                finalAcceptedFiles.push(file);
            });

            setIsDragOver(false);
            if (!acceptedFiles.length) {
                setDragErrorState(true);
            }
            if (finalRejectedFiles.length) {
                setDragErrorState(true);
            } else {
                setDragErrorState(false);
            }
            handleAttachments(finalAcceptedFiles, finalRejectedFiles);
        },
        [handleAttachments],
    );

    useEffect(() => {
        if (errorState?.length) {
            setDragErrorState(true);
            setAttachmentsError(errorState);
        } else {
            setDragErrorState(false);
            setAttachmentsError(undefined);
        }
    }, [errorState, dragErrorState]);

    useEffect(() => {
        setShowAttachments(!!attachedFiles?.length);
    }, [attachedFiles?.length]);

    // Accepts all types.
    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        onDragEnter,
        onDragLeave,
        maxSize: MAX_FILE_SIZE,
        multiple: true,
    });

    return (
        <Flex width="100%" height="size-2400">
            <div {...getRootProps()} style={{ width: "100%" }}>
                <input {...getInputProps()} />
                <Flex direction="column" rowGap="size-20" width="100%">
                    <View
                        paddingX="size-100"
                        backgroundColor={
                            isDarkMode ? "gray-100" : "static-white"
                        }
                        borderColor={
                            dragErrorState || errorState
                                ? "red-400"
                                : isDragOver
                                ? "blue-600"
                                : "gray-300"
                        }
                        borderWidth="thick"
                        borderRadius="medium"
                        UNSAFE_style={{ borderStyle: "dashed" }}>
                        <AttachmentView onPressHandler={open} />
                    </View>
                    {dragErrorState && errorState ? (
                        <Flex direction="row" columnGap="size-50">
                            <Alert
                                size="XS"
                                color="negative"
                                marginTop="size-100"
                            />
                            <Text
                                marginTop="size-100"
                                UNSAFE_style={{
                                    fontSize: "12px",
                                    lineHeight: "15.6px",
                                    color: "#EA3829",
                                }}>
                                {attachmentsError}
                            </Text>
                        </Flex>
                    ) : (
                        <Text
                            marginTop="size-100"
                            UNSAFE_style={{
                                fontSize: "12px",
                                lineHeight: "15.6px",
                            }}>
                            {t("feedback.form.attachments.description")}
                        </Text>
                    )}
                </Flex>
            </div>
            {showAttachments ? (
                <View
                    width="100%"
                    height="size-2400"
                    marginStart="size-100"
                    overflow="auto">
                    <Flex direction="column" rowGap="size-50">
                        {attachedFiles?.map((file) => {
                            return (
                                <AttachmentItemView
                                    key={file.name}
                                    file={file}
                                    updateAttachment={updateAttachment}
                                />
                            );
                        })}
                    </Flex>
                </View>
            ) : null}
        </Flex>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionMenu, Flex, Heading, Item, View } from "@adobe/react-spectrum";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { DownloadProjectDialog } from "../components/DownloadProjectDialog";
import { useDownloads } from "../hooks/useDownloads";
import { useSendTo } from "../hooks/useSendTo";
import { useAppContext } from "@src/contexts/AppContext";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import { rpc } from "@src/contexts/RpcContext";
import { useDoNotShowMeAgain } from "@src/interfaces/common/hooks/useDoNotShowMeAgain";

import type { ProjectToDownload } from "../components/DownloadProjectDialog";
import type { Key, PropsWithChildren } from "react";

interface Props {
    projectId: string;
}

type ProjectActionMenuKey = "download" | "sendToAem" | "sendToWf";
const ProjectActionMenuItems: readonly {
    key: ProjectActionMenuKey;
    labelKey: string;
}[] = [
    {
        key: "download",
        labelKey: "actions.download",
    },
] as const;

export const BaseProjectNavBar = ({
    projectId,
    children,
}: PropsWithChildren<Props>) => {
    const { t } = useTranslation("common");
    const { error, success } = useMessagingContext();
    const menuItems = [...ProjectActionMenuItems];

    const { data: project } = rpc.projects.get.useQuery(projectId);
    const { data: orgConfigs } = rpc.orgs.getConfigs.useQuery();

    const { client: apiClient } = rpc.useContext();
    const [projectToDownload, setProjectToDownload] =
        useState<ProjectToDownload>();
    const { downloadProject } = useDownloads(apiClient);
    const { doNotShowProjectDownloadDialog } = useDoNotShowMeAgain();
    const { ims, logger } = useAppContext();
    const { sendToAem } = useSendTo(ims.accessToken || "");

    if (orgConfigs?.aemConfig) {
        menuItems.push({key: "sendToAem", labelKey: "actions.sendToAem"});

    }

    function doDownloadProject(projectId: string, projectName: string) {
        downloadProject(projectId, projectName, ims, logger).catch(() => {
            error(
                t("toast.downloadFailed", {
                    name: projectName,
                    interpolation: { escapeValue: false },
                }),
            );
        });
        success(t("toast.downloadRequested"));
    }

    const onActionMenu = useCallback(
        (key: Key) => {
            switch (key as ProjectActionMenuKey) {
                case "download":
                    if (!project) {
                        break;
                    }
                    if (doNotShowProjectDownloadDialog) {
                        doDownloadProject(project.id, project.name);
                    } else {
                        setProjectToDownload({
                            projectId: project.id,
                            projectName: project.name,
                        });
                    }
                    break;
                case "sendToAem":
                    if (!project) return;
                    sendToAem(projectId, project.name).then(() => {
                        success(`${project.name} sent to AEM`)
                    }).catch((e) => {
                        if (e) {
                            error(`Failed to send to AEM ${e}`);
                        }
                    })
                    break;

            }
        },
        [project, doNotShowProjectDownloadDialog],
    );

    // If no project is selected, the Project Navbar should not not be shown.
    return (
        <View
            backgroundColor="gray-100"
            height="size-800"
            width="100%"
            borderBottomWidth="thick"
            borderBottomColor="gray-50">
            {project && (
                <Flex
                    alignItems="center"
                    height="100%"
                    justifyContent="space-between"
                    marginStart="size-125">
                    <Heading level={4}>{project.name}</Heading>

                    <Flex
                        gap="size-125"
                        alignItems="center"
                        marginEnd="size-125">
                        <ActionMenu
                            onAction={onActionMenu}
                            items={menuItems}
                            isQuiet>
                            {(item) => (
                                <Item key={item.key}>{t(item.labelKey)}</Item>
                            )}
                        </ActionMenu>
                        {children}
                        <DownloadProjectDialog
                            projectToDownload={projectToDownload}
                            setProjectToDownload={setProjectToDownload}
                            doDownloadProject={doDownloadProject}
                        />
                    </Flex>
                </Flex>
            )}
        </View>
    );
};

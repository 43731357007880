/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionButton, DialogTrigger, Text } from "@adobe/react-spectrum";
import UploadToCloudOutline from "@spectrum-icons/workflow/UploadToCloudOutline";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { UploadDialog } from "./UploadDialog";
import { useAppContext } from "@src/contexts/AppContext";
import type { UploadAnalyticsBatchFields } from "@src/lib/analytics/AnalyticsConstants";

import type { FC } from "react";
import type { FileRejection } from "react-dropzone";

interface Props {
    handleFiles: (
        acceptedFiles: File[],
        rejectedFiles: FileRejection[],
        uploadAnalyticsFields: UploadAnalyticsBatchFields,
    ) => Promise<void>;
}

export const Upload: FC<Props> = ({ handleFiles }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { rpcConnection } = useAppContext();

    const { t } = useTranslation("library");

    return (
        <DialogTrigger
            isOpen={isOpen}
            onOpenChange={(isOpen) => setIsOpen(isOpen)}>
            <ActionButton
                isQuiet
                isDisabled={!(rpcConnection.connected && rpcConnection.online)}>
                <UploadToCloudOutline size="S" />
                <Text
                    UNSAFE_style={{
                        fontWeight: "bold",
                    }}>
                    {t("actions.uploadAssets")}
                </Text>
            </ActionButton>
            <UploadDialog
                multiSelect
                handleFiles={handleFiles}
                close={() => setIsOpen(false)}
            />
        </DialogTrigger>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Divider, Flex, Heading, Text, View } from "@adobe/react-spectrum";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AddAssetsTrigger } from "./AddAssetsTrigger";
import { PreviewAssetRow } from "./PreviewAssetRow";
import { RenderTrigger } from "./RenderTrigger";
import { SelectionTrigger } from "./SelectionTrigger";
import { rpc } from "@src/contexts/RpcContext";
import { ScrollingContainer } from "@src/interfaces/common/components/ScrollingContainer";
import { useInfiniteScroll } from "@src/interfaces/common/hooks/useInfiniteScroll";

import type { ProjectsPanelProps } from "./BaseProjectLibrary";
import type { FC } from "react";

interface Props extends ProjectsPanelProps {
    projectId: string;
    canUserModify: boolean;
    reviewAccess?: boolean;
}

export const ProjectView: FC<Props> = observer(
    ({
        projectId,
        canUserModify,
        reviewAccess,
        ControlsPortal,
        SelectionPortal,
    }) => {
        const { t } = useTranslation(["studio"]);

        const { data: all2DShotIds } =
            rpc.shots.get2DShotsInProject.useQuery(projectId);
        const assetsQueryResult =
            rpc.projects.getAssetsInProjectPaged.useInfiniteQuery(
                {
                    projectId,
                    count: 5,
                },
                {
                    getNextPageParam: (lastPage) => lastPage.nextCursor,
                },
            );

        const { scrollRef, handleScroll } =
            useInfiniteScroll("project-view",assetsQueryResult);

        const [selectedRenders, setSelectedRenders] = useState<string[]>([]);

        const [assetsInProject, setAssetsInProject] = useState<
            { id: string; name: string }[]
        >([]);

        useEffect(() => {
            setAssetsInProject(
                assetsQueryResult.data?.pages
                    .filter((page) => page.assets?.length > 0)
                    .flatMap((page) => page.assets) ?? [],
            );
        }, [assetsQueryResult.data?.pages]);

        useEffect(() => {
            setSelectedRenders([]);
        }, [projectId]);

        return (
            <View
                backgroundColor="gray-75"
                width="100%"
                height="100%"
                paddingTop="size-125">
                {SelectionPortal && (
                    <SelectionPortal>
                        {selectedRenders.length > 0 && (
                            <Text>
                                {t("studio:label.numSelected", {
                                    count: selectedRenders.length,
                                })}
                            </Text>
                        )}
                    </SelectionPortal>
                )}
                {!reviewAccess && ControlsPortal && (
                    <ControlsPortal>
                        <Flex columnGap="size-100">
                            {selectedRenders.length > 0 && (
                                <SelectionTrigger
                                    all2DShotIds={
                                        new Set(
                                            all2DShotIds?.map(({ id }) => id) ??
                                                [],
                                        )
                                    }
                                    selectedRenders={selectedRenders}
                                    setSelectedRenders={setSelectedRenders}
                                />
                            )}
                            <RenderTrigger
                                projectId={projectId}
                                canUserModify={canUserModify}
                                selectedRenders={selectedRenders}
                                setSelectedRenders={setSelectedRenders}
                            />
                            <Divider
                                size="S"
                                orientation="vertical"
                                marginStart="size-50"
                                marginEnd="size-50"
                                isHidden={!canUserModify}
                            />
                            <AddAssetsTrigger
                                projectId={projectId}
                                canUserModify={canUserModify}
                                hasSelectedRenders={selectedRenders.length > 0}
                            />
                        </Flex>
                    </ControlsPortal>
                )}

                {assetsInProject.length > 0 && (
                    <ScrollingContainer
                        forwardedRef={scrollRef}
                        onScroll={handleScroll}>
                        {assetsInProject.map((asset) => (
                            <React.Fragment key={projectId + asset.id}>
                                <Flex
                                    alignItems="center"
                                    gap="size-200"
                                    margin="size-350">
                                    <Heading level={3} margin="size-0">
                                        {asset.name}
                                    </Heading>
                                </Flex>
                                {asset && (
                                    <PreviewAssetRow
                                        projectId={projectId}
                                        assetId={asset.id}
                                        canUserModify={canUserModify}
                                        selectedRenders={selectedRenders}
                                        setSelectedRenders={setSelectedRenders}
                                        reviewAccess={reviewAccess}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </ScrollingContainer>
                )}
            </View>
        );
    },
);

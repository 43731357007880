/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionButton, Flex, Heading, View } from "@adobe/react-spectrum";
import BackAndroid from "@spectrum-icons/workflow/BackAndroid";
import { useTranslation } from "react-i18next";

import type { PropsWithChildren } from "react";

type Props = {
    name: string;
    disableBackButton?: boolean;
    onBackButtonClicked: () => void;
};

export const DetailViewNav = ({
    name,
    disableBackButton,
    onBackButtonClicked,
    children,
}: PropsWithChildren<Props>) => {
    const { t } = useTranslation(["common"]);

    return (
        <View width="100%" backgroundColor="gray-100">
            <Flex
                height="100%"
                justifyContent="space-between"
                alignItems="center">
                <Flex
                    alignItems="center"
                    marginStart="size-250"
                    columnGap="size-150"
                    marginEnd="size-250">
                    {!disableBackButton && (
                        <ActionButton
                            aria-label={t("actions.back")}
                            isQuiet
                            onPress={() => {
                                onBackButtonClicked();
                            }}>
                            <BackAndroid />
                        </ActionButton>
                    )}
                    <Heading level={3}>{name}</Heading>
                </Flex>
                {children}
            </Flex>
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useState, useEffect } from "react";

import { use3dHotSpotData } from "./use3dHotSpotData";
import { use3dPins } from "./use3dPins";
import { useBoundedPositioning } from "./useBoundedPositioning";
import { portalFactory } from "../utils/portalFactory";

import type { BoundedBoxOptions } from "./useBoundedPositioning";
import type { SceneManager } from "../scene/SceneManager";
import type { PinEvent, SerializedPin } from "@3di/adobe-3d-viewer";
import type { HotSpot } from "@shared/types";

type ActiveHotSpot = HotSpot & Pick<PinEvent, "pointer" | "boundingBox">;

/**
 *
 * @param assetId
 * @param sceneManager
 * @param overlayRef
 * @param hotSpotDialogBoundingOptions - The options used to position the hot spot dialog
 * They should be updated when ever the dialog markup is changed. It's much more expensive to
 * actually measure the dialog so this is a performance op because the dialog is a fixed size.
 * This might break if there is a user zoom level.
 * @returns
 */
export function useHotSpots(
    sceneManager: SceneManager | undefined,
    overlayRef: React.RefObject<HTMLDivElement>,
    dialogRef: HTMLDivElement | null,
    hotSpotDialogBoundingOptions: BoundedBoxOptions,
    initialHotSpots?: HotSpot[],
    onHotSpotsUpdate?: (hotSpots: HotSpot[]) => void,
) {
    const [pins, setPins] = useState<SerializedPin[]>([]);
    const [activeHotSpot, setActiveHotSpot] = useState<ActiveHotSpot>();
    const [hotSpotToEdit, setHotSpotToEdit] = useState<HotSpot>();

    const [OverlayPortal, setOverlayPortal] =
        useState<ReturnType<typeof portalFactory>>();
    // These hard coded values are the size in pixels of the hot spot dialog and padding in pixels
    // They should be updated when ever the dialog markup is changed. It's much more expensive to
    // actually measure the dialog so this is a performance op because the dialog is a fixed size.
    // This might break if there is a user zoom level.
    const { getBoundedPosition } = useBoundedPositioning(
        hotSpotDialogBoundingOptions,
        overlayRef,
        dialogRef,
    );

    const [activePosition, setActivePosition] = useState({ x: 0, y: 0 });
    useEffect(() => {
        if (activeHotSpot) {
            setActivePosition(() =>
                getBoundedPosition(activeHotSpot.boundingBox),
            );
        }
    }, [activeHotSpot, getBoundedPosition]);

    const {
        hotSpots,
        hotSpotsRef,
        createHotSpot,
        deleteHotSpot,
        updateHotSpotPin,
        updateHotSpotTitle,
        updateHotSpotText,
    } = use3dHotSpotData(initialHotSpots, onHotSpotsUpdate);

    useEffect(() => {
        const updatedPins: SerializedPin[] = hotSpots
            .filter((hotSpot) => hotSpot.pin)
            .map((hotSpot) => hotSpot.pin as SerializedPin);
        setPins(updatedPins);
    }, [hotSpots]);

    const {
        addOrUpdatePin,
        onPinSelected,
        onPinDeselected,
        onCameraMove,
        getPinBoundingBox,
        deselectPins,
        cancelAddOrEditPin,
    } = use3dPins(pins, sceneManager);

    useEffect(() => {
        if (!activeHotSpot && !hotSpotToEdit) {
            deselectPins();
        }
    }, [activeHotSpot, hotSpotToEdit]);

    useEffect(() => {
        if (overlayRef.current) {
            const newOverlayPortal = portalFactory(overlayRef.current);
            setOverlayPortal(() => newOverlayPortal);
        }
    }, [overlayRef.current]);

    const addOrUpdateHotSpot = (hotSpot?: HotSpot) => {
        setActiveHotSpot(undefined);
        setHotSpotToEdit(hotSpot || createHotSpot());
    };

    const clearHotSpotToEdit = () => {
        cancelAddOrEditPin();
        setHotSpotToEdit(undefined);
    };

    useEffect(() => {
        let timer: number;
        if (hotSpotToEdit) {
            timer = window.setTimeout(() => {
                addOrUpdatePin(
                    hotSpotToEdit.id,
                    (pin) => {
                        setHotSpotToEdit(undefined);
                        updateHotSpotPin(hotSpotToEdit.id, pin);
                    },
                    (createMode) => {
                        setHotSpotToEdit(undefined);
                        if (createMode) {
                            deleteHotSpot(hotSpotToEdit.id);
                        }
                    },
                );
            }, 100);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [hotSpotToEdit]);

    useEffect(() => {
        onPinSelected(({ pointer, boundingBox, pinData: { id } }) => {
            // We have to use a ref here because this effect should only
            // be run once and needs to always access the latest hotSpotList
            const hotSpot = hotSpotsRef.current.find(
                (hotSpot) => hotSpot.id === id,
            );
            if (hotSpot) {
                setActivePosition({ x: 0, y: 0 });
                setActiveHotSpot({ pointer, boundingBox, ...hotSpot });
            }
        });
    }, [onPinSelected]);

    useEffect(() => {
        onPinDeselected(() => {
            setActivePosition({ x: 0, y: 0 });
            setActiveHotSpot(undefined);
        });
    }, [onPinDeselected]);

    useEffect(() => {
        onCameraMove(() => {
            setActiveHotSpot((activeHotSpot) => {
                if (activeHotSpot) {
                    const boundingBox = getPinBoundingBox(activeHotSpot.id);

                    if (boundingBox) {
                        return {
                            ...activeHotSpot,
                            pointer: { x: boundingBox.x, y: boundingBox.y },
                            boundingBox,
                        };
                    }
                }
                return activeHotSpot;
            });
        });
    }, [onCameraMove]);

    const clearActiveHotSpot = () => {
        setActiveHotSpot(undefined);
    };

    return {
        activeHotSpot,
        clearActiveHotSpot,
        hotSpotToEdit,
        clearHotSpotToEdit,
        OverlayPortal,
        addOrUpdateHotSpot,
        deleteHotSpot,
        updateHotSpotTitle,
        updateHotSpotText,
        activePosition,
    };
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Button, Tooltip, TooltipTrigger } from "@adobe/react-spectrum";

import { dropShadowStyle } from "./CameraControls";
import { CameraFrame } from "../svg/camera-frame";

import type { SceneManager } from "../scene/SceneManager";

export interface FrameButtonProps {
    sceneManager?: SceneManager;
    aspectRatio: number;
    strings: {
        frameButton: string;
    };
}

export function FrameButton({
    aspectRatio,
    sceneManager,
    strings,
}: FrameButtonProps) {
    return (
        <TooltipTrigger placement="right">
            <Button
                UNSAFE_style={{
                    pointerEvents: "auto",
                    filter: dropShadowStyle,
                }}
                style="fill"
                variant="accent"
                staticColor="white"
                onPress={() => {
                    if (sceneManager) {
                        sceneManager.frame(aspectRatio);
                    }
                }}>
                <CameraFrame />
            </Button>
            <Tooltip>{strings.frameButton}</Tooltip>
        </TooltipTrigger>
    );
}

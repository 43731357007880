/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, View } from "@adobe/react-spectrum";

import { AssetGridPaged } from "./AssetGridPaged";
import { useUserLibraryContext } from "../UserLibrary";
import type { LibraryAssetsFilters } from "@src/contexts/RpcContext";
import { rpc } from "@src/contexts/RpcContext";
import { DelayedSpinner } from "@src/interfaces/common/components/DelayedSpinner";

export const AllAssetsWithFilter = () => {
    const { filterKey, searchValue } = useUserLibraryContext();

    const filteredAssetsCount = rpc.libraryAssets.getCount.useQuery({
        searchValue,
        filters:
            filterKey !== "all"
                ? ([filterKey] as LibraryAssetsFilters)
                : undefined,

    }, {
        staleTime: searchValue ? 0 : undefined,
    });


    return (
        <View flexGrow={1} height="100%">
            {/* TODO: If allAssets never returns, we will be stuck in an infinite spinner */}
            {filteredAssetsCount.data === undefined ? (
                <DelayedSpinner size="L" />
            ) : (
                <Flex height="100%">
                    <View flexGrow={1} height="100%">
                        <AssetGridPaged assetCount={filteredAssetsCount.data} />
                    </View>
                </Flex>
            )}
        </View>
    );
};

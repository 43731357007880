/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect } from "react";

import { config } from "@src/config";
import { rpc } from "@src/contexts/RpcContext";

const CheckInterval = 120_000; // 2 minutes
const RefreshDelay = 420_000; // 7 minutes

export function useVersionCheck() {
    const versionMutation = rpc.utilities.version.useMutation();
    useEffect(() => {
        let checkTimer = 0;
        let refreshTimer = 0;
        let needsRefresh = false;
        let refreshTimeStart = 0;
        let version: string;

        function doRefresh() {
            clearTimeout(refreshTimer);
            // Make delay relative to the time the refresh was requested and floor at 0
            const delay = Math.max(
                RefreshDelay - (Date.now() - refreshTimeStart),
                0,
            );

            // Allow deploy to fully complete before updating
            refreshTimer = window.setTimeout(() => {
                // eslint-disable-next-line no-self-assign
                location.href = location.href;
            }, delay);
        }

        function checkVersion() {
            window.clearTimeout(checkTimer);
            if (needsRefresh) {
                return;
            }

            versionMutation
                .mutateAsync()
                .then(async ({ version: newVersion }) => {
                    if (version && version !== newVersion) {
                        needsRefresh = true;
                        refreshTimeStart = Date.now();
                        doRefresh();
                    }
                    if (!version) {
                        version = newVersion;
                    }
                    checkTimer = window.setTimeout(checkVersion, CheckInterval);
                });
        }

        function blur() {
            window.clearTimeout(checkTimer);
            window.clearTimeout(refreshTimer);
        }

        function focus() {
            if (needsRefresh) {
                doRefresh();
            } else {
                checkVersion();
            }
        }

        if (config.env !== "local") {
            window.addEventListener("blur", blur);

            window.addEventListener("focus", focus);

            checkVersion();
        }

        return () => {
            if (config.env !== "local") {
                window.removeEventListener("blur", blur);
                window.removeEventListener("focus", focus);
            }
        };
    }, []);
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Content,
    Dialog,
    DialogContainer,
    Divider,
    Heading,
} from "@adobe/react-spectrum";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { FeedbackForm } from "./FeedbackForm";
import { useAppContext } from "@src/contexts/AppContext";
import { useFeatureFlagContext } from "@src/contexts/FeatureFlagContext";
import { useSentryContext } from "@src/contexts/SentryContext";

import type { FormHandlers, FeedbackUserContext } from "./FeedbackForm";
import type { DOMRefValue } from "@react-types/shared";
import type { FC } from "react";

interface Props {
    onClose: () => void;
    setShowMessageToUser: (type: string) => void;
}

export const SentryUserFeedback: FC<Props> = ({
    onClose,
    setShowMessageToUser,
}) => {
    const { FF_SENTRY_INTEGRATION } = useFeatureFlagContext();
    const { addUserFeedback } = useSentryContext();
    const { ims: imsUser } = useAppContext();
    const { t } = useTranslation("common");

    // Forward Ref
    const formValidationRef = useRef<FormHandlers>(null);
    const formRef = useRef<DOMRefValue<HTMLFormElement>>(null);

    const [userContext, setUserContext] = useState<FeedbackUserContext>({
        userId: "",
        userOrgId: "",
        email: "",
    });
    useEffect(() => {
        imsUser.getUserProfile().then((profileData) => {
            setUserContext({
                userId: profileData.userId,
                userOrgId: profileData.ownerOrg,
                email: profileData.email,
            });
        });
    }, []);

    const closeDialog = (feedbackType: string) => {
        setShowMessageToUser(feedbackType);
        onClose();
    };

    const onSubmitFeedbackForm = useCallback(async () => {
        const feedbackData = formValidationRef.current?.validateForm(true);
        if (!feedbackData) {
            return;
        }

        addUserFeedback(feedbackData, userContext);
        closeDialog(feedbackData.issueType);
    }, [formValidationRef.current]);

    const onCanceled = () => {
        // Clear attachments in case user added these.
        Sentry.getCurrentScope().clearAttachments();
        onClose();
    };

    return (
        <DialogContainer onDismiss={onClose}>
            {FF_SENTRY_INTEGRATION && (
                <Dialog size="M">
                    <Heading>{t("feedback.title")}</Heading>
                    <Divider />
                    <Content width="100%">
                        <FeedbackForm
                            ref={formValidationRef}
                            formRef={formRef}
                        />
                    </Content>
                    <ButtonGroup marginEnd="size-150" align="end">
                        <Button variant="secondary" onPress={onCanceled}>
                            {t("actions.cancel")}
                        </Button>
                        <Button
                            variant="accent"
                            type="submit"
                            onPress={onSubmitFeedbackForm}>
                            {t("actions.submit")}
                        </Button>
                    </ButtonGroup>
                </Dialog>
            )}
        </DialogContainer>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export const QueryableFields = [
    "id",
    "userId",
    "orgId",
    "state",
    "completion",
    "assetId",
    "name",
    "description",
    "queueId",
    "tier",
    "original_tier",
    "type",
    "spec",
    "result",
    "messages",
    "shouldCancel",
    "clusterId",
    "clusterJobId",
    "submitCtr",
    "submitClusterId",
    "timeSubmitAfter",
    "allocCpu",
    "notified",
    "cleanUp",
    "timeCreated",
    "timeLastChecked",
    "time_first_scheduled",
    "time_terminated",
] as const;

export type QueryableFieldsType = (typeof QueryableFields)[number];

export type QueryableFieldsDataTypes =
    | "string"
    | "boolean"
    | "date"
    | "json"
    | "clientInfo"
    | "contextMetadata"
    | "number";

export const QueryableFieldValueTypeMap: Record<
    QueryableFieldsType,
    QueryableFieldsDataTypes
> = {
    id: "string",
    userId: "clientInfo",
    orgId: "clientInfo",
    assetId: "contextMetadata",
    name: "contextMetadata",
    description: "contextMetadata",
    state: "string",
    completion: "number",
    queueId: "string",
    tier: "string",
    original_tier: "string",
    type: "string",
    spec: "json",
    result: "json",
    messages: "json",
    shouldCancel: "boolean",
    clusterId: "string",
    clusterJobId: "string",
    submitCtr: "number",
    submitClusterId: "string",
    allocCpu: "string",
    notified: "string",
    cleanUp: "string",
    timeCreated: "date",
    timeLastChecked: "date",
    time_first_scheduled: "date",
    time_terminated: "date",
    timeSubmitAfter: "date",
};

export type QueryFieldsMap = {
    [K in QueryableFieldsType]?: string;
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

import { QueryObserverResult, RefetchOptions, useQuery } from "react-query";
import { fetchTasksAssignedToMe } from "../persistence/workfront/aggregations/fetchTasksAssignedToMe";
import { fetchPossibleTaskStatuses } from "../persistence/workfront/aggregations/fetchPossibleTaskStuses";
import { SetStateAction } from "react";
import { TasksAssignedToMeWithSingleProject } from "../types/TasksAssignedToMeWithSingleProject";
import { fetchTaskById } from "../persistence/workfront/aggregations/fetchTaskById";
import { fetchSubTasksByParentTaskId } from "../persistence/workfront/aggregations/fetchSubTasksByParentTaskId";
import { TaskDetails } from "../types/TaskDetails";
import { WorkfrontParams } from "../types/wfParams";

export function useFetchTasksAssignedToMe(wfParams: WorkfrontParams) {
    return useQuery(["tasksAssignedToMe"], () => fetchTasksAssignedToMe(wfParams));
}

export function useFetchPossibleTaskStatuses(wfParams: WorkfrontParams) {
    return useQuery(["taskPossibleStatuses"], () => fetchPossibleTaskStatuses(wfParams));
}

export function useFetchTaskById(wfParams: WorkfrontParams, taskId: string) {
    return useQuery(["taskDetails"], () => fetchTaskById(wfParams, taskId), {
        enabled: false,
    });
}

export function useFetchSubTasksByParentTaskId(wfParams: WorkfrontParams, parentId: string) {
    return useQuery(
        [`subTasksByParentId: ${parentId}`],
        () => fetchSubTasksByParentTaskId(wfParams, parentId),
        {
            refetchOnWindowFocus: false,
        },
    );
}

export async function refetchData(
    refetch: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<TasksAssignedToMeWithSingleProject | TaskDetails, unknown>>,
    setIsRefetching: {
        (value: SetStateAction<boolean>): void;
        (arg0: boolean): void;
    },
) {
    try {
        setIsRefetching(true);
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        await refetch();
    } catch (error) {
        console.error(error);
    } finally {
        setIsRefetching(false);
    }
}

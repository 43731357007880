/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { DialogContainer } from "@adobe/react-spectrum";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { ConfirmDialog } from "@src/interfaces/common/components/ConfirmDialog";
import { useDoNotShowMeAgain } from "@src/interfaces/common/hooks/useDoNotShowMeAgain";

import type { FC } from "react";

export type ProjectToDownload = { projectId: string; projectName: string };

type Props = {
    projectToDownload?: ProjectToDownload;
    setProjectToDownload: ReturnType<typeof useState<ProjectToDownload>>[1];
    doDownloadProject: (projectId: string, projectName: string) => void;
};

export const DownloadProjectDialog: FC<Props> = ({
    projectToDownload,
    setProjectToDownload,
    doDownloadProject,
}) => {
    const { t } = useTranslation(["studio", "common"]);
    const {
        doNotShowProjectDownloadDialog: {
            saveFn: saveDoNotShowProjectDownloadDialog,
        },
    } = useDoNotShowMeAgain();
    const [doNotAskAgain, setDoNotAskAgain] = useState<boolean>(false);

    const closeDialog = () => {
        setProjectToDownload(undefined);
    };

    const doDownload = useCallback(() => {
        if (doNotAskAgain) {
            saveDoNotShowProjectDownloadDialog();
        }
        if (!projectToDownload) {
            throw new Error("The dialog should not be open without an id");
        }
        doDownloadProject(
            projectToDownload.projectId,
            projectToDownload.projectName,
        );
        closeDialog();
    }, [projectToDownload]);

    return (
        <DialogContainer onDismiss={closeDialog}>
            {!!projectToDownload && (
                <ConfirmDialog
                    title={t("dialogs.download.title")}
                    setDoNotAskAgain={setDoNotAskAgain}
                    onCancel={closeDialog}
                    onConfirm={doDownload}
                    confirmBtnText={t("common:actions.download")}>
                    {t("dialogs.download.body")}
                </ConfirmDialog>
            )}
        </DialogContainer>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type { UseTranslationResponse } from "react-i18next";

export const MODEL_CONTENT_TYPES = {
    glb: "model/gltf-binary",
    usdz: "model/vnd.usdz+zip",
    fbx: "application/octet-stream",
} as const;

export function formatName(
    t: UseTranslationResponse<"common">["t"] | undefined,
    fileName: string | undefined,
    maxLength = 255,
    defaultStr = "",
): string {
    if (!fileName || fileName.length === 0) {
        if (!t) {
            return defaultStr;
        }
        return t("common:filename.untitled");
    }
    let newName = fileName;
    newName = newName.replace(/\.[^/.]+$/, "");
    if (newName.length > maxLength) {
        newName = newName.substring(0, maxLength);
    }
    return newName;
}

/**
 * Returns the lowercase file extension for the given name. If `name` does not have an
 * extension, the defaultExtension is returned.
 */
export function fileExtension(name: string, defaultExtension: string): string {
    return (
        name
            ?.match(/\.[^/.]+$/)
            ?.toString()
            .toLowerCase() || defaultExtension.toLowerCase()
    );
}

export function getContentType(
    filename: string,
): undefined | (typeof MODEL_CONTENT_TYPES)[keyof typeof MODEL_CONTENT_TYPES] {
    const fileExtension = filename.split(".").pop()?.toLowerCase();
    if (!fileExtension) return;
    return MODEL_CONTENT_TYPES[
        fileExtension as keyof typeof MODEL_CONTENT_TYPES
    ];
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionGroup,
    Avatar,
    Divider,
    Flex,
    Heading,
    Item,
    Link as LinkSpectrum,
    Picker,
    Text,
    View,
} from "@adobe/react-spectrum";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useLocale } from "../hooks/useLocale";
import { useOpenInNewTab } from "../hooks/useOpenInNewTab";
import { config } from "@src/config";
import { useAppContext } from "@src/contexts/AppContext";
import { ThemeContext } from "@src/contexts/ThemeContext";
import { DefaultAvatar } from "@src/interfaces/common/components/DefaultAvatar";

import type { Selection } from "@adobe/react-spectrum";

const AVATAR_DESIGN_SIZE = 75;

interface Props {
    userDisplayName: string;
    userAvatarLink?: string;
    company: string;
    onShowLegalNotices: VoidFunction;
}

export enum ColorThemeType {
    light = "Light",
    dark = "Dark",
}

export function UserAccountOverlay ({
    userDisplayName,
    userAvatarLink,
    company,
    onShowLegalNotices,
}: Props) {
    const { isDarkMode, handleThemeToggle } = useContext(ThemeContext);
    const { ims } = useAppContext();
    const { t } = useTranslation(["common"]);
    const {locale, changeLocale, supportedLocales} = useLocale();

    const [userEmailAddress, setUserEmailAddress] = useState<string>();
    useEffect(() => {
        ims.getUserProfile().then((profileData) => {
            setUserEmailAddress(profileData.email);
        });
    }, [userEmailAddress]);

    const [selectedTheme, setSelectedTheme] = useState<Selection>(
        isDarkMode
            ? new Set([ColorThemeType.dark])
            : new Set([ColorThemeType.light]),
    );

    useEffect(() => {
        const themeType = (selectedTheme as Set<ColorThemeType>)
            .keys()
            .next().value;
        if (
            (isDarkMode && themeType !== ColorThemeType.dark) ||
            (!isDarkMode && themeType !== ColorThemeType.light)
        ) {
            handleThemeToggle();
        }
    }, [selectedTheme]);

    const { openInNewTab } = useOpenInNewTab();
    function onClickEditProfile() {
        openInNewTab(config.editProfileEndpoint);
    }

    return (
        <View
            borderColor="gray-100"
            borderWidth="thick"
            borderRadius="small"
            UNSAFE_style={{
                backgroundColor: isDarkMode ? "#1e1e1e" : "#ffffff",
            }}>
            <Flex
                direction="column"
                alignItems="start"
                columnGap="size-200"
                marginTop="size-200"
                marginY="size-200"
                marginX="size-400"
                marginStart="size-400"
                marginEnd="size-400">
                <Flex
                    alignItems="start"
                    justifyContent="start"
                    columnGap="size-200"
                    marginTop="size-200"
                    marginBottom="size-200">
                    {userAvatarLink ? (
                        <Avatar
                            src={userAvatarLink}
                            size={AVATAR_DESIGN_SIZE}
                            alt={t("common:user.avatar")}
                        />
                    ) : (
                        <DefaultAvatar
                            aria-label={t("common:user.avatar")}
                            size="L"
                        />
                    )}
                    <Flex
                        direction="column"
                        alignItems="start"
                        rowGap="size-100">
                        <Heading level={3}>{userDisplayName}</Heading>
                        <Text>{userEmailAddress}</Text>
                        <Text>{company}</Text>
                        <LinkSpectrum
                            onPress={onClickEditProfile}
                            variant="secondary"
                            aria-label={t("common:account.editProfile")}>
                            {t("common:account.editProfile")}
                        </LinkSpectrum>
                    </Flex>
                </Flex>
                <Divider size="S" />
                <Flex
                    direction="column"
                    alignItems="start"
                    rowGap="size-200"
                    marginTop="size-200"
                    marginBottom="size-200">
                    <Text>{t("common:account.themeSwitcher")}</Text>
                    <View width="100%">
                        <ActionGroup
                            disallowEmptySelection
                            orientation="horizontal"
                            selectionMode="single"
                            density="compact"
                            selectedKeys={selectedTheme}
                            onSelectionChange={setSelectedTheme}>
                            <Item key={ColorThemeType.light}>
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "11px",
                                    }}>
                                    {t("account.theme.light")}
                                </Text>
                            </Item>
                            <Item key={ColorThemeType.dark}>
                                <Text
                                    UNSAFE_style={{
                                        fontSize: "11px",
                                    }}>
                                    {t("account.theme.dark")}
                                </Text>
                            </Item>
                        </ActionGroup>
                    </View>
                </Flex>
                <Divider size="S" />
                <Picker
                    label={t("account.language-selector.label")}
                    selectedKey={locale}
                    onSelectionChange={(key) => {
                        changeLocale(key as string);
                    }}
                    items={supportedLocales}
                    marginTop="size-200"
                    marginBottom="size-200">
                    {(lang) => <Item key={lang.value}>{lang.language}</Item>}
                </Picker>
                <Divider size="S" />
                <Flex
                    direction="column"
                    alignItems="start"
                    rowGap="size-200"
                    marginTop="size-200"
                    marginBottom="size-200">
                    <LinkSpectrum
                        onPress={() => {
                            onShowLegalNotices();
                        }}
                        variant="secondary"
                        isQuiet
                        aria-label={t("common:account.legal.notices")}>
                        {t("common:account.legal.notices")}
                    </LinkSpectrum>
                    <LinkSpectrum
                        onPress={() => ims.logOut()}
                        variant="secondary"
                        isQuiet
                        aria-label={t("common:account.logout")}>
                        {t("common:account.logout")}
                    </LinkSpectrum>
                </Flex>
            </Flex>
        </View>
    );
};

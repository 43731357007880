/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useCallback, useEffect, useRef, useState } from "react";

import { useSavedScrollPosition } from "./useSavedScrollPosition";

import type { UIEvent } from "react";

// Trigger loading when distance to bottom is less than half a page
const PAGING_PERCENTAGE = 0.5;

type InfiniteQueryList = {
    hasNextPage?: boolean;
    isFetching: boolean;
    fetchNextPage: () => void;
};

export const useInfiniteScroll = (
    context: string,
    itemList: InfiniteQueryList,
) => {
    const [scrollResizeObserver, setScrollResizeObserver] =
        useState<ResizeObserver>();
    const scrollDivRef = useRef<HTMLDivElement>();

    const {
        handleScroll: savedHandleScroll,
    } = useSavedScrollPosition(context, scrollDivRef);

    function handleScroll(event: UIEvent) {
        savedHandleScroll(event);
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

        // scrollBottom should be the distance from the bottom of the
        // viewable area to the bottom of the content container
        const scrollBottom = scrollHeight - scrollTop - clientHeight;
        // calculate distance to the bottom in percentage of page height
        const pagePercentRemaining = scrollBottom / clientHeight;

        if (
            pagePercentRemaining < PAGING_PERCENTAGE &&
            itemList.hasNextPage &&
            !itemList.isFetching
        ) {
            itemList.fetchNextPage();
        }
    }

    useEffect(() => {
        const newScrollResizeObserver = new ResizeObserver((entries) => {
            if (
                entries.length &&
                entries[0].target.clientHeight ===
                    entries[0].target.scrollHeight &&
                itemList.hasNextPage &&
                !itemList.isFetching
            ) {
                itemList.fetchNextPage();
            }
        });
        setScrollResizeObserver(newScrollResizeObserver);
        if (scrollDivRef.current) {
            newScrollResizeObserver.observe(scrollDivRef.current);
        }
        return () => {
            if (scrollResizeObserver) {
                scrollResizeObserver.disconnect();
            }
        };
    }, [itemList.hasNextPage, itemList.isFetching]);

    const scrollRef = useCallback(
        (node: HTMLDivElement) => {
            if (scrollResizeObserver && scrollDivRef.current) {
                scrollResizeObserver.unobserve(scrollDivRef.current);
            }

            if (node) {
                scrollDivRef.current = node;
                if (scrollResizeObserver) {
                    scrollResizeObserver.observe(node);
                }
            }
        },
        [scrollResizeObserver],
    );

    return { scrollRef, handleScroll };
};

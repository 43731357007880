/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useState } from "react";

export function useLocalStorage<T extends object | string | number>(
    key: string,
    defaultValue: T,
    convert?: (val: string | null) => T,
): [T, (update: T | ((currentVal: T) => T)) => void] {
    const cov = convert || ((val: string | null) => val as T);
    const [value, setValue] = useState<T>(
        cov(window.localStorage.getItem(key)) || defaultValue,
    );

    function setLocalStorage(update: T) {
        window.localStorage.setItem(key, JSON.stringify(update));
    }

    function updateStorage(update: T | ((currentVal: T) => T)) {
        setValue((currentVal) => {
            if (typeof update === "function") {
                const updateVal = update(currentVal)
                setLocalStorage(updateVal);
                return updateVal
            }
            setLocalStorage(update);
            return update;
        });
    }

    return [
        value,
        updateStorage,
    ];
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { createHeaders, getWorkfrontApiSearchUrl } from "../util";
import {
    CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX,
    PREVIEW_URL_CUSTOM_FIELD_NAME_WITH_PREFIX,
    PUBLISH_URL_CUSTOM_FIELD_NAME_WITH_PREFIX,
    WF_OBJECT_CODE_TASK,
    WF_WILDCARD_CURRENT_USER_ID,
} from "../constants";
import { TaskResponse, TaskListResponse } from "./types/TaskResponse";
import { WorkfrontParams } from "../../../types/wfParams";

const WF_TASK_FIELDS_TO_BE_LOADED = `status,name,ID,description,plannedStartDate,plannedCompletionDate,parentID,
    assignedTo:avatarDownloadURL,percentComplete,project:ID,project:owner:title,
    project:owner:avatarDownloadURL,project:status,project:condition,
    project:plannedCompletionDate,project:percentComplete,project:description,
    ${PUBLISH_URL_CUSTOM_FIELD_NAME_WITH_PREFIX},${PREVIEW_URL_CUSTOM_FIELD_NAME_WITH_PREFIX},
    ${CONTENT_FRAGMENT_CUSTOM_FIELD_NAME_WITH_PREFIX}`;

const get_WF_FETCH_TASKS_ASSIGNED_TO_ME_URL = (wfInstanceUrl: string) =>
    `${getWorkfrontApiSearchUrl({
        objCode: WF_OBJECT_CODE_TASK,
        wfInstanceUrl: wfInstanceUrl,
    })}?assignedToID=${WF_WILDCARD_CURRENT_USER_ID}&fields=${WF_TASK_FIELDS_TO_BE_LOADED}`;
//`&${PREVIEW_URL_CUSTOM_FIELD_NAME}_Mod=notnull`; //Preview Url field is not empty on the task

export const fetchWorkfrontTasksAssignedToMe = async (
    wfParams: WorkfrontParams,
): Promise<TaskResponse[]> => {
    try {
        const headers = createHeaders(wfParams.imsToken);
        const res = await fetch(get_WF_FETCH_TASKS_ASSIGNED_TO_ME_URL(wfParams.wfInstanceUrl), {
            headers,
        });

        if (res.status === 401) {
            throw new Error(
                "Unauthorized request: your Workfront token is invalid, make sure it is on the same IMS as AEM",
            );
        }
        const taskListResponse: TaskListResponse = await res.json();

        return taskListResponse.data;
    } catch (e) {
        throw new Error((e as {message: string}).message);
    }
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useAppContext } from "./AppContext";
import { useFeatureFlagContext } from "./FeatureFlagContext";
import { useMessagingContext } from "./MessagingContext";
import { rpc } from "./RpcContext";
import { UploadStatusEvent } from "@src/lib/upload/Upload";
import type { UploadEmitEventData } from "@src/lib/upload/UploadManager";
import { UploadManager } from "@src/lib/upload/UploadManager";
import { fileExtension } from "@src/lib/utils/filenameHelper";

import type { TRPCClientError } from "@trpc/client";
import type { AnyRouter } from "@trpc/server";

const uploadManager = new UploadManager();

export const uploadDownloadContext = {
    uploadManager,
    uploading: false,
    addDownload: (name: string) => name,
    removeDownload: (id: string) => {
        console.log(id);
    },
    downloading: false,
};

export const UploadDownloadContext = createContext<typeof uploadDownloadContext>(uploadDownloadContext);

export const UploadDownloadContextProvider = ({
    children,
}: React.PropsWithChildren) => {
    const { t } = useTranslation("library");
    const { showToastForInvalidPermissions, error } = useMessagingContext();

    const addUploadIdMutation = rpc.libraryAssets.addUploadId.useMutation();
    const updateUploadProgressMutation =
        rpc.libraryAssets.updateUploadProgress.useMutation();
    const uploadCompleteMutation =
        rpc.libraryAssets.uploadSourceComplete.useMutation();
    const uploadErrorsMutation =
        rpc.libraryAssets.addUploadErrors.useMutation();
    const uploadCancelMutation = rpc.libraryAssets.cancel.useMutation();

    const [uploading, setUploading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const [activeDownloads, setActiveDownloads] = useState<{id: string, name: string}[]>([]);

    function addDownload(name: string) {
        const id = window.crypto.randomUUID();
        setActiveDownloads((downloads) => {
            return [{id, name}, ...downloads];
        });
        return id;
    }

    function removeDownload(id: string) {
        setActiveDownloads((downloads) => {
            return downloads.filter((download) => download.id !== id);
        });
    }

    useEffect(() => {
        setDownloading(!!activeDownloads.length);
    }, [activeDownloads]);

    useEffect(() => {
        console.log("Uploading, Downloading", [uploading, downloading])
        if (uploading || downloading) {
            window.onbeforeunload = () => confirm();
        } else {
            window.onbeforeunload = null;
        }
    }, [uploading, downloading]);

    const { logger } = useAppContext();
    uploadManager.logger = logger;

    const { FF_SKIP_ONEAPI } = useFeatureFlagContext();

    function handleUploadStarted(upload: UploadEmitEventData) {
        setUploading(true);
        addUploadIdMutation.mutate({
            id: upload.libraryAssetId,
            uploadId: upload.uploadId,
        });
    }

    async function handleUploadProgress(upload: UploadEmitEventData) {
        if (upload.libraryAssetId && upload.progress > 0) {
            try {
                await updateUploadProgressMutation.mutateAsync({
                    id: upload.libraryAssetId,
                    progress: upload.progress,
                });
            } catch (err) {
                logger.logError({
                    errorCode: "2052",
                    resources: [
                        {
                            assetId: upload.libraryAssetId,
                            uploadId: upload.uploadId,
                        },
                    ],
                });
            }
        }
    }

    function cleanUpUpload(uploadId: string) {
        if (!uploadId) {
            throw Error("There must be a valid upload id to clean up");
        }
        uploadManager.deleteUpload(uploadId);
        setUploading(uploadManager.hasUploads);
    }

    const handleUploadCompleted = useCallback(
        async (upload: UploadEmitEventData) => {
            if (upload.libraryAssetId) {
                try {
                    await uploadCompleteMutation.mutateAsync({
                        id: upload.libraryAssetId,
                        sourceModelVersion: upload.uploadResult.version,
                        fileExtension: fileExtension(upload.fileName, ".usdz"),
                        skipOneApi: FF_SKIP_ONEAPI,
                    });
                } catch (e) {
                    error(
                        t("notification.upload.error", {
                            filename: upload.fileName,
                            interpolation: { escapeValue: false },
                        }),
                    );
                }
            }
            cleanUpUpload(upload.uploadId);
        },
        [FF_SKIP_ONEAPI],
    );

    function handleUploadCanceled(upload: UploadEmitEventData) {
        uploadCancelMutation.mutateAsync(upload.libraryAssetId).catch((e) => {
            if ((e as TRPCClientError<AnyRouter>)?.data?.code === "FORBIDDEN") {
                showToastForInvalidPermissions();
            } else {
                throw e;
            }
        });
        cleanUpUpload(upload.uploadId);
    }

    function handleUploadError(uploadData: UploadEmitEventData) {
        error(
            t("notification.upload.error", {
                filename: uploadData.fileName,
                interpolation: { escapeValue: false },
            }),
            undefined,
            { type: "file", id: uploadData.fileName },
        );
        cleanUpUpload(uploadData.uploadId);
        uploadErrorsMutation.mutate({
            id: uploadData.libraryAssetId,
            uploadErrors: [{ message: uploadData.error.message }],
        });
    }

    const uploadListeners = {
        [UploadStatusEvent.STARTED]: handleUploadStarted,
        [UploadStatusEvent.PROGRESS]: handleUploadProgress,
        [UploadStatusEvent.COMPLETED]: handleUploadCompleted,
        [UploadStatusEvent.CANCELED]: handleUploadCanceled,
        [UploadStatusEvent.ERROR]: handleUploadError,
    };

    useEffect(() => {
        Object.entries(uploadListeners).forEach(([statusEvent, handler]) => {
            uploadManager.on(statusEvent, handler);
        });
        return () => {
            Object.entries(uploadListeners).forEach(
                ([statusEvent, handler]) => {
                    uploadManager.off(statusEvent, handler);
                },
            );
        };
    }, [handleUploadCompleted]);

    return (
        <UploadDownloadContext.Provider
            value={{
                uploadManager,
                uploading,
                addDownload,
                removeDownload,
                downloading,
            }}>
            {children}
        </UploadDownloadContext.Provider>
    );
};

export const useUploadDownloadContext = () => {
    const context = useContext(UploadDownloadContext);
    if (!context) {
        throw new Error(
            "useUploadContext must be used within a UploadContextProvider.",
        );
    }
    return context;
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import * as Sentry from "@sentry/react";
import { createContext, useContext, useEffect, useState } from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

import { config } from "@src/config";
import type {
    FeedbackFormData,
    FeedbackUserContext,
} from "@src/interfaces/sentry/FeedbackForm";

import type { UserFeedback } from "@sentry/types";
import type { PropsWithChildren } from "react";
import { useFeatureFlagContext } from "./FeatureFlagContext";

export const SENTRY_EVENT_ID_PLACEHOLDER = "placeholder";
// For local development, change this to a higher rate temporarily.
// Note that this affects our monthly Sentry session replay quota.
const REPLAYS_SESSION_SAMPLE_RATE_DEV = 0.1; // 10%, sampling rate for stage should be higher than prod.
const SUNRISE_USER_CONTEXT_KEY = "sunrise_user";
const SENTRY_PRODUCTION = "production";
const SENTRY_STAGE = "stage";

interface SentryContextProps {
    lastExceptionEventId: string;
    addActiveAttachment: (name: string, data: Uint8Array) => void;
    removeActiveAttachment: (name: string) => void;
    addUserFeedback: (
        feedbackData: FeedbackFormData,
        context: FeedbackUserContext,
    ) => void;
}

export const SentryContext = createContext<SentryContextProps>(
    {} as SentryContextProps,
);

async function loadSentry(initSentry: boolean, debugMode: boolean) {
    if (initSentry) {
        const Sentry = await import("@sentry/react");
        Sentry.init({
            dsn: config.sentryDSN,
            environment:
                config.adobeEnv === "prod" ? SENTRY_PRODUCTION : SENTRY_STAGE,
            integrations: [
                // Required for browser performance tracing & monitoring
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect: useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
                // Required to send custom user feedback
                Sentry.feedbackIntegration({
                    autoInject: false,
                    showBranding: false,
                    colorScheme: "system",
                }),
                // To enable session replay
                Sentry.replayIntegration({
                    maskAllText: true,
                    blockAllMedia: true,
                }),
            ],

            release: `${config.appName}-${config.appVersion}`,
            debug: debugMode && config.adobeEnv !== "prod",
            // Could consider reducing the sampling rate to something lower for production environment.
            // Error sample rate. 1.0 means all errors are captured.
            sampleRate: 1.0,
            // Transaction sample rate. 1.0 means all transactions are recorded and sent.
            tracesSampleRate: 1.0,
            // Session replays
            // Higher rate during development and could be turned off entirely for production,
            // and only record when error occurs.
            replaysSessionSampleRate:
                config.adobeEnv !== "prod"
                    ? REPLAYS_SESSION_SAMPLE_RATE_DEV
                    : 0,
            // If the entire session is not sampled, use this sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: 1.0,
        });
    }
}

export const SentryContextProvider = ({ children }: PropsWithChildren) => {
    const { FF_SENTRY_INTEGRATION, FF_SENTRY_INTEGRATION_DEBUG } = useFeatureFlagContext();

    useEffect(() => {
        loadSentry(FF_SENTRY_INTEGRATION, FF_SENTRY_INTEGRATION_DEBUG);
    }, [FF_SENTRY_INTEGRATION]);

    const [lastExceptionEventId, setLastExceptionEventId] = useState<string>(
        SENTRY_EVENT_ID_PLACEHOLDER,
    );
    const updateCurrentEventId = (
        sentryEvent: Sentry.Event,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _hint: Sentry.EventHint,
    ) => {
        if (sentryEvent.exception && sentryEvent.event_id) {
            setLastExceptionEventId(sentryEvent.event_id);
        }
        return sentryEvent;
    };

    useEffect(() => {
        // This will run for all events.
        Sentry.addEventProcessor(updateCurrentEventId);
        return () => {
            // There's no corresponding removeEventProcessor.
        };
    }, []);

    const addActiveAttachment = (filename: string, data: Uint8Array) => {
        Sentry.getCurrentScope().addAttachment({
            filename,
            data,
        });
    };

    const removeActiveAttachment = (filename: string) => {
        const scope = Sentry.getCurrentScope();
        const attachments = scope.getScopeData().attachments;
        const index = attachments.findIndex(
            (entry) => entry.filename === filename,
        );
        if (index >= 0) {
            attachments.splice(index, 1);
        }
    };

    const addUserFeedback = (
        feedbackData: FeedbackFormData,
        userContext: FeedbackUserContext,
    ) => {
        try {
            const feedbackEventId = Sentry.captureMessage(
                feedbackData.summary,
                (scope) => {
                    scope.setContext(SUNRISE_USER_CONTEXT_KEY, userContext);
                    return scope;
                },
            );

            const sentryFeedback = {
                event_id: feedbackEventId,
                comments: JSON.stringify(feedbackData),
            };

            // Note: data related to OS and browser are auto-populated by the Sentry
            // OS and browser context. (https://develop.sentry.dev/sdk/event-payloads/contexts/)

            Sentry.captureUserFeedback(sentryFeedback as UserFeedback);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(
                `SentryContext: captureUserFeedback failed with ${error}`,
            );
        } finally {
            Sentry.getCurrentScope().clearAttachments();
        }
    };

    return (
        <SentryContext.Provider
            value={{
                lastExceptionEventId,
                addActiveAttachment,
                removeActiveAttachment,
                addUserFeedback,
            }}>
            {children}
        </SentryContext.Provider>
    );
};

export const useSentryContext = () => {
    const context = useContext(SentryContext);
    if (!context) {
        throw new Error(
            "useSentryContext must be used within a SentryContextProvider.",
        );
    }
    return context;
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { createTRPCClient } from "@trpc/client";

import { ConnectedService } from "./ConnectedService";
import { RpcConnection } from "./RpcConnection";
import type {
    LogErrorInput,
    LogResources,
    LogInfoInput,
    LogResource,
} from "@src/contexts/RpcContext";
import {
    deleteKeyFromStorage,
    getArray,
    storeArray,
} from "@src/interfaces/common/utils/HeliosSessionStorage";

import type { ImsUser } from "./ims";
import type { ApiRouter } from "@components/hermes/src/controllers/helios-api";

const SESSION_STORAGE_KEY = "sunriseErrorLogger" as const;
const POLLING_DELAY = 5000 as const;

export class HeliosLogger extends ConnectedService {
    private connection: RpcConnection;
    private trpcClient?: ReturnType<typeof createTRPCClient<ApiRouter>>;

    constructor(imsUser: ImsUser) {
        super();
        this.connection = new RpcConnection(imsUser, "HeliosLogger Connection");
        this.connect();
    }

    async initialize() {
        await this.connection?.connect();

        if (!this.trpcClient && this.connection.connected) {
            this.trpcClient = createTRPCClient<ApiRouter>({
                links: [this.connection.wsLink],
            });
        }
        this.writeErrorsToHermes();
        return await Promise.resolve();
    }

    private writeErrorsToHermes = () => {
        if (this.trpcClient) {
            const storedErrors = getArray<LogErrorInput>(SESSION_STORAGE_KEY);
            deleteKeyFromStorage(SESSION_STORAGE_KEY);
            if (storedErrors) {
                this.trpcClient.logger.error.mutate(storedErrors);
            }
        }
        setTimeout(this.writeErrorsToHermes, POLLING_DELAY);
    };

    public logError(e: LogErrorInput) {
        const storedErrors = getArray<LogErrorInput>(SESSION_STORAGE_KEY) ?? [];
        const input = e;
        if (input.resources) {
            input.resources = this.sanitizeResources(input.resources);
        }
        storedErrors.push(input);
        storeArray<LogErrorInput>(SESSION_STORAGE_KEY, storedErrors);
    }

    public logInfo(i: LogInfoInput) {
        if (!this.trpcClient) {
            return;
        }
        const input = i;
        if (input.resources) {
            input.resources = this.sanitizeResources(input.resources);
        }
        this.trpcClient.logger.info.mutate(input);
    }

    private sanitizeResources(resources: LogResources) {
        return resources.map((resource) => {
            Object.keys(resource).forEach((key) => {
                const logErrorResourceKey = key as keyof LogResource;
                if (!logErrorResourceKey || logErrorResourceKey === "dbTable") {
                    return;
                }
                const currentValue = resource[logErrorResourceKey];
                if (currentValue) {
                    resource[logErrorResourceKey] =
                        this.sanitizeGuid(currentValue);
                }
            });
            return resource;
        });
    }

    private sanitizeGuid(guid: string) {
        // Any prefixes to the guid fail the zod/trpc type checker, so strip them off
        const colonIndex = guid.lastIndexOf(":");
        return colonIndex < 0 ? guid : guid.substring(colonIndex + 1);
    }
}

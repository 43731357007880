/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { IllustratedMessage, Image as SpectrumImage, Text } from "@adobe/react-spectrum";
import Alert from "@spectrum-icons/workflow/Alert";
import { useEffect, useState } from "react";

import { DelayedSpinner } from "./DelayedSpinner";
import { useCachingContext } from "@src/contexts/CachingContext";

import type { SpectrumImageProps } from "@adobe/react-spectrum";

interface Props {
    errorMessage?: string;
    showErrorIcon?: boolean;
    enableSpinner?: boolean;
    onload?: () => void;
}

export function ImageFallback({
    errorMessage,
    enableSpinner = false,
    showErrorIcon = false,
    onload,
    src,
    alt,
    width,
    height,
    ...props
}: Props & SpectrumImageProps) {
    const { loadStarted, loadComplete } = useCachingContext();
    const [error, setError] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (src) {
            const img = new Image();

            function onLoaded() {
                setLoaded(true);
                loadComplete();
                onload && onload();
            }

            function onError() {
                setError(true);
                loadComplete();
            }

            img.addEventListener("error", onError);
            img.addEventListener("load", onLoaded);

            img.src = src;
            loadStarted();

            return () => {
                img.removeEventListener("error", onError);
                setError(false);
                img.removeEventListener("load", onLoaded);
                setLoaded(false);
                loadComplete();
            };
        }
        return;
    }, [src]);

    if (error) {
        return (
            <IllustratedMessage width={width} height={height}>
                {showErrorIcon ? <Alert size="XXL"/> : undefined}
                {errorMessage ? (
                    <Text UNSAFE_style={{ userSelect: "none" }}>
                        {errorMessage}
                    </Text>
                ) : undefined}
            </IllustratedMessage>
        );
    }

    if (enableSpinner && !loaded) {
        return <DelayedSpinner delayTime={250} />;
    }

    return (
        <SpectrumImage
            src={src}
            alt={alt}
            width={width}
            height={height}
            {...props}
        />
    );
}

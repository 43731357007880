import type { IconProps } from "@adobe/react-spectrum";
import { Icon } from "@adobe/react-spectrum";

export const CameraOrbit = (props: Omit<IconProps, "children">) => {
    return (
        <Icon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                data-name="S_OrbitCamera_Lg_N@2x"
                viewBox="0 0 44 36">
                <path d="M32.436 14.311a6.148 6.148 0 0 0-10.744-5.905 24.125 24.125 0 0 0-7.933-1.24c-4.017.126-6.908 1.589-8.141 4.119-1.848 3.794.531 8.908 5.944 13.185l-2.407 2.14a.5.5 0 0 0 .244.866l7.749 1.4-.477-7.859a.5.5 0 0 0-.831-.343l-2.374 2.11c-4.575-3.505-6.906-7.715-5.6-10.4.794-1.631 2.971-2.62 5.971-2.714a21.588 21.588 0 0 1 6.94 1.069 6.142 6.142 0 0 0 10.179 5.6c3.739 3.244 5.641 6.938 4.421 9.444-.863 1.771-3.239 2.749-6.582 2.721a.5.5 0 0 0-.511.48l-.056 1.5a.5.5 0 0 0 .492.521c5.174.057 7.859-1.982 8.9-4.126 1.78-3.643-.43-8.502-5.184-12.568z" />
            </svg>
        </Icon>
    );
};

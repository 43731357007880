/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { User } from "../../../types/User";
import { WF_OBJECT_CODE_TASK } from "../constants";
import { RecommendedAssigneesQuery } from "../requests/types/RecommendedAssigneesQuery";
import { fetchTaskRecommendedAssignees } from "./private/fetchTaskRecommendedAssignees";
import { WorkfrontParams } from "../../../types/wfParams";

export const generateQuery = (
    parentTaskId: string,
    searchTerm: string | undefined,
): RecommendedAssigneesQuery => ({
    objCode: WF_OBJECT_CODE_TASK,
    objID: "-1",
    searchTerm: searchTerm || "",
    parentID: parentTaskId,
    parentObjCode: WF_OBJECT_CODE_TASK,
});

export const fetchTaskRecommendedAssigneesForNewSubTask = async (
    wfParams: WorkfrontParams,
    parentTaskId: string,
    searchTerm: string | undefined,
): Promise<User[]> => {
    const recommendedAssigneesQuery: RecommendedAssigneesQuery = generateQuery(
        parentTaskId,
        searchTerm,
    );
    return fetchTaskRecommendedAssignees(wfParams, recommendedAssigneesQuery);
};

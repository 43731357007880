/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Card, CardView, GridLayout } from "@react-spectrum/card";
import React from "react";

import { OutputCard } from "./OutputCard";
import { useOpenShot } from "../hooks/useOpenShot";
import type { ShotListItem } from "@src/contexts/RpcContext";
import { rpc } from "@src/contexts/RpcContext";

import type { FC } from "react";

type Props = {
    projectId: string;
    assetId: string;
    canUserModify: boolean;
    selectedRenders: string[];
    setSelectedRenders: React.Dispatch<React.SetStateAction<string[]>>;
    reviewAccess?: boolean;
};

export const PreviewAssetRow: FC<Props> = ({
    projectId,
    assetId,
    canUserModify,
    selectedRenders,
    setSelectedRenders,
    reviewAccess,
}) => {
    const { data: shots } = rpc.shots.getShotsForAsset.useQuery({
        projectId,
        assetId,
    });
    const { data: approved } =
        rpc.projects.isProjectApproved.useQuery(projectId);
    const { openShotDetail } = useOpenShot(projectId);

    return (
        <CardView
            items={shots ?? []}
            width="100%"
            position="relative"
            layout={GridLayout}
            selectionMode={
                !reviewAccess && canUserModify && !approved
                    ? "multiple"
                    : "none"
            }
            disabledKeys={
                shots
                    ? shots
                          .filter((shot) => shot && shot.type === "SHOT_3D")
                          .map(({ id }) => id)
                    : []
            }
            selectedKeys={selectedRenders}
            onOpenItem={(shot: ShotListItem) => {
                openShotDetail(shot.id, reviewAccess);
            }}
            onSelectionChange={(keys) => {
                setSelectedRenders([...keys] as string[]);
            }}
            aria-label="Grid of shots organized by Asset">
            {(shot: ShotListItem) => (
                <Card key={shot.id}>
                    <OutputCard shotListItem={shot} />
                </Card>
            )}
        </CardView>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { usePrevious } from "./usePrevious";

import type { DetailedHTMLProps, HTMLAttributes } from "react";

export function useDialogStyles(activePosition: { x: number; y: number }) {
    const hasPosition = !(activePosition.x === 0 && activePosition.y === 0);

    const hadPosition = usePrevious(hasPosition);

    return {
        display: "flex",
        position: "absolute",
        transform: `translate(${activePosition.x}px, ${activePosition.y}px)`,
        transition: hadPosition && hasPosition ? "transform 1.0/60.0s" : "none",
        visibility: hasPosition ? "visible" : "hidden",
    } as DetailedHTMLProps<
        HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >["style"];
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@adobe/react-spectrum";
import { useHover } from "@react-aria/interactions";
import { useEffect } from "react";

import type { PropsWithChildren, Dispatch, SetStateAction } from "react";

const SELECTION_COLOR = "gray-200";

interface Props {
    isSelected: boolean;
    shouldDisableHover?: boolean;
    onClickHandler: (event: React.MouseEvent<HTMLElement>) => void;
    setIsHovered: Dispatch<SetStateAction<boolean>>;
}

export const LeftNavListItem = ({
    isSelected,
    shouldDisableHover,
    onClickHandler,
    setIsHovered,
    children,
}: PropsWithChildren<Props>) => {
    const { hoverProps, isHovered } = useHover({
        isDisabled: shouldDisableHover,
    });

    useEffect(() => {
        setIsHovered(isHovered);
    }, [isHovered]);

    return (
        <View
            backgroundColor={
                (isSelected || isHovered) && !shouldDisableHover
                    ? SELECTION_COLOR
                    : "transparent"
            }
            borderRadius="regular"
            height="size-450"
            width="100%"
            marginBottom="size-50">
            <div
                {...hoverProps}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                    onClickHandler(event);
                }}>
                {children}
            </div>
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    ButtonGroup,
    Content,
    Dialog,
    Flex,
    Heading,
    IllustratedMessage,
    Link,
    Text,
    View,
} from "@adobe/react-spectrum";
import UploadIcon from "@spectrum-icons/illustrations/Upload";
import Alert from "@spectrum-icons/workflow/Alert";
import CloudDisconnected from "@spectrum-icons/workflow/CloudDisconnected";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

import { config } from "@src/config";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";
import { useAppContext } from "@src/contexts/AppContext";
import type { UploadAnalyticsBatchFields } from "@src/lib/analytics/AnalyticsConstants";
import { combineFileWithBatchFields } from "@src/lib/analytics/UploadAnalyticsUtil";
import {
    fileExtension,
    MODEL_CONTENT_TYPES,
} from "@src/lib/utils/filenameHelper";

import type { FC } from "react";
import type { FileRejection, DropEvent } from "react-dropzone";

const uploadMegSizeLimitPerEnv: Record<string, number> = {
    "local": 4 * 1024, // 4 gb
    "feature": 1 * 1024, // 1 gb
    "dev": 700, // 700 mb
    "stage": 700, // 700 mb
    "prod": 500 // 500 mb
}

export const MAX_UPLOAD_FILE_SIZE_IN_MB = uploadMegSizeLimitPerEnv[config.env] || uploadMegSizeLimitPerEnv.local;
const MAX_FILE_SIZE = MAX_UPLOAD_FILE_SIZE_IN_MB * 1024 * 1024;
const MAX_NUMBER_OF_FILES = 10;

// Object with key of mime type, value is array of acceptable file extensions.
// See https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker
const dropAcceptTypes = Object.entries(MODEL_CONTENT_TYPES).reduce<{
    [key: string]: string[];
}>((accumulator, modelContentEntry) => {
    if (!accumulator[modelContentEntry[1]]) {
        accumulator[modelContentEntry[1]] = [];
    }
    accumulator[modelContentEntry[1]].push(`.${modelContentEntry[0]}`);
    return accumulator;
}, {});

interface Props {
    multiSelect: boolean;
    isRevision?: boolean;
    handleFiles: (
        acceptedFiles: File[],
        rejectedFiles: FileRejection[],
        uploadAnalyticsFields: UploadAnalyticsBatchFields,
    ) => Promise<string | void>;
    close: () => void;
}

export const UploadDialog: FC<Props> = ({
    multiSelect,
    isRevision,
    handleFiles,
    close,
}) => {
    const [dragErrorState, setDragErrorState] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);
    const { rpcConnection } = useAppContext();
    const { t } = useTranslation("library");
    const { logIngestEvent } = useAnalyticsContext();

    const onDrop = useCallback(
        (
            acceptedFiles: File[],
            rejectedFiles: FileRejection[],
            dropEvent: DropEvent,
        ) => {
            const analyticsBatchFields = {
                "event.context_guid": v4(),
                "ui.path_to_access": dropEvent ? "drag-drop" : "file-picker",
                "3di.dimensions": {
                    upload_type: isRevision ? "existing" : "new",
                },
            } as UploadAnalyticsBatchFields;

            const logErrorWithSynthesizedStart = (file: File) => {
                const contentId = v4();
                const fields = combineFileWithBatchFields(
                    analyticsBatchFields,
                    file,
                    contentId,
                );
                logIngestEvent("uploadStart", fields);
                logIngestEvent("uploadError", fields);
            };

            if (
                acceptedFiles.length + rejectedFiles.length >
                (multiSelect ? MAX_NUMBER_OF_FILES : 1)
            ) {
                setDragErrorState(true);
                [
                    ...acceptedFiles,
                    ...rejectedFiles.map((rejFile) => rejFile.file),
                ].forEach((file: File) => {
                    logErrorWithSynthesizedStart(file);
                });
                return;
            } else {
                setDragErrorState(false);
            }

            const finalAcceptedFiles: File[] = [];
            const finalRejectedFiles: FileRejection[] = [...rejectedFiles];
            acceptedFiles.forEach((file) => {
                const extension = fileExtension(file.name, "");
                if (file.type === "application/octet-stream") {
                    if (extension !== ".fbx") {
                        const errors = [
                            {
                                message: `Unsupported file type ${extension}`,
                                code: "file-invalid-type",
                            },
                        ];
                        finalRejectedFiles.push({ file, errors });
                    } else {
                        finalAcceptedFiles.push(file);
                    }
                } else {
                    finalAcceptedFiles.push(file);
                }
            });

            // File error analytics for rejected files.
            finalRejectedFiles
                .map((rejFile) => rejFile.file)
                .forEach((file: File) => {
                    logErrorWithSynthesizedStart(file);
                });

            setIsDragOver(false);
            close();
            handleFiles(
                finalAcceptedFiles,
                finalRejectedFiles,
                analyticsBatchFields,
            )
        },
        [handleFiles],
    );

    const onDragEnter = () => {
        setDragErrorState(false);
        setIsDragOver(true);
    };

    const onDragLeave = () => {
        setIsDragOver(false);
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        accept: dropAcceptTypes,
        onDragEnter,
        onDragLeave,
        maxSize: MAX_FILE_SIZE,
        maxFiles: MAX_NUMBER_OF_FILES,
        multiple: multiSelect,
    });
    return (
        <Dialog>
            <Heading marginBottom="size-150">
                {t("dialogs.uploadAssets.title")}
            </Heading>
            <Content data-uia="upload-assets-dropzone">
                {rpcConnection.connected ? (
                    <>
                        {dragErrorState && (
                            <Flex
                                direction="row"
                                alignContent="center"
                                alignItems="center"
                                alignSelf="center"
                                position="absolute"
                                gap="size-100"
                                width="100%"
                                left="size-0"
                                justifyContent="center"
                                marginTop="size-200">
                                <Alert
                                    aria-label="Negative Alert"
                                    color="negative"
                                />
                                <span
                                    style={{
                                        color: "var(--spectrum-global-color-red-400)",
                                    }}>
                                    {multiSelect
                                        ? t(
                                              "dialogs.uploadAssets.tooManyFiles.multi.error",
                                              { count: MAX_NUMBER_OF_FILES },
                                          )
                                        : t(
                                              "dialogs.uploadAssets.tooManyFiles.error",
                                          )}
                                </span>
                            </Flex>
                        )}
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <View
                                height="100%"
                                paddingY="size-800"
                                borderColor={
                                    dragErrorState
                                        ? "red-400"
                                        : isDragOver
                                        ? "blue-600"
                                        : "light"
                                }
                                borderWidth="thick"
                                borderRadius="medium">
                                <IllustratedMessage>
                                    <UploadIcon />
                                    <Heading>
                                        {t("dialogs.uploadAssets.subtitle")}
                                    </Heading>
                                    <Text>
                                        <Link variant="primary" onPress={open}>
                                            {t("dialogs.uploadAssets.input")}
                                        </Link>{" "}
                                        {t(
                                            "dialogs.uploadAssets.input.description",
                                        )}
                                    </Text>
                                    <Flex
                                        direction="column"
                                        alignItems="start"
                                        marginTop="size-350">
                                        <Text>
                                            {t(
                                                "dialogs.uploadAssets.description2",
                                            )}
                                        </Text>
                                    </Flex>
                                </IllustratedMessage>
                            </View>
                        </div>
                    </>
                ) : (
                    <View
                        height="100%"
                        paddingY="size-800"
                        borderWidth="thick"
                        borderRadius="medium"
                        borderColor="gray-400">
                        <IllustratedMessage>
                            <CloudDisconnected size="XXL" />
                            <Heading>
                                {t("dialogs.uploadAssets.disconnected.title")}
                            </Heading>
                            <Flex
                                direction="column"
                                gap="size-200"
                                margin="size-200"
                                width="70%">
                                <Text>
                                    {t(
                                        "dialogs.uploadAssets.disconnected.description",
                                    )}
                                </Text>
                                <Text>
                                    <Link
                                        variant="primary"
                                        onPress={() =>
                                            window.location.reload()
                                        }>
                                        {t(
                                            "dialogs.uploadAssets.disconnected.refresh",
                                        )}
                                    </Link>{" "}
                                </Text>
                            </Flex>
                        </IllustratedMessage>
                    </View>
                )}
            </Content>
            <ButtonGroup>
                <Button
                    variant="secondary"
                    onPress={() => {
                        setIsDragOver(false);
                        setDragErrorState(false);
                        close();
                    }}>
                    {t("actions.uploadAssets.cancel")}
                </Button>
                {/* Remove until phase 2 when the upload is a two-step process.
        In phase 1 we immediately start processing on file selection.
    <Button variant="cta" onPress={handleUploadInputClick}>
        {t("library:actions.uploadAssets")}
    </Button> */}
            </ButtonGroup>
        </Dialog>
    );
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { createHeaders, getWorkfrontApiObjectBaseUrl } from "../util";
import { WF_OBJECT_CODE_USER } from "../constants";
import { UserResponse, UserSingleResponse } from "./types/UserResponse";
import { WorkfrontParams } from "../../../types/wfParams";

const WF_USER_FIELDS_TO_BE_LOADED = "ID,name,avatarDownloadURL,title";

export const fetchWorkfrontUserById = async function (
    wfParams: WorkfrontParams,
    userWfId: string,
): Promise<UserResponse> {
    try {
        const headers = createHeaders(wfParams.imsToken);

        const WF_FETCH_USER_BY_ID_URL = `${getWorkfrontApiObjectBaseUrl({
            objCode: WF_OBJECT_CODE_USER,
            wfInstanceUrl: wfParams.wfInstanceUrl,
        })}/${userWfId}?fields=${WF_USER_FIELDS_TO_BE_LOADED}`;

        const res = await fetch(WF_FETCH_USER_BY_ID_URL, {
            headers,
        });
        const apiResponse: UserSingleResponse = await res.json();
        return apiResponse.data;
    } catch (e) {
        throw new Error("Was not able to fetch the user");
    }
};

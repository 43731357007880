/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ProgressBar } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { AssetStatusLight } from "./AssetStatusLight";
import { LibraryAssetCardItem, rpc } from "@src/contexts/RpcContext";

interface UploadProps {
    progress: number;
    isNewVersion: boolean;
}

function UploadProgressBar({
    isNewVersion,
    progress,
}: UploadProps){
    const { t } = useTranslation();
    const label =
        progress >= 100
            ? "progress.upload.completed"
            : `progress.upload.${isNewVersion ? "version" : "asset"}`;
    return (
        <ProgressBar
            label={t(label)}
            value={progress}
            width="100%"
            marginTop="size-150"
        />
    );
};

interface ProcessProps {
    progress: number;
    version: number;
    jobsQueued: boolean;
    width?: string;
    marginTop?: string;
}

export function ProcessProgressBar({
    progress,
    version,
    jobsQueued,
    width,
    marginTop,
}: ProcessProps) {
    const { t } = useTranslation();
    return (
        <ProgressBar
            label={t(
                `progress.processing.${version > 1 ? "version" : "asset"}${
                    jobsQueued ? ".queued" : ""
                }`,
            )}
            value={progress}
            width={width}
            marginTop={marginTop}
        />
    );
};

interface AssetProgressProps {
    asset: LibraryAssetCardItem | undefined,
    disableStatusLight?: boolean
}

export function AssetProgressBar({asset, disableStatusLight}: AssetProgressProps) {
    const { data: predecessor } =
        rpc.libraryAssets.getAssetPredecessor.useQuery(asset?.id ?? "");

    return asset && (
        !asset.uploadComplete && !asset.uploadFailed ? (
            <UploadProgressBar
                isNewVersion={!!predecessor}
                progress={asset.uploadProgress}
            />
        ) : !asset.jobsCompleted &&
          !asset.uploadFailed &&
          !asset.hasValidationErrors ? (
            <ProcessProgressBar
                version={asset.version}
                jobsQueued={asset.jobsQueued}
                progress={asset.jobsProgress}
                width="100%"
                marginTop="size-150"
            />
        ) : (
            !disableStatusLight && <AssetStatusLight
                assetStatus={asset}
                marginStart="-10px"
                marginTop="6px"
            />
        )
    )
}

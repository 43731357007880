/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import React from "react";
import { Content, View, Flex } from "@adobe/react-spectrum";
import "./OwnerDetails.css";
import { OwnerDetailsProps } from "./types";

export const OwnerDetails = (props: OwnerDetailsProps) => {
    const { owner } = props;
    const { avatar = null, name = null, title = null } = owner || {};
    return (
        <View>
            {owner && (
                <>
                    <Content UNSAFE_className="label">Assignees</Content>
                    <Flex direction="row">
                        {avatar ? (
                            <img
                                className="owner-avatar"
                                src={URL.createObjectURL(avatar as Blob)}
                            />
                        ) : (
                            <></>
                        )}
                        <Flex direction="column" alignSelf="center">
                            <Content UNSAFE_className="owner-full-name">{name}</Content>
                            <Content UNSAFE_className="owner-title ">{title}</Content>
                        </Flex>
                    </Flex>
                </>
            )}
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type { AssetStatusData } from "@src/interfaces/common/components/AssetStatusLight";

/**
 * See https://react-spectrum.adobe.com/react-spectrum/StatusLight.html
 * for information on the variant property.
 * @param status - The AssetStatus string
 * @returns The React-Spectrum status light property
 */
export function statusLightVariant(asset: AssetStatusData) {
    if (asset.jobsFailed || asset.uploadFailed || asset.hasValidationErrors) {
        return "negative";
    }
    if (asset.approved) {
        return "positive";
    }
    if (asset.jobsCompleted) {
        return "info";
    }
    return "neutral";
}

/**
 * @param status - The AssetStatus string
 * @returns The localization token string for the given asset.
 */
export function statusToDisplayString(asset: AssetStatusData) {
    if (asset.uploadFailed) {
        return "progress.upload.error";
    }
    if (asset.hasValidationErrors) {
        return "progress.validationFailed";
    }
    if (asset.jobsFailed) {
        return "progress.error";
    }
    if (!asset.jobsCompleted) {
        return "progress.processing";
    }
    if (!asset.approved) {
        return "progress.inProgress";
    }
    if (asset.approved) {
        return "progress.approved";
    }
    return "progress.loading";
}

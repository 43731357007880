/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    ActionButton,
    AlertDialog,
    DialogTrigger,
    Flex,
    Text,
    View,
} from "@adobe/react-spectrum";
import AlertCircle from "@spectrum-icons/workflow/AlertCircle";
import { Fragment } from "react";

import type { JobErrors } from "@shared/types";
import type { FC } from "react";

interface Props {
    errors?: JobErrors;
}

export const CardErrorBadge: FC<Props> = ({ errors }) => {
    // Note that the "Process Id" below could be either serviceJobId or uploadId if errors are upload errors.
    return (
        <>
            {!!errors?.length && (
                <DialogTrigger>
                    <ActionButton isQuiet>
                        <AlertCircle />
                    </ActionButton>
                    <AlertDialog
                        minWidth="60vw"
                        maxWidth="80vw"
                        primaryActionLabel="OK"
                        title="Job Errors"
                        variant="error">
                        <Flex
                            direction="column"
                            rowGap="size-100"
                            marginBottom="size-100"
                            aria-label="Job errors">
                            {errors.map((error, idx) => (
                                <Fragment key={idx}>
                                    <View key={idx + "_processId"}>
                                        {"Process Id: " + error.processId}
                                    </View>
                                    <View key={idx + "_messages"}>
                                        {error.messages.map(
                                            (message, msg_idx) => (
                                                <Text key={idx + "_" + msg_idx}>
                                                    {message}
                                                </Text>
                                            ),
                                        )}
                                    </View>
                                </Fragment>
                            ))}
                        </Flex>
                    </AlertDialog>
                </DialogTrigger>
            )}
        </>
    );
};

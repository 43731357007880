/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export function fulfilledValues<T>(results: PromiseSettledResult<T>[]): T[] {
    return results
        .filter(
            (res): res is PromiseFulfilledResult<T> =>
                res.status === "fulfilled",
        )
        .map((res) => res.value);
}

export function fulfilledResults<T>(
    results: PromiseSettledResult<T>[],
): PromiseFulfilledResult<T>[] {
    return results.filter(
        (res): res is PromiseFulfilledResult<T> => res.status === "fulfilled",
    );
}

export function rejectedResults<T>(
    results: PromiseSettledResult<T>[],
): PromiseRejectedResult[] {
    return results.filter(
        (res): res is PromiseRejectedResult => res.status === "rejected",
    );
}

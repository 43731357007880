/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

/* eslint-disable no-undef */
window.fedsConfig = window.fedsConfig || {};
window.fedsConfig.privacy = window.fedsConfig.privacy || {};
window.fedsConfig.privacy.otDomainId =
    "7a5eb705-95ed-4cc4-a11d-0cc5760e93db-test";
window.fedsConfig.privacy.footerLinkSelector = "#cookieFooter";
import(
    "https://www.stage.adobe.com/etc.clientlibs/globalnav/clientlibs/base/privacy-standalone.js"
);

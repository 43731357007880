/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Content,
    Dialog,
    Flex,
    Heading,
    Image,
    View,
} from "@adobe/react-spectrum";
import { forwardRef, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { GenericJobError } from "./GenericJobError";
import { ImageFallback } from "../../../common/components/ImageFallback";
import { DISPLAY_SIZE } from "../projects/DisplaySettingsPicker";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import { DelayedSpinner } from "@src/interfaces/common/components/DelayedSpinner";

import type { DisplaySize } from "../projects/DisplaySettingsPicker";
import type { ArtifactData, JobErrors } from "@shared/types";
import type { CSSProperties } from "react";

const ARTIFACT_BASE_UNIT = 256;
const ARTIFACT_SCALE_FACTOR = 1;
const ARTIFACT_WIDTH = ARTIFACT_BASE_UNIT * 2;
const ARTIFACT_HEIGHT = ARTIFACT_BASE_UNIT * 2;

interface GeneratedArtifactLayoutProps {
    base: number;
    width: number;
    height: number;
}

export const ARTIFACT_LAYOUT_PROPS: GeneratedArtifactLayoutProps = {
    base: ARTIFACT_BASE_UNIT,
    width: ARTIFACT_WIDTH,
    height: ARTIFACT_HEIGHT,
};
export interface Artifact2DViewProps {
    url?: string;
    alt: string;
    aspectRatio?: number;
    resolution?: number[];
    displaySize?: DisplaySize;
    errors?: JobErrors;
    jobCompleted: boolean;
    jobFailed: boolean;
    onLoad?: () => void;
}

export function Artifact2DView({
    url,
    alt,
    aspectRatio = 1,
    resolution,
    displaySize,
    onLoad,
    errors,
    jobCompleted,
    jobFailed,
}: Artifact2DViewProps) {
    const aspectGreaterOrEqualTo1 = useMemo(() => {
        return aspectRatio >= 1;
    }, [aspectRatio]);
    const isDefaultSize = useMemo(() => {
        return (
            displaySize !== DISPLAY_SIZE.fitToScreen &&
            displaySize !== DISPLAY_SIZE.fullSize
        );
    }, [displaySize]);
    const progressWidth = useMemo(() => {
        if (!isDefaultSize) {
            return "100%";
        }
        return aspectGreaterOrEqualTo1 ? ARTIFACT_WIDTH : undefined;
    }, [aspectGreaterOrEqualTo1, isDefaultSize]);
    const progressMaxWidth = useMemo(() => {
        return aspectGreaterOrEqualTo1 || !isDefaultSize
            ? undefined
            : ARTIFACT_WIDTH;
    }, [aspectGreaterOrEqualTo1, isDefaultSize]);
    const progressHeight = useMemo(() => {
        if (!isDefaultSize) {
            return "100%";
        }
        return aspectGreaterOrEqualTo1 ? undefined : ARTIFACT_HEIGHT;
    }, [aspectGreaterOrEqualTo1, isDefaultSize]);
    const progressMaxHeight = useMemo(() => {
        return aspectGreaterOrEqualTo1 && isDefaultSize
            ? ARTIFACT_HEIGHT
            : undefined;
    }, [aspectGreaterOrEqualTo1, isDefaultSize]);
    const width = useMemo(() => {
        if (displaySize === DISPLAY_SIZE.fullSize) {
            return resolution?.[0];
        } else if (displaySize === DISPLAY_SIZE.fitToScreen) {
            return "100%";
        } else if (aspectGreaterOrEqualTo1) {
            return ARTIFACT_WIDTH;
        }
        return undefined;
    }, [aspectGreaterOrEqualTo1, resolution, displaySize]);
    const height = useMemo(() => {
        if (displaySize === DISPLAY_SIZE.fullSize) {
            return resolution?.[1];
        } else if (displaySize === DISPLAY_SIZE.fitToScreen) {
            return "100%";
        } else if (!aspectGreaterOrEqualTo1) {
            return ARTIFACT_HEIGHT;
        }
        return undefined;
    }, [aspectGreaterOrEqualTo1, resolution, displaySize]);

    return !url ? (
        <Flex
            direction="column"
            width={progressWidth}
            maxWidth={progressMaxWidth}
            minWidth="100px"
            height={progressHeight}
            maxHeight={progressMaxHeight}
            minHeight="100px"
            justifyContent="center"
            alignItems="center"
            UNSAFE_style={{
                aspectRatio: aspectRatio?.toString(),
            }}>
            {jobFailed ? (
                <GenericJobError titleKey="error.render" jobErrors={errors} />
            ) : !jobCompleted ? (
                <DelayedSpinner delayTime={250} size="L" />
            ) : null}
        </Flex>
    ) : (
        <ImageFallback
            src={url}
            alt={alt}
            width={width}
            height={height || width}
            showErrorIcon
            objectFit="contain"
            onload={onLoad}
            UNSAFE_style={{
                // crisp edges chrome variant
                imageRendering: "-webkit-optimize-contrast",
            }}
        />
    );
}

export interface ArtifactViewOverlayProps {
    artifact: ArtifactData;
    showOverlay: boolean;
    onViewLoaded: () => void;
    displaySize?: DisplaySize;
}

export const ArtifactViewOverlay = forwardRef(function ArtifactViewOverlay(
    {
        artifact,
        showOverlay,
        onViewLoaded,
        displaySize,
    }: ArtifactViewOverlayProps,

    ref: any,
) {
    const { t } = useTranslation("library");
    const { showModal } = useMessagingContext();
    const commentsRef = useRef<HTMLElement | null>(null);

    const openZoomed = () => {
        if (!artifact.isShot && artifact.url) {
            showModal(
                <Dialog width="min(80vw, 1104px)">
                    <Content>
                        <div id="zoomed-ortho" style={{ position: "relative" }}>
                            <Image
                                src={artifact.url}
                                width="1024px"
                                height="1024px"
                                objectFit="contain"
                                UNSAFE_style={{
                                    // crisp edges chrome variant
                                    imageRendering: "-webkit-optimize-contrast",
                                }}
                            />
                        </div>
                    </Content>
                </Dialog>,
                {
                    isDismissable: true,

                    onDismiss: () => {
                        if (ref.current && commentsRef.current) {
                            ref.current?.lastChild?.appendChild(
                                commentsRef.current,
                            );
                            commentsRef.current.setAttribute(
                                "scalefactorx",
                                "1",
                            );
                            commentsRef.current.setAttribute(
                                "scalefactory",
                                "1",
                            );
                        }
                    },
                },
            );
            setTimeout(() => {
                if (commentsRef.current) {
                    document
                        .getElementById("zoomed-ortho")
                        ?.appendChild(commentsRef.current);
                    commentsRef.current.setAttribute("scalefactorx", "2");
                    commentsRef.current.setAttribute("scalefactory", "2");
                }
            }, 100);
        }
    };

    const styleObject = useMemo(() => {
        if (displaySize === DISPLAY_SIZE.fullSize) {
            return {
                position: "absolute",
                top: "50%",
                left: "50%",
                maxWidth: "100%",
                maxHeight: "100%",
                overflow: "auto",
                transform: "translate(-50%, -50%)",
            } as CSSProperties;
        } else if (displaySize === DISPLAY_SIZE.fitToScreen) {
            return {
                width: "100%",
                height: "100%",
            };
        }
        return {};
    }, [displaySize]);

    const annotationsOverlayProps = useMemo(() => {
        return {
            scalefactorx: ARTIFACT_SCALE_FACTOR,
            scalefactory: ARTIFACT_SCALE_FACTOR,
            style: {
                transformOrigin: "0 0",
                transform: ARTIFACT_SCALE_FACTOR as unknown as string,
                width: "100%",
                height: "100%",
            },
        };
    }, []);

    const headingString = !artifact.isShot
        ? t("detailView.views2Dview", {
              name:
                  artifact.type.charAt(0).toUpperCase() +
                  artifact.type.slice(1).toLowerCase(),
          })
        : "";

    // Since Spectrum View is a virtual react DOM element, we need to use a div
    // in order to be able to call the element's scrollIntoView method.
    return (
        <div ref={ref} style={styleObject}>
            {headingString && (
                <View
                    backgroundColor="gray-200"
                    width="100%"
                    height="size-400"
                    padding="size-100">
                    <Heading level={5}>{headingString}</Heading>
                </View>
            )}
            {/** Requires a div here so that the overlay works correctly. */}
            <div
                onClick={(e) => {
                    // @ts-ignore
                    if (e.target.tagName.toLowerCase() === "img") {
                        openZoomed();
                    }
                }}
                style={{
                    position: "relative",
                }}>
                <Artifact2DView
                    url={artifact.url}
                    alt={artifact.type}
                    aspectRatio={artifact.aspectRatio}
                    resolution={artifact.resolution}
                    displaySize={displaySize}
                    onLoad={onViewLoaded}
                    errors={artifact.jobErrors}
                    jobCompleted={artifact.jobCompleted}
                    jobFailed={artifact.jobFailed}
                />
                {showOverlay && (
                    <cc-comments-annotations-overlay
                        ref={commentsRef}
                        {...annotationsOverlayProps}
                        nodeid={artifact.componentId}
                    />
                )}
            </div>
        </div>
    );
});

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type { FC } from "react";

export const FeedbackDropzoneIcon: FC = () => {
    return (
        <svg
            width="129"
            height="48"
            viewBox="0 0 129 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.68765 11.5C1.58401 11.5 1.5 11.584 1.5 11.6876V35.4115C1.5 35.5151 1.58401 35.5991 1.68765 35.5991H1.9205C1.92608 35.593 1.93184 35.5871 1.93778 35.5812L14.6165 23.0786C14.6172 23.0779 14.6178 23.0773 14.6185 23.0766C15.2472 22.4462 16.2678 22.4489 16.8932 23.0831C16.8933 23.0832 16.8931 23.083 16.8932 23.0831L24.2775 30.5377L27.5244 27.1009C28.2362 26.3836 29.4508 26.388 30.1588 27.1063L38.4975 35.5856C38.5011 35.5892 38.5045 35.5928 38.508 35.5965C38.5967 35.5816 38.6644 35.5044 38.6644 35.4115V11.6876C38.6644 11.584 38.5804 11.5 38.4767 11.5H1.68765ZM37.1083 35.5991L29.4467 27.8083C29.4465 27.8081 29.4468 27.8084 29.4467 27.8083C29.1296 27.4871 28.5575 27.4843 28.2381 27.8016L24.9816 31.2485L29.2912 35.5991H37.1083ZM27.8836 35.5991L16.1813 23.7854C15.9458 23.5464 15.5622 23.5456 15.3258 23.7835L15.3222 23.7871L3.34384 35.5991H27.8836ZM0.5 11.6876C0.5 11.0317 1.03173 10.5 1.68765 10.5H38.4767C39.1327 10.5 39.6644 11.0317 39.6644 11.6876V35.4115C39.6644 36.0674 39.1327 36.5991 38.4767 36.5991H1.68765C1.03173 36.5991 0.5 36.0674 0.5 35.4115V11.6876ZM28.0649 18.1044C28.0691 16.0594 29.7205 14.3907 31.7571 14.3949C33.7939 14.3992 35.4373 16.0751 35.4331 18.1199C35.4289 20.1649 33.7783 21.8328 31.7417 21.8285C29.7053 21.8242 28.0606 20.1496 28.0649 18.1044ZM31.755 15.3949C30.2803 15.3918 29.068 16.6041 29.0649 18.1065C29.0618 19.6086 30.2689 20.8254 31.7438 20.8285C33.2186 20.8316 34.43 19.6202 34.4331 18.1178C34.4362 16.6153 33.2296 15.3981 31.755 15.3949Z"
                fill="#909090"
            />
            <path
                d="M127.311 37H87.9167C87.5511 36.9996 87.2007 36.8542 86.9422 36.5957C86.6838 36.3372 86.5383 35.9868 86.5379 35.6212V12.3788C86.5383 12.0132 86.6838 11.6628 86.9422 11.4043C87.2007 11.1458 87.5511 11.0004 87.9167 11H127.311C127.676 11.0004 128.027 11.1458 128.285 11.4043C128.544 11.6628 128.689 12.0132 128.689 12.3788V35.6212C128.689 35.9868 128.544 36.3372 128.285 36.5957C128.027 36.8542 127.676 36.9996 127.311 37ZM87.9167 12.1818C87.8645 12.1819 87.8144 12.2027 87.7775 12.2396C87.7406 12.2765 87.7198 12.3266 87.7197 12.3788V35.6212C87.7198 35.6734 87.7406 35.7235 87.7775 35.7604C87.8144 35.7973 87.8645 35.8181 87.9167 35.8182H127.311C127.363 35.8181 127.413 35.7973 127.45 35.7604C127.487 35.7235 127.508 35.6734 127.508 35.6212V12.3788C127.508 12.3266 127.487 12.2765 127.45 12.2396C127.413 12.2027 127.363 12.1819 127.311 12.1818H87.9167Z"
                fill="#909090"
            />
            <path
                d="M90.8712 14.1514V15.3332H89.6894V14.1514H90.8712ZM90.8712 13.3635H89.6894C89.4805 13.3635 89.2801 13.4465 89.1323 13.5943C88.9846 13.742 88.9016 13.9424 88.9016 14.1514V15.3332C88.9016 15.5422 88.9846 15.7426 89.1323 15.8903C89.2801 16.0381 89.4805 16.1211 89.6894 16.1211H90.8712C91.0802 16.1211 91.2806 16.0381 91.4284 15.8903C91.5761 15.7426 91.6591 15.5422 91.6591 15.3332V14.1514C91.6591 13.9424 91.5761 13.742 91.4284 13.5943C91.2806 13.4465 91.0802 13.3635 90.8712 13.3635Z"
                fill="#909090"
            />
            <path
                d="M95.2046 14.1514V15.3332H94.0228V14.1514H95.2046ZM95.2046 13.3635H94.0228C93.8138 13.3635 93.6134 13.4465 93.4657 13.5943C93.3179 13.742 93.2349 13.9424 93.2349 14.1514V15.3332C93.2349 15.5422 93.3179 15.7426 93.4657 15.8903C93.6134 16.0381 93.8138 16.1211 94.0228 16.1211H95.2046C95.4135 16.1211 95.6139 16.0381 95.7617 15.8903C95.9095 15.7426 95.9925 15.5422 95.9925 15.3332V14.1514C95.9925 13.9424 95.9095 13.742 95.7617 13.5943C95.6139 13.4465 95.4135 13.3635 95.2046 13.3635Z"
                fill="#909090"
            />
            <path
                d="M99.5379 14.1514V15.3332H98.3561V14.1514H99.5379ZM99.5379 13.3635H98.3561C98.1472 13.3635 97.9468 13.4465 97.799 13.5943C97.6512 13.742 97.5682 13.9424 97.5682 14.1514V15.3332C97.5682 15.5422 97.6512 15.7426 97.799 15.8903C97.9468 16.0381 98.1472 16.1211 98.3561 16.1211H99.5379C99.7469 16.1211 99.9473 16.0381 100.095 15.8903C100.243 15.7426 100.326 15.5422 100.326 15.3332V14.1514C100.326 13.9424 100.243 13.742 100.095 13.5943C99.9473 13.4465 99.7469 13.3635 99.5379 13.3635Z"
                fill="#909090"
            />
            <path
                d="M103.871 14.1514V15.3332H102.689V14.1514H103.871ZM103.871 13.3635H102.689C102.48 13.3635 102.28 13.4465 102.132 13.5943C101.985 13.742 101.902 13.9424 101.902 14.1514V15.3332C101.902 15.5422 101.985 15.7426 102.132 15.8903C102.28 16.0381 102.48 16.1211 102.689 16.1211H103.871C104.08 16.1211 104.281 16.0381 104.428 15.8903C104.576 15.7426 104.659 15.5422 104.659 15.3332V14.1514C104.659 13.9424 104.576 13.742 104.428 13.5943C104.281 13.4465 104.08 13.3635 103.871 13.3635Z"
                fill="#909090"
            />
            <path
                d="M108.205 14.1514V15.3332H107.023V14.1514H108.205ZM108.205 13.3635H107.023C106.814 13.3635 106.613 13.4465 106.466 13.5943C106.318 13.742 106.235 13.9424 106.235 14.1514V15.3332C106.235 15.5422 106.318 15.7426 106.466 15.8903C106.613 16.0381 106.814 16.1211 107.023 16.1211H108.205C108.414 16.1211 108.614 16.0381 108.762 15.8903C108.909 15.7426 108.992 15.5422 108.992 15.3332V14.1514C108.992 13.9424 108.909 13.742 108.762 13.5943C108.614 13.4465 108.414 13.3635 108.205 13.3635Z"
                fill="#909090"
            />
            <path
                d="M112.538 14.1514V15.3332H111.356V14.1514H112.538ZM112.538 13.3635H111.356C111.147 13.3635 110.947 13.4465 110.799 13.5943C110.651 13.742 110.568 13.9424 110.568 14.1514V15.3332C110.568 15.5422 110.651 15.7426 110.799 15.8903C110.947 16.0381 111.147 16.1211 111.356 16.1211H112.538C112.747 16.1211 112.947 16.0381 113.095 15.8903C113.243 15.7426 113.326 15.5422 113.326 15.3332V14.1514C113.326 13.9424 113.243 13.742 113.095 13.5943C112.947 13.4465 112.747 13.3635 112.538 13.3635Z"
                fill="#909090"
            />
            <path
                d="M116.871 14.1514V15.3332H115.689V14.1514H116.871ZM116.871 13.3635H115.689C115.48 13.3635 115.28 13.4465 115.132 13.5943C114.985 13.742 114.902 13.9424 114.902 14.1514V15.3332C114.902 15.5422 114.985 15.7426 115.132 15.8903C115.28 16.0381 115.48 16.1211 115.689 16.1211H116.871C117.08 16.1211 117.281 16.0381 117.428 15.8903C117.576 15.7426 117.659 15.5422 117.659 15.3332V14.1514C117.659 13.9424 117.576 13.742 117.428 13.5943C117.281 13.4465 117.08 13.3635 116.871 13.3635Z"
                fill="#909090"
            />
            <path
                d="M121.205 14.1514V15.3332H120.023V14.1514H121.205ZM121.205 13.3635H120.023C119.814 13.3635 119.613 13.4465 119.466 13.5943C119.318 13.742 119.235 13.9424 119.235 14.1514V15.3332C119.235 15.5422 119.318 15.7426 119.466 15.8903C119.613 16.0381 119.814 16.1211 120.023 16.1211H121.205C121.414 16.1211 121.614 16.0381 121.762 15.8903C121.909 15.7426 121.992 15.5422 121.992 15.3332V14.1514C121.992 13.9424 121.909 13.742 121.762 13.5943C121.614 13.4465 121.414 13.3635 121.205 13.3635Z"
                fill="#909090"
            />
            <path
                d="M125.538 14.1514V15.3332H124.356V14.1514H125.538ZM125.538 13.3635H124.356C124.147 13.3635 123.947 13.4465 123.799 13.5943C123.651 13.742 123.568 13.9424 123.568 14.1514V15.3332C123.568 15.5422 123.651 15.7426 123.799 15.8903C123.947 16.0381 124.147 16.1211 124.356 16.1211H125.538C125.747 16.1211 125.947 16.0381 126.095 15.8903C126.243 15.7426 126.326 15.5422 126.326 15.3332V14.1514C126.326 13.9424 126.243 13.742 126.095 13.5943C125.947 13.4465 125.747 13.3635 125.538 13.3635Z"
                fill="#909090"
            />
            <path
                d="M90.8712 32.6668V33.8486H89.6894V32.6668H90.8712ZM90.8712 31.8789H89.6894C89.4805 31.8789 89.2801 31.9619 89.1323 32.1097C88.9846 32.2574 88.9016 32.4578 88.9016 32.6668V33.8486C88.9016 34.0576 88.9846 34.258 89.1323 34.4057C89.2801 34.5535 89.4805 34.6365 89.6894 34.6365H90.8712C91.0802 34.6365 91.2806 34.5535 91.4284 34.4057C91.5761 34.258 91.6591 34.0576 91.6591 33.8486V32.6668C91.6591 32.4578 91.5761 32.2574 91.4284 32.1097C91.2806 31.9619 91.0802 31.8789 90.8712 31.8789Z"
                fill="#909090"
            />
            <path
                d="M95.2046 32.6668V33.8486H94.0228V32.6668H95.2046ZM95.2046 31.8789H94.0228C93.8138 31.8789 93.6134 31.9619 93.4657 32.1097C93.3179 32.2574 93.2349 32.4578 93.2349 32.6668V33.8486C93.2349 34.0576 93.3179 34.258 93.4657 34.4057C93.6134 34.5535 93.8138 34.6365 94.0228 34.6365H95.2046C95.4135 34.6365 95.6139 34.5535 95.7617 34.4057C95.9095 34.258 95.9925 34.0576 95.9925 33.8486V32.6668C95.9925 32.4578 95.9095 32.2574 95.7617 32.1097C95.6139 31.9619 95.4135 31.8789 95.2046 31.8789Z"
                fill="#909090"
            />
            <path
                d="M99.5379 32.6668V33.8486H98.3561V32.6668H99.5379ZM99.5379 31.8789H98.3561C98.1472 31.8789 97.9468 31.9619 97.799 32.1097C97.6512 32.2574 97.5682 32.4578 97.5682 32.6668V33.8486C97.5682 34.0576 97.6512 34.258 97.799 34.4057C97.9468 34.5535 98.1472 34.6365 98.3561 34.6365H99.5379C99.7469 34.6365 99.9473 34.5535 100.095 34.4057C100.243 34.258 100.326 34.0576 100.326 33.8486V32.6668C100.326 32.4578 100.243 32.2574 100.095 32.1097C99.9473 31.9619 99.7469 31.8789 99.5379 31.8789Z"
                fill="#909090"
            />
            <path
                d="M103.871 32.6668V33.8486H102.689V32.6668H103.871ZM103.871 31.8789H102.689C102.48 31.8789 102.28 31.9619 102.132 32.1097C101.985 32.2574 101.902 32.4578 101.902 32.6668V33.8486C101.902 34.0576 101.985 34.258 102.132 34.4057C102.28 34.5535 102.48 34.6365 102.689 34.6365H103.871C104.08 34.6365 104.281 34.5535 104.428 34.4057C104.576 34.258 104.659 34.0576 104.659 33.8486V32.6668C104.659 32.4578 104.576 32.2574 104.428 32.1097C104.281 31.9619 104.08 31.8789 103.871 31.8789Z"
                fill="#909090"
            />
            <path
                d="M108.205 32.6668V33.8486H107.023V32.6668H108.205ZM108.205 31.8789H107.023C106.814 31.8789 106.613 31.9619 106.466 32.1097C106.318 32.2574 106.235 32.4578 106.235 32.6668V33.8486C106.235 34.0576 106.318 34.258 106.466 34.4057C106.613 34.5535 106.814 34.6365 107.023 34.6365H108.205C108.414 34.6365 108.614 34.5535 108.762 34.4057C108.909 34.258 108.992 34.0576 108.992 33.8486V32.6668C108.992 32.4578 108.909 32.2574 108.762 32.1097C108.614 31.9619 108.414 31.8789 108.205 31.8789Z"
                fill="#909090"
            />
            <path
                d="M112.538 32.6668V33.8486H111.356V32.6668H112.538ZM112.538 31.8789H111.356C111.147 31.8789 110.947 31.9619 110.799 32.1097C110.651 32.2574 110.568 32.4578 110.568 32.6668V33.8486C110.568 34.0576 110.651 34.258 110.799 34.4057C110.947 34.5535 111.147 34.6365 111.356 34.6365H112.538C112.747 34.6365 112.947 34.5535 113.095 34.4057C113.243 34.258 113.326 34.0576 113.326 33.8486V32.6668C113.326 32.4578 113.243 32.2574 113.095 32.1097C112.947 31.9619 112.747 31.8789 112.538 31.8789Z"
                fill="#909090"
            />
            <path
                d="M116.871 32.6668V33.8486H115.689V32.6668H116.871ZM116.871 31.8789H115.689C115.48 31.8789 115.28 31.9619 115.132 32.1097C114.985 32.2574 114.902 32.4578 114.902 32.6668V33.8486C114.902 34.0576 114.985 34.258 115.132 34.4057C115.28 34.5535 115.48 34.6365 115.689 34.6365H116.871C117.08 34.6365 117.281 34.5535 117.428 34.4057C117.576 34.258 117.659 34.0576 117.659 33.8486V32.6668C117.659 32.4578 117.576 32.2574 117.428 32.1097C117.281 31.9619 117.08 31.8789 116.871 31.8789Z"
                fill="#909090"
            />
            <path
                d="M121.205 32.6668V33.8486H120.023V32.6668H121.205ZM121.205 31.8789H120.023C119.814 31.8789 119.613 31.9619 119.466 32.1097C119.318 32.2574 119.235 32.4578 119.235 32.6668V33.8486C119.235 34.0576 119.318 34.258 119.466 34.4057C119.613 34.5535 119.814 34.6365 120.023 34.6365H121.205C121.414 34.6365 121.614 34.5535 121.762 34.4057C121.909 34.258 121.992 34.0576 121.992 33.8486V32.6668C121.992 32.4578 121.909 32.2574 121.762 32.1097C121.614 31.9619 121.414 31.8789 121.205 31.8789Z"
                fill="#909090"
            />
            <path
                d="M125.538 32.6668V33.8486H124.356V32.6668H125.538ZM125.538 31.8789H124.356C124.147 31.8789 123.947 31.9619 123.799 32.1097C123.651 32.2574 123.568 32.4578 123.568 32.6668V33.8486C123.568 34.0576 123.651 34.258 123.799 34.4057C123.947 34.5535 124.147 34.6365 124.356 34.6365H125.538C125.747 34.6365 125.947 34.5535 126.095 34.4057C126.243 34.258 126.326 34.0576 126.326 33.8486V32.6668C126.326 32.4578 126.243 32.2574 126.095 32.1097C125.947 31.9619 125.747 31.8789 125.538 31.8789Z"
                fill="#909090"
            />
            <path
                d="M105.681 21.6604L109.085 23.6415L105.681 25.6226H105.417V21.6604H105.681ZM105.681 21H105.417C105.242 21 105.074 21.0696 104.95 21.1934C104.826 21.3173 104.757 21.4852 104.757 21.6604V25.6226C104.757 25.7978 104.826 25.9658 104.95 26.0896C105.074 26.2134 105.242 26.283 105.417 26.283H105.681C105.797 26.283 105.912 26.2521 106.013 26.1934L109.417 24.2123C109.517 24.1542 109.6 24.0709 109.658 23.9707C109.715 23.8706 109.746 23.757 109.746 23.6415C109.746 23.526 109.715 23.4125 109.658 23.3123C109.6 23.2121 109.517 23.1288 109.417 23.0707L106.013 21.0896C105.912 21.0309 105.797 21 105.681 21Z"
                fill="#909090"
            />
            <path
                d="M54.4856 22.8326C54.4856 22.8326 54.4982 22.8828 54.4982 22.9079C54.4982 22.9519 54.5107 22.9958 54.5233 23.0335C54.5358 23.0711 54.5546 23.1025 54.5735 23.1402C54.5923 23.1778 54.6111 23.2092 54.6425 23.2406C54.6739 23.272 54.7053 23.2971 54.7429 23.3284C54.7618 23.3473 54.7806 23.3661 54.7994 23.3787L62.4312 27.7406C62.4312 27.7406 62.4877 27.7657 62.5191 27.7782C62.5379 27.7845 62.563 27.7971 62.5818 27.8033C62.632 27.8159 62.6885 27.8222 62.7387 27.8222C62.7952 27.8222 62.8454 27.8159 62.9019 27.8033C62.9521 27.7908 63.0086 27.7657 63.0525 27.7406L70.6843 23.3787C70.8789 23.2657 70.9982 23.0586 70.9982 22.8326V14.1088C70.9982 14.0523 70.9919 13.9958 70.973 13.9456C70.9542 13.8766 70.9228 13.8138 70.8789 13.7573C70.8664 13.7448 70.8601 13.7259 70.8475 13.7134C70.8036 13.6569 70.7471 13.6067 70.6843 13.569L63.0525 9.20711C63.0525 9.20711 63.0023 9.19456 62.9835 9.18201C62.9459 9.16318 62.9019 9.15063 62.8643 9.14435C62.8266 9.13808 62.7889 9.13808 62.745 9.13808C62.7074 9.13808 62.6697 9.13808 62.6258 9.14435C62.5818 9.15063 62.5442 9.16946 62.5002 9.18828C62.4751 9.20084 62.45 9.20084 62.4312 9.21339L54.7994 13.5753C54.7994 13.5753 54.7806 13.5941 54.768 13.6004C54.7429 13.613 54.7241 13.6318 54.699 13.6506C54.6802 13.6695 54.6613 13.6883 54.6425 13.7134C54.6237 13.7322 54.6048 13.7573 54.5923 13.7824C54.586 13.795 54.5735 13.8013 54.5672 13.8138C54.5609 13.8264 54.5672 13.8389 54.5546 13.8452C54.5421 13.8703 54.5358 13.8954 54.5233 13.9205C54.5107 13.9519 54.5044 13.977 54.4982 14.0084C54.4982 14.0335 54.4856 14.0649 54.4856 14.09C54.4856 14.1025 54.4793 14.1151 54.4793 14.1276V22.8515L54.4856 22.8326ZM62.745 10.4749L69.109 14.1151L62.745 17.7552L56.381 14.1151L62.745 10.4749ZM55.7408 15.1946L62.1174 18.841V26.1151L55.7408 22.4686V15.1946ZM69.7492 22.4686L63.3726 26.1151V18.841L69.7492 15.1946V22.4686Z"
                fill="#909090"
            />
            <path
                d="M67.7722 40.9456L63.3726 45.3326V33.3766C63.3726 33.0314 63.0902 32.749 62.745 32.749C62.3998 32.749 62.1174 33.0314 62.1174 33.3766V45.3326L57.7178 40.9456C57.473 40.7008 57.0777 40.7008 56.8329 40.9456C56.5881 41.1904 56.5881 41.5858 56.8329 41.8305L61.9291 46.9079C62.1551 47.1339 62.45 47.2469 62.7513 47.2469C63.0525 47.2469 63.3475 47.1339 63.5735 46.9079L68.6697 41.8305C68.9145 41.5858 68.9145 41.1904 68.6697 40.9456C68.4249 40.7008 68.0295 40.7008 67.7848 40.9456H67.7722Z"
                fill="#909090"
            />
            <path
                d="M76.8475 6.69665L71.2618 1.11088C70.5463 0.395397 69.5923 0 68.5756 0H51.335C49.2387 0 47.5379 1.70711 47.5379 3.79707V33.1695C47.5379 35.2657 49.245 36.9665 51.335 36.9665H58.358C58.7032 36.9665 58.9856 36.6841 58.9856 36.3389C58.9856 35.9937 58.7032 35.7113 58.358 35.7113H51.335C49.9291 35.7113 48.7931 34.569 48.7931 33.1695V3.80335C48.7931 2.39749 49.9354 1.26151 51.335 1.26151H68.5756C69.2534 1.26151 69.8935 1.5251 70.3768 2.00837L75.9626 7.59414C76.4396 8.07741 76.7094 8.7113 76.7094 9.3954V33.1883C76.7094 34.5941 75.5672 35.7301 74.1676 35.7301H67.1948C66.8496 35.7301 66.5672 36.0126 66.5672 36.3577C66.5672 36.7029 66.8496 36.9854 67.1948 36.9854H74.1676C76.2638 36.9854 77.9647 35.2782 77.9647 33.1883V9.38284C77.9647 8.36611 77.5693 7.41213 76.8538 6.69665H76.8475Z"
                fill="#909090"
            />
        </svg>
    );
};

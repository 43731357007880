/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

export const ASSET_COMPOSITE_TYPE = "application/vnd.adobe.sunrise.asset+dcx";

export enum SnacComponentPaths {
    sourceModel = "models/source",
    webModel = "models/web",
    arModel = "models/ar",
    thumbnailDraft = "renditions/thumbnail_draft",
    thumbnailMedium = "renditions/thumbnail_medium",
    thumbnailHigh = "renditions/thumbnail_high",
    rendersFront = "renders/front",
    rendersBack = "renders/back",
    rendersLeft = "renders/left",
    rendersRight = "renders/right",
    rendersTop = "renders/top",
    // key is constructed dynamically:
    // <modelType>-converted-<FileExtension>
    "sourceModel-converted-.glb" = "models/source/converted/glb",
    "sourceModel-merged-.glb" = "models/source/merged/glb",
    "sourceModel-converted-.usdz" = "models/source/converted/usdz",
    "webModel-converted-.glb" = "models/web/converted/glb",
    "webModel-converted-.usdz" = "models/web/converted/usdz",
    "arModel-converted-.glb" = "models/ar/converted/glb",
    "arModel-converted-.usdz" = "models/ar/converted/usdz",
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import axios from "axios";

import type { Axios } from "axios";

export const CONTENT_JSON = "application/json";
export const RESPONSE_JSON = "application/json";

/**
 * Invitations Service V4 API references
 * https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=acp&title=Invitations+Service+V4#InvitationsServiceV4-Overview
 *
 * For bulk shares POST call to add/remove collaborators/invitations to list of resources
 * https://wiki.corp.adobe.com/display/acp/Bulk+POST+Shares
 * https://wiki.corp.adobe.com/pages/viewpage.action?pageId=2741481220
 */

/** Sunrise supported invitations V4 API  */
export enum InvitationsApi {
    /**
     * share GET - get sharing information of a resource.
     * share POST - add collaborators or invitations for a resource.
     */
    share = "share",
    /**
     * invitations urn POST - confirm an invitation for a resource.
     */
    confirmInvitation = "invitations/urn",
    /**
     * capabilities GET - get the sharing capabilities on a resource for an user.
     */
    capabilities = "capabilities",
    /**
     * auth GET - get the access URL for a resource.
     */
    auth = "auth",

    /**
     * bulk shares - a list of asset urns
     * GET, POST, PATCH (call with POST), DELETE
     */
    shares = "shares",
}

export enum RecipientRole {
    viewer = "viewer",
    editor = "editor",
}

export type CapabilitiesData = {
    canComment: boolean;
    canShare: boolean;
    commentPermissions: string[]; // "read", "write", "delete"
    customEmailMessage: boolean;
    publicPrincipalsAllowed: boolean;
    resourceOrgName: string;
    role: string;
    resourceOrgID: string;
    sendEmail: boolean;
};

export type ResourceAccessData = {
    accessURL: string;
    ownerID: string;
    resourceType: string;
};

/** Used in the share POST and PATCH request */
export type RecipientUpdateData = {
    recipient?: string; // One of email address, address book entry, adobeID, pre-defined principal
    id?: string; // same as recipient
    action?: string; // optional for add (POST), required for update (PATCH)
    role: string; // "editor", "viewer"
    canShare: boolean;
    canComment: boolean;
};
export type RecipientRemoveData = {
    recipient?: string; // One of email address, address book entry, adobeID, pre-defined principal
    id?: string; // same as recipient
    action: string;
};

export type RecipientData = RecipientUpdateData | RecipientRemoveData;

export type ResponseCollaborators = {
    inviteLabel: string;
    recipients: RecipientData[];
    configuration: {
        useMount: boolean;
        acceptanceRequired: boolean;
    };
};

export type ImsUserData = {
    displayName: string /** display name is only available if user has a public profile. */;
    email: string;
    userId: string;
};

export type GroupData = {
    groupUrn: string;
    groupName: string;
    organizationName: string;
};

export type PrincipalData = {
    organizationName: string;
    organizationID: string;
};

/** Minimum data needed for role and display name for a specified resource. */
export type CollaboratorData = {
    id: string;
    type: string /**  one of "imsUser", "addressBookGroup", "predefinedPrincipal" */;
    role: string;

    /**
     * ImsUserData if type is "imsUser"
     * GroupData if type is "addressBookGroup"
     * PrincipalData if type is "predefinedPrincipal" and if "orgEverybody"
     */
    additionalData?: ImsUserData | GroupData | PrincipalData;
};

export type ResponseDetail = {
    status: number;
    responseText?: string;
    data?: CapabilitiesData | ResourceAccessData | ResponseCollaborators;
};

export type InvitationsAPIConfig = {
    /** Ims client Id for api-key */
    imsClientId: string;
    /** prod or nonprod, e.g. stage, etc */
    imsEnv: string;
    /** authentication token */
    authToken: string;
};

/**
 * Invitations service API supported:
 * - add collaborators for a resource.
 * - confirm invitation for a resource.
 * - get resource access URL.
 * - get resource capabilities
 * @returns The result promise for the request.
 */
export class InvitationsApiClient {
    private instance: Axios;
    private bulkInstance: Axios;

    constructor(imsClientId: string, imsEnv: string, authToken: string) {
        const baseUrl =
            imsEnv === "prod"
                ? "invitations.adobe.io"
                : "invitations-stage.adobe.io";
        const INVITATION_API_URL = `https://${baseUrl}/api/v4`;

        this.instance = axios.create({
            baseURL: INVITATION_API_URL,
            headers: {
                authorization: `Bearer ${authToken}`,
                "x-api-key": imsClientId,
                Accept: RESPONSE_JSON,
                "Content-Type": CONTENT_JSON,
            },
        });
        this.bulkInstance = axios.create({
            baseURL: `https://${baseUrl}/ops/v4`,
            headers: {
                authorization: `Bearer ${authToken}`,
                "x-api-key": imsClientId,
                Accept: RESPONSE_JSON,
                "Content-Type": CONTENT_JSON,
            },
        });
    }

    public async request(
        method: "GET" | "POST" | "PATCH" | "DELETE",
        api: string,
        assetUrns: string[],
        requestParameter?:
            | RecipientData[]
            | {
                  collaborators?: RecipientData[];
                  invitations?: RecipientData[];
              },
    ) {
        if (!assetUrns.length) {
            throw new Error(
                `Must specify at least one asset urn for any invitation service request `,
            );
        }
        const assetUrn = assetUrns[0];
        const bulkRequest = api === InvitationsApi.shares;
        const url = `/${api}${!bulkRequest ? "/" + assetUrn : ""}`;
        if (
            (method === "PATCH" && api !== InvitationsApi.share) ||
            (method === "DELETE" && api !== InvitationsApi.shares)
        ) {
            throw new Error(`Unsupported method for the request, ${url}`);
        }

        let data;
        if (api === InvitationsApi.share || api === InvitationsApi.shares) {
            if (method === "POST") {
                if (!requestParameter) {
                    throw new Error(`Request ${url} expects valid recipients`);
                }
                // TODO: should we set ANS?
                // Email is set to null so that no email notification would be sent.
                // When recipient is "all", "allWithPassword", and "orgEverybody", role must be
                // set to "viewer". Setting to "editor" will return status code 400.1 (malformed recipient)
                // To set "editor", must use email address, address book entry, or adobeID
                const recipients = Array.isArray(requestParameter)
                    ? { recipients: requestParameter }
                    : requestParameter;

                const requestBody = {
                    ...recipients,
                    configuration: {
                        useMounts: false,
                        acceptanceRequired: false,
                    },
                    notification: {},
                };
                // Set this up for bulk shares requests.
                const parameters = {
                    urns: assetUrns,
                    requestBody,
                };
                data = bulkRequest
                    ? {
                          operation: "bulkPostShares",
                          parameters,
                      }
                    : {
                          inviteLabel: "Service Label", // Only valid if making service call.
                          ...requestBody,
                      };
            } else if (method === "PATCH") {
                if (!requestParameter) {
                    throw new Error(
                        `Request ${url} expects valid recipients or requestParameter`,
                    );
                }
                // Update existing collaborators: modify or remove sharing for a resource.
                // If updating with bulk share update, should use POST, but
                // following the request parameters for single resource PATCH request.
                const collaborators = Array.isArray(requestParameter)
                    ? requestParameter
                    : requestParameter.collaborators;
                let invitations;
                if (!collaborators) {
                    invitations = Array.isArray(requestParameter)
                        ? requestParameter
                        : requestParameter.invitations;
                }
                data = { collaborators, invitations };
            } else if (method === "DELETE") {
                data = {
                    operation: "deleteShares",
                    parameters: {
                        urns: assetUrns,
                    },
                };
            }
        }

        const requestInstance = bulkRequest ? this.bulkInstance : this.instance;
        const response = await requestInstance.request({
            url,
            method,
            data,
        });

        if (!response) {
            throw new Error(
                `Unable to get a response from ${
                    bulkRequest ? "bulk " : ""
                }request url, ${url}`,
            );
        }

        // eslint-disable-next-line no-console
        console.log(
            `Invitations service: ${
                bulkRequest ? "bulk " : ""
            }request url, ${api}, ${method}, ${url} complete`,
        );
        if (response.status > 309) {
            // eslint-disable-next-line no-console
            console.log(
                `Invitations service: Error uploading ${response.status}, ${response.statusText}`,
            );
        } else if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 202
        ) {
            // eslint-disable-next-line no-console
            console.log(
                `Invitations service: response statusText ${
                    response.statusText
                }, additional Info ${JSON.stringify(response.data)}`,
            );
        }

        return response;
    }

    public async jobRequest(jobId: string) {
        const url = `/jobs/${jobId}`;
        const response = await this.instance.request({
            url,
            method: "GET",
        });

        if (!response) {
            throw new Error(
                `Unable to get results from bulk share job Id "${jobId}"`,
            );
        }
        // eslint-disable-next-line no-console
        console.log(
            `Job request response status: ${response.status}, ${response.statusText}`,
        );

        return response;
    }
}

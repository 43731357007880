/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, Text, View } from "@adobe/react-spectrum";

import { HealthCheckStatusIcon } from "./HealthCheckStatusIcon";
import { useFormatHealthCheckValue } from "@src/interfaces/common/hooks/useFormatHealthCheckValue";

import type { HealthCheckAttribute, HealthCheckStatus } from "@shared/types";
import type { FC, ReactNode } from "react";
interface HealthCheckWellProps {
    attribute: HealthCheckAttribute;
    heading: string;
    text?: string;
    value: number;
    status?: HealthCheckStatus;
    children: ReactNode;
}

export const HealthCheckWell: FC<HealthCheckWellProps> = ({
    attribute,
    heading,
    text,
    value,
    status,
    children: iconArea,
}) => {
    const subHeading = useFormatHealthCheckValue(value, attribute) ?? "";
    return (
        <View
            backgroundColor="gray-100"
            padding="size-200"
            borderRadius="large">
            <Flex alignItems="center" marginBottom="size-100">
                <View paddingEnd="size-100">{iconArea}</View>
                <Flex direction="row" alignItems="center">
                    <Heading level={4}>{heading}</Heading>
                    <Heading level={4} marginX="size-50">
                        {subHeading}
                    </Heading>
                    {status && <HealthCheckStatusIcon status={status} />}
                </Flex>
            </Flex>
            <Text
                UNSAFE_style={{
                    fontSize: "12px",
                }}>
                {text}
            </Text>
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { createContext, useContext, useEffect, useState } from "react";

import { config } from "@src/config";

const SESSION_STORAGE_KEY = "featureFlagOverrides";

const featureFlagDefaults = {
    // Keep this list alphabetized for cleaner merges

    // Persistent feature flags, DFF_ prefix is used for Dev/Debug FFs. Placed
    // before the standard "FF_" flags to comply with alphabetizing the entries.
    DFF_JOB_ERRORS_UI: false,
    DFF_SHOW_HEALTH_CHECK_RESULTS: false,

    // Use the FF_MY_FEATURE pattern to increase clarity when used
    // Removal: https://jira.corp.adobe.com/browse/SRV3D-6435
    FF_ASSET_APPROVAL_CONFIRMATION: true,
    // Removal: https://jira.corp.adobe.com/browse/SRV3D-6861
    FF_ASSET_LOGS_PANEL: false,
    // Removal: https://jira.corp.adobe.com/browse/SRV3D-7006
    FF_CHROME_POPUP: true,
    // Removal: https://jira.corp.adobe.com/browse/SRV3D-5870
    FF_HEADER_CONTEXTUAL_HELP: true,
    // Removal: https://jira.corp.adobe.com/browse/SRV3D-5915
    FF_LEFT_NAV_HOME: false,
    // Removal: https://jira.corp.adobe.com/browse/SRV3D-6621
    FF_PROJECT_APPROVAL_CONFIRMATION: true,
    // Removal: https://jira.corp.adobe.com/browse/SRV3D-5873
    FF_SEARCH_FIELD: false,
    // Removal: https://jira.corp.adobe.com/browse/SRV3D-6927
    FF_SENTRY_INTEGRATION: config.env !== "local",
    // Removal: https://jira.corp.adobe.com/browse/SRV3D-6927
    FF_SENTRY_INTEGRATION_DEBUG: true,
    // Removal: https://jira.corp.adobe.com/browse/SRV3D-6940
    FF_SKIP_ONEAPI: false,
};

export type FeatureFlags = typeof featureFlagDefaults;

export type FeatureFlagKey = keyof FeatureFlags;

declare global {
    interface Window {
        featureFlag: {
            set: (...ff: FeatureFlagKey[]) => string;
            unset: (...ff: FeatureFlagKey[]) => string;
            print: (ff?: FeatureFlagKey) => string;
            dump: () => FeatureFlags;
            reset: () => string;
        };
    }
}

export const FeatureFlagContext =
    createContext<FeatureFlags>(featureFlagDefaults);

export const FeatureFlagContextProvider = ({
    children,
}: React.PropsWithChildren) => {
    const [featureFlagOverrides, setFeatureFlagOverrides] =
        useState<FeatureFlags>(
            JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY) ?? "{}"),
        );
    const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({
        ...featureFlagDefaults,
        ...featureFlagOverrides,
    });

    function ffsToFeatureFlags(ffs: FeatureFlagKey[], enabled: boolean) {
        return ffs.reduce((acc, ff) => {
            acc[ff] = enabled;
            return acc;
        }, {} as FeatureFlags);
    }

    globalThis.window.featureFlag = {
        set: (...ffs: FeatureFlagKey[]) => {
            setFeatureFlagOverrides((prevValue) => ({
                ...prevValue,
                ...ffsToFeatureFlags(ffs, true),
            }));
            return `${ffs} enabled.`;
        },
        unset: (...ffs: FeatureFlagKey[]) => {
            setFeatureFlagOverrides((prevValue) => ({
                ...prevValue,
                ...ffsToFeatureFlags(ffs, false),
            }));
            return `${ffs} disabled.`;
        },
        print: (ff?: FeatureFlagKey) => {
            if (ff) {
                return `${ff} ${featureFlags[ff]}`;
            } else {
                return JSON.stringify(featureFlags);
            }
        },

        dump: () => {
            return featureFlags;
        },

        reset: () => {
            setFeatureFlagOverrides(featureFlagDefaults);
            return "Feature flags reset to default.";
        },
    };

    useEffect(() => {
        if (Object.keys(featureFlagOverrides).length) {
            setFeatureFlags((prevValue) => ({
                ...prevValue,
                ...featureFlagOverrides,
            }));
        } else {
            setFeatureFlags(featureFlagDefaults);
        }
        sessionStorage.setItem(
            SESSION_STORAGE_KEY,
            JSON.stringify(featureFlagOverrides),
        );
    }, [featureFlagOverrides]);

    return (
        <FeatureFlagContext.Provider value={featureFlags}>
            {children}
        </FeatureFlagContext.Provider>
    );
};

export const useFeatureFlagContext = () => {
    const context = useContext(FeatureFlagContext);
    if (!context) {
        throw new Error(
            "useFeatureFlagContext must be used within a FeatureFlagContextProvider.",
        );
    }
    return context;
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

async function sleep(seconds: number) {
    return new Promise((res) => {
        window.setTimeout(res, seconds * 1000);
    });
}

export async function pollForAdobeWebsocketUrl(
    apiRoute: string,
    user: { userToken: string; orgId?: string },
    config: { imsClientId: string; hostApi: string; wsApiUrl: string },
) {
    let url = "";

    async function getWSPresignedUrl() {
        const authParams =
            `user_token=${user.userToken}` +
	    `&api_key=${config.imsClientId}`;

        if (config.wsApiUrl) {
            return config.wsApiUrl + apiRoute + "?" + authParams;
        }
        const res = await window.fetch(
            `${config.hostApi}/api/generate-signed-url`,
            {
                headers: {
                    "X-Gw-Cookie-Path": apiRoute,
                    Authorization: `Bearer ${user.userToken}`,
                    "X-Api-Key": config.imsClientId,
                },
            },
        );
        const url = (await res.text()) + "&" + authParams;
        return url;
    }

    while (!url) {
        try {
            url = await getWSPresignedUrl();
        } catch (e) {
            await sleep(10);
        }
    }

    return url;
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { createHeaders, getWorkfrontApiSearchUrl } from "../util";
import { CustomFormListResponse, CustomFormResponse } from "./types/CustomFormResponse";
import { CONTENT_FRAGMENT_CUSTOM_FIELD_NAME, WF_OBJECT_CODE_CUSTOM_FORM } from "../constants";
import { WorkfrontParams } from "../../../types/wfParams";

const WF_CUSTOM_FORM_FIELDS_TO_BE_LOADED = `ID,name,categoryParameters:parameterID`;

export const fetchRequiredCustomFormContentFragment = async function (
    wfParams: WorkfrontParams,
    contentFragmentCustomFieldId: string,
): Promise<CustomFormResponse[]> {
    try {
        const headers = createHeaders(wfParams.imsToken);

        const WF_FETCH_CUSTOM_FORMS_URL =
            `${getWorkfrontApiSearchUrl({
                objCode: WF_OBJECT_CODE_CUSTOM_FORM,
                wfInstanceUrl: wfParams.wfInstanceUrl,
            })}?fields=${WF_CUSTOM_FORM_FIELDS_TO_BE_LOADED}` +
            `&categoryParameters:parameterID=${contentFragmentCustomFieldId}`;

        const res = await fetch(WF_FETCH_CUSTOM_FORMS_URL, {
            headers,
        });
        const apiResponse: CustomFormListResponse = await res.json();
        return apiResponse.data;
    } catch (e) {
        throw new Error(
            `Was not able to fetch the required custom form with custom field ${CONTENT_FRAGMENT_CUSTOM_FIELD_NAME}.`,
        );
    }
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Item, Picker } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { AssetStatusLight } from "./AssetStatusLight";

import type { FC, Key } from "react";

export enum ReviewStatus {
    IN_PROGRESS = "IN_PROGRESS",
    APPROVED = "APPROVED",
}

interface Props {
    approved: boolean;
    setApproval: (approved: boolean) => void;
    dataUiaPrefix: string;
    disabled?: boolean;
}

// Setting status light height to anything less will end up clipping the text rendered in the
// Picker when list is collapsed.
// TODO: Since this is a one-way operation, change approval from a drop-down
//      picker to a button with a confirmation dialog.
export const ApprovalPicker: FC<Props> = ({
    approved,
    setApproval,
    dataUiaPrefix,
    disabled,
}) => {
    const { t } = useTranslation("common");
    const initialStatus = approved
        ? ReviewStatus.APPROVED
        : ReviewStatus.IN_PROGRESS;
    return (
        <Picker
            aria-label={t("actions.approvalPicker")}
            data-uia={`${dataUiaPrefix}approval-btn`}
            marginEnd="size-100"
            justifySelf="center"
            isQuiet
            isDisabled={disabled || approved}
            selectedKey={initialStatus}
            onSelectionChange={(selected: Key) =>
                setApproval(selected === ReviewStatus.APPROVED)
            }>
            <Item
                key={ReviewStatus.IN_PROGRESS}
                textValue={ReviewStatus.IN_PROGRESS}>
                <AssetStatusLight
                    assetStatus={{
                        uploadComplete: true,
                        jobsCompleted: true,
                        jobsFailed: false,
                        approved: false,
                        jobErrors: false,
                        uploadFailed: false,
                        hasValidationErrors: false,
                        version: 1,
                    }}
                    maxHeight="static-size-300"
                />
            </Item>
            <Item
                data-uia={`${dataUiaPrefix}approved-item`}
                key={ReviewStatus.APPROVED}
                textValue={ReviewStatus.APPROVED}>
                <AssetStatusLight
                    assetStatus={{
                        uploadComplete: true,
                        jobsCompleted: true,
                        jobsFailed: false,
                        approved: true,
                        jobErrors: false,
                        uploadFailed: false,
                        hasValidationErrors: false,
                        version: 1,
                    }}
                    maxHeight="static-size-300"
                />
            </Item>
        </Picker>
    );
};

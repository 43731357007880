/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { WorkfrontWidgetProps } from "../types/WorkfrontWidget";
import { AuthStatusScreen } from "../AuthStatusScreen";
import { SharedContext } from "../context";
import { QueryClient, QueryClientProvider } from "react-query";
import { useImsAuthFlow } from "@assets/selectors";
import { generateImsSusiData } from "../utils/imsAuthData";
import { StatusBasedView } from "../StatusBasedView";

const queryClient = new QueryClient();

export const ShellWrapper = (props: WorkfrontWidgetProps) => {
    const { requiredAuth, imsConfig, wfInstanceUrl } = props.configuration;
    const { imsToken } = props.sharedContext;

    const displayAuthFlow = () => {
        if (requiredAuth) {
            const { imsAuthService, ImsAuthFlow } = useImsAuthFlow(
                generateImsSusiData(imsConfig),
            );

            return (
                <ImsAuthFlow>
                    <AuthStatusScreen
                        imsToken={imsAuthService.imsToken || ""}
                        wfInstanceUrl={wfInstanceUrl}>
                    </AuthStatusScreen>
                </ImsAuthFlow>
            );
        }
        return (
            <StatusBasedView
                imsToken={imsToken}
                wfInstanceUrl={wfInstanceUrl}
            />
        );
    };

    return (
        <QueryClientProvider client={queryClient}>
            <SharedContext.Provider
                value={{
                    configuration: props.configuration,
                    editorState: props.editorState,
                }}>
                {displayAuthFlow()}
            </SharedContext.Provider>
        </QueryClientProvider>
    );
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { WF_API_VERSION } from "./constants";

export const getWorkfrontApiBaseUrl = (wfInstanceUrl: string) =>
    `${wfInstanceUrl}/attask/api/${WF_API_VERSION}`;
export const getWorkfrontApiObjectBaseUrl = (obj: { objCode: string; wfInstanceUrl: string }) =>
    `${getWorkfrontApiBaseUrl(obj.wfInstanceUrl)}/${obj.objCode}`;
export const getWorkfrontApiSearchUrl = (obj: { objCode: string; wfInstanceUrl: string }) =>
    `${getWorkfrontApiObjectBaseUrl({
        objCode: obj.objCode,
        wfInstanceUrl: obj.wfInstanceUrl,
    })}/search`;

export const createHeaders = (imsToken: string) => ({
    Authorization: `Bearer ${imsToken}`,
    "Content-Type": "application/x-www-form-urlencoded",
});

export const convertToTimestamp = (dateInWfApiFormat: string): number => {
    return new Date(dateInWfApiFormat.substring(0, 10)).getTime();
};

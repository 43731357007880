/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Divider, Item, Picker, Text } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { useUIStateContext } from "@src/contexts/UIStateContext";

import type { Key } from "react";

export const DISPLAY_SIZE = {
    fullSize: "fullSize",
    fitToScreen: "fitToScreen",
} as const;
export type DisplaySize = (typeof DISPLAY_SIZE)[keyof typeof DISPLAY_SIZE];

export const DisplaySettingsPicker = () => {
    const { t } = useTranslation(["common"]);
    const { projectDetailDisplaySize, setProjectDetailDisplaySize } =
        useUIStateContext();
    const items = [
        {
            name: t("common:viewSettings.fitToScreen"),
            key: DISPLAY_SIZE.fitToScreen,
        },
        {
            name: t("common:viewSettings.fullSize"),
            key: DISPLAY_SIZE.fullSize,
        },
    ];

    return (
        <>
            <Picker
                selectedKey={projectDetailDisplaySize}
                items={items}
                width="size-2000"
                isQuiet
                onSelectionChange={(key: Key) => {
                    setProjectDetailDisplaySize(key as DisplaySize);
                }}
                aria-label={t("common:viewSettings.title")}>
                {(item) => (
                    <Item key={item.key} textValue={item.name}>
                        <Text maxHeight={"single-line-height"}>
                            {item.name}
                        </Text>
                    </Item>
                )}
            </Picker>
            <Divider
                orientation="vertical"
                size="S"
                height="size-300"
                marginX="size-100"
                marginY="size-100"
            />
        </>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { DirectionalLight } from "@babylonjs/core/Lights/directionalLight";
import { ShadowGenerator } from "@babylonjs/core/Lights/Shadows/shadowGenerator";
import { ShadowGeneratorSceneComponent } from "@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent";
import { CubeTexture } from "@babylonjs/core/Materials/Textures/cubeTexture";
import { HDRCubeTexture } from "@babylonjs/core/Materials/Textures/hdrCubeTexture";
import {
    Matrix,
    Quaternion,
} from "@babylonjs/core/Maths/math";
import { Color4 } from "@babylonjs/core/Maths/math.color";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import { CreateDisc } from "@babylonjs/core/Meshes/Builders/discBuilder";
import {
    SceneOptimizerOptions,
    CustomOptimization,
} from "@babylonjs/core/Misc/sceneOptimizer";
import { FxaaPostProcess } from "@babylonjs/core/PostProcesses/fxaaPostProcess";
import { ShadowOnlyMaterial } from "@babylonjs/materials/shadowOnly";
import {
    DEFAULT_ASPECT_RATIO,
    DEFAULT_IBL_URL,
    DEFAULT_RENDER_SETTINGS,
} from "@shared/common";

import { throttle } from "../utils/throttle";

import type { OptimizerSettings } from "../Studio";
import type { AdobeViewer, SerializedVector3 } from "@3di/adobe-3d-viewer";
import type { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import type { Camera } from "@babylonjs/core/Cameras/camera";
import type { PBRMaterial } from "@babylonjs/core/Materials/PBR/pbrMaterial";
import type { SceneOptimizer } from "@babylonjs/core/Misc/sceneOptimizer";
import type { Scene } from "@babylonjs/core/scene";
import type { Nullable } from "@babylonjs/core/types";
import type { CameraOverride, RenderSettings } from "@shared/types";

// eslint-disable-next-line no-console
console.debug(
    "We require this as a side effect",
    ShadowGeneratorSceneComponent,
);

export interface SceneManagerOptions {
    cameraOverride?: CameraOverride;
    onUpdateCameraPosition?: (override: CameraOverride) => void;
    enableLimitedZoom?: boolean;
    cameraName?: string;
    renderSettings?: RenderSettings;
    aspectRatio?: number;
    iblUrl?: string;
    optimizerSettings?: OptimizerSettings;
}

class ContactHardeningShadowOptimization extends CustomOptimization {
    private shadowGenerator?: ShadowGenerator;

    constructor(priority: number, shadowGenerator?: ShadowGenerator) {
        super(priority);
        this.shadowGenerator = shadowGenerator;
    }

    onApply = (scene: Scene, optimizer: SceneOptimizer) => {
        if (this.shadowGenerator) {
            // Turn dynamic shadow hardening off, unless optimizer is in improvement mode
            this.shadowGenerator.useContactHardeningShadow =
                optimizer.isInImprovementMode;

            // Always ensure transparencyShadow is the same, otherwise transparency artifacts may appear that
            // look crosshatched in the shadow
            this.shadowGenerator.transparencyShadow =
                optimizer.isInImprovementMode;

            this.shadowGenerator.recreateShadowMap();

            return true;
        } else {
            return false;
        }
    };

    onGetDescription = () => {
        return "Disabling contact hardening shadows (enabling in improvement mode)";
    };
}

class SsaoOptimization extends CustomOptimization {
    private viewer?: AdobeViewer;

    constructor(priority: number, viewer?: AdobeViewer) {
        super(priority);
        this.viewer = viewer;
    }

    onApply = (scene: Scene, optimizer: SceneOptimizer) => {
        if (this.viewer) {
            // Turn SSAO off, unless optimizer is in improvement mode
            this.viewer.ssaoEnabled = optimizer.isInImprovementMode;
            return true;
        } else {
            return false;
        }
    };

    onGetDescription = () => {
        return "Disabling SSAO (enabling in improvement mode)";
    };
}

export class SceneManager {
    scene: AdobeViewer["sceneManager"]["scene"];
    camera?: Nullable<ArcRotateCamera>;
    optimizer?: SceneOptimizer;
    shadowGenerator?: ShadowGenerator;

    modelBounds!: { min: Vector3; max: Vector3 };

    constructor(
        public viewer: AdobeViewer,
        private options: SceneManagerOptions,
    ) {
        if (!viewer.modelInitialized) {
            throw new Error(
                "Viewer model must be initialized before creating this manager",
            );
        }
        this.viewer = viewer;

        this.scene = viewer.sceneManager.scene;

        this.modelBounds = this.scene.getWorldExtends();
        this.setupScene();

        // Babylon's FPS calculations seem inconsistent and vary somewhat, so the target FPS for the optimizer will be
        // lower than the actual ideal 60 FPS, but instead an acceptable 30 FPS
        const optimizerOptions = new SceneOptimizerOptions(
            options.optimizerSettings?.optimizerFps ?? 30,
            options.optimizerSettings?.optimizerFreq ?? 5000, // Recheck FPS every 5 second
        );

        // Add a delay before applying the optimizations, because the FPS is in flux the first few seconds
        optimizerOptions.addCustomOptimization(
            () => true,
            () => "Optimizer delay finished",
            0,
        );
        optimizerOptions.addOptimization(
            new ContactHardeningShadowOptimization(1, this.shadowGenerator),
        );
        optimizerOptions.addOptimization(new SsaoOptimization(2, this.viewer));

        // This is an intense GPU optimization, that quickly both speeds up the viewer and degrades the quality. At
        // this point, we don't think it will be necessary
        // optimizerOptions.addOptimization(
        //     new HardwareScalingOptimization(4, 1.5),
        // );

        this.viewer.startOptimizer(optimizerOptions);
    }

    private setupScene() {
        if (!this.scene) {
            throw new Error(`Scene doesn't exist`);
        }
        const {
            cameraOverride,
            onUpdateCameraPosition,
            enableLimitedZoom,
            renderSettings,
            iblUrl,
        } = this.options;

        // Get the background color from the render settings, from the template. If there is none, use the default
        const backgroundColor =
            renderSettings?.backgroundColor ??
            DEFAULT_RENDER_SETTINGS.backgroundColor;

        this.scene.clearColor = new Color4(...backgroundColor);

        const IBLFilepath = iblUrl ?? DEFAULT_IBL_URL;

        let hdrTexture: CubeTexture | HDRCubeTexture;

        // Check the file extension to determine if it is a .env file or a .hdr file
        if (IBLFilepath.endsWith(".env")) {
            hdrTexture = CubeTexture.CreateFromPrefilteredData(
                IBLFilepath,
                this.scene,
            );
        } else {
            // Assumed to be a .hdr file
            hdrTexture = new HDRCubeTexture(
                IBLFilepath,
                this.scene,
                128,
                false,
                true,
                false,
                true,
                undefined,
                // eslint-disable-next-line no-console
                () => console.error("ERROR creating HDR scene from IBL file"),
            );
        }

        const domeLightRotationY =
            renderSettings?.domeLightRotation != undefined
                ? renderSettings?.domeLightRotation * (Math.PI / 180) - Math.PI
                : DEFAULT_RENDER_SETTINGS.domeLightRotation;

        hdrTexture.rotationY = domeLightRotationY;

        // This is weird.
        if (hdrTexture instanceof HDRCubeTexture ) {
            hdrTexture.onLoadObservable.addOnce(() => {
                this.viewer.renderLoop.toggle(100);
            });
        } else {// if (hdrTexture instanceof CubeTexture) {
            hdrTexture.onLoadObservable.addOnce(() => {
                this.viewer.renderLoop.toggle(100);
            });
        }
        this.scene.environmentTexture = hdrTexture;
        this.scene.environmentIntensity =
            renderSettings?.domeLightIntensity ??
            DEFAULT_RENDER_SETTINGS.domeLightIntensity;

        this.scene.materials.forEach((mat) => {
            if (!(mat as PBRMaterial).reflectionTexture) {
                (mat as PBRMaterial).reflectionTexture = hdrTexture;
            }
        });

        this.viewer.onAdobeViewerRenderCompleteObservable.addOnce(() => {
            let camera: Nullable<ArcRotateCamera> = null;
            if (this.options.cameraName) {
                const foundCamera = this.viewer.cameraManager
                    .getCameraArray()
                    .find((camera) => camera.name === this.options.cameraName);
                if (foundCamera) {
                    this.scene.activeCamera =
                        foundCamera as unknown as Nullable<Camera>;
                    camera = foundCamera as unknown as ArcRotateCamera;
                }
            }
            if (!camera) {
                camera =
                    this.viewer.cameraManager.getActiveCamera() as Nullable<ArcRotateCamera>;
            }
            if (camera) {
                const sceneRadius =
                    Vector3.Distance(
                        this.modelBounds.min,
                        this.modelBounds.max,
                    ) / 2;

                if (enableLimitedZoom) {
                    camera.lowerRadiusLimit = sceneRadius * 1.5;
                    camera.upperRadiusLimit = sceneRadius * 8;
                } else {
                    camera.lowerRadiusLimit = 0;
                    camera.upperRadiusLimit = null;
                }

                if (cameraOverride) {
                    // The values in the webviewer are normalized to the scene bounding box for rendering purposes.
                    // For the overrides that are fed back into the webviewer, we must apply the modelScale scale factor
                    // to get units back into the right coordinate space
                    const translation = cameraOverride.translation.map(
                        (value) => value * this.viewer.modelScale,
                    ) as SerializedVector3;
                    const target = cameraOverride.target.map(
                        (value) => value * this.viewer.modelScale,
                    ) as SerializedVector3;
                    camera.target.set(...target);
                    camera.setPosition(new Vector3(...translation));
                }

                if (onUpdateCameraPosition) {
                    camera.onViewMatrixChangedObservable.add(
                        throttle(() => {
                            let translation: SerializedVector3 = [0, 0, 0];
                            let target: SerializedVector3 = [0, 0, 0];
                            if (camera) {
                                camera.position.toArray(translation);
                                camera.target.toArray(target);
                                // The values in the webviewer are normalized to the scene bounding box for rendering purposes.
                                // But the overrides require the original, unscaled units.  This inverse scale factor will correct
                                // for this and return the original asset units
                                translation = translation.map(
                                    (value) => value / this.viewer.modelScale,
                                ) as SerializedVector3;
                                target = target.map(
                                    (value) => value / this.viewer.modelScale,
                                ) as SerializedVector3;
                            }
                            onUpdateCameraPosition({
                                target,
                                translation,
                            });
                        }, 250),
                    );
                }
                this.camera = camera;

                if (this.camera) {
                    new FxaaPostProcess("fxaa", 1, this.camera);
                }
            }
        });

        const lightDirArr: number[] =
            renderSettings?.directionalLightVector ??
            DEFAULT_RENDER_SETTINGS.directionalLightVector;
        let lightDirVec: Vector3 = new Vector3(...lightDirArr);
        if (renderSettings?.domeLightRotation != undefined) {
            lightDirVec = lightDirVec.applyRotationQuaternion(
                Quaternion.FromEulerAngles(
                    0,
                    -renderSettings.domeLightRotation * (Math.PI / 180) +
                        Math.PI / 2,
                    0,
                ),
            );
        }

        const light = new DirectionalLight("light", lightDirVec, this.scene);
        light.shadowMinZ = 0.07;
        light.shadowMaxZ = 3.5;

        light.intensity = 0;

        const shadowIntensity: number =
            renderSettings?.shadowIntensity ??
            DEFAULT_RENDER_SETTINGS.shadowIntensity;

        this.shadowGenerator = new ShadowGenerator(1024, light);
        this.shadowGenerator.forceBackFacesOnly = true;
        this.shadowGenerator.useContactHardeningShadow = true;
        this.shadowGenerator.contactHardeningLightSizeUVRatio = 0.05;
        this.shadowGenerator.enableSoftTransparentShadow = true;
        this.shadowGenerator.transparencyShadow = true;
        this.shadowGenerator.setDarkness(1.0 - shadowIntensity);
        this.scene.meshes.forEach((mesh) => {
            this.shadowGenerator?.getShadowMap()?.renderList?.push(mesh);
            mesh.receiveShadows = true;
        });

        const groundPlaneEnabled =
            renderSettings?.groundPlaneEnabled ??
            DEFAULT_RENDER_SETTINGS.groundPlaneEnabled;

        if (groundPlaneEnabled) {
            const ground = CreateDisc(
                "ground",
                {
                    radius: 50,
                },
                this.scene,
            );
            ground.rotation.x = Math.PI / 2;
            ground.material = new ShadowOnlyMaterial(
                "groundShadows",
                this.scene,
            );
            ground.receiveShadows = true;
            ground.isPickable = false;
        }
    }

    keyDownHandler = (e: KeyboardEvent) => {
        switch (e.key) {
            // Debug keys
            /*
            case "w":
                this.wireframeMode = !this.wireframeMode;
                break;
            */

            // User keys
            case "f":
                this.frame(this.options.aspectRatio ?? DEFAULT_ASPECT_RATIO);
                break;
        }
    };

    private _wireframeMode = false;

    /**
     * Enable hot keys for web viewer. Currently, this is simply "f" to frame the subject
     */
    public attachWindowListeners() {
        window.addEventListener("keydown", this.keyDownHandler);
    }

    /**
     * Disable hot keys for web viewer. Currently, this is simply "f" to frame the subject
     */
    public detachWindowListeners() {
        window.removeEventListener("keydown", this.keyDownHandler);
    }

    get wireframeMode() {
        return this._wireframeMode;
    }

    set wireframeMode(on: boolean) {
        this._wireframeMode = on;
        this.scene?.materials.forEach((mat) => {
            mat.wireframe = on;
        });
        this.viewer.renderLoop.toggle(100);
    }

    frame(aspectRatio: number) {
        if (this.camera) {
            // Defines fraction of the screen does the bounding box extend to. Since the bounding box is already
            // larger than the object, this will be set to 1 for now
            const screenFraction = 1.0;

            // The camera direction, reversed. We will travel along this ray from the scene center to reposition
            // the camera at the correct location
            const reversedDirection = this.camera.position
                .subtract(this.camera.target)
                .normalize();

            // Calculate the center point of the scene
            const sceneMax = this.modelBounds.max;
            const sceneMin = this.modelBounds.min;
            const sceneCenter = sceneMax.add(sceneMin).scale(0.5);

            // Get the rotation matrix for the camera, so we can transform the given axis aligned bounding box into
            // camera space. We will be changing the camera's position, so we don't want any translation (or scale).
            // We will use the inverse matrix to "un-rotate" the camera, and put everything else into camera space.
            const cameraRotationMatrix = this.camera
                .getWorldMatrix()
                .getRotationMatrix();
            const invertedCameraRotationMatrix: Matrix = new Matrix();
            cameraRotationMatrix.invertToRef(invertedCameraRotationMatrix);

            const bounds: Vector3[] = [];
            const sceneBounds = [sceneMin, sceneMax];
            for (let x = 0; x < 2; ++x) {
                for (let y = 0; y < 2; ++y) {
                    for (let z = 0; z < 2; ++z) {
                        const bound = new Vector3(
                            sceneBounds[x].x,
                            sceneBounds[y].y,
                            sceneBounds[z].z,
                        );
                        // Orient the bounding box relative to the camera
                        bounds.push(
                            Vector3.TransformCoordinates(
                                bound,
                                invertedCameraRotationMatrix,
                            ),
                        );
                    }
                }
            }

            const epsilon = 0.0001;

            // Find the min and max corners of the bounding box (in camera space), in both the X and Y dimensions
            let transformedSceneMinX = bounds[0].clone();
            let transformedSceneMaxX = bounds[0].clone();
            let transformedSceneMinY = bounds[0].clone();
            let transformedSceneMaxY = bounds[0].clone();
            for (let i = 1; i < bounds.length; ++i) {
                if (
                    // This bound is more left than the current one
                    bounds[i].x < transformedSceneMinX.x &&
                    // Floating point precision could mean two equivalent X values are slightly different, but we want
                    // the closer one (which will appear bigger in the projection). Ensure we don't accidentally chose
                    // the further one because it registers as slightly more left
                    (Math.abs(bounds[i].x - transformedSceneMinX.x) > epsilon ||
                        bounds[i].z > transformedSceneMinX.z)
                ) {
                    transformedSceneMinX = bounds[i];
                }
                if (
                    // This bound is more right than the current one
                    bounds[i].x > transformedSceneMaxX.x &&
                    // Choose the closer of two point with essentially equivalent X's
                    (Math.abs(bounds[i].x - transformedSceneMaxX.x) > epsilon ||
                        bounds[i].z > transformedSceneMaxX.z)
                ) {
                    transformedSceneMaxX = bounds[i];
                }

                if (
                    // This bound is further down than the current one
                    bounds[i].y < transformedSceneMinY.y &&
                    // Choose the closer of two point with essentially equivalent Y's
                    (Math.abs(bounds[i].y - transformedSceneMinY.y) > epsilon ||
                        bounds[i].z > transformedSceneMinY.z)
                ) {
                    transformedSceneMinY = bounds[i];
                }
                if (
                    // This bound is further up than the current one
                    bounds[i].y > transformedSceneMaxY.y &&
                    // Choose the closer of two point with essentially equivalent Y's
                    (Math.abs(bounds[i].y - transformedSceneMaxY.y) > epsilon ||
                        bounds[i].z > transformedSceneMaxY.z)
                ) {
                    transformedSceneMaxY = bounds[i];
                }
            }

            const boundingBoxSizeX =
                transformedSceneMaxX.x - transformedSceneMinX.x;
            const boundingBoxSizeY =
                transformedSceneMaxY.y - transformedSceneMinY.y;

            const boundingBoxAspectRatio = boundingBoxSizeX / boundingBoxSizeY;

            let closerBound = new Vector3();
            let boundingBoxSize = 0;
            if (boundingBoxAspectRatio > aspectRatio) {
                // The X axis should be the defining dimension, because the bounding box's width : height ratio is
                // greater than the screen's. The box is more wide relative to the screen.

                // Larger Z is closer
                closerBound =
                    transformedSceneMinX.z > transformedSceneMaxX.z
                        ? transformedSceneMinX
                        : transformedSceneMaxX;

                boundingBoxSize =
                    transformedSceneMaxX.x - transformedSceneMinX.x;
            } else {
                // The Y axis should be the defining dimension, because the bounding box's width : height ratio is
                // lesser than the screen's. The box is more tall relative to the screen.

                // Larger Z is closer
                closerBound =
                    transformedSceneMinY.z > transformedSceneMaxY.z
                        ? transformedSceneMinY
                        : transformedSceneMaxY;

                boundingBoxSize =
                    transformedSceneMaxY.y - transformedSceneMinY.y;
            }

            // If we bisect a cross section of the view frustum, we get a right triangle, where the angle from the
            // camera is fov / 2, the height is the screen's height / 2, and the width is the distance from the
            // camera to the bounding box corner, giving us:
            // tan(fov / 2) = (screenHeight / 2) / distance
            // =>   distance = screenHeight / (2 tan (fov/2))
            //
            // screenFraction * screenHeight = boundingBoxSize
            // =>   screenHeight = boundingBoxSize / screenFraction
            //
            // This above calculation assumes we are traveling from the plane perpendicular to the viewing ray in
            // which the bounding box corner is. Actually, we position the camera based on the bounding box center,
            // so we need to get the distance from the bounding box center to that plane, along the camera viewing
            // ray. In camera space, this viewing ray is the z axis, and the plane is perpendicular to this axis is
            // the xy plane, so we can simply find the difference in the z coordinate (in camera space) between the
            // scene center and the chosen bounding box corner. We then add this to the calculated distance/

            const additionalDistance =
                closerBound.z -
                Vector3.TransformCoordinates(
                    sceneCenter,
                    invertedCameraRotationMatrix,
                ).z;

            const distance =
                boundingBoxSize /
                    screenFraction /
                    2 /
                    Math.tan(this.camera.fov / 2) +
                additionalDistance;

            this.camera.target = sceneCenter;
            this.camera.setPosition(
                sceneCenter.add(reversedDirection.scale(distance)),
            );
        }
    }

    dispose() {
        this.detachWindowListeners();
    }
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionButton, Flex, View } from "@adobe/react-spectrum";
import { HeliosRoutes } from "@shared/common";
import BackAndroid from "@spectrum-icons/workflow/BackAndroid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    generatePath,
    matchPath,
    useLocation,
    useNavigate,
} from "react-router-dom";

import { HeaderLeftSide } from "./HeaderLeftSide";
import { HeaderRightSide } from "./HeaderRightSide";
import { SearchControl } from "./SearchControl";
import { useIsChromeless } from "../hooks/useIsChromeless";
import { useAccessContext } from "@src/contexts/AccessContext";
import { Routes } from "@src/routes";

import type { usePortal } from "../hooks/usePortal";

type Props = {
    setPortal: ReturnType<typeof usePortal>["setPortalDiv"];
};

export const AppHeader = ({ setPortal }: Props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { accessLevel } = useAccessContext();

    const { suppressHeader } = useIsChromeless();
    const {
        params: { searchValue = "" },
    } = matchPath(Routes.search.path, location.pathname) || {
        params: { searchValue: "" },
    };

    const matches = location.pathname.match("/review");
    const reviewOnly = matches?.length === 1;

    const [isEditMode, setIsEditMode] = useState(false);
    const [hasCustomUI, setHasCustomUI] = useState(false);
    const [isSearchPage, setIsSearchPage] = useState(false);

    useEffect(() => {
        if (!isSearchPage && matchPath(Routes.search.path, location.pathname)) {
            setIsSearchPage(true);
        } else if (
            isSearchPage &&
            !matchPath(Routes.search.path, location.pathname)
        ) {
            setIsSearchPage(false);
        }

        if (
            matchPath(Routes.modelEditor.path, location.pathname) ||
            matchPath(Routes.modelEditorPreview.path, location.pathname)
        ) {
            setIsEditMode(true);
            setHasCustomUI(true);
        } else if (
            matchPath(Routes.libraryDetailView.path, location.pathname) ||
            matchPath(Routes.projectOutputDetailView.path, location.pathname) ||
            matchPath(
                Routes.limitedProjectDetailReview.path,
                location.pathname,
            ) ||
            matchPath(Routes.limitedAssetReview.path, location.pathname)
        ) {
            setIsEditMode(false);
            setHasCustomUI(true);
        } else {
            setIsEditMode(false);
            setHasCustomUI(false);
        }
    }, [location.pathname, isSearchPage]);

    return (
        <View
            isHidden={suppressHeader}
            zIndex={2}
            position="relative"
            width={{ base: "size-8000" }}>
            <View
                height="size-800"
                width="100%"
                zIndex={5}
                backgroundColor="gray-100"
                borderBottomColor="gray-50"
                borderBottomWidth="thick">
                <Flex
                    height="100%"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between">
                    {hasCustomUI ? (
                        <div ref={setPortal} style={{ width: "100%" }} />
                    ) : (
                        <>
                            <HeaderLeftSide />
                            {accessLevel === "full" && (
                                <Flex>
                                    {isSearchPage && (
                                        <ActionButton
                                            aria-label={t("actions.back")}
                                            isQuiet
                                            onPress={() => {
                                                navigate(Routes.library.path);
                                            }}>
                                            <BackAndroid />
                                        </ActionButton>
                                    )}
                                    <SearchControl
                                        initialValue={searchValue}
                                        width={"size-5000"}
                                        placeholder={t("actions.searchAssets")}
                                        searchHistory
                                        blurOnSubmit
                                        onSubmit={(text) => {
                                            if (text) {
                                                navigate(
                                                    generatePath(
                                                        HeliosRoutes.search
                                                            .path,
                                                        {
                                                            searchValue: text,
                                                        },
                                                    ),
                                                );
                                            }
                                        }}
                                    />
                                </Flex>
                            )}
                        </>
                    )}

                    {!isEditMode && (
                        <HeaderRightSide isReviewOnly={reviewOnly} />
                    )}
                </Flex>
            </View>
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionButton, Text } from "@adobe/react-spectrum";
import Deselect from "@spectrum-icons/workflow/Deselect";
import Selection from "@spectrum-icons/workflow/Selection";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { FC } from "react";

interface Props {
    all2DShotIds: Set<string>;
    selectedRenders: string[];
    setSelectedRenders: (renders: string[]) => void;
}

export const SelectionTrigger: FC<Props> = ({
    all2DShotIds,
    selectedRenders,
    setSelectedRenders,
}) => {
    const { t } = useTranslation(["studio"]);

    const areAll2DShotsSelected = useMemo(() => {
        if (all2DShotIds.size !== selectedRenders.length) {
            return false;
        }
        const rendersSet = new Set(selectedRenders);
        return [...all2DShotIds].every((shot) => rendersSet.has(shot));
    }, [all2DShotIds, selectedRenders]);

    return areAll2DShotsSelected ? (
        <ActionButton onPress={() => setSelectedRenders([])}>
            <Deselect />
            <Text>{t("studio:actions.deselectAll")}</Text>
        </ActionButton>
    ) : (
        <ActionButton onPress={() => setSelectedRenders([...all2DShotIds])}>
            <Selection />
            <Text>{t("studio:actions.selectAll")}</Text>
        </ActionButton>
    );
};

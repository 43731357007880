/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, View } from "@adobe/react-spectrum";

import type { FC } from "react";

interface Props {
    name: string;
}

export const DetailReviewHeader: FC<Props> = ({ name }) => {
    return (
        <View
            width="100%"
            height="size-800"
            backgroundColor="gray-100"
            borderBottomColor="gray-50"
            borderBottomWidth="thick">
            <Flex
                direction="row"
                position="relative"
                height="100%"
                justifyContent="start"
                alignItems="center">
                <Heading level={3} marginStart={"size-250"}>
                    {name}
                </Heading>
            </Flex>
        </View>
    );
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import React, { useContext, useEffect, useState } from "react";
import {
    Content,
    Flex,
    Item,
    Picker,
    ActionButton,
    ProgressCircle,
    ContextualHelp,
    Link,
    Heading,
} from "@adobe/react-spectrum";
import Add from "@spectrum-icons/workflow/Add";
import "./TaskDetails.css";
import { convertDateToFormat } from "../utils/convertDateToFormat";
import { changeTaskStatus } from "../persistence/workfront/aggregations/changeTaskStatus";
import { TaskDetailsProps } from "./types";
import { CreateSubTaskForm } from "./CreateSubTaskForm";
import { refetchData, useFetchSubTasksByParentTaskId } from "../queries";
import { TaskList } from "../TaskList";
import { QueryObserverResult, RefetchOptions } from "react-query";
import { TasksAssignedToMeWithSingleProject } from "../types/TasksAssignedToMeWithSingleProject";
import { SharedContext } from "../context";
import { OwnerDetails } from "../OwnerDetails";

export const TaskDetails = (props: TaskDetailsProps) => {
    const dateNotSet = "N/A";
    const { editorState } = useContext(SharedContext);

    const {
        task: {
            name,
            description,
            plannedCompletionDate,
            status,
            assignedTo,
            ID,
            previewUrl,
            publishUrl,
            contentFragment,
            projectName,
            projectId,
            documents,
        },
        imsToken,
        setUpdateStatus,
        setIsRefetching,
        setTaskView,
        wfInstanceUrl,
        statusData,
        isRefetching,
    } = props;

    const completionDate = plannedCompletionDate
        ? convertDateToFormat(plannedCompletionDate)
        : dateNotSet;

    const setTaskStatus = async (selected: any) => {
        setIsRefetching(true);
        await changeTaskStatus({ imsToken, wfInstanceUrl }, ID, selected);
        setUpdateStatus(true);
    };

    const {
        data,
        status: subTaskStatus,
        refetch,
    } = useFetchSubTasksByParentTaskId({ imsToken, wfInstanceUrl }, ID);

    const [showSubTaskForm, setShowSubTaskForm] = useState(false);
    const toggleChecked = () => setShowSubTaskForm((value) => !value);

    const [fetchDataTrigger, setFetchDataTrigger] = React.useState(false);
    const [isSubtaskRefetching, setSubtaskIsRefetching] = React.useState(false);

    useEffect(() => {
        if (fetchDataTrigger) {
            refetchData(
                refetch as (
                    options?: RefetchOptions | undefined,
                ) => Promise<QueryObserverResult<TasksAssignedToMeWithSingleProject, unknown>>,
                setSubtaskIsRefetching,
            );
            setFetchDataTrigger(false);
        }
    }, [fetchDataTrigger]);

    return (
        !isRefetching && (
            <>
                <Content UNSAFE_className="task-details-section task-name">{name}</Content>
                <Content UNSAFE_className="task-details-section">
                    <Content UNSAFE_className="label">Assignment details</Content>
                    <Content><p dangerouslySetInnerHTML={{__html: description.replace(/\n/g, "<br />")}}></p></Content>
                </Content>
                <Flex wrap="wrap" UNSAFE_className="task-details-section">
                    <Flex width="50%" direction="column">
                        <Content UNSAFE_className="label">Due date</Content>
                        <Content marginTop="5px">{completionDate}</Content>
                    </Flex>
                    <Flex width="50%" direction="column">
                        <Content UNSAFE_className="label">Status</Content>
                        <Picker
                            aria-label="Status"
                            selectedKey={status.label}
                            UNSAFE_className="status-picker"
                            onSelectionChange={(selected) =>
                                statusData.filter(
                                    (item) => item.label === selected && setTaskStatus(item.value),
                                )
                            }
                        >
                            {statusData.map((item) => (
                                <Item key={item.label} textValue={item.value} hasChildItems={true}>
                                    <Content
                                        UNSAFE_className={"status-light--custom"}
                                        UNSAFE_style={{ backgroundColor: `#${item.color}` }}
                                    >
                                        <></>
                                    </Content>
                                    <Content UNSAFE_className="status-option">{item.label}</Content>
                                </Item>
                            ))}
                        </Picker>
                    </Flex>
                </Flex>
                <Content UNSAFE_className="task-details-section">
                    <Content UNSAFE_className="label">Project</Content>
                    <Content>{projectName}</Content>
                </Content>
                {documents?.length ? (
                    <Content UNSAFE_className="task-details-section">
                        <Content UNSAFE_className="label">Documents</Content>
                        <Content>
                            {documents.length}{" "}
                            <ContextualHelp variant="info">
                                <Heading>Workfront Documents</Heading>
                                <Content>
                                    {documents.map((document) => (
                                        <Link
                                            key={document.name}
                                            UNSAFE_style={{ display: "block" }}
                                            href={document.url}
                                            target="_blank"
                                        >
                                            {document.name}
                                        </Link>
                                    ))}
                                </Content>
                            </ContextualHelp>
                        </Content>
                    </Content>
                ) : null}
                <Content UNSAFE_className="task-details-section">
                    <OwnerDetails owner={assignedTo} />
                </Content>
                <Flex UNSAFE_className="task-details-section">
                    <Flex flex="1 1 50%" UNSAFE_className="task-name">
                        Subtasks
                    </Flex>
                    <Flex flex="1 1 50%" UNSAFE_className="task-name" direction="row-reverse">
                        <ActionButton
                            onPress={() => {
                                toggleChecked();
                            }}
                        >
                            <Add />
                        </ActionButton>
                    </Flex>
                </Flex>
                <Content UNSAFE_className="task-details-section">
                    <CreateSubTaskForm
                        imsToken={imsToken}
                        ID={ID}
                        wfInstanceUrl={wfInstanceUrl}
                        projectID={projectId}
                        showSubTaskForm={showSubTaskForm}
                        setSubtaskIsRefetching={setSubtaskIsRefetching}
                        setFetchDataTrigger={setFetchDataTrigger}
                        toggleChecked={toggleChecked}
                    />
                </Content>
                <Content UNSAFE_className="task-details-section">
                    {(subTaskStatus === "loading" || isSubtaskRefetching) && (
                        <Flex justifyContent="center" width="100%">
                            {
                                <ProgressCircle
                                    aria-label="Loading…"
                                    isIndeterminate={true}
                                    alignSelf="center"
                                />
                            }
                        </Flex>
                    )}
                    {subTaskStatus === "success" && data && !isSubtaskRefetching && (
                        <TaskList tasks={data.subTasks} setTaskView={setTaskView} />
                    )}
                </Content>
            </>
        )
    );
};

export const MemoizedTaskDetails = React.memo(TaskDetails);

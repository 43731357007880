/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Item, ListView } from "@adobe/react-spectrum";
import { PropsWithChildren } from "react";

import { AssetInputDialogGrid } from "./AssetInputDialogGrid";

import type { ReactSpectrumMapKey } from "./AssetInputDialog";
import type { CardItem } from "./AssetInputDialogGrid";
import type { Selection, SelectionMode } from "@react-types/shared";

interface Props {
    listItems: ReactSpectrumMapKey[];
    selectedListKeys: Selection;
    setSelectedListKeys: (selection: Selection) => void;
    gridItems: CardItem[];
    selectedGridKeys: Selection;
    setSelectedGridKeys: (selection: Selection) => void;
    gridSelectionMode: SelectionMode;
    dataUiaGrid: string;
    isDisabled: boolean;
    isAsset: boolean;
    onLoadMore?: () => any;
}

export function AssetInputDialogListGrid({
    children,
    listItems,
    selectedListKeys,
    setSelectedListKeys,
    gridItems,
    selectedGridKeys,
    setSelectedGridKeys,
    gridSelectionMode,
    dataUiaGrid,
    isDisabled,
    isAsset,
    onLoadMore,
}: PropsWithChildren<Props>) {
    return (
        <Flex>
            <Flex direction="column" width="30%">
                {/* left pane */}
                {children}
                <ListView
                    selectionMode="single"
                    width="100%"
                    items={listItems}
                    aria-label="Asset Selection Categories"
                    selectedKeys={selectedListKeys}
                    onSelectionChange={setSelectedListKeys}
                    disallowEmptySelection
                    selectionStyle="highlight"
                    isQuiet
                    disabledKeys={
                        isDisabled
                            ? listItems.map((item) => item.label)
                            : undefined
                    }>
                    {(item: ReactSpectrumMapKey) => (
                        <Item key={item.label}>{item.label}</Item>
                    )}
                </ListView>
            </Flex>
            <Flex width="70%">
                <AssetInputDialogGrid
                    data-uia={dataUiaGrid}
                    items={gridItems}
                    selectedKeys={selectedGridKeys}
                    setSelectedKeys={setSelectedGridKeys}
                    selectionMode={gridSelectionMode}
                    isDisabled={isDisabled}
                    isAsset={isAsset}
                    onLoadMore={onLoadMore}
                />
            </Flex>
        </Flex>
    );
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ComponentLoader } from "@ccx-public/component-loader";

import { ConnectedService } from "./ConnectedService";
import { config } from "@src/config";
import type {
    AnalyticsData,
    AnnotatingChangePayload,
    AnnotationTypesEnabled,
    CCACImsData,
    CommentInteractionPayload,
    CommentsAssetData,
    CommentsEnvironment,
    CommentsProviderProps,
    CommentSupplementalData,
    GroupBy,
    ShareSheetAnalyticsDataEvent,
    ShareSheetAnalyticsDataSource,
    ShareSheetAsset,
    ShareSheetAssetData,
    ShareSheetProviderProps,
    ShareStatus,
    StringKeyedObject,
    ValidNode,
    WizardDataFormat,
} from "@src/types/ccac/CCACData";
import {
    SHARE_SHEET_SHARE_ID,
    ACE_ANALYTICS_PLATFORMS,
    IMS_SERVICE_ACCOUNT_CODE,
    IMS_SERVICE_LEVEL,
} from "@src/types/ccac/CCACData";

import type { HeliosLogger } from "./HeliosLogger";
import type { ImsUser } from "./ims";
import type { ShareSheet, Commenting, Env } from "@ccx-public/component-loader";

/**
 * ACE component delivery for web:
 * https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=assetscollab&title=Component+Delivery+for+Web
 */

/**
 * Invitation API versions.
 * Require V4 to update link settings directly in the share dialog.
 */
const INVITATION_API_VERSION4 = "V4";

/** Initial imsData */
const BlankImsData = { id: "", token: "" };

const COMMENTING_COMPONENT_NAME = "Commenting";
const COMMENTING_VERSION = "30.13.0";
const SHARE_SHEET_COMPONENT_NAME = "ShareSheet";
const SHARE_SHEET_VERSION = "30.14.0";

/**
 * Default share sheet dialog feature configuration.
 */
const SHARE_SHEET_FEATURE_CONFIG = {
    analyticsEnabled: false,
    dialogId: SHARE_SHEET_SHARE_ID,
    invitationAPIVersion: INVITATION_API_VERSION4,
    theme: "darkest",
};

const SHARE_SHEET_ANALYTICS_DATA = {
    event: {
        // Currently, there doesn't seem to be consensus on how to set this.
        // One recommendation is to use the Machine ID.
        // This needs to be a stable ID to use in future sessions and needs to be
        // reliable to detect the device.
        // Passing an empty string for now since the actual analytics collection is disabled.
        device_guid: "", // RFC 4122 - v4 compliant (TODO: Verify)
        category: "WEB",
    } as ShareSheetAnalyticsDataEvent,
    source: {
        client_id: config.imsClientId,
        name: config.appName,
        platform: ACE_ANALYTICS_PLATFORMS.web,
        version: SHARE_SHEET_VERSION,
    } as ShareSheetAnalyticsDataSource,
    ui: {
        view_type: config.appName,
    },
};

// Basic minimum required share-sheet asset data for initial load of the share-sheet component.
const BlankShareAssetData = {
    id: "",
    assetId: "",
    resourceId: "",
    resourceViewUrl: "",
    mimeType: "",
    name: "",
    type: "",
    useProductViewUrl: true,
};

const DefaultShareDialogConfig: ShareSheetProviderProps = {
    analyticsData: SHARE_SHEET_ANALYTICS_DATA,
    apiKey: config.imsClientId,
    assetData: BlankShareAssetData,
    idpClientId: config.imsClientId,
    imsData: BlankImsData,

    // Sunrise props that shouldn't change.
    analyticsEnabled: SHARE_SHEET_FEATURE_CONFIG.analyticsEnabled,
    environment: config.adobeEnv,
    inviteApiVersion: SHARE_SHEET_FEATURE_CONFIG.invitationAPIVersion,
    openedDialog: SHARE_SHEET_FEATURE_CONFIG.dialogId,
    theme: SHARE_SHEET_FEATURE_CONFIG.theme,
    themeType: "spectrum",
};

/**
 * Configurable share-sheet attributes we want to override.
 */
export interface ShareSheetConfigOverrides {
    analyticsData?: AnalyticsData; // We may need to update device_guid
    assetData: ShareSheetAssetData | ShareSheetAsset;

    // Optional props
    analyticsEnabled?: boolean;
    enableFacepile?: boolean; // for share dialog
    features?: StringKeyedObject; // "group"
    isAncestorShared?: boolean;
    locale?: string; // default is "en_US"
    showTour?: boolean;
    suppressEmailNotification?: boolean;
    theme?: string; // default is "light", type is always "spectrum"
    wizardData?: WizardDataFormat;
}

/** Supported annotation types */
const SupportedAnnotationTypes: AnnotationTypesEnabled = {
    pin: true,
    draw: true,
};

const COMMENTS_FEATURE_CONFIG = {
    spectrumUIScale: "medium",
    annotationsEnabled: true,
    annotationsMultiPageEnabled: true,
    annotationTypes: SupportedAnnotationTypes,
    groupBy: "node",
    enableEmojiPicker: true,
    enableReadUnread: false,
    mentionsEnabled: true,
    pollForUpdates: true,
};

export interface CommentingConfigOverrides {
    analyticsData?: AnalyticsData; // may need to update session_id
    annotationsEnabled?: boolean;
    annotationsMultiPageEnabled?: boolean;
    annotationTypes?: AnnotationTypesEnabled;
    compactLayout?: boolean;
    latestMobileEnabled?: boolean;
    disableNetworkIndicator?: boolean;
    globalComments?: boolean;
    globalCommentsUnmapped?: boolean;
    globalCommentsViewAll?: boolean;
    groupBy?: GroupBy;
    richTextEntry?: boolean;
    enableEmojiPicker?: boolean;
    theme?: string;
    locale?: string;
    hasReviewAccess?: boolean;
    spectrumUIScale?: string;
    reCaptchaEnabled?: boolean;
    disableCommentCreation?: boolean;
    pollForUpdates?: boolean;

    // asset data override
    assetData?: CommentsAssetData;

    // Callbacks
    onAnnotatingChange?: (payload: AnnotatingChangePayload) => void;
    onNodeChange?: (
        nodeId: string,
        _sectionId?: string,
        supplementalData?: CommentSupplementalData,
    ) => void;
    onCommentInteraction?: (
        payload: CommentInteractionPayload,
        supplementalData: CommentSupplementalData,
    ) => void;
}

export const COMMENTS_LIST_TAG_NAME = "cc-comments-list";

const COMMENTING_ANALYTICS_DATA = {
    event: {
        session_guid: "",
    },
    source: {
        client_id: config.imsClientId,
        name: config.appName,
        platform: ACE_ANALYTICS_PLATFORMS.web,
    },
    ui: {
        view_type: config.appName,
    },
};

type ShareSheetOptions = Parameters<ShareSheet.Init>[0];
type CommentingOptions = Parameters<Commenting.Init>[0];

const BlankAssetData = {
    id: "",
    ownerId: "",
    validNodes: [] as ValidNode[],
    shareStatus: "orgShared" as ShareStatus,
};

// Default configuration for the commenting component
const DefaultCommentingConfig: CommentsProviderProps = {
    analyticsData: COMMENTING_ANALYTICS_DATA,
    annotationsEnabled: COMMENTS_FEATURE_CONFIG.annotationsEnabled, // can be overridden
    annotationsMultiPageEnabled:
        COMMENTS_FEATURE_CONFIG.annotationsMultiPageEnabled,
    annotationTypes: COMMENTS_FEATURE_CONFIG.annotationTypes, // can be overridden
    appKey: config.imsClientId,
    appName: config.appName,
    assetData: BlankAssetData,
    enableEmojiPicker: COMMENTS_FEATURE_CONFIG.enableEmojiPicker,
    enableReadUnread: COMMENTS_FEATURE_CONFIG.enableReadUnread,
    environment: config.adobeEnv as CommentsEnvironment,
    groupBy: COMMENTS_FEATURE_CONFIG.groupBy as GroupBy,
    imsData: BlankImsData,
    mentionsEnabled: COMMENTS_FEATURE_CONFIG.mentionsEnabled,
    peopleGraphAPIKey: config.imsClientId,
    pollForUpdates: COMMENTS_FEATURE_CONFIG.pollForUpdates,
    spectrumUIScale: COMMENTS_FEATURE_CONFIG.spectrumUIScale,
    theme: "darkest",
};

export class ACE extends ConnectedService {
    private _loader!: ComponentLoader;
    private _imsUser: ImsUser;
    private _imsData: CCACImsData = BlankImsData;
    private _logger: HeliosLogger;

    private _commentsApi?: any;
    private _commentingLoaded = false;

    constructor(imsUser: ImsUser, logger: HeliosLogger) {
        super();
        this._logger = logger;
        this._imsUser = imsUser;
        this.connect();
    }

    private setupIMSData() {
        const profile = this._imsUser.userProfile;
        const accessToken = this._imsUser.accessToken;

        if (!profile || !accessToken) {
            this._imsUser.invalidateAuth();
            throw new Error("User profile or token is missing");
        }

        const imsData: CCACImsData = {
            id: profile.userId,
            token: accessToken,
        };

        // Retrieve account information.
        let serviceAccount;
        if (profile && accessToken) {
            serviceAccount = (profile.serviceAccounts || []).find(
                (service: { serviceCode: string }) =>
                    service.serviceCode === IMS_SERVICE_ACCOUNT_CODE,
            );
        }

        // Both share-sheet and commenting use the same IMS data setup.
        this._imsData = {
            ...imsData,
            countryCode: profile.countryCode,
            email: profile.email,
            name: profile.displayName,
            serviceCode:
                serviceAccount?.serviceCode ?? IMS_SERVICE_ACCOUNT_CODE,
            serviceLevel: serviceAccount?.serviceLevel ?? IMS_SERVICE_LEVEL,
        };
    }

    /** Loads the share-sheet component
     * @param options: configuration for loading the share-sheet component instance.
     * Once loaded, the custom UI element for the share dialog component,
     * cc-share-dialog is registered and can be rendered as a react component.
     * Note that cc-share-dialog is a Web Component wrapper around the React ShareDialog component.
     */
    public async loadShareSheet(overrides: ShareSheetConfigOverrides) {
        // cc-share-dialog is the renderable web component UI
        const options = {
            ...DefaultShareDialogConfig,
            ...overrides,
            imsData: this._imsData,
        };
        return this._loader.load(
            SHARE_SHEET_COMPONENT_NAME,
            SHARE_SHEET_VERSION,
            options as unknown as ShareSheetOptions,
        );
    }

    /** Update configuration for the commenting component
     * @param overrides: configuration for updating the cc-comment-list instance
     */
    public updateCommentingConfig(overrides: CommentingConfigOverrides) {
        if (!this._commentsApi || !this._commentingLoaded) {
            throw new Error(
                "Commenting API is not defined, is 'commenting' component loaded?",
            );
        }

        const options = {
            ...DefaultCommentingConfig,
            ...overrides,
            imsData: this._imsData,
        };
        this._commentsApi().updateConfig(options);
    }

    public get commentingComponentLoaded() {
        return this._commentingLoaded;
    }

    public get commentsApi() {
        if (!this._commentsApi || !this._commentingLoaded) {
            throw new Error(
                "Commenting API is not defined, is 'commenting' component loaded?",
            );
        }
        return this._commentsApi;
    }

    /**
     * Preload the commenting component with default configuration.
     * Once loaded, the custom UI elements for the commenting component,
     * cc-comments-list and cc-comments-overlays will be registered and available globally.
     * These custom elements can be rendered as react components.
     * The component loader will also return the comments API + a function to update cc-comments-list configuration:
     * https://git.corp.adobe.com/CCX/ccx-comments/blob/main/ONBOARDING.md#2-load-ccx-comments-via-component-loader
     */
    private async _preloadCommentingComponent() {
        const options = {
            ...DefaultCommentingConfig,
            imsData: this._imsData,
        };

        return this._loader
            .load(
                COMMENTING_COMPONENT_NAME,
                COMMENTING_VERSION,
                options as unknown as CommentingOptions,
            )
            .then((result) => {
                if (!result) {
                    this._logger.logError({
                        errorCode: "2190",
                    });
                }
                this._commentsApi = result;
                this._commentingLoaded = Boolean(
                    customElements.get("cc-comments-list"),
                );
            })
            .catch(() => {
                this._logger.logError({
                    errorCode: "2190",
                });

                throw new Error(
                    `Unable to load the commenting component version ${COMMENTING_VERSION}`,
                );
            });
    }

    async initialize() {
        await this._imsUser.connect();
        if (this._imsUser.accessToken === undefined) {
            this._logger.logError({
                errorCode: "2013",
            });
            throw new Error("Access token for user does not exist");
        }
        this.setupIMSData();

        this._loader = new ComponentLoader({
            cdnEnvironment: config.adobeEnv as Env,
        });
        await this._preloadCommentingComponent();
    }
}

import { useCachingContext } from "@src/contexts/CachingContext";
import { rpc } from "@src/contexts/RpcContext";
import { useState } from "react";

export function usePrecacheDetails(assetId: string) {
    const [requested, setRequested] = useState(false);
    const { preCacheUrlsOnIdle } = useCachingContext();
    const { client } = rpc.useUtils();

    function precacheDetails() {
        if (!requested) {
            client.libraryAssets.getOrthoRenders
                .query(assetId)
                .then((orthoRenders) => {
                    if (orthoRenders) {
                        const orthoRenderUrls = Object.values(orthoRenders)
                            .filter((render) => !!render.url)
                            .map((render) => {
                                return render.url as string;
                            });
                        preCacheUrlsOnIdle(orthoRenderUrls);
                    }
                });
            client.libraryAssets.getModelForViewer
                .query(assetId)
                .then((modelInfo) => {
                    if (modelInfo?.url) {
                        preCacheUrlsOnIdle([modelInfo.url]);
                    }
                });
            setRequested(true);
        }
    }

    return {
        precacheDetails,
    };
}

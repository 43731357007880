/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { pollForAdobeWebsocketUrl } from "@shared/client";
import { createWSClient, wsLink } from "@trpc/client";

import { config as HeliosConfig } from "@src/config";

import type { ImsUser } from "../services/ims";

const WS_API_ROUTE = "/api/helios/rpc/ws";

export function createTrpcClient(
    imsInstance: ImsUser,
    onOpen: () => void,
    onClose: (cause?: { code?: number }) => void,
) {
    const wsClient = createWSClient({
        url: async () => {
            await imsInstance.connect();
            const userToken = await imsInstance.getAccessToken();
            const userProfile = await imsInstance.getUserProfile();
            return pollForAdobeWebsocketUrl(
                WS_API_ROUTE,
                {
                    userToken,
                    orgId: userProfile.ownerOrg,
                },
                HeliosConfig,
            );
        },
        onOpen,
        onClose,
    });

    const link = wsLink({
        client: wsClient,
    });

    return {
        wsClient,
        wsLink: link,
    };
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { config as commonConfig } from "@shared/common";
// eslint-disable-next-line prefer-const
let { HERMES_API_HOST, IMS_KEY_HOST, SVC_ENV } = window.adbHls;
const imsKeyHost = IMS_KEY_HOST; // prod | nonprod
let apiHost = HERMES_API_HOST;
let apiProtocol = "https://";
let wsApiUrl = "";
const localBuildEnv = $LOCAL_BUILD_ENV;
const isProd = imsKeyHost === "prod";

SVC_ENV = SVC_ENV === "$SUBST_SVC_ENV" ? "local" : SVC_ENV;

// Local Build Env Overrides
if (localBuildEnv) {
    switch (localBuildEnv) {
        case "api-local":
            apiHost = "localhost:8050";
            apiProtocol = "http://";
            wsApiUrl = "ws://" + apiHost;
            break;
        case "api-dev":
            apiHost = "substance3d-dev-va6.adobe.io";
            break;
    }
}

// PR Branch Overrides
if (apiHost.match(/ethos\.adobe\.net$/)) {
    wsApiUrl = "wss://" + apiHost;
}

export const config = {
    env: SVC_ENV,
    adobeEnv: imsKeyHost === "prod" ? "prod" : "stage",
    appName: "Sunrise (Beta)",
    appVersion: "0.1",
    apsAppId: "Sunrise1",
    apsEndpoint: isProd
        ? "https://aps-web.adobe.io"
        : "https://aps-web-stage.adobe.io",
    developerReadmeUrl: isProd
        ? "https://www.adobe.com/go/developerreadme"
        : "https://www.stage.adobe.com/go/developerreadme",
    editProfileEndpoint: isProd
        ? "https://account.adobe.com/profile"
        : "https://stage.account.adobe.com/profile",
    // For Ingest values, see https://jira.corp.adobe.com/browse/ECOINT-8675
    ingestProjectKey: isProd ? "sunrise-web-service" : "sunrise-web-service",
    ingestApiKey: isProd ? "sunrise-web-service" : "sunrise-web-service",
    ingestEndpoint: isProd
        ? "https://cc-api-data.adobe.io/ingest"
        : "https://cc-api-data-stage.adobe.io/ingest",
    helpDocumentationUrl: isProd
        ? "https://www.adobe.com/go/sunrisedocumentationhome"
        : "https://www.stage.adobe.com/go/sunrisedocumentationhome",
    helpFAQUrl: isProd
        ? "https://www.adobe.com/go/sunrisedocumentationfaq"
        : "https://www.stage.adobe.com/go/sunrisedocumentationfaq",
    helpGetStartedUrl: isProd
        ? "https://www.adobe.com/go/Sunrisegetstarted"
        : "https://www.stage.adobe.com/go/Sunrisegetstarted",
    helpStarterAssetsUrl: isProd
        ? "https://www.adobe.com/go/sunrisestarterassets"
        : "https://www.stage.adobe.com/go/sunrisestarterassets",
    helpTutorialUrl: isProd
        ? "https://www.adobe.com/go/sunrisevideotutorial"
        : "https://www.stage.adobe.com/go/sunrisevideotutorial",
    hostAcp: isProd
        ? "https://platform-cs.adobe.io"
        : "https://platform-cs-stage.adobe.io",
    hostApi: apiProtocol + apiHost,
    wsApiUrl,
    hostCcApi: isProd
        ? "https://cc-api-storage.adobe.io"
        : "https://cc-api-storage-stage.adobe.io",
    hostHermes: apiHost,
    imsClientId: commonConfig.imsClientIdHelios,
    imsKeyHost,
    imsReferrerUrl: isProd
        ? "https://adobeid-na1.services.adobe.com/"
        : "https://adobeid-na1-stg1.services.adobe.com/",
    learnOptimizationUrl: isProd
        ? "https://www.adobe.com/go/sunriselearnmoreoptimization"
        : "https://www.stage.adobe.com/go/sunriselearnmoreoptimization",
    modelViewerScriptUrl:
        "https://ajax.googleapis.com/ajax/libs/model-viewer/3.2.0/model-viewer.min.js",
    sentryDSN:
        "https://965583179baeb6d9d845882c9f3b44c7@o4504299284332544.ingest.sentry.io/4506655125471233",
};

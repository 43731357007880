/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Icon, IconProps } from "@adobe/react-spectrum";

export function CloudActivity(props: Omit<IconProps, "children">) {

    return (
        <Icon {...props}>
            <svg
                id="e20kGxV4eYK1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18">
                <path
                    d="M1.88159,14L8,14h1.86147.13816C9.37194,13.1643,9,12.1256,9,11c0-2.74143,2.2063-4.96755,4.9401-4.99965-.1445-.78543-.5453-1.50732-1.1475-2.05014-.7235-.65218-1.6784-.99247-2.6548-.94601-.89255.03-1.75085.34826-2.44449.90644s-1.18471,1.32575-1.39861,2.18612c-.34483-.09655-.70139-.14563-1.05968-.14586-.83491-.01951-1.64364.28984-2.24932.86038s-.95903,1.35586-.98277,2.18421c-.02164.2654.00113.53252.06739.79054-.54238.05877-1.04664.30497-1.424519.69547s-.605285.9005-.642418,1.4406l-.00091.017c-.02623.5206.157011,1.0301.509414,1.4166s.845103.6183,1.369703.6443Z"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                />
                <g id="e20kGxV4eYK3">
                    <path
                        d="M14,15c2.2091,0,4-1.7909,4-4s-1.7909-4-4-4-4,1.79086-4,4s1.7909,4,4,4Zm0-2c1.1046,0,2-.8954,2-2s-.8954-2-2-2-2,.89543-2,2s.8954,2,2,2Z"
                        clip-rule="evenodd"
                        fill-rule="evenodd"
                    />
                    <path
                        d="M18,11c0-2.20914-1.7909-4-4-4v2c1.1046,0,2,.89543,2,2h2Z"
                        clip-rule="evenodd"
                        fill="#59a7f6"
                        fill-rule="evenodd"
                    />
                    <animateTransform
                      attributeName="transform"
                      begin="0s"
                      dur="2s"
                      type="rotate"
                      from="0 14 11"
                      to="360 14 11"
                      repeatCount="indefinite" />
                </g>
            </svg>
        </Icon>
    );
}

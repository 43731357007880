/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, } from "@adobe/react-spectrum";
import { PropsWithChildren } from "react";

import { useHover } from "../hooks/useHover";

const DefaultStyles = {
    borderRadius: "var(--spectrum-alias-border-radius-regular)",
    padding:
        "var(--spectrum-global-dimension-size-125, var(--spectrum-alias-size-125))",
};

export function ActivityItemWrapper({children}: PropsWithChildren) {

    const hover = useHover(
        { background: "var(--spectrum-gray-300)", ...DefaultStyles },
        DefaultStyles,
    );

    return (
        <div {...hover}>
            <Flex justifyContent="space-between" alignItems="center" gap="size-600">
                {children}
            </Flex>
        </div>
    );
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from "@react-spectrum/view";
import { useIntersectionObserver } from "usehooks-ts";

import { AssetCard } from "./AssetCard";
import { GRID_STYLE_SIZES } from "./AssetGridPaged";
import { useUserLibraryContext } from "../UserLibrary";
import type { LibraryAssetsFilters } from "@src/contexts/RpcContext";
import { rpc } from "@src/contexts/RpcContext";
import { CardGrid } from "@src/interfaces/common/components/CardGrid";

import type { MutableRefObject } from "react";

interface Props {
    offset: number;
    itemCount: number;
    observerRootRef: MutableRefObject<HTMLDivElement | null>;
}

export function AssetGridSection({
    offset,
    itemCount,
    observerRootRef,
}: Props) {
    const { sortDirection, sortField, filterKey, searchValue } =
        useUserLibraryContext();

    const { isIntersecting, ref } = useIntersectionObserver({
        root: observerRootRef.current,
        rootMargin: "50%",
    });

    const filteredAssets = rpc.libraryAssets.getAllListPaged.useQuery(
        {
            count: itemCount,
            offset,
            searchValue,
            filters:
                filterKey !== "all"
                    ? ([filterKey] as LibraryAssetsFilters)
                    : undefined,
            sortField,
            sortDirection,
        },
        {
            enabled: isIntersecting,
            staleTime: searchValue ? 0 : undefined,
        },
    );

    function renderPlaceHolders() {
        const placeholderCards: JSX.Element[] = [];
        for (let i = 0; i < itemCount; i++) {
            // 240 / 363 = 0.66115702479
            placeholderCards.push(
                <View
                    key={"placeholder-" + i}
                    borderColor="gray-200"
                    borderWidth="thin"
                    UNSAFE_style={{ aspectRatio: "0.66115702479" }}
                />,
            );
        }
        return placeholderCards;
    }

    return (
        <div key={`offset-${offset}`} ref={ref}>
            <CardGrid
                dataUia="library-assets"
                width={GRID_STYLE_SIZES.width.spectrumParam}
                gap={GRID_STYLE_SIZES.gap.spectrumParam}>
                {isIntersecting && filteredAssets.data
                    ? filteredAssets.data.items.map((assetListItem) => (
                          <AssetCard
                              key={assetListItem.id}
                              assetListItem={assetListItem}
                          />
                      ))
                    : renderPlaceHolders()}
            </CardGrid>
        </div>
    );
}

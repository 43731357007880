/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import { useState } from "react";
import { SetTaskView } from "../types/SetTaskView";

export interface UseActiveTask {
    isTaskActive: boolean;
    taskId: string;
    setTaskView: SetTaskView;
}

export const useActiveTask = (initialValue: boolean): UseActiveTask => {
    const [value, setValue] = useState<boolean>(initialValue);
    const [id, setId] = useState<string>("");

    const updateValueAndId = (isTaskActive: boolean, taskId: string) => {
        setValue(isTaskActive);
        setId(taskId);
    };

    return { isTaskActive: value, taskId: id, setTaskView: updateValueAndId };
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2022 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, View } from "@adobe/react-spectrum";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ProjectDetailViewNav } from "./ProjectDetailViewNav";
import { ProjectOutputDetailContent } from "./ProjectOutputDetailContent";
import type { DefaultPageProps } from "@src/app";
import { rpc } from "@src/contexts/RpcContext";
import { useUIStateContext } from "@src/contexts/UIStateContext";
import { useFormatShotCameraName } from "@src/interfaces/common/hooks/useFormatShotCameraName";

import type { FC } from "react";

export const ProjectOutputDetailView: FC<DefaultPageProps> = ({
    AppBarPortal,
}) => {
    const { projectId = "", shotId = "" } = useParams();
    const navigate = useNavigate();

    const backToProjectLibrary = () => {
        navigate(-1);
    };

    const { data: shot } = rpc.shots.getShot.useQuery(shotId);
    const { data: assetShots } = rpc.shots.getSiblingShots.useQuery({
        projectId,
        shotId,
    });

    const { projectDetailDisplaySize } = useUIStateContext();

    const is3D = shot?.type === "SHOT_3D";

    const { data: userProfile } =
        rpc.projects.getProjectWithOwner.useQuery(projectId);

    const { formatShotCameraName } = useFormatShotCameraName();
    const cameraName = useMemo(() => {
        return formatShotCameraName(shot?.camera, shot?.cameraName, shot?.type);
    }, [shot?.camera, shot?.type, shot?.cameraName]);

    return (
        <View height="100%" width="100%">
            {shot?.compositeId && assetShots && userProfile && (
                <View height="100%" backgroundColor="gray-75">
                    {AppBarPortal && (
                        <AppBarPortal>
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                                height="100%">
                                {shot && (
                                    <ProjectDetailViewNav
                                        projectId={projectId}
                                        shotId={shot.id}
                                        name={cameraName}
                                        is3D={is3D}
                                        showEditButton={true}
                                        onBackButtonClicked={
                                            backToProjectLibrary
                                        }
                                    />
                                )}
                            </Flex>
                        </AppBarPortal>
                    )}
                    <Flex
                        direction="row"
                        justifyContent="space-between"
                        height="100%">
                        <ProjectOutputDetailContent
                            projectId={projectId}
                            shotId={shot.id}
                            ownerId={userProfile.ownerUser}
                            displaySize={projectDetailDisplaySize}
                        />
                    </Flex>
                </View>
            )}
        </View>
    );
};

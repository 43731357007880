/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    DialogContainer,
    Content,
    Button,
    Heading,
    Image,
    Text,
    View,
    Flex,
} from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { ISSUE_TYPE_KEYS } from "./FeedbackForm";

import type { FC } from "react";

interface Props {
    showMessageType: string;
    onClose: () => void;
}

export const PostFeedbackMessage: FC<Props> = ({
    showMessageType,
    onClose,
}) => {
    const coverImage = "/assets/images/feedback-message-cover.png";
    const { t } = useTranslation();
    const titleString =
        showMessageType === ISSUE_TYPE_KEYS.bug
            ? t("feedback.message.bug.title")
            : t("feedback.message.feature.title");
    const messageString =
        showMessageType === ISSUE_TYPE_KEYS.bug
            ? t("feedback.message.bug.content")
            : t("feedback.message.feature.content");
    return (
        <DialogContainer onDismiss={onClose}>
            <Flex direction="column" width="size-6000">
                <Image src={coverImage} alt={t("feedback.message.alt.image")} />
                <View padding="size-400">
                    <Heading>{titleString}</Heading>
                    <Content marginTop="size-200">
                        <Text>{messageString}</Text>
                    </Content>
                </View>
                <Button
                    marginEnd="size-400"
                    marginBottom="size-400"
                    variant="accent"
                    alignSelf="end"
                    onPress={onClose}>
                    {t("actions.done")}
                </Button>
            </Flex>
        </DialogContainer>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { HealthCheckAttribute } from "@shared/types";
import { useMemo } from "react";

export const useFormatHealthCheckValue = (
    value: number,
    type?: HealthCheckAttribute,
) => {
    return useMemo(() => {
        if (isNaN(value) || value < 0) {
            return undefined;
        }
        const options = {
            notation: "compact",
            maximumFractionDigits: 0,
        } as Intl.NumberFormatOptions;
        if (type === HealthCheckAttribute.fileSize) {
            options.style = "unit";
            options.unit = "byte";
            options.unitDisplay = "narrow";
        }
        return `${new Intl.NumberFormat([], options).format(value)}`;
    }, [value, type]);
};

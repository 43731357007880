/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type React from "react";

type Bounds = {
    x: number;
    y: number;
    width: number;
    height: number;
};

export interface BoundedBoxOptions {
    offsetPadding?: number;
}

export function useBoundedPositioning(
    { offsetPadding }: BoundedBoxOptions,
    containerRef: React.RefObject<HTMLElement>,
    boxRef: HTMLElement | null,
) {
    const padding = offsetPadding || 10;

    const getBoundedPosition = ({
        x: sourceX,
        y: sourceY,
        width,
        height,
    }: Bounds) => {
        if (containerRef.current && boxRef) {
            const { width: containerWidth, height: containerHeight } =
                containerRef.current.getBoundingClientRect();
            const { width: targetWidth, height: targetHeight } =
                boxRef.getBoundingClientRect();

            if (
                sourceX + width - padding * 2 > containerWidth ||
                sourceY + height - padding * 2 > containerHeight ||
                sourceY < 0 - padding * 2 ||
                sourceX < 0 - padding * 2
            ) {
                return { x: 0, y: 0 };
            }

            // Try to place the target box to the right of the source box
            let x = sourceX + width + padding;
            // Try to center the target box with the source box
            let y = sourceY + (height - targetHeight) / 2;

            if (x + targetWidth > containerWidth) {
                // If the target box would overrun the container width, shift it to the left
                x = sourceX - padding - targetWidth;
            }

            if (y + targetHeight > containerHeight) {
                // if the target box would overrun the container height
                // position it's bottom up padding from the container bottom
                y = containerHeight - targetHeight - padding;
            }

            if (y < 0) {
                // If the target box would be positioned out of the top of the canvas
                // position it so that it's 1 padding from the top
                y = padding;
            }

            return { x, y };
        }
        return { x: 0, y: 0 };
    };

    return { getBoundedPosition };
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

// this feels unsound?
import { ArcRotateCameraPointersInput } from "@babylonjs/core/Cameras/Inputs/arcRotateCameraPointersInput";

import type {
    IPointerEvent,
    Nullable,
    PointerTouch,
} from "@babylonjs/core";
import type { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";

export type ToggleMode = "rotate" | "pan" | "dolly";
export const DEFAULT_TOGGLE_MODE: ToggleMode = "rotate";

// Factor is in zoom level per pixels
const DEFAULT_ZOOM_FACTOR = 1 / 150;

export class PanningArcRotateCameraPointersInput extends ArcRotateCameraPointersInput {
    private _overridePanClick = false;

    // When not null, we are in the process of zooming
    public zoomDragStart?: { y: number; radius: number };

    constructor(
        public camera: ArcRotateCamera,
        public toggleMode: ToggleMode,
        public zoomFactor = DEFAULT_ZOOM_FACTOR,
    ) {
        super();
    }

    /**
     * Called each time a new POINTERDOWN event occurs. Ie, for each button
     * press.
     *
     * This function has been modified from the BabylonJS implementation in this file:
     * core/src/Cameras/Inputs/arcRotateCameraPointersInput.ts
     *
     * We use this function to initiate the correct action based on the type of button click and the current setting
     *
     * @param event Defines the event to track
     */
    public override onButtonDown(event: IPointerEvent): void {
        // The default action is rotation, for the ArcRotateCamera
        if (this.toggleMode === "pan" || event.button != 0) {
            // Always pan on middle and right click, and on left click if the chosen mode is "pan"
            this._overridePanClick = true;
        } else if (this.toggleMode === "dolly" && event.button == 0) {
            // When the chosen mode is "dolly", left click should initiate a zoom
            this.zoomDragStart = {
                y: event.clientY,
                radius: this.camera.radius,
            };
        }
    }

    /**
     * Called each time a new POINTERUP event occurs. Ie, for each button
     * release.
     *
     * This function has been modified from the BabylonJS implementation in this file:
     * core/src/Cameras/Inputs/arcRotateCameraPointersInput.ts
     *
     * Note that this modification still calls the original function of the superclass
     */
    public override onButtonUp(_evt: IPointerEvent): void {
        super.onButtonUp(_evt);

        // Reset zoom and pan
        this.zoomDragStart = undefined;
        this._overridePanClick = false;
    }

    /**
     * Called on pointer POINTERMOVE event if only a single touch is active.
     *
     * This function has been modified from the BabylonJS implementation in this file:
     * core/src/Cameras/Inputs/arcRotateCameraPointersInput.ts
     *
     * We use this function to properly rotate, pan, or zoom, based on the toggle mode and the result of onButtonDown()
     *
     * @param point
     * @param offsetX
     * @param offsetY
     */
    public override onTouch(
        point: Nullable<PointerTouch>,
        offsetX: number,
        offsetY: number,
    ): void {
        if (
            this.panningSensibility !== 0 &&
            ((this._ctrlKey && this.camera._useCtrlForPanning) ||
                this._overridePanClick)
        ) {
            // _overridePanClick, as set by onButtonDown(), indicates we are panning
            this.camera.inertialPanningX += -offsetX / this.panningSensibility;
            this.camera.inertialPanningY += offsetY / this.panningSensibility;
        } else if (this.zoomDragStart) {
            // zoomDragStart being defined indicates we are zooming
            if (point) {
                const radiusDelta =
                    (point.y - this.zoomDragStart.y) * this.zoomFactor;
                this.camera.radius = this.zoomDragStart.radius + radiusDelta;
            }
        } else if (
            this.angularSensibilityX !== 0 &&
            this.angularSensibilityY !== 0
        ) {
            // this.toggleMode === "rotate"
            this.camera.inertialAlphaOffset -=
                offsetX / this.angularSensibilityX;
            this.camera.inertialBetaOffset -=
                offsetY / this.angularSensibilityY;
        }
    }
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Text } from "@adobe/react-spectrum";
import { HealthCheckAttribute, HealthCheckStatus } from "@shared/types";
import { useTranslation } from "react-i18next";

import { DetailsDisclosure } from "./DetailsDisclosure";
import { HealthCheckIcon } from "./HealthCheckIcon";
import { HealthCheckWell } from "./HealthCheckWell";
import { MessagesWell } from "./MessagesWell";
import { useFeatureFlagContext } from "@src/contexts/FeatureFlagContext";

import type { HealthCheckResults } from "@shared/types";
import type { FC } from "react";

interface Props {
    results: HealthCheckResults;
}

export const HealthCheckSection: FC<Props> = ({ results }) => {
    const { t } = useTranslation(["library"]);
    const { DFF_SHOW_HEALTH_CHECK_RESULTS } = useFeatureFlagContext();

    return (
        <MessagesWell
            type="info"
            title={t("detailView.messagesPanel.warning.title.healthCheck")}
            description={t(
                "detailView.messagesPanel.warning.description.healthCheck",
            )}>
            <DetailsDisclosure openByDefault={false} orientation="right">
                <Text marginStart="size-400">
                    {t("detailView.messagesPanel.tips")}
                </Text>
                <Flex
                    direction="column"
                    rowGap="size-150"
                    marginTop="size-100"
                    marginBottom="size-50">
                    {Object.values(HealthCheckAttribute).map((attribute) => {
                        const result = results[attribute];
                        const value = result.value ?? -1;
                        const checkFailed =
                            results.formatVersion === 1
                                ? result.status === HealthCheckStatus.fail
                                : result.status === HealthCheckStatus.warn;

                        return (
                            (checkFailed || DFF_SHOW_HEALTH_CHECK_RESULTS) && (
                                <HealthCheckWell
                                    key={`source-${attribute}`}
                                    attribute={attribute}
                                    value={value}
                                    heading={t(
                                        `detailView.detailsPanel.${attribute}`,
                                    )}
                                    text={t(
                                        `detailView.detailsPanel.${attribute}.warn`,
                                    )}
                                    status={
                                        DFF_SHOW_HEALTH_CHECK_RESULTS
                                            ? result?.status
                                            : undefined
                                    }>
                                    <HealthCheckIcon type={attribute} />
                                </HealthCheckWell>
                            )
                        );
                    })}
                </Flex>
            </DetailsDisclosure>
        </MessagesWell>
    );
};

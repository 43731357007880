/**
 *
 * This code is borrowed primarily from this utility
 * UpChunk[https://github.com/muxinc/upchunk]
 * Specifically from this file[https://github.com/muxinc/upchunk/blob/master/src/upchunk.ts]
 *
 **/

import type { AxiosRequestConfig, Canceler } from "axios";
import axios from "axios";
import EventEmitter from "eventemitter3";

const isNode = typeof window === "undefined";

const SUCCESSFUL_CHUNK_UPLOAD_CODES = [200, 201, 202, 204, 308];
const TEMPORARY_ERROR_CODES = [408, 502, 503, 504]; // These error codes imply a chunk may be retried

type EventName =
    | "attempt"
    | "attemptFailure"
    | "chunkSuccess"
    | "error"
    | "offline"
    | "online"
    | "progress"
    | "success"
    | "canceled";

type AllowedMethods = "PUT" | "POST" | "PATCH";

type File = {
    size: number;
    type: string;
    blob: Blob;
};

type Headers = AxiosRequestConfig["headers"];

export interface ChunkUploaderOptions {
    endpoint?: string | ((file?: File) => Promise<string>);
    endpointList?: string[];
    file: File;
    method?: AllowedMethods;
    headers?: Headers;
    maxFileSize?: number;
    chunkSize?: number;
    attempts?: number;
    delayBeforeAttempt?: number;
}

export interface ChunkUploadProgress {
    percent: number;
    totalBytes: number;
    completedBytes: number;
    currentChunk: number;
    totalChunks: number;
}

export class ChunkUploader {
    public endpoint?: string | string[] | ((file?: File) => Promise<string>);
    public endpointList?: string[];
    public file: File;
    public headers: Headers;
    public method: AllowedMethods;
    public chunkSize: number;
    public attempts: number;
    public delayBeforeAttempt: number;

    private chunk?: Blob;
    private chunkCount: number;
    private chunkByteSize: number;
    private maxFileBytes: number;
    private endpointValue!: string;
    private totalChunks: number;
    private attemptCount: number;
    private offline: boolean;
    private paused: boolean;
    private success: boolean;
    private canceler?: Canceler;

    private eventTarget: EventEmitter;

    constructor(options: ChunkUploaderOptions) {
        this.endpoint = options.endpoint;
        this.endpointList = options.endpointList;
        this.file = options.file;
        this.headers = options.headers || ({} as Headers);
        this.method = options.method || "PUT";
        this.chunkSize = options.chunkSize || 30720;
        this.attempts = options.attempts || 5;
        this.delayBeforeAttempt = options.delayBeforeAttempt || 1;

        this.maxFileBytes = (options.maxFileSize || 0) * 1024;
        this.chunkCount = 0;
        this.chunkByteSize = this.chunkSize * 1024;
        this.totalChunks = Math.ceil(this.file.size / this.chunkByteSize);
        this.attemptCount = 0;
        this.offline = false;
        this.paused = false;
        this.success = false;

        this.eventTarget = new EventEmitter();

        this.validateOptions();
        this.getEndpoint().then(() => this.sendChunks());

        // restart sync when back online
        // trigger events when offline/back online
        if (typeof globalThis.window !== "undefined") {
            globalThis.window.addEventListener("online", () => {
                if (!this.offline) {
                    return;
                }

                this.offline = false;
                this.dispatch("online");
                this.sendChunks();
            });

            globalThis.window.addEventListener("offline", () => {
                this.offline = true;
                this.dispatch("offline");
            });
        }
    }

    /**
     * Subscribe to an event
     */
    public on(eventName: EventName, fn: (...args: any) => void) {
        this.eventTarget.addListener(eventName, fn);
    }

    public abort() {
        this.pause();
        if (this.canceler) {
            this.canceler();
        }
        this.eventTarget.emit("canceled");
    }

    public pause() {
        this.paused = true;
    }

    public resume() {
        if (this.paused) {
            this.paused = false;

            this.sendChunks();
        }
    }

    /**
     * Dispatch an event
     */
    private dispatch(eventName: EventName, detail?: any) {
        this.eventTarget.emit(eventName, { detail });
    }

    /**
     * Validate options and throw errors if expectations are violated.
     */
    private validateOptions() {
        if (
            (!this.endpoint ||
                (typeof this.endpoint !== "function" &&
                    typeof this.endpoint !== "string")) &&
            !this.endpointList
        ) {
            throw new TypeError(
                "endpoint must be defined as a string or a function that returns a promise",
            );
        }
        if (this.endpointList && this.endpointList.length === 0) {
            throw new TypeError("endpointList must not be length 0");
        }
        if (!this.file.blob.slice) {
            throw new TypeError("file must be a File object");
        }
        if (this.headers && typeof this.headers !== "object") {
            throw new TypeError("headers must be null or an object");
        }
        if (
            this.chunkSize &&
            (typeof this.chunkSize !== "number" || this.chunkSize <= 0)
        ) {
            throw new TypeError(
                "chunkSize must be a positive number in multiples of 256",
            );
        }
        if (this.maxFileBytes > 0 && this.maxFileBytes < this.file.size) {
            throw new Error(
                `file size exceeds maximum (${this.file.size} > ${this.maxFileBytes})`,
            );
        }
        if (
            this.attempts &&
            (typeof this.attempts !== "number" || this.attempts <= 0)
        ) {
            throw new TypeError("retries must be a positive number");
        }
        if (
            this.delayBeforeAttempt &&
            (typeof this.delayBeforeAttempt !== "number" ||
                this.delayBeforeAttempt < 0)
        ) {
            throw new TypeError("delayBeforeAttempt must be a positive number");
        }
    }

    /**
     * Endpoint can either be a URL or a function that returns a promise that resolves to a string.
     */
    private getEndpoint() {
        if (typeof this.endpoint === "string") {
            this.endpointValue = this.endpoint;
            return Promise.resolve(this.endpoint);
        }

        if (typeof this.endpoint === "function") {
            return this.endpoint(this.file).then((value) => {
                this.endpointValue = value;
                return this.endpointValue;
            });
        }
        return Promise.resolve(undefined);
    }

    /**
     * Get portion of the file of x bytes corresponding to chunkSize
     */
    private getChunk() {
        return new Promise((resolve) => {
            // Since we start with 0-chunkSize for the range, we need to subtract 1.
            const length =
                this.totalChunks === 1 ? this.file.size : this.chunkByteSize;
            const start = length * this.chunkCount;

            this.chunk = this.file.blob.slice(
                start,
                start + length,
                "application/octet-stream",
            );
            resolve(this.chunk);
        });
    }

    private xhrPromise(options: AxiosRequestConfig) {
        return axios.request({
            ...options,
            cancelToken: new axios.CancelToken(
                (cancel) => (this.canceler = cancel),
            ),
            onUploadProgress: (event) => {
                this.updateProgress(event.loaded);
            },
        });
    }

    updateProgress(currentChunkBytesLoaded: number) {
        const completedBytes =
            currentChunkBytesLoaded + this.chunkCount * this.chunkByteSize;
        const totalBytes = this.file.size;
        const percent = Math.min((completedBytes / totalBytes) * 100, 100);

        const progress: ChunkUploadProgress = {
            percent,
            completedBytes,
            totalBytes,
            currentChunk: this.chunkCount,
            totalChunks: this.totalChunks,
        };

        this.dispatch("progress", progress);
    }

    /**
     * Send chunk of the file with appropriate headers
     */
    protected async sendChunk() {
        const headers: Headers = {
            ...this.headers,
            "Content-Type": this.file.type,
            "Content-Range": undefined,
        };

        if (this.file.size > this.chunkByteSize && !this.endpointList) {
            const rangeStart = this.chunkCount * this.chunkByteSize;
            const rangeEnd = rangeStart + (this.chunk?.size || 0) - 1;
            headers[
                "Content-Range"
            ] = `bytes ${rangeStart}-${rangeEnd}/${this.file.size}`;
        }

        this.dispatch("attempt", {
            chunkNumber: this.chunkCount,
            chunkSize: this.chunk?.size,
        });
        let data;
        if (isNode && this.chunk) {
            data = await this.chunk.arrayBuffer();
        } else {
            data = this.chunk;
        }
        return this.xhrPromise({
            headers,
            url: this.endpointList
                ? this.endpointList[this.chunkCount]
                : this.endpointValue,
            method: this.method,
            data,
        });
    }

    /**
     * Called on net failure. If retry counter !== 0, retry after delayBeforeAttempt
     */
    private manageRetries(err: unknown) {
        if (this.attemptCount < this.attempts) {
            setTimeout(() => this.sendChunks(), this.delayBeforeAttempt * 1000);
            this.dispatch("attemptFailure", {
                message: `An error occured uploading chunk ${
                    this.chunkCount
                }. ${this.attempts - this.attemptCount} retries left.`,
                chunkNumber: this.chunkCount,
                attemptsLeft: this.attempts - this.attemptCount,
                err,
            });
            return;
        }

        this.dispatch("error", {
            message: `An error occured uploading chunk ${this.chunkCount}. No more retries, stopping upload`,
            chunk: this.chunkCount,
            attempts: this.attemptCount,
        });
    }

    /**
     * Manage the whole upload by calling getChunk & sendChunk
     * handle errors & retries and dispatch events
     */
    private sendChunks() {
        if (this.paused || this.offline || this.success) {
            return;
        }

        this.getChunk()
            .then(() => {
                this.attemptCount = this.attemptCount + 1;

                return this.sendChunk();
            })
            .then((res) => {
                if (SUCCESSFUL_CHUNK_UPLOAD_CODES.includes(res.status)) {
                    this.dispatch("chunkSuccess", {
                        chunk: this.chunkCount,
                        attempts: this.attemptCount,
                        response: res,
                    });

                    this.attemptCount = 0;
                    this.chunkCount = this.chunkCount + 1;

                    if (this.chunkCount < this.totalChunks) {
                        this.sendChunks();
                    } else {
                        this.success = true;
                        this.dispatch("success");
                    }

                    this.updateProgress(0);
                } else if (TEMPORARY_ERROR_CODES.includes(res.status)) {
                    if (this.paused || this.offline) {
                        return;
                    }
                    this.manageRetries(undefined);
                } else {
                    if (this.paused || this.offline) {
                        return;
                    }

                    this.dispatch("error", {
                        message: `Server responded with ${res.status}. Stopping upload.`,
                        chunkNumber: this.chunkCount,
                        attempts: this.attemptCount,
                    });
                }
            })
            .catch((err) => {
                if (this.paused || this.offline) {
                    return;
                }

                // this type of error can happen after network disconnection on CORS setup
                this.manageRetries(err);
            });
    }
}

export const createUpload = (options: ChunkUploaderOptions) =>
    new ChunkUploader(options);

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, Text, View } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { FailedToLoadIcon } from "./FailedToLoadIcon";

import type { FC } from "react";

export const ViewerError: FC = () => {
    const { t } = useTranslation("common");
    return (
        <View height="100%" width="100%">
            <Flex
                height="100%"
                direction="column"
                alignItems="center"
                justifyContent="center"
                rowGap="size-50">
                <FailedToLoadIcon />
                <Heading level={2} marginTop="size-100">
                    {t("error.modelLoad")}
                </Heading>
                <Text UNSAFE_style={{ fontStyle: "italic" }}>
                    {t("error.modelLoad.description")}
                </Text>
            </Flex>
        </View>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

import type { CommentsAssetData } from "@src/types/ccac/CCACData";

/**
 * Hook for setting up ACE comments component
 * @param ownerId - The asset owner.
 * @param compositeId - The asset for the comments component.
 * @param requiresAnalytics - If true setup additional data for using analytics. Default is false.
 * @returns { imsData: CCACImsData, commentAssetData: CommentsAssetData,
 *            setCommentsAssetData: Dispatch<SetStateAction<CommentsAssetData | undefined>> }
 */
export const useCommentsAssetData = (
    ownerId?: string,
    compositeId?: string,
) => {
    const [commentsAssetData, setCommentsAssetData] =
        useState<CommentsAssetData>();

    useEffect(() => {
        if (compositeId && ownerId && commentsAssetData?.id !== compositeId) {
            const assetData: CommentsAssetData = {
                id: compositeId,
                ownerId,
                // Default to "orgShared" ShareStatus. This affects the @mention suggestions.
                // For "orgShared" & "public", all commenters are included, and only collaborators are included for "shared".
                shareStatus: "orgShared",
                // Nodes are the parts of our dcx that are commentable, for example we probably want to be able to comment
                // on the web-optimized asset part of our asset structure. Valid nodes are nodes that are currently available, because
                // it is possible for nodes to be removed. This will be updated in the Comments component.
                validNodes: [],
            };
            setCommentsAssetData(assetData);
        }
    }, [compositeId, ownerId, commentsAssetData?.id]);

    return { commentsAssetData, setCommentsAssetData };
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionButton, View } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { AssetInputDialog, AssetInputDialogState } from "./AssetInputDialog";
import { rpc } from "@src/contexts/RpcContext";

import type { AssetInputDialogProps } from "./AssetInputDialog";
import type { Key } from "@react-types/shared";
import type { TRPCClientError } from "@trpc/client";
import type { AnyRouter } from "@trpc/server";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import { useAnalyticsContext } from "@src/contexts/AnalyticsContext";

interface Props {
    projectId: string;
    canUserModify: boolean;
    hasSelectedRenders: boolean;
}

export const AddAssetsTrigger = ({
    canUserModify,
    projectId,
    hasSelectedRenders,
}: Props) => {
    const { t } = useTranslation(["studio"]);
    const { showToastForInvalidPermissions } = useMessagingContext();

    const addAssetsMutation = rpc.projects.addAssets.useMutation();
    const { data: existingAssets } =
        rpc.projects.getAssetIdsInProject.useQuery(projectId);
    const { logIngestEvent } = useAnalyticsContext();

    const handleAssetSubmit = async ({
        modelAssetIds: assetIds,
    }: Parameters<AssetInputDialogProps["onSubmit"]>[0]) => {
        if (!assetIds || !projectId) return;
        await addAssetsMutation
            .mutateAsync({
                id: projectId,
                assetIds,
            })
            .catch((e) => {
                if (
                    (e as TRPCClientError<AnyRouter>)?.data?.code ===
                    "FORBIDDEN"
                ) {
                    showToastForInvalidPermissions(true);
                } else {
                    throw e;
                }
            });
        logIngestEvent("editProjectAddAssets", {
            "event.context_guid": projectId,
        });
    };

    const { data: approved } =
        rpc.projects.isProjectApproved.useQuery(projectId);

    return (
        <AssetInputDialog
            dataUiaPrefix="projects-add-"
            triggerButton={
                <View>
                    <ActionButton
                        data-uia="projects-add-asset-btn"
                        isHidden={!canUserModify}
                        isDisabled={
                            !projectId || approved || hasSelectedRenders
                        }>
                        {t("actions.add.assets")}
                    </ActionButton>
                </View>
            }
            onSubmit={handleAssetSubmit}
            dialogStateSequence={[AssetInputDialogState.SelectAssets]}
            existingAssets={
                new Set<Key>(existingAssets?.map((asset) => asset.id))
            }
        />
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Icon } from "@adobe/react-spectrum";

import type { FC } from "react";

interface Props {
    size: "XXS" | "XS" | "S" | "M" | "L" | "XL" | "XXL";
}

export const DefaultAvatar: FC<Props> = ({ size }) => {
    return (
        <Icon size={size}>
            <svg
                id="_2__00cfff"
                data-name="2_#00cfff"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40">
                <g id="Layer_1" data-name="Layer 1">
                    <circle
                        id="Ellipse_89"
                        data-name="Ellipse 89"
                        cx="20"
                        cy="20"
                        r="20"
                        fill="#00cfff"
                    />
                    <g
                        id="Group_144"
                        data-name="Group 144"
                        transform="translate(2.56 0.504)"
                        opacity="0.25">
                        <path
                            id="Path_12948"
                            data-name="Path 12948"
                            // eslint-disable-next-line max-len
                            d="M38.532,30.841,25.988.806A20,20,0,0,1,38.532,30.841ZM4.1,30.089a19.991,19.991,0,0,0,16.6,10.192L10.268,15.547Z"
                            transform="translate(-4.096 -0.806)"
                            fill="#001d26"
                        />
                    </g>
                </g>
            </svg>
        </Icon>
    );
};

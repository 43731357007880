/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
    Button,
    Content,
    Flex,
    IllustratedMessage,
    View,
} from "@adobe/react-spectrum";
import Heading from "@react/react-spectrum/Heading";
import Unauthorized from "@spectrum-icons/illustrations/Unauthorized";
import { useTranslation } from "react-i18next";

import { useAppContext } from "@src/contexts/AppContext";

export function UnauthorizedPage() {
    const { ims } = useAppContext();
    const { t } = useTranslation("common");

    // Grouping the Content into a View tag to avoid having the Heading
    // showing under all of the Content.
    return (
        <Flex width="100%" height="100%" justifyContent="center">
            <IllustratedMessage>
                <Unauthorized />
                <Heading>{t("access.denied.title")}</Heading>
                <View>
                    <Content>{t("access.denied.body")}</Content>
                    <Content>
                        <Button
                            marginTop="size-200"
                            alignSelf="center"
                            variant="cta"
                            onPress={() => ims.logOut()}>
                            {t("actions.logout")}
                        </Button>
                    </Content>
                </View>
            </IllustratedMessage>
        </Flex>
    );
}

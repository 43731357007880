/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { HeliosRoutes } from "@shared/common";
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LegalNoticePopup } from "../components/LegalNoticePopup";
import { useAppContext } from "@src/contexts/AppContext";
import { useMessagingContext } from "@src/contexts/MessagingContext";
import { rpc } from "@src/contexts/RpcContext";

export function useTermsCheck() {
    const navigate = useNavigate();
    const { showModal } = useMessagingContext();
    const { rpcConnection } = useAppContext();

    const { data: didUserAcceptTerms } = rpc.users.didUserAcceptTerms.useQuery();
    const termsTimer = useRef<number>();

    useEffect(() => {
        termsTimer.current = window.setTimeout(() => {
            if (!rpcConnection.online) {
                navigate(HeliosRoutes.unavailable.path);
            }
        }, 10_000);
        return () => {
            if (termsTimer?.current) {
                clearTimeout(termsTimer.current);
            }
        };
    }, [rpcConnection.online]);

    useEffect(() => {
        if (didUserAcceptTerms === undefined) {
            return;
        }
        if (didUserAcceptTerms) {
            if (termsTimer?.current) {
                clearTimeout(termsTimer.current);
            }
        } else {
            showModal(<LegalNoticePopup />);
        }
    }, [didUserAcceptTerms]);

    return { didUserAcceptTerms };
}

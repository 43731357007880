/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, View } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { AssetTechInfoTable } from "./AssetTechInfoTable";
import type { LibraryAsset } from "@src/contexts/RpcContext";
import { useIsOptimized } from "@src/interfaces/detail/hooks/useIsOptimized";

import type { FC } from "react";

interface Props {
    asset: LibraryAsset;
}

export const AssetInfoSection: FC<Props> = ({ asset }) => {
    const { t } = useTranslation(["library"]);

    const { isOptimized } = useIsOptimized(asset);

    const originalResults = asset.modelMetaData.source?.healthCheckResults;
    const optimizedResults = asset.modelMetaData.web?.healthCheckResults;

    return (
        <View>
            <Heading level={4} marginTop="size-300">
                {t("detailView.detailsPanel.techInfo")}
            </Heading>
            <Flex
                direction="column"
                alignItems="start"
                gap="size-100"
                columnGap="size-50"
                width="100%">
                {originalResults && (
                    <AssetTechInfoTable
                        results={originalResults}
                        fileType={asset.modelMetaData.source?.fileExtension}
                    />
                )}
                {isOptimized && optimizedResults && (
                    <AssetTechInfoTable
                        results={optimizedResults}
                        fileType={asset.modelMetaData.web?.fileExtension}
                        isOptimized
                    />
                )}
            </Flex>
        </View>
    );
};

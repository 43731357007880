/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { AdobeIMS } from "@identity/imslib";
import { IEnvironment } from "@identity/imslib/adobe-id/IEnvironment";
import { TokenFields } from "@identity/imslib/token/TokenFields";
import { makeObservable, observable } from "mobx";

import { connected, ConnectedService } from "./ConnectedService";
import { config } from "@src/config";

import type { ITokenInformation } from "@identity/imslib/adobe-id/custom-types/CustomTypes";

const tokenRefreshExpireThreshold = 60 * 60 * 1000; // expires in 1 hour

export const imsConfig = {
    client_id: config.imsClientId,
    environment:
        config.imsKeyHost === "prod" ? IEnvironment.PROD : IEnvironment.STAGE,
    redirect_uri: location.href,
    scope: "ab.manage,pps.read,pps.search,AdobeID,openid,creative_cloud,additional_info.ownerOrg",
    locale: "en_US",
    useLocalStorage: false,
    autoValidateToken: true,
    api_parameters: {
        authorize: {
            el: true,
        },
    },
};

// These correspond to individual user, enterprise, business, federated.
type AccountType = "type1" | "type2" | "type2e" | "type3";
export interface UserProfile {
    account_type: AccountType;
    displayName: string;
    first_name: string;
    last_name: string;
    ownerOrg: string;
    userId: string;
    email: string;
    countryCode: string;
    serviceAccounts: { serviceCode: string; serviceLevel: string }[];
}

export type AuthState = "verifying" | "authorized" | "failed";

export type Logger = (event: string, data?: Record<string, any>) => void;
export class ImsUser extends ConnectedService {
    @observable
    accessToken?: string;
    @observable
    tokenExpiry?: Date;
    @observable
    userProfile?: UserProfile;
    @observable
    authState: AuthState = "verifying";
    @observable
    sid?: string;

    ims!: AdobeIMS;
    apiVerificationUrl?: string;
    logger!: Logger;

    constructor(apiVerificationUrl?: string, logger: Logger = () => {}) {
        super();
        makeObservable(this);
        this.apiVerificationUrl = apiVerificationUrl;
        this.logger = logger;
        this.ims = new AdobeIMS({
            ...imsConfig,
            onReady: async () => {
                if (this.ims.isSignedInUser()) {
                    // This has to happen before the refresh.
                    const { sid } =
                        this.ims.tokenService.getTokenFields() as TokenFields;
                    if (sid) {
                        this.sid = sid;
                    }
                    await this.ims.refreshToken();
                    await this.updateImsInfo();
                    await this.updateApiAccess();
                    this.setConnected();
                } else {
                    this.ims.signIn();
                }
            },
            onAccessTokenHasExpired: () => {
                console.error("Token Expired");
                this.invalidateAuth();
            },
            onError: (e) => {
                console.error("Error accessing IMS", e);
            },

            // Requiring these nulls isn't great issue logged here:
            // https://git.corp.adobe.com/IMS/imslib2.js/issues/259
            onAccessToken: null,
            onReauthAccessToken: null,
        });

        // @ts-ignore For developer convenience
        window.adobeIMS = this.ims;

        if (config.env === "local") {
            this.ims.enableLogging();
        }

        this.ims.initialize();

        window.addEventListener("focus", () => this.checkTokenExpiry());
        this.connect();
    }

    async initialize() {
        return new Promise<void>((res) => {
            const poll = () => {
                if (this.authState === "authorized") {
                    res();
                    return;
                }
                setTimeout(poll, 100);
            };
            poll();
        });
    }

    @connected
    async getAuthHeaders() {
        const updatedToken = await this.checkTokenExpiry();

        return {
            "Content-Type": "application/json",
            authorization: `Bearer ${
                updatedToken?.accessToken || this.accessToken
            }`,
            "x-api-key": imsConfig.client_id,
        };
    }

    async hasAPIAccess() {
        if (!this.apiVerificationUrl) {
            return true;
        }

        const response = await fetch(this.apiVerificationUrl, {
            method: "POST",
            headers: await this.getAuthHeaders(),
        });

        if (response.ok) {
            return true;
        } else if (response.status === 403 || response.status === 401) {
            return false;
        }

        this.logger("userAccessCheckFailed", {
            status: response.status,
            body: response.body,
        });


        console.error(
            "Error accessing api test during authorization check.",
            response.body,
        );

        return false;
    }

    @connected
    async getUserProfile() {
        // The userProfile is set during updateImsInfo after user signed-in.
        if (!this.userProfile) {
            throw new Error("IMS did not return userProfile");
        }
        return this.userProfile;
    }

    isType2Account() {
        if (!this.connected) {
            return;
        }
        if (!this.userProfile) {
            throw new Error("IMS did not return userProfile");
        }
        return (
            this.userProfile.account_type === "type2" ||
            this.userProfile.account_type === "type2e"
        );
    }

    async invalidateAuth() {
        this.accessToken = undefined;
        this.userProfile = undefined;
        this.tokenExpiry = undefined;
        this.ims.signOut();
    }

    @connected
    async getAccessToken() {
        await this.checkTokenExpiry();
        if (this.accessToken) {
            return this.accessToken;
        }
        this.invalidateAuth();
        throw new Error("Auth session no longer valid. Kicked user to login.");
    }

    logOut() {
        this.logger("userSignOut");
        this.ims.signOut();
    }

    private async updateImsInfo() {
        const accessTokenInfo = this.ims.getAccessToken();
        this.accessToken = accessTokenInfo?.token;
        this.tokenExpiry = accessTokenInfo?.expire;
        this.userProfile = await this.ims.getProfile();
    }

    private async updateApiAccess() {
        const allowed = await this.hasAPIAccess();
        if (allowed) {
            this.logger("userAuthorized");
            this.authState = "authorized";
        } else {
            this.logger("userNotInAllowList");
            this.authState = "failed";
        }
    }

    private async checkTokenExpiry() {
        let tokenIsValid = true;
        try {
            tokenIsValid = await this.ims.validateToken();
        } catch (e) {
            this.logger("Could not validate token, reauthenticate");
            this.ims.reAuthenticate();
        }
        if (!tokenIsValid) {
            this.logger("Token invalid, reauthenticate");
            this.ims.reAuthenticate();
        } else if (
            this.tokenExpiry &&
            this.tokenExpiry.getTime() - tokenRefreshExpireThreshold <
                Date.now()
        ) {
            this.logger("Token expired, updating token");
            try {
                const tokenInfo: ITokenInformation | null =
                    await this.ims.refreshToken();
                if (tokenInfo) {
                    this.tokenExpiry = tokenInfo.expire;
                    this.accessToken = tokenInfo.token;
                    return {
                        accessToken: tokenInfo.token,
                        tokenExpiry: tokenInfo.expire,
                    };
                } else {
                    this.ims.reAuthenticate();
                }
            } catch (e) {
                this.ims.reAuthenticate();
            }
        }
        return;
    }
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useSessionState } from "@shared/client";
import { debounce } from "lodash";
import { useEffect, useMemo } from "react";

import type { UIEvent, MutableRefObject } from "react";

export function useSavedScrollPosition(
    context: string,
    scrollDivRef: MutableRefObject<HTMLDivElement | null | undefined>,
) {
    const { sessionState: savedScrollPosition, setSessionState } =
        useSessionState(`saved-scroll-position-${context}`, 0);

    const handleScroll = useMemo(
        () =>
            debounce((event: UIEvent) => {
                const top = (event.target as HTMLDivElement).scrollTop;
                setSessionState(top);
            }, 500),
        [setSessionState, context],
    );

    useEffect(() => {
        scrollDivRef.current?.scrollTo({ top: savedScrollPosition });
    }, [scrollDivRef.current, context, savedScrollPosition]);

    return { handleScroll };
}

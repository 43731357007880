/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import React, { useContext } from "react";
import { Item, ComboBox } from "@adobe/react-spectrum";
import { useAsyncList } from "react-stately";

import { fetchTaskRecommendedAssigneesForNewSubTask } from "../../persistence/workfront/aggregations/fetchTaskRecommendedAssigneesForNewSubTask";
import { AsigneeForSubTaskProps } from "./types";
import { SharedContext } from "../../context";

export const AsigneeForSubTask = (props: AsigneeForSubTaskProps) => {
    const { imsToken, ID, setAssignedToId } = props;
    const { wfInstanceUrl } = useContext(SharedContext).configuration;

    interface Character {
        ID: string | null;
        name: string;
    }

    const list = useAsyncList<Character>({
        async load({ signal, cursor, filterText }) {
            const res = await fetchTaskRecommendedAssigneesForNewSubTask(
                { imsToken, wfInstanceUrl },
                ID,
                filterText,
            );
            return {
                items: res,
            };
        },
    });

    const onSelectionChange = (value: any) => {
        const foundItem = list.items.find((item) => item.name === value);
        const itemId = foundItem?.ID ?? "";
        setAssignedToId(itemId);
    };

    return (
        <ComboBox
            label="Asignee"
            items={list.items}
            inputValue={list.filterText}
            onInputChange={list.setFilterText}
            loadingState={list.loadingState}
            onLoadMore={list.loadMore}
            onSelectionChange={onSelectionChange}
            isRequired={true}
        >
            {(item) => <Item key={item.name}>{item.name}</Item>}
        </ComboBox>
    );
};

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { assetTemplate, projectTemplates } from "@shared/common";
import { useEffect } from "react";

import { useCachingContext } from "@src/contexts/CachingContext";

function iblFileNameToUrl(iblFilename: string) {
    return `/assets/ibl/${iblFilename}.env`;
}

export function useStudioAssetPrecache() {
    const { preCacheUrlsOnIdle } = useCachingContext();

    useEffect(() => {
        const iblUrls: string[] = [iblFileNameToUrl(assetTemplate.iblFilename)];
        iblUrls.push(
            ...Object.entries(projectTemplates).map((entry) => {
                return iblFileNameToUrl(entry[1].iblFilename);
            }),
        );

        preCacheUrlsOnIdle(iblUrls);
    }, []);
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useEffect, useState } from "react";

export function useDelayedDisplay(delayTime = 1000) {
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        const timer = window.setTimeout(() => {
            setDisplay(true);
        }, delayTime);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    return display
}

/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Flex, Heading, View } from "@adobe/react-spectrum";
import { useTranslation } from "react-i18next";

import { useUserLibraryContext } from "../UserLibrary";
import { LibraryAssetsFilters, rpc } from "@src/contexts/RpcContext";
import { useUIStateContext } from "@src/contexts/UIStateContext";

import type { FC } from "react";

const StyleDefaultThemeColor = "gray-900";

export const UserLibraryHeader: FC = () => {
    const { t } = useTranslation("library");
    const { assetFilterSelection } = useUIStateContext();
    const { searchValue, filterKey } = useUserLibraryContext();

    const filteredAssetsCount = rpc.libraryAssets.getCount.useQuery({
        searchValue,
        filters:
            filterKey !== "all"
                ? ([filterKey] as LibraryAssetsFilters)
                : undefined,
    });

    function getTitle() {
        if (searchValue) {
            return t("page.search.title", {
                searchValue,
                resultsCount: filteredAssetsCount.data || 0
            })
        }
        return assetFilterSelection
            ? t(`library:page.${assetFilterSelection}.title`)
            : "";
    }

    return (
        <View position="relative" backgroundColor="gray-75">
            <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                height="size-1000"
                marginStart="size-800"
                marginEnd="size-400">
                <Heading
                    level={2}
                    UNSAFE_style={{
                        color: StyleDefaultThemeColor,
                        fontWeight: 600,
                    }}>
                    {getTitle()}
                </Heading>
            </Flex>
        </View>
    );
};
